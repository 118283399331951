import React from 'react';

import { Loader } from 'components';

export const withLoading =
    <TValue extends { isLoading: boolean }>(Component: (props: TValue) => JSX.Element | null) =>
    (props: TValue) =>
        (
            <>
                {props.isLoading && <Loader />}
                <Component {...props} />
            </>
        );
