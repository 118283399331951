import { LanguageEnum } from '../../../enums/language.enum';
import { Category } from '../../../models/category';
import { AddTest } from '../../../models/test/add-test';
import { TestCategory } from '../../../models/test/test-category';
import { TestCategoryForm } from '../../../models/test/test-category-form';
import { TestFormData } from '../test-form/TestForm';

export const mapTestFormDataToStore = (data: TestFormData): AddTest => ({
    ...data,
    language: getLanguage(+data.language),
    maxQuantityAttempt: +data.quantityAttempt,
    maxQuantityMinutes: +data.quantityMinutes,
    lowestPoints: +data.lowestPoints || 1,
    middlePoints: +data.middlePoints || 2,
    highestPoints: +data.highestPoints || 3,
    testCategories: getTestCategories(data.category),
    minimumPercent: +data.minimumPercent || 0,
    highestPointsDescription: data.highestPointsDescription || '',
    lowestPointsDescription: data.lowestPointsDescription || '',
    middlePointsDescription: data.middlePointsDescription || '',
    testLanguage: +data.testLanguage,
});

const getTestCategories = (categories: TestCategoryForm[]): TestCategory[] =>
    categories
        .filter((x) => x.checked && x.quantitySelectedQuestions && +x.quantitySelectedQuestions > 0)
        .map((x) => ({
            categoryId: +x.id,
            quantityQuestions: +x.quantitySelectedQuestions,
        }));

const getLanguage = (language: LanguageEnum) =>
    language === LanguageEnum.English || language === LanguageEnum.Russian ? language : LanguageEnum.Russian;

export const mapToTestCategories = (categories: Category[], testCategories: TestCategory[]): TestCategoryForm[] =>
    categories
        .filter((x) => x.quantityQuestions > 0)
        .map((x) => {
            const category = testCategories.find((y) => y.categoryId === x.id);
            if (category) {
                return {
                    ...x,
                    checked: true,
                    quantitySelectedQuestions: category.quantityQuestions,
                };
            }

            return { ...x, checked: false, quantitySelectedQuestions: 0 };
        });

export const checkTestCategory = (categories: TestCategoryForm[], id: number) =>
    categories.map((c) => {
        if (c.id === id) {
            c = { ...c, checked: !c.checked };
            return c;
        }

        return c;
    });

export const setQuantitySelectedQuestions = (categories: TestCategoryForm[], id: number, value: number) =>
    categories.map((c) => {
        if (c.id === id) {
            c = { ...c, quantitySelectedQuestions: value };
        }

        return c;
    });
