import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useLocation } from 'react-router-dom';
import SelectDirection from '../../components/SelectDirection/SelectDirection';

import { actionCreators } from './store/actions';
import { QuestionsState } from './store/store';
import { ApplicationState } from 'store';
import {
    Container,
    Column,
    Title,
    Button,
    ModalWindow,
    EmptyListWarning,
    Option,
    AccordionWithActions,
    Accordion,
} from 'components';
import { connectComponent, withLoading } from 'utils';
import { ANSWERS_LETTERS } from './constants';
import { useQuery } from '../../hooks/useQuery';
import { QuestionFormData, QuestionInnerForm } from './question-form/QuestionInnerForm';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';

type QuestionsListProps = typeof actionCreators &
    QuestionsState & {
        selectedCategory: Option;
        selectionOptions: Option[];
    };

const QuestionsList = (props: QuestionsListProps) => {
    const {
        getQuestionsByCategory,
        loadCategories,
        setCategory,
        setDirection,
        setEditFormForQuestion,
        setCloseEditFormForQuestion,
        selectedDirectionId,
        questions,
        selectedCategory,
        selectionOptions,
        deleteQuestion,
        showHideOptions,
        showAllOptions,
        hideAllOptions,
        updateQuestionList,
        AddItemQuestionList,
        updateTempData,
        questionError,
        questionSuccess,
        selectedCategoryId,
        clearQuestionMessages,
        isAllExpanded,
        isLoading,
    } = props;

    const alert = useAlert();
    const [init, setInit] = useState(true);
    const { pathname } = useLocation();
    const { push } = useHistory();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [questionId, setQuestionId] = useState(-1);

    const handleModalClose = () => {
        setModalIsOpen(false);
    };

    const handleModalOpen = (id?: number): void => {
        if (id) {
            setModalIsOpen(true);
            setQuestionId(id);
        }
    };

    const handleExpandClick = (id?: number) => {
        if (id) {
            showHideOptions(id);
        }
    };

    const handleChangeAllExpanded = () => {
        if (!isAllExpanded) {
            showAllOptions(questions);
        } else {
            hideAllOptions(questions);
        }
    };

    const handleDeleteClick = () => {
        if (selectedCategoryId) {
            deleteQuestion(questionId, selectedCategoryId);
        }
        setModalIsOpen(false);
    };

    const handleEditClick = (id?: number) => {
        setEditFormForQuestion(id);
    };

    const handleAddQuestionClick = (): void => {
        AddItemQuestionList();
    };

    const selectCategoryHandleChange = (value: Option[]) => {
        setCategory(value[0].value as number);
    };

    const queryParams = useQuery();

    useEffect(() => {
        const urlCategoruId = +(queryParams.get('categoryId') as string);
        const urlDirectionId = +(queryParams.get('directionId') as string);
        if (urlCategoruId && urlDirectionId) {
            setDirection(urlDirectionId);
            setCategory(urlCategoruId);
            loadCategories(urlDirectionId);
        } else {
            loadCategories(selectedDirectionId);
        }
        setInit(false);
    }, []);

    useEffect(() => {
        if (!init) loadCategories(selectedDirectionId);
    }, [selectedDirectionId]);

    useEffect(() => {
        if (!init) getQuestionsByCategory(selectedCategoryId);
    }, [selectedCategoryId]);

    useEffect(() => {
        if (questionSuccess) {
            alert.success(questionSuccess);
        }
        if (questionError) {
            alert.error(questionError);
        }
        clearQuestionMessages();
    }, [alert, questionSuccess, clearQuestionMessages, questionError]);

    const cancelEdit = (index: number, id?: number) => {
        setCloseEditFormForQuestion(index, id);
    };
    const saveEdit = (model: QuestionFormData, index: number, id?: number) => {
        updateQuestionList(
            {
                categoryId: selectedCategoryId ?? 0,
                question: {
                    id,
                    title: model.questionTitle,
                    questionOptions: model.answers,
                },
            },
            index
        );
    };

    return (
        <Container>
            <Column columnSize="25">
                <Title titleText="Вопросы" />
            </Column>
            <Column columnSize="75" className="text-right">
                <div className="questions-list-buttons">
                    <Button buttonClass="primary" handleClick={() => push(`${pathname}/statistic`)}>
                        Статистика
                    </Button>
                    <Button buttonClass="primary" handleClick={() => push(`${pathname}/upload`)}>
                        Загрузить вопросы
                    </Button>
                    <Button buttonClass="primary" handleClick={handleAddQuestionClick}>
                        Создать вопрос
                    </Button>
                </div>
            </Column>
            <Column columnSize="50" className="border-box p-right-space-3x">
                <SelectDirection selectedDirectionId={selectedDirectionId} onChange={setDirection} />
            </Column>
            <Column columnSize="50" className="border-box p-left-space-3x">
                <AutoCompliteForm
                    placeholder="Не выбрано"
                    label="Выберите категорию"
                    dataArr={selectionOptions.map((r) => ({ id: r.value, name: r.label }))}
                    name="testAreaId"
                    id="testAreaId"
                    onChange={(e) => selectCategoryHandleChange([{ label: e?.name ?? '', value: e?.id ?? '' }])}
                    values={{
                        id: selectedCategory?.value ?? '',
                        name: selectedCategory?.label ?? '',
                    }}
                />
            </Column>
            <Column columnSize="100" className="top-space-10x">
                <Accordion
                    title={isAllExpanded ? 'Свернуть все ответы' : 'Развернуть все ответы'}
                    isExpanded={isAllExpanded}
                    handleExpand={handleChangeAllExpanded}
                />
            </Column>
            {questions.length && !isLoading ? (
                <Column columnSize="100">
                    <div className="question-name">Текст вопроса</div>
                    {questions.map((x, index) =>
                        !x.isEdit ? (
                            <AccordionWithActions
                                key={x.id ?? index}
                                isExpanded={!!x?.isExpanded}
                                title={x.title}
                                handleExpand={() => handleExpandClick(x.id)}
                                handleEditClick={() => handleEditClick(x.id)}
                                handleDeleteClick={() => handleModalOpen(x.id)}
                                additionalClasses="question-accordion"
                            >
                                {x.questionOptions.map((y, index) => (
                                    <div
                                        className={`question-accordion-item ${y.isRight ? 'right' : ''}`}
                                        key={`${y.id}-${index}`}
                                    >
                                        {`${ANSWERS_LETTERS[index]}. ${y.text}`}
                                    </div>
                                ))}
                            </AccordionWithActions>
                        ) : (
                            <QuestionInnerForm
                                key={x.id ?? index}
                                updateTempData={(v) => updateTempData(index, v)}
                                handleClickCancel={() => cancelEdit(index, x.id)}
                                questionForEdit={x.isEdit}
                                onSubmit={(r) => saveEdit(r, index, x.id)}
                            />
                        )
                    )}
                </Column>
            ) : (
                <EmptyListWarning />
            )}
            <ModalWindow
                isOpen={modalIsOpen}
                onCloseClick={handleModalClose}
                modalMessage="Вы уверены, что хотите удалить данный вопрос?"
                confirmButtonText="Удалить"
                cancelButtonText="Отмена"
                onConfirmAction={handleDeleteClick}
            />
        </Container>
    );
};

const mapStateToProps = ({ questions }: ApplicationState) => {
    const { selectedCategoryId, categories } = questions;
    const [firstCategory] = categories;
    const newSelectCategoryId = !selectedCategoryId && firstCategory ? firstCategory?.id : selectedCategoryId;
    const selectedCategory = categories.find((x) => x.id === newSelectCategoryId);

    return {
        ...questions,
        selectedCategoryId: newSelectCategoryId,
        selectionOptions: categories.map((x) => ({ label: x.name, value: x.id })),
        selectedCategory: {
            label: selectedCategory?.name,
            value: selectedCategory?.id,
        },
    };
};

export default connectComponent({
    mapStateToProps,
    actions: actionCreators,
    component: withLoading(QuestionsList),
});
