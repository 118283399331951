import api from 'api';
import { ResponsePagination } from 'models/response-pagination';
import { AddProductGroup, CheckModel, ProductGroup, UpdateProductGroup } from '../models/ProductGroupsModels';

export const addGroup = (model: AddProductGroup): Promise<{ data: ProductGroup }> => api.post('/ProductGroup/', model);
export const updateGroup = (model: UpdateProductGroup): Promise<{ data: ProductGroup }> =>
    api.put('/ProductGroup/', model);
export const deleteGroup = (ind: string): Promise<void> =>
    api.delete('/ProductGroup/', {
        params: {
            id: ind,
        },
    });
export const getGroups = (
    page: number,
    size: number,
    str?: string
): Promise<{ data: ResponsePagination<ProductGroup> }> =>
    api.get('/ProductGroup/', {
        params: {
            page,
            size,
            str,
        },
    });

export const checkBeforeDelete = (id: string): Promise<{ data: CheckModel }> =>
    api.get('/ProductGroup/Check', {
        params: {
            id,
        },
    });

export const addExcel = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return api.post<void>('ProductGroup/AddExcel', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
