import { withAlert } from 'react-alert';
import { connect, MapStateToPropsParam } from 'react-redux';

interface ConnectorProps<TValue, TStore, TActions> {
    mapStateToProps: MapStateToPropsParam<TStore, Record<string, unknown>, never>;
    actions: TActions;
    component: (props: TValue) => JSX.Element;
}

export const connectComponent = <TValue, TStore, TActions>(props: ConnectorProps<TValue, TStore, TActions>) =>
    withAlert()(connect(props.mapStateToProps, props.actions)(props.component as () => JSX.Element));
