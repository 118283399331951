export const MAX_TEST_NAME_LENGTH = 200;
export const MAX_TEST_TIME = 1440;
export const MIN_TEST_TIME = 1;
export const MAX_QUANTITY_ATTEMPTS = 1000;
export const MIN_QUANTITY_ATTEMPTS = 1;
export const MAX_ASSESSMENT_DESCRIPTION_LENGTH = 60;
export const MIN_ASSESSMENT_INPUT_VALUE = 1;
export const MIN_QUANTITY_SELECTED_QUESTION_IN_CATEGORY = 0;
export const MIN_PASSING_TEST_PERCENT = 0;
export const MAX_PASSING_TEST_PERCENT = 100;
export const MIN_MONTH_ENABLE_CERTIFICATE = 1;
