import React, { FC, useState } from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import {
    Button,
    Checkbox,
    Column,
    Container,
    EmptyListWarning,
    Grid,
    ModalWindow,
    Row,
    TextInput,
    Title,
} from 'components';
import { useUrlQueryParamsForGrid } from '../../../hooks/useUrlQueryParamsForGrid';
import { ApplicationState } from '../../../store';
import { UserAgreementsState } from './store/store';
import { actions } from './store/actions';
import { connectComponent, withLoading } from '../../../utils';
import { dateTimeFormat } from '../../../constants/date-format';

type UserAgreementsListProps = UserAgreementsState & typeof actions;

const UserAgreementsList: FC<UserAgreementsListProps> = ({ agreements, agreementsCount, getAgreements }) => {
    const {
        pagination,
        queryState: [query, setQuery],
    } = useUrlQueryParamsForGrid({
        total: agreementsCount,
        loadFn: getAgreements,
    });

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalText, setModalText] = useState('');

    const showAgreement = (text: string) => {
        setModalText(text);
        setModalIsOpen(true);
    };

    const getGridData = (): Row[] =>
        agreements.map((item) => ({
            userName: {
                additionalComponent: (
                    <>
                        <div>{item.userName}</div>
                        <div>{item.userEmail}</div>
                    </>
                ),
            },
            creationDate: {
                additionalComponent: moment(item.creationDate).format(dateTimeFormat),
            },
            personalAgreement: {
                align: 'center',
                additionalComponent: item.personalText && (
                    <Checkbox isChecked={item.personalAgreement} id={`personalAgreement-${item.userId}}`} isDisabled />
                ),
            },
            personalCheckBoxText: {
                additionalComponent: item.personalText && (
                    <Button
                        buttonClass="secondary"
                        pSize="small"
                        handleClick={() => showAgreement(item.personalCheckBoxText)}
                    >
                        Показать
                    </Button>
                ),
            },
            personalText: {
                additionalComponent: item.marketingText && (
                    <Button buttonClass="secondary" pSize="small" handleClick={() => showAgreement(item.marketingText)}>
                        Показать
                    </Button>
                ),
            },
            marketingAgreement: {
                align: 'center',
                additionalComponent: item.marketingText && (
                    <Checkbox
                        isChecked={item.marketingAgreement}
                        id={`marketingAgreement-${item.userId}}`}
                        isDisabled
                    />
                ),
            },
            marketingCheckBoxText: {
                additionalComponent: item.marketingText && (
                    <Button
                        buttonClass="secondary"
                        pSize="small"
                        handleClick={() => showAgreement(item.marketingCheckBoxText)}
                    >
                        Показать
                    </Button>
                ),
            },
        }));

    return (
        <Container>
            <ModalWindow
                isOpen={modalIsOpen}
                size={'big'}
                onCloseClick={() => setModalIsOpen(false)}
                cancelButtonText="Закрыть"
            >
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalText) }} />
            </ModalWindow>

            <Column columnSize="100">
                <Title titleText="Соглашения пользователей и сотрудников УЦ" />
            </Column>
            <Column columnSize="100">
                <TextInput
                    id="query"
                    value={query}
                    onChangeHandler={(e) => setQuery(e.currentTarget.value)}
                    label={'Поиск (email, id, имя)'}
                />
            </Column>
            <Column columnSize="100">
                {agreements.length ? (
                    <Grid rows={getGridData()} columns={columns} pagination={pagination} />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.userAgreements,
    actions,
    component: withLoading(UserAgreementsList),
});

const columns = {
    userName: {
        value: 'адрес электронной почты + имя',
        columnSize: '30',
    },
    creationDate: {
        value: 'дата и время принятия соглашений',
        columnSize: '10',
    },
    personalAgreement: {
        value: 'факт согласия с обязательным соглашением',
        columnSize: '5',
    },
    personalCheckBoxText: {
        value: 'текст согласия с обязательным соглашением',
        columnSize: '10',
    },
    personalText: {
        value: 'текст Privacy Policy',
        columnSize: '10',
    },
    marketingAgreement: {
        value: 'факт согласия с необязательным соглашением',
        columnSize: '5',
    },
    marketingCheckBoxText: {
        value: 'текст согласия с необязательным соглашением',
        columnSize: '10',
    },
};
