import { RouterTab } from '../models/router-tab';

export const agreementsRoutesTabs: RouterTab[] = [
    {
        path: 'users',
        title: 'Пользователи и сотрудники УЦ',
    },
    {
        path: 'partners',
        title: 'Партнеры',
    },
];
