import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'isomorphic-dompurify';

import { QuestionMulti } from './test-question/QuestionMulti';
import { QuestionOption as ServerQuestionOption, TestQuestion } from '../../store';
import { QuestionViewButtons } from './question-view-buttons/QuestionViewButtons';
import { QuestionOne } from './test-question/QuestionOne';

export const QUESTION_INPUT_NAME = 'selectedQuestionOptionId';

interface QuestionViewProps {
    questionTitle: string;
    questionOptions: ServerQuestionOption[];
    currentQuestion: TestQuestion;
    questionsWithoutAnswer: TestQuestion[];
    setIsQuit: () => void;
    finishTest: () => void;
    setAnswer: (passingTestQuestionId: number, answers: string[]) => void;
    switchToQuestion: (newQuestionId: number) => void;
    nextQuestion?: TestQuestion;
    prevQuestion?: TestQuestion;
}

export const QuestionView = (props: QuestionViewProps) => {
    const {
        questionOptions,
        questionTitle,
        setIsQuit,
        currentQuestion,
        finishTest,
        switchToQuestion,
        setAnswer,
        nextQuestion,
        prevQuestion,
        questionsWithoutAnswer,
    } = props;
    const { t } = useTranslation('user-test');
    const { handleSubmit, reset, watch } = useFormContext();

    const handleSubmitAnswer = async (answers: { [key: string]: string }) => {
        const keys = Object.keys(answers);
        const singleAnswer = !!answers[QUESTION_INPUT_NAME];
        const testAnswers = singleAnswer ? [answers[QUESTION_INPUT_NAME]] : keys.filter((k) => answers[k]);
        if (testAnswers.length && !questionAnswered()) {
            await setAnswer(+currentQuestion.id, testAnswers);
        }
        if (nextQuestion) {
            switchToQuestion(nextQuestion.id);
        }
    };

    useEffect(() => {
        reset();
    }, [currentQuestion]);

    const handleBack = () => {
        if (prevQuestion) {
            switchToQuestion(prevQuestion.id);
        }
    };

    const handleFinish = () => {
        finishTest();
        setIsQuit();
    };

    const questionAnswered = (): boolean => !!currentQuestion?.selectedQuestionOptionsId?.length;
    const isButtonDisabled = () =>
        currentQuestion.isMulti && currentQuestion.rightCount
            ? Object.values(watch()).filter((a) => a).length < currentQuestion.rightCount
            : false;

    return (
        <div className="block-test-body">
            <h2
                className="test-body-title"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(questionTitle || ''),
                }}
            />
            <p className="test-body-remark">
                {currentQuestion.rightCount === 1
                    ? t('choose-option-one')
                    : t('choose-option', { count: currentQuestion.rightCount })}
            </p>
            <form onSubmit={handleSubmit(handleSubmitAnswer)}>
                <div className="test-body-question">
                    {currentQuestion.isMulti ? (
                        <QuestionMulti options={questionOptions} disable={!isButtonDisabled()} />
                    ) : (
                        <QuestionOne options={questionOptions} />
                    )}
                </div>
                <QuestionViewButtons
                    isHavePrevQuestions={!!prevQuestion}
                    isLastQuestion={questionsWithoutAnswer.length <= 1}
                    handleBack={handleBack}
                    handleFinish={handleFinish}
                    isDisabled={isButtonDisabled()}
                />
            </form>
        </div>
    );
};
