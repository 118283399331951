import api from '../api';
import { StudyGroupTestCondition, StudyGroupTestConditionPassed } from '../models/study-group-test-condition';
import { GetTrainingGroupParams } from '../models/study-group/get-training-group-params';
import { TrainingGroup } from '../models/training-group/training-group';
import { AddTrainingGroup } from '../models/training-group/add-training-group';
import { UpdateTrainingGroup } from '../models/training-group/update-training-group';

export class TrainingGroupService {
    static getList = (params: GetTrainingGroupParams) => api.get<TrainingGroup[]>(`TrainingGroup`, { params });

    static getById = (id: number) => api.get<TrainingGroup>(`TrainingGroup/${id}`);

    static add = (model: AddTrainingGroup) => api.post<number>('TrainingGroup', model);

    static update = (model: UpdateTrainingGroup) => api.put<void>('TrainingGroup', model);

    static delete = (id: number) => api.delete<boolean>(`TrainingGroup/${id}`);

    static getByStudyCenterId = (id: number) =>
        api.get<TrainingGroup[]>(`AdminStudyCenter/GetTrainingGroupsByStudyCenterId/${id}`);

    static getConditionsByCode = (code: string, skipCheckCert = false, zipCoded = false) =>
        api.get<StudyGroupTestCondition | StudyGroupTestConditionPassed>(
            `TrainingGroupCode/GetConditions/${code}/${skipCheckCert.toString()}/${zipCoded.toString()}`
        );
}
