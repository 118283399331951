import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '../Accordion';
import { CategoryResult } from './DetailsModal';

type AttemptAccordionProps = CategoryResult;

export const DetailsModalAccordion = (props: AttemptAccordionProps) => {
    const { categoryName, quantityOfCorrectAnswers, quantityOfQuestions } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const [t] = useTranslation(['attempts-list']);

    return (
        <Accordion title={categoryName} isExpanded={isExpanded} handleExpand={() => setIsExpanded(!isExpanded)}>
            <div className="accordion-flex-content">
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('correct')}</div>
                    <div className="accordion-flex-content-item-content">{quantityOfCorrectAnswers}</div>
                </div>
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('from')}</div>
                    <div className="accordion-flex-content-item-content">{quantityOfQuestions}</div>
                </div>
            </div>
        </Accordion>
    );
};
