import { Action, Reducer } from 'redux';
import { AppThunkAction } from 'store';

import api from 'api';
import { Role } from '../enums/roles';
import { ADMIN_LOGOUT_PATH } from '../global-constants';
import { BaseUser } from '../models/base-user';

const ADMIN_MAX_NO_ACTIVE_TIME = 900;

export interface AdminAuthenticationState {
    isSessionEnd: boolean;
    maxNoActiveTime: number;
    isLoading: boolean;
    admin?: BaseUser;
    loginError: string;
}

const LOGIN = 'admin-authentication/LOGIN';
const LOGIN_ERROR = 'admin-authentication/LOGIN_ADMIN_ERROR';
const LOGOUT = 'admin-authentication/LOGOUT';
const SET_LOADING = 'admin-authentication/SET_LOADING';
const CLEAR_ADMIN = 'admin-authentication/CLEAR_ADMIN';
const CLEAR_ERROR = 'admin-authentication/CLEAR_ERROR';
const SET_IS_SESSION_END = 'admin-authentication/SET_IS_SESSION_END';

export interface UpdateAdmin {
    userName: string;
    lastName: string;
    middleName: string;
}

interface LoginError {
    type: typeof LOGIN_ERROR;
    errorMessage: string;
}

interface Login {
    type: typeof LOGIN;
    admin: BaseUser;
}

interface Logout {
    type: typeof LOGOUT;
}

interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

interface ClearAdmin {
    type: typeof CLEAR_ADMIN;
}

interface ClearError {
    type: typeof CLEAR_ERROR;
}

interface SetIsSessionEnd {
    type: typeof SET_IS_SESSION_END;
    isSessionEnd: boolean;
}

type AuthenticationActionTypes = LoginError | Login | Logout | SetLoading | ClearAdmin | ClearError | SetIsSessionEnd;

export const actions = {
    logoutAdmin: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        dispatch({
            type: SET_LOADING,
        });

        await api.get<void>(ADMIN_LOGOUT_PATH);

        dispatch({
            type: LOGOUT,
        });
    },
    updateAdmin:
        (model: UpdateAdmin): AppThunkAction<AuthenticationActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const admin = {
                    ...(await api.put<BaseUser>('AdminAccount/UpdateAdmin', model)).data,
                    role: Role.SuperAdmin,
                };

                dispatch({
                    type: LOGIN,
                    admin,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    loadAdmin: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        try {
            dispatch({
                type: SET_LOADING,
            });

            const admin = { ...(await api.get<BaseUser>('AdminAccount')).data, role: Role.SuperAdmin };

            dispatch({
                type: LOGIN,
                admin,
            });
        } catch {
            dispatch({
                type: SET_LOADING,
                isLoading: false,
            });
        }
    },
    setLoading: (isLoading: boolean) => ({ type: SET_LOADING, isLoading }),
    clearAdmin: () => ({ type: CLEAR_ADMIN }),
    clearError: () => ({ type: CLEAR_ERROR }),
    actionAfterEndSessionTime: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        dispatch({
            type: SET_IS_SESSION_END,
            isSessionEnd: true,
        });
        await api.get<void>(ADMIN_LOGOUT_PATH.replace('/api', ''));
    },
};

const initialState: Readonly<AdminAuthenticationState> = {
    isSessionEnd: false,
    isLoading: true,
    loginError: '',
    maxNoActiveTime: ADMIN_MAX_NO_ACTIVE_TIME,
};

export const reducer: Reducer<AdminAuthenticationState> = (
    state: AdminAuthenticationState = initialState,
    incomingAction: Action
): AdminAuthenticationState => {
    const action = incomingAction as AuthenticationActionTypes;
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                admin: action.admin,
                loginError: '',
                isLoading: false,
                isSessionEnd: false,
                maxNoActiveTime: ADMIN_MAX_NO_ACTIVE_TIME,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.errorMessage,
                isLoading: false,
            };
        case LOGOUT:
            return {
                ...state,
                admin: undefined,
                isLoading: false,
                loginError: '',
            };
        case CLEAR_ADMIN: {
            return {
                ...state,
                admin: undefined,
            };
        }
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                loginError: '',
            };
        case SET_IS_SESSION_END:
            return {
                ...state,
                isSessionEnd: action.isSessionEnd,
            };
        default:
            return state;
    }
};
