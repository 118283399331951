import React from 'react';
import { RoutingConfig } from '../models/routing-config';
import Categories from '../pages/categories/Categories';
import MoveCertificate from '../pages/move-cert/MoveCertificate';
import Questions from '../pages/questions/Questions';
import StudyGroups from '../pages/study-groups/StudyGroups';
import SuperAdmin from '../pages/super-admin/SuperAdmin';
import Tests from '../pages/tests/Tests';
import UserAgreements from '../pages/user-agreements/UserAgreements';
import UserTestResult from '../pages/user-test-result/UserTestResult';
import UsersPage from '../pages/users/UsersPage';
import StudyCentersPage from '../pages/study-center-page/StudyCenterPage';

export const adminRouting: RoutingConfig[] = [
    {
        menuItemHref: `categories`,
        menuItemIcon: 'menu-test',
        menuItemText: 'Категории',
        component: <Categories />,
    },
    {
        menuItemHref: `questions`,
        menuItemIcon: 'menu-stats',
        menuItemText: 'Вопросы',
        component: <Questions />,
    },
    {
        menuItemHref: `tests`,
        menuItemIcon: 'menu-cert',
        menuItemText: 'Тесты',
        component: <Tests />,
    },
    {
        menuItemHref: `study-groups`,
        menuItemIcon: 'menu-group',
        menuItemText: 'Учебные группы',
        component: <StudyGroups />,
    },
    {
        menuItemHref: 'study-centers',
        menuItemIcon: 'menu-students',
        menuItemText: 'Учебные центры',
        component: <StudyCentersPage />,
    },
    {
        menuItemHref: `test-result`,
        menuItemIcon: 'menu-journal',
        menuItemText: 'Результаты',
        component: <UserTestResult />,
    },
    {
        menuItemHref: `move-certificates`,
        menuItemIcon: 'academic-cap',
        menuItemText: 'Переместить сертификаты',
        component: <MoveCertificate />,
    },
    {
        menuItemHref: `agreements`,
        menuItemIcon: 'window-with-shield',
        menuItemText: 'Соглашения',
        component: <UserAgreements />,
    },
    {
        menuItemHref: `users`,
        menuItemIcon: 'eye-icon',
        menuItemText: 'Пользователи',
        component: <UsersPage />,
    },
    {
        id: 'profile',
        menuItemHref: `admins`,
        menuItemIcon: 'menu-admins',
        menuItemText: 'Администраторы',
        component: <SuperAdmin />,
    },
];
