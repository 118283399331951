import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';

import EnterCode from './enter-code/EnterCode';
import Error404 from 'pages/error-404/Error404';
import TestPages from './TestPages';

const UserTest = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`} component={EnterCode} />
            <TestPages />
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default UserTest;
