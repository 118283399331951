import React, { FC } from 'react';
import { ColumnSortProps } from 'types/column.sort.type';

interface Props {
    sort: ColumnSortProps;
    changeSort: () => void;
    fieldLabel: string;
    className: string;
}

const Sort: FC<Props> = ({ sort, fieldLabel, className, changeSort }) => {
    const { isActive, desc } = sort;
    return (
        <div className={'sort_header ' + className} onClick={changeSort}>
            {fieldLabel}
            {isActive && !desc && <span>↓</span>}
            {isActive && desc && <span>↑</span>}
        </div>
    );
};

export default Sort;
