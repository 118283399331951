import { Action, Reducer } from 'redux';
import { AppThunkAction } from 'store';

import api from 'api';
import { Role } from '../enums/roles';
import { UserState } from '../enums/user-state';
import { ADMIN_STUDY_LOGOUT_PATH } from '../global-constants';
import { Agreements } from '../models/agreements';
import { AllowCaptcha } from '../models/allow-captcha';
import { BaseUser } from '../models/base-user';
import { CountryBackend } from '../models/country';
import { StudyCenter } from '../models/study-center';

const TC_ADMIN_MAX_NO_ACTIVE_TIME = 14400;

export interface TCAdminAuthenticationState {
    isSessionEnd: boolean;
    maxNoActiveTime: number;
    isLoading: boolean;
    admin?: TCAdmin;
    loginError: string;
    studyCenterError: string;
    redirect_error: boolean;
}

export interface TCAdmin extends BaseUser {
    approved: UserState;
    studyCenter: StudyCenter;
    country: CountryBackend;
    approvedAgreements: boolean;
    personalVersion: number;
    marketingVersion: number;
}

export interface UpdateTCAdmin extends AllowCaptcha {
    userName: string;
    lastName: string;
    middleName: string;
    trainingCenterId: string;
    countryId: number;
}

export interface UpdateTCAdminWithAgreements extends UpdateTCAdmin, Agreements {}

const LOGIN = 'tc-admin-authentication/LOGIN';
const LOGIN_ERROR = 'tc-admin-authentication/LOGIN_ADMIN_ERROR';
const LOGOUT = 'tc-admin-authentication/LOGOUT';
const SET_LOADING = 'tc-admin-authentication/SET_LOADING';
const CLEAR_ADMIN = 'tc-admin-authentication/CLEAR_ADMIN';
const CLEAR_ERROR = 'tc-admin-authentication/CLEAR_ERROR';
const SET_IS_SESSION_END = 'tc-admin-authentication/SET_IS_SESSION_END';
const ERROR_STUDY_CENTER = 'tc-admin-study-center/ERROR_STUDY_CENTER';
const SET_REDIRECT_ERROR = 'tc-admin-study-center/SET_REDIRECT_ERROR';

interface SetRedirectError {
    type: typeof SET_REDIRECT_ERROR;
}

interface LoginError {
    type: typeof LOGIN_ERROR;
    errorMessage: string;
}

interface Login {
    type: typeof LOGIN;
    admin: TCAdmin;
}

interface ErrorStudyCenter {
    type: typeof ERROR_STUDY_CENTER;
    error: string;
}

interface Logout {
    type: typeof LOGOUT;
}

interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

interface ClearAdmin {
    type: typeof CLEAR_ADMIN;
}

interface ClearError {
    type: typeof CLEAR_ERROR;
}

interface SetIsSessionEnd {
    type: typeof SET_IS_SESSION_END;
}

interface AddNewAgreement {
    captchaCode: string;
    personalCheckBoxText: string;
    personalText: string;
    marketingCheckBoxText: string;
    marketingText: string;
    marketingVersion: number;
    personalVersion: number;
    marketingAgreement: boolean;
    personalAgreement: boolean;
}

type AuthenticationActionTypes =
    | LoginError
    | Login
    | Logout
    | SetLoading
    | ClearAdmin
    | ClearError
    | SetIsSessionEnd
    | ErrorStudyCenter
    | SetRedirectError;

export const actions = {
    addAgreements:
        (values: AddNewAgreement): AppThunkAction<AuthenticationActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const admin = {
                    ...(await api.post<TCAdmin>('AdminStudyCenter/UpdateAgreements', values)).data,
                    role: Role.AdminStudyCenter,
                };

                dispatch({
                    type: LOGIN,
                    admin,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    logoutAdmin: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        dispatch({
            type: SET_LOADING,
        });

        await api.get<void>(ADMIN_STUDY_LOGOUT_PATH.replace('/api', ''));

        dispatch({
            type: LOGOUT,
        });
    },
    updateAdmin:
        (model: UpdateTCAdmin): AppThunkAction<AuthenticationActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const admin = {
                    ...(await api.put<TCAdmin>('AdminStudyCenter/UpdateWithoutAgreements', model)).data,
                    role: Role.AdminStudyCenter,
                };

                dispatch({
                    type: LOGIN,
                    admin,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    updateAdminWithAgreements:
        (model: UpdateTCAdminWithAgreements): AppThunkAction<AuthenticationActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const admin = {
                    ...(await api.put<TCAdmin>('AdminStudyCenter/update', model)).data,
                    role: Role.AdminStudyCenter,
                };

                dispatch({
                    type: LOGIN,
                    admin,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    loadAdmin: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        try {
            dispatch({
                type: SET_LOADING,
            });

            const admin = { ...(await api.get<TCAdmin>('AdminStudyCenter')).data, role: Role.AdminStudyCenter };

            dispatch({
                type: LOGIN,
                admin,
            });
        } catch {
            const reg = /tc-admin\/[\W\w]+/gm;
            const url = window.location.href;

            if (reg.test(url)) {
                dispatch({
                    type: SET_REDIRECT_ERROR,
                });
            }

            dispatch({
                type: SET_LOADING,
                isLoading: false,
            });
        }
    },
    setLoading: (isLoading: boolean) => ({ type: SET_LOADING, isLoading }),
    clearTcAdmin: () => ({ type: CLEAR_ADMIN }),
    clearError: () => ({ type: CLEAR_ERROR }),
    actionAfterEndSessionTime: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        dispatch({ type: SET_IS_SESSION_END });
        await api.get<void>(ADMIN_STUDY_LOGOUT_PATH.replace('/api', ''));
    },
};

const initialState: Readonly<TCAdminAuthenticationState> = {
    isSessionEnd: false,
    admin: undefined,
    isLoading: true,
    loginError: '',
    studyCenterError: '',
    maxNoActiveTime: TC_ADMIN_MAX_NO_ACTIVE_TIME,
    redirect_error: false,
};

export const reducer: Reducer<TCAdminAuthenticationState> = (
    state: TCAdminAuthenticationState = initialState,
    incomingAction: Action
): TCAdminAuthenticationState => {
    const action = incomingAction as AuthenticationActionTypes;
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                admin: action.admin,
                loginError: '',
                isLoading: false,
                isSessionEnd: false,
                maxNoActiveTime: TC_ADMIN_MAX_NO_ACTIVE_TIME,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.errorMessage,
                isLoading: false,
            };
        case LOGOUT:
            return {
                ...state,
                admin: undefined,
                isLoading: false,
                loginError: '',
            };
        case CLEAR_ADMIN: {
            return {
                ...state,
                admin: undefined,
            };
        }
        case ERROR_STUDY_CENTER:
            return {
                ...state,
                studyCenterError: action.error,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                loginError: '',
            };
        case SET_IS_SESSION_END:
            return {
                ...state,
                isSessionEnd: true,
            };
        case SET_REDIRECT_ERROR:
            return {
                ...state,
                redirect_error: true,
            };
        default:
            return state;
    }
};
