import React from 'react';
import { TestGridItem, GridItemProps } from './TestGridItem';
import Sort from '../Sort/Sort';
import Pagination, { PaginationProps } from '../Pagination/Pagination';
import { testSort, testSortArr } from 'types/sort.types';
import { Column } from 'components/Column';

interface GridProps {
    items: GridItemProps[];
    pagination?: PaginationProps;
    sortArr: testSortArr;
    showLk: boolean;
    changeSort: (field: testSort) => void;
}

export const TestGrid = (props: GridProps) => {
    const { pagination, sortArr, changeSort, showLk } = props;

    return (
        <>
            <ul className="test-grid scroll">
                <li className="test-grid-header">
                    <Sort
                        sort={sortArr.name}
                        changeSort={() => changeSort('name')}
                        fieldLabel="Название теста"
                        className="test-grid-header-name cell"
                    />
                    <div className="d-flex align-items-center text-left">
                        <Sort
                            sort={sortArr.time}
                            changeSort={() => changeSort('time')}
                            fieldLabel="Длительность"
                            className="test-grid-header-time cell"
                        />
                        <Sort
                            sort={sortArr.creationDate}
                            changeSort={() => changeSort('creationDate')}
                            fieldLabel="Дата создания"
                            className="test-grid-header-time cell"
                        />
                        <Sort
                            sort={sortArr.editDate}
                            changeSort={() => changeSort('editDate')}
                            fieldLabel="Дата редактирования"
                            className="test-grid-header-time cell"
                        />
                        <Sort
                            sort={sortArr.testLanguage}
                            changeSort={() => changeSort('testLanguage')}
                            fieldLabel="Язык теста"
                            className="test-grid-header-language cell"
                        />
                        <Sort
                            sort={sortArr.questionCount}
                            changeSort={() => changeSort('questionCount')}
                            fieldLabel="К-во вопросов"
                            className="test-grid-header-quantity cell"
                        />
                        <Sort
                            sort={sortArr.certificationMonthCount}
                            changeSort={() => changeSort('certificationMonthCount')}
                            fieldLabel="Кол-во мес-в дей-я сер-та"
                            className="test-grid-header-month-count cell"
                        />
                        <Sort
                            sort={sortArr.certificationFinishDate}
                            changeSort={() => changeSort('certificationFinishDate')}
                            fieldLabel="Дата окон-я дей-я сер-та"
                            className="test-grid-header-finish-date cell"
                        />
                        {showLk && (
                            <>
                                <Sort
                                    sort={sortArr.testArea}
                                    changeSort={() => changeSort('testArea')}
                                    fieldLabel="Область применения"
                                    className="test-grid-header-test-area cell"
                                />
                                <Sort
                                    sort={sortArr.productGroup}
                                    changeSort={() => changeSort('productGroup')}
                                    fieldLabel="Продуктовая группа"
                                    className="test-grid-header-product-group cell"
                                />
                                <Sort
                                    sort={sortArr.isVersionActive}
                                    changeSort={() => changeSort('isVersionActive')}
                                    fieldLabel="Акту-я версия"
                                    className="test-grid-header-active-version cell"
                                />
                                <Sort
                                    sort={sortArr.testType}
                                    changeSort={() => changeSort('testType')}
                                    fieldLabel="Тип теста"
                                    className="test-grid-header-test-type cell"
                                />
                            </>
                        )}
                    </div>
                </li>
                {props.items.map((item, index) => (
                    <TestGridItem key={`${item.id}-${index}`} {...item} showLk={showLk} />
                ))}
            </ul>
            {pagination?.size && (
                <Column columnSize="100" className="top-space-10x">
                    <Pagination {...pagination} />
                </Column>
            )}
        </>
    );
};
