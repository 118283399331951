import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Link, Page } from 'components';
import { KASPERSKY_MAIL, KASPERSKY_PRIVACY_POLICY } from 'global-constants';
import { language } from '../../../constants/language';

const TermsOfUse = () => {
    const { t, i18n } = useTranslation(['terms-of-use']);
    const currentLanguage = i18n.language;

    const title = `${t('terms-of-use')} CERTIFICATION.KASPERSKY.COM ${
        currentLanguage === language.en.key ? 'website' : ''
    }`;

    return (
        <Page>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-title">{title}</h2>
                <div className="terms-of-use-content">
                    <h4 className="terms-of-use-subtitle">{t('attention')}</h4>
                    <div className="terms-of-use-content">
                        <p>{t('definitions')}</p>
                        <ul className="terms-of-use-content-list">
                            <li>
                                <span className="bold">{t('definitions-website-strong')}</span>
                                {t('definitions-website')}
                            </li>
                            <li>
                                <span className="bold">{t('definitions-user-strong')}</span>
                                {t('definitions-user')}
                            </li>
                            <li>
                                <span className="bold">{t('definitions-kaspersky-lab-strong')}</span>
                                {t('definitions-kaspersky-lab')}
                            </li>
                            <li>
                                <span className="bold">{t('definitions-content-strong')}</span>
                                {t('definitions-content')}
                            </li>
                            <li>
                                <span className="bold">{t('definitions-website-administrator-strong')}</span>
                                {t('definitions-website-administrator')}
                            </li>
                            <li>
                                <span className="bold">{t('definitions-account-strong')}</span>
                                {t('definitions-account')}
                            </li>
                        </ul>
                    </div>
                    <p>
                        <Trans>{t('use-web-site')}</Trans>
                    </p>
                    <p>
                        <Trans>{t('use-web-site-registration')}</Trans>
                    </p>
                </div>
            </div>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-subtitle">{t('intellectual-property-title')}</h2>
                <div className="terms-of-use-content">
                    <p>
                        <Trans>{t('intellectual-property-content')}</Trans>
                    </p>
                </div>
            </div>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-subtitle">{t('general-terms-and-conditions')}</h2>
                <div className="terms-of-use-content">
                    <ul className="terms-of-use-content-list">
                        <li>{t('conditions-grants')}</li>
                        <li>{t('conditions-access-certification-process')}</li>
                        <li>
                            {t('conditions-access-functionality')}
                            {'('}
                            <Link linkHref="https://auth.hq.uis.kaspersky.com/" target="_blank">
                                https://auth.hq.uis.kaspersky.com
                            </Link>
                            {')'}
                            {t('conditions-rules-according')}{' '}
                            <Link linkHref={KASPERSKY_PRIVACY_POLICY} target="_blank">
                                {t('privacy-policy')}
                            </Link>
                        </li>
                        <li>
                            {t('conditions-provide-data')}{' '}
                            <Link linkHref={KASPERSKY_PRIVACY_POLICY} target="_blank">
                                {t('privacy-policy')}
                            </Link>
                        </li>
                        <li>{t('conditions-after-paying-for-testing')}</li>
                        <li>{t('conditions-validity-period-of-the-access-code')}</li>
                        <li>{t('conditions-right-to-start-online-testing')}</li>
                        <li>{t('conditions-user-responsible')}</li>
                        <li>{t('conditions-kaspersky-has-right')}</li>
                        <li>{t('conditions-forbidden-to-transfer')}</li>
                        <li>{t('conditions-testing-is-passed-successfully')}</li>
                        <li>{t('conditions-results-valid-time-new')}</li>
                        <li>{t('conditions-responsibility')}</li>
                        <li>{t('conditions-can-reject')}</li>
                        <li>{t('conditions-changing')}</li>
                    </ul>
                </div>
            </div>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-subtitle">{t('limitations-and-restrictions')}</h2>
                <div className="terms-of-use-content">
                    <p>{t('user-forbidden')}</p>
                    <ul className="terms-of-use-content-list">
                        <li>{t('forbidden-pass-access-code')}</li>
                        <li>{t('forbidden-register')}</li>
                        <li>{t('forbidden-transfer-information')}</li>
                        <li>{t('forbidden-use-automated-mechanism')}</li>
                        <li>{t('forbidden-vulnerabilities')}</li>
                    </ul>
                </div>
            </div>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-subtitle">{t('limitation-of-liability')}</h2>
                <div className="terms-of-use-content">
                    <p>{t('links-third-party-websites')}</p>
                    <p>{t('website-provided')}</p>
                </div>
            </div>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-subtitle">{t('applicable-law')}</h2>
                <div className="terms-of-use-content">
                    <p>{t('agreement-is-governed')}</p>
                </div>
            </div>
            <div className="terms-of-use-item">
                <h2 className="terms-of-use-subtitle">{t('support')}</h2>
                <div className="terms-of-use-content">
                    <p>
                        {t('send-question')}{' '}
                        <Link linkHref={`mailto:${KASPERSKY_MAIL}`} target="_blank">
                            {KASPERSKY_MAIL.toLowerCase()}
                        </Link>
                    </p>
                </div>
            </div>
            <div className="terms-of-use-item">
                <div className="terms-of-use-content">
                    <p>{t('kaspersky-Lab')}</p>
                    <p>{t('last-changes-date')}</p>
                </div>
            </div>
        </Page>
    );
};

export default TermsOfUse;
