import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { StudyGroupState } from './store/store';
import { actions } from './store/actions';
import { ApplicationState } from '../../store';
import { StudyGroupForm } from './study-group-form/StudyGroupForm';
import { connectComponent, withLoading } from 'utils';

type StudyGroupFormProps = StudyGroupState & typeof actions;

const StudyGroupFormAdd = (props: StudyGroupFormProps) => {
    const { addStudyGroup, getStudyGroup, getStudyCenters, studyGroupSuccess, studyGroupError, clearMessage } = props;
    const alert = useAlert();
    const { push } = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        getStudyCenters();
    }, [getStudyCenters]);

    useEffect(() => {
        getStudyGroup();
    }, [getStudyGroup]);

    const goBack = useCallback(() => {
        push(pathname.replace('/add', ''));
    }, [push, pathname]);

    useEffect(() => {
        if (studyGroupSuccess) {
            alert.success(studyGroupSuccess);
            goBack();
        } else if (studyGroupError) {
            alert.error(studyGroupError);
        }

        clearMessage();
    }, [alert, clearMessage, studyGroupError, goBack, studyGroupSuccess]);

    return (
        <StudyGroupForm
            {...props}
            studyCenters={[...props.studyCenters]}
            title="Создать учебную группу"
            onSubmit={addStudyGroup}
            goBack={goBack}
        />
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyGroups,
    actions,
    component: withLoading(StudyGroupFormAdd),
});
