import React from 'react';

import { TestQuestion } from 'pages/user-test/store';

interface TestQuestionListProps {
    currentQuestionId: number;
    questions: TestQuestion[];
    switchToQuestion: (newQuestionId: number) => void;
    handleQuestionView: () => void;
}

export const TestQuestionsList = (props: TestQuestionListProps) => {
    const { questions, switchToQuestion, currentQuestionId, handleQuestionView } = props;

    const handleQuestionClick = (question: TestQuestion) => {
        if (question.selectedQuestionOptionsId.length === 0) {
            switchToQuestion(question.id);
            handleQuestionView();
        }
    };

    return (
        <div className="block-test-body">
            <div className="block-test-body-inner">
                <div className="test-body-answers">
                    {questions.map((x, index) => {
                        const isActive = x.id === currentQuestionId;
                        const isPassed = x.selectedQuestionOptionsId.length > 0;
                        const classes = `question-list-item ${isPassed ? 'disabled' : ''} ${isActive ? 'active' : ''}`;
                        return (
                            <button key={index} className={classes} onClick={() => handleQuestionClick(x)}>
                                {index + 1}
                            </button>
                        );
                    })}
                </div>
                <div className="test-body-close" onClick={handleQuestionView} />
            </div>
        </div>
    );
};
