import { AppThunkAction } from 'store';
import api from 'api';
import { CertDirection } from '../../../enums/cert-direction';
import { Category } from '../../../models/category';
import { CategoryService } from '../../../services/category.service';
import { Question, AddQuestion, EditOneQuestion } from './store';

export const GET_ALL_CATEGORY_QUESTIONS = 'questions/GET_ALL_CATEGORY_QUESTIONS';
export const EDIT_QUESTION_FORM = 'questions/EDIT_QUESTION_FORM';
export const CLOSE_EDIT_QUESTION_FORM = 'questions/CLOSE_EDIT_QUESTION_FORM';
export const ADD_QUESTION = 'questions/ADD_QUESTION';
export const LOAD_CATEGORIES = 'questions/LOAD_CATEGORIES';
export const SET_CATEGORY = 'questions/SET_CATEGORY';
export const SET_DIRECTION = 'questions/SET_DIRECTION';
export const SET_EDIT_QUESTION = 'questions/SET_EDIT_QUESTION';
export const EDIT_QUISTION = 'questions/EDIT_QUISTION';
export const SET_QUESTION_SUCCESS = 'questions/SET_QUESTION_SUCCESS';
export const SET_QUESTION_ERROR = 'questions/SET_QUESTION_ERROR';
export const GET_QUESTION = 'questions/GET_QUESTION';
export const SET_LOADING = 'questions/SET_LOADING';
export const SHOW_ALL_OPTIONS = 'questions/SHOW_ALL_OPTIONS';
export const HIDE_ALL_OPTIONS = 'questions/HIDE_ALL_OPTIONS';
export const SHOW_HIDE_OPTIONS = 'questions/SHOW_HIDE_OPTIONS';
export const DELETE_QUESTION = 'questions/DELETE_QUESTION';
export const CLEAR_EDIT_QUESTION = 'questions/CLEAR_EDIT_QUESTION';
export const CLEAR_QUESTION_MESSAGES = 'questions/CLEAR_QUESTION_MESSAGES';
export const ADD_ANSWER = 'questions/ADD_ANSWER';
export const DELETE_ANSWER = 'questions/DELETE_ANSWER';
export const UPDATE_QUESTION_LIST = 'questions/UPDATE_QUESTION_LIST';
export const ADD_QUESTION_LIST = 'questions/ADD_QUESTION_LIST';
export const ADD_ITEM_QUESTION_LIST = 'questions/ADD_ITEM_QUESTION_LIST';
export const UPDATE_TEMP_DATA = 'questions/UPDATE_TEMP_DATA';

interface UpdateTempData {
    type: typeof UPDATE_TEMP_DATA;
    question: Question;
    index: number;
}

interface UpdateQuestionList {
    type: typeof UPDATE_QUESTION_LIST;
    question: Question;
    index: number;
}

interface AddItemQuestionList {
    type: typeof ADD_ITEM_QUESTION_LIST;
}

interface EditQuestionForm {
    type: typeof EDIT_QUESTION_FORM;
    questionId?: number;
}

interface CloseEditQuestionForm {
    type: typeof CLOSE_EDIT_QUESTION_FORM;
    questionId?: number;
    index: number;
}

interface GetAllCategoryQuestions {
    type: typeof GET_ALL_CATEGORY_QUESTIONS;
    questions: Question[];
}

interface GetQuestion {
    type: typeof GET_QUESTION;
    question: Question;
}

interface AddQuestionAction {
    type: typeof ADD_QUESTION;
    newQuestion: Question;
}

interface LoadCategories {
    type: typeof LOAD_CATEGORIES;
    categories: Category[];
}

interface SetCategory {
    type: typeof SET_CATEGORY;
    categoryId: number;
}

interface SetDirection {
    type: typeof SET_DIRECTION;
    directionId: CertDirection;
}

interface SetEditQuestion {
    type: typeof SET_EDIT_QUESTION;
    question: Question;
}

interface EditQuestion {
    type: typeof EDIT_QUISTION;
    question: Question;
}

interface SetQuestionSuccess {
    type: typeof SET_QUESTION_SUCCESS;
    message: string;
}

interface SetQuestionError {
    type: typeof SET_QUESTION_ERROR;
    error: string;
}

interface SetLoading {
    type: typeof SET_LOADING;
    value?: boolean;
}

interface ShowAllOptions {
    type: typeof SHOW_ALL_OPTIONS;
    expandedQuestions: Question[];
}

interface HideAllOptions {
    type: typeof HIDE_ALL_OPTIONS;
    expandedQuestions: Question[];
}

interface ShowHideOptions {
    type: typeof SHOW_HIDE_OPTIONS;
    id: number;
}

interface DeleteQuestion {
    type: typeof DELETE_QUESTION;
}

interface ClearEditQuestion {
    type: typeof CLEAR_EDIT_QUESTION;
}

interface ClearQuestionMessages {
    type: typeof CLEAR_QUESTION_MESSAGES;
}

interface AddAnswer {
    type: typeof ADD_ANSWER;
}

interface DeleteAnswer {
    type: typeof DELETE_ANSWER;
    index: number;
}

export type QuestionsActionTypes =
    | GetAllCategoryQuestions
    | GetQuestion
    | AddQuestionAction
    | LoadCategories
    | SetCategory
    | SetDirection
    | SetEditQuestion
    | EditQuestion
    | SetQuestionSuccess
    | SetQuestionError
    | SetLoading
    | ShowAllOptions
    | HideAllOptions
    | ShowHideOptions
    | DeleteQuestion
    | ClearEditQuestion
    | ClearQuestionMessages
    | AddAnswer
    | DeleteAnswer
    | EditQuestionForm
    | CloseEditQuestionForm
    | UpdateQuestionList
    | AddItemQuestionList
    | UpdateTempData;

export const actionCreators = {
    updateTempData: (index: number, question: Question): QuestionsActionTypes => ({
        type: UPDATE_TEMP_DATA,
        index,
        question,
    }),
    AddItemQuestionList: (): QuestionsActionTypes => ({
        type: ADD_ITEM_QUESTION_LIST,
    }),
    updateQuestionList:
        (question: EditOneQuestion, index: number): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const newQuestion = (await api.put<Question>(`Question/One`, question)).data;

                dispatch({
                    type: UPDATE_QUESTION_LIST,
                    question: newQuestion,
                    index,
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    setEditFormForQuestion: (questionId?: number): QuestionsActionTypes => ({
        type: EDIT_QUESTION_FORM,
        questionId,
    }),
    setCloseEditFormForQuestion: (index: number, questionId?: number): QuestionsActionTypes => ({
        type: CLOSE_EDIT_QUESTION_FORM,
        questionId,
        index,
    }),
    getQuestionsByCategory:
        (categoryId?: number): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                let questions: Question[] = [];

                if (categoryId) {
                    const category = (await api.get<{ questions: Question[] }>(`Question/${categoryId}`)).data;
                    questions = category.questions;
                }

                dispatch({
                    type: GET_ALL_CATEGORY_QUESTIONS,
                    questions,
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    getQuestion:
        (id: number): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const respQuestion = await api.get<Question>(`Question/GetQuestion/${id}`);

                dispatch({
                    type: GET_QUESTION,
                    question: respQuestion.data,
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    deleteQuestion:
        (questionId: number, categoryId: number): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await api.delete<Question>(`Question/${questionId}`);
                const category = (await api.get<{ questions: Question[] }>(`Question/${categoryId}`)).data;

                dispatch({
                    type: SET_QUESTION_SUCCESS,
                    message: 'Вопрос был успешно удален',
                });
                dispatch({
                    type: GET_ALL_CATEGORY_QUESTIONS,
                    questions: category.questions,
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    loadCategories:
        (directionId?: CertDirection): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const resp = await CategoryService.getList(directionId);

                dispatch({
                    type: LOAD_CATEGORIES,
                    categories: resp.data,
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    setCategory: (categoryId: number): QuestionsActionTypes => ({
        type: SET_CATEGORY,
        categoryId: categoryId,
    }),
    setDirection: (directionId: CertDirection): QuestionsActionTypes => ({
        type: SET_DIRECTION,
        directionId: directionId,
    }),
    showHideOptions: (id: number) => ({
        type: SHOW_HIDE_OPTIONS,
        id,
    }),
    showAllOptions: (questions: Question[]): QuestionsActionTypes => ({
        type: SHOW_ALL_OPTIONS,
        expandedQuestions: questions,
    }),
    hideAllOptions: (questions: Question[]) => ({
        type: HIDE_ALL_OPTIONS,
        expandedQuestions: questions,
    }),
    editQuestion:
        (editQuestion: AddQuestion): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await api.put<Question[]>('Question', editQuestion);

                dispatch({
                    type: SET_QUESTION_SUCCESS,
                    message: 'Вопрос был успешно изменен',
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    setQuestionSuccess: (message: string): QuestionsActionTypes => ({
        type: SET_QUESTION_SUCCESS,
        message: message,
    }),
    setQuestionError: (error: string): QuestionsActionTypes => ({
        type: SET_QUESTION_ERROR,
        error: error,
    }),
    addQuestion:
        (newQuestion: AddQuestion): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await api.post<number>('Question', newQuestion);

                dispatch({
                    type: SET_QUESTION_SUCCESS,
                    message: 'Вопрос был успешно добавлен',
                });
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    uploadQuestions:
        (questionsFile: File): AppThunkAction<QuestionsActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const formData = new FormData();
                formData.append('jsonFile', questionsFile);
                api.postForm<void>('Question/import', formData).then(
                    () => {
                        dispatch({
                            type: SET_QUESTION_SUCCESS,
                            message: `Успех`,
                        });
                    },
                    (e) => {
                        dispatch({
                            type: SET_QUESTION_ERROR,
                            error: e.response.data,
                        });
                    }
                );
            } catch {
                dispatch({
                    type: SET_QUESTION_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    clearQuestionMessages: () => ({ type: CLEAR_QUESTION_MESSAGES }),
    clearEditQuestion: (): QuestionsActionTypes => ({
        type: CLEAR_EDIT_QUESTION,
    }),
    addAnswer: () => ({ type: ADD_ANSWER }),
    deleteAnswer: (index: number) => ({ type: DELETE_ANSWER, index }),
    setLoading: (value?: boolean) => ({ type: SET_LOADING, value }),
};
