import React, { PropsWithChildren } from 'react';

interface ParagraphProps {
    additionalClass: 'lead' | 'success' | 'secondary' | 'danger' | 'excerpt' | string;
}

export const Paragraph = (props: PropsWithChildren<ParagraphProps>) => {
    const { additionalClass } = props;

    const classes = `paragraph ${additionalClass ? `paragraph-${additionalClass}` : ''}`;

    return <p className={classes}>{props.children}</p>;
};
