import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import Journal from './journal/Journal';
import UserCard from './user-card/UserCard';

const UsersJournal = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={Journal} />
            <Route exact path={`${path}/:userId`} component={UserCard} />
        </Switch>
    );
};

export default UsersJournal;
