import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../constants/date-format';
import { layoutRoutingUrls } from '../../constants/layout-routing-urls';
import { useUrlQueryParamsForGrid } from '../../hooks/useUrlQueryParamsForGrid';
import { getCountryTransKey } from '../../utils/get-country-trans-key';
import { actions, AdminsState } from './store';
import { ApplicationState } from '../../store';
import { connectComponent, withLoading } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Column,
    Container,
    EmptyListWarning,
    Grid,
    Icon,
    ModalWindow,
    Row,
    TextInput,
    Title,
} from '../../components';
import moment from 'moment';
import { UserState } from '../../enums/user-state';
import { User } from '../../authentication/UserAuthenticationStore';
import { useQuery } from 'hooks';

type StudyCentersAdminsProps = AdminsState & typeof actions;

const StudyCentersAdminPage = (props: StudyCentersAdminsProps) => {
    const { users, getAdmins, deleteUser, getExcel, approveUser } = props;
    const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false);
    const [userDeleteId, setUserDeleteId] = useState('');
    const { t } = useTranslation('countries');
    const queryParams = useQuery();

    const {
        pagination,
        queryState: [email, setEmail],
    } = useUrlQueryParamsForGrid({
        total: users.count,
        loadFn: (params) => {
            getAdmins({ page: params.page, size: params.size, email: params.query });
        },
    });

    const { push } = useHistory();

    const changeModalState = () => {
        setIsRemoveConfirmOpen(!isRemoveConfirmOpen);
    };

    const onDelete = (id: string) => {
        setUserDeleteId(id);
        changeModalState();
    };

    const showAgreementsClick = (id: string) => {
        push(`${layoutRoutingUrls.admin}/agreements/users?query=${id}`);
    };

    const isExpire = (date: string): boolean => {
        const now = moment(new Date());
        const start = moment(date);

        const duration = moment.duration(now.diff(start));

        return duration.asYears() > 5;
    };

    const buildApproveButtons = (user: User): ReactElement => {
        const { approved } = user;

        if (approved === UserState.WaitingFor) {
            return (
                <>
                    <Button buttonClass={'secondary'} pSize={'small'} handleClick={() => approveUser(user, true)}>
                        Подтвердить
                    </Button>
                    <Button buttonClass={'secondary'} pSize={'small'} handleClick={() => approveUser(user, false)}>
                        Отклонить
                    </Button>
                </>
            );
        }

        return (
            <Button
                buttonClass={'secondary'}
                pSize={'small'}
                handleClick={() => approveUser(user, user.approved !== UserState.Approve)}
            >
                {approved === UserState.Approve ? 'Отклонить' : 'Подтвердить'}
            </Button>
        );
    };

    const getGridData = (): Row[] =>
        users.items.map((user) => ({
            email: {
                additionalClasses: 'link',
                value: user.email,
                handleClick: () => showAgreementsClick(user.id),
            },
            name: `${user.lastName} ${user.userName} ${user.middleName}`,
            country: t(getCountryTransKey(user?.country?.id)) as string,
            studyCenter: user?.adminStudyCenter?.name,
            creationDate: moment(user.creationDate).format(dateTimeFormat),
            lastLoginDate: {
                value: moment(user.lastLoginDate).format(dateTimeFormat),
                additionalClasses: isExpire(user.lastLoginDate as string) ? 'danger-text' : '',
            },
            approve: {
                additionalComponent: buildApproveButtons(user),
                additionalClasses: 'cell-button-group',
            },
            actions: {
                additionalClasses: 'cell-button-group',
                additionalComponent: (
                    <Button pSize="none" handleClick={() => onDelete(user.id)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
            },
        }));

    return (
        <Container>
            <ModalWindow
                isOpen={isRemoveConfirmOpen}
                onCloseClick={changeModalState}
                modalMessage={'Вы действительно хотите удалить администратора учебного центра?'}
                confirmButtonText="Удалить"
                cancelButtonText="Отмена"
                onConfirmAction={() => {
                    setIsRemoveConfirmOpen(false);
                    deleteUser(userDeleteId);
                }}
            />
            <Column columnSize="100">
                <Title titleText="Сотрудники учебных центров" />
            </Column>
            <Column columnSize="50">
                <TextInput
                    id="search-field"
                    label="Email"
                    value={email}
                    onChangeHandler={(e) => setEmail(e.target.value)}
                />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button buttonClass="primary" handleClick={() => getExcel(queryParams.get('query'))}>
                    Excel
                </Button>
            </Column>
            <Column columnSize="100" className="top-space-10x">
                {users.items.length ? (
                    <Grid rows={getGridData()} columns={columns} pagination={pagination} />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

const mapStateToProps = ({ studyCentersAdmins }: ApplicationState) => ({
    ...studyCentersAdmins,
});

export default connectComponent({
    mapStateToProps,
    actions,
    component: withLoading(StudyCentersAdminPage),
});

const columns = {
    email: {
        value: 'Email',
        columnSize: '20',
    },
    name: {
        value: 'ФИО',
        columnSize: '15',
    },
    country: {
        value: 'Страна',
        columnSize: '10',
    },
    studyCenter: {
        value: 'Учебный центр',
        columnSize: '15',
    },
    creationDate: {
        value: 'Дата регистрации',
        columnSize: '7',
    },
    lastLoginDate: {
        value: 'Дата последнего входа',
        columnSize: '7',
    },
    approve: {
        value: 'Изменить статус доступа',
        columnSize: '20',
    },
    actions: {
        value: '',
        columnSize: '5',
    },
};
