import React from 'react';

import { HeaderName } from '../HeaderName';
import { MainNavItemProps, MainNav } from './MainNav';

export interface SidebarMenuProps {
    navItems?: MainNavItemProps[];
}

export const SidebarMenu = (props: SidebarMenuProps) => {
    const { navItems } = props;

    return (
        <aside className="sidebar-menu">
            <div className="sidebar-menu-item">
                <HeaderName />
            </div>

            {navItems && <MainNav navItems={navItems} />}
        </aside>
    );
};
