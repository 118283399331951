import { TrainingGroup } from '../../../../models/training-group/training-group';
import { TrainingGroupService } from '../../../../services/training-group.service';
import { AppThunkAction } from '../../../../store';

export const LOAD_TRAINING_GROUPS = 'tc-admin-training-group/LOAD_TRAINING_GROUPS';
export const ERROR_TRAINING_GROUPS = 'tc-admin-training-group/ERROR_TRAINING_GROUPS';
export const SET_LOADING = 'tc-admin-training-group/SET_LOADING';

interface LoadTrainingGroups {
    type: typeof LOAD_TRAINING_GROUPS;
    trainingGroups: TrainingGroup[];
}

interface ErrorTrainingGroups {
    type: typeof ERROR_TRAINING_GROUPS;
    error?: string;
}

interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export type ActionTypes = LoadTrainingGroups | SetLoading | ErrorTrainingGroups;

export const actions = {
    loadTrainingGroups:
        (id: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const trainingGroups = (await TrainingGroupService.getByStudyCenterId(id)).data;

                dispatch({
                    type: LOAD_TRAINING_GROUPS,
                    trainingGroups,
                });
            } catch {
                dispatch({
                    type: ERROR_TRAINING_GROUPS,
                    error: 'Ошибка',
                });
            }
        },
};
