import { Reducer, Action } from 'redux';

import {
    ActionTypes,
    SET_SELECTED_TEST,
    SET_TESTS,
    SET_LOADING,
    SET_ATTEMPTS,
    SET_SELECTED_TRAINING_GROUP,
    SET_ATTEMPT_DETAILS,
    SET_USER_CARD_INFO,
    CLEAR_INFO,
} from './actions';
import { LevelOfEvaluation } from 'pages/user-test/store';
import { Option, Details, CategoryResult } from 'components';
import { LanguageEnum } from 'enums/language.enum';

export interface UserCardAttempt {
    passingTestId: number;
    trainingGroupCode: string;
    testName: string;
    attemptNumber: number;
    allAttempts: number;
    attemptDate: string;
    duration: string;
    resultPoint: number;
    allPoint: number;
    levelOfEvaluation: LevelOfEvaluation;
    levelOfEvaluationDescription: string;
}

export interface UserCardInfo {
    userName: string;
    email: string;
    journalUserInfoDetails: UserCardAttempt[];
}

export interface Attempt {
    passingTestId: number;
    userName: string;
    userId: string;
    email: string;
    attemptNumber: number;
    allAttempts: number;
    attemptDate: string;
    editDate: string;
    duration: string;
    resultPoint: number;
    allPoint: number;
    levelOfEvaluation: LevelOfEvaluation;
    levelOfEvaluationDescription: string;
    isCertificateExists: boolean;
    isCertificateActive: boolean;
    company: string;
    certificateId?: string;
    language: LanguageEnum;
    isClientStudyCenter: boolean;
    isDeleted: boolean;
}

export interface JournalDetailsByCategory {
    categoryName: string;
    rightPoint: number;
    allPoint: number;
}

export interface AttemptDetails {
    userName: string;
    attemptNumber: number;
    allAttempts: number;
    totalRightPoint: number;
    totalAllPoint: number;
    journalDetailsByCategories: JournalDetailsByCategory[];
}

export interface TrainingGroup {
    trainingGroupId: number;
    code: string;
    dateEnd: string;
    dateStart: string;
    quantityPlaces: string;
    name: string;
}

export interface Test {
    testId: number;
    testTitle: string;
    duration: string;
    quantityQuestions: string;
    quantityAttempt: string;
    language: string;
}

export interface UsersJournalState {
    selectedTrainingGroup: TrainingGroup;
    selectedTrainingGroupOption: Option;
    selectedTest: Test;
    selectedTestOption: Option;
    trainingGroups: TrainingGroup[];
    trainingGroupsOptions: Option[];
    tests: Test[];
    testsOptions: Option[];
    attempts: Attempt[];
    attemptDetails: AttemptDetails;
    attemptDetailsModalOptions: Details;
    userCardInfo: UserCardInfo;
    isLoading: boolean;
}

const initialState: Readonly<UsersJournalState> = {
    selectedTrainingGroup: {} as TrainingGroup,
    selectedTrainingGroupOption: {} as Option,
    selectedTest: {} as Test,
    selectedTestOption: {} as Option,
    trainingGroups: [],
    trainingGroupsOptions: [],
    tests: [],
    testsOptions: [],
    attempts: [],
    attemptDetails: {} as AttemptDetails,
    attemptDetailsModalOptions: {
        resultByCategories: [] as CategoryResult[],
    } as Details,
    userCardInfo: {
        journalUserInfoDetails: [] as UserCardAttempt[],
    } as UserCardInfo,
    isLoading: false,
};

const mapTestToOption = (test: Test) => ({
    label: test.testTitle,
    value: test.testId,
});

const mapTrainingGroupToOptions = (trainingGroup: TrainingGroup) => ({
    label: trainingGroup.name ?? trainingGroup.code,
    value: trainingGroup.trainingGroupId,
});

export const reducer: Reducer<UsersJournalState> = (
    state: UsersJournalState = initialState,
    incomingAction: Action
): UsersJournalState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_SELECTED_TRAINING_GROUP: {
            const selectedTrainingGroup =
                state.trainingGroups.find((x) => x.trainingGroupId === action.selectedTrainingGroupId) ||
                state.trainingGroups[0];

            return {
                ...state,
                selectedTrainingGroup,
                selectedTrainingGroupOption: mapTrainingGroupToOptions(selectedTrainingGroup),
                isLoading: false,
            };
        }
        case SET_SELECTED_TEST: {
            const selectedTest = state.tests.find((x) => x.testId === action.selectedTestId) || state.tests[0];

            return {
                ...state,
                selectedTest,
                selectedTestOption: mapTestToOption(selectedTest),
                trainingGroups: action.trainingGroups,
                trainingGroupsOptions: action.trainingGroups.map(mapTrainingGroupToOptions),
                selectedTrainingGroup: action.trainingGroups[0],
                selectedTrainingGroupOption: mapTrainingGroupToOptions(action.trainingGroups[0]),
                isLoading: false,
            };
        }
        case SET_TESTS:
            return {
                ...state,
                tests: action.tests,
                selectedTest: action.selectedTest,
                testsOptions: action.tests.map(mapTestToOption),
                selectedTestOption: mapTestToOption(action.selectedTest),
                trainingGroups: action.trainingGroups,
                selectedTrainingGroup: action.selectedTrainingGroup,
                trainingGroupsOptions: action.trainingGroups.map(mapTrainingGroupToOptions),
                selectedTrainingGroupOption: mapTrainingGroupToOptions(action.selectedTrainingGroup),
                isLoading: false,
            };
        case SET_ATTEMPTS: {
            return {
                ...state,
                attempts: action.attempts,
                isLoading: false,
            };
        }
        case SET_ATTEMPT_DETAILS: {
            return {
                ...state,
                attemptDetails: action.attemptDetails,
                attemptDetailsModalOptions: {
                    totalQuantityOfCorrectAnswers: action.attemptDetails.totalRightPoint,
                    totalQuantityOfQuestions: action.attemptDetails.totalAllPoint,
                    resultByCategories: action.attemptDetails.journalDetailsByCategories.map((x) => ({
                        categoryName: x.categoryName,
                        quantityOfCorrectAnswers: x.rightPoint,
                        quantityOfQuestions: x.allPoint,
                    })),
                },
                isLoading: false,
            };
        }
        case SET_USER_CARD_INFO: {
            return {
                ...state,
                userCardInfo: action.userCardInfo,
                isLoading: false,
            };
        }
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case CLEAR_INFO:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
