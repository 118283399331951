export const getDateZeroTimeZone = (date = new Date(), time: 'current' | 'end' | 'start' = 'start'): Date => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (time === 'end') {
        hours = 23;
        minutes = 59;
        seconds = 59;
    }

    if (time === 'current') {
        hours = date.getHours();
        minutes = date.getMinutes();
        seconds = date.getSeconds();
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds));
};

export const getDateZeroTimeZoneISO = (date?: Date, time?: 'current' | 'end' | 'start'): string =>
    getDateZeroTimeZone(date, time).toISOString();

export const getTomorrowDate = (): Date => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    return date;
};
