import React, { PropsWithChildren } from 'react';

interface PageProps {
    additionalClass?: string;
}

export const Page = (props: PropsWithChildren<PageProps>) => {
    const { additionalClass } = props;
    return <main className={`page-wrapper ${additionalClass ? additionalClass : ''}`}>{props.children}</main>;
};
