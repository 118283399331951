import { AppThunkAction } from 'store';
import api from 'api';
import { AddUserData } from './store';

export const SET_USER_NAME = 'userInfo/SET_USER_NAME';
export const SET_USER_NAME_SUCCESS = 'userInfo/SET_USER_NAME_SUCCESS';
export const SET_USER_NAME_ERROR = 'userInfo/SET_USER_NAME_ERROR';
export const SET_LOADING = 'userInfo/SET_LOADING';
export const CLEAR_MESSAGES = 'userInfo/CLEAR_MESSAGES';

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface SetUserName {
    type: typeof SET_USER_NAME;
}

export interface SetUserNameSuccess {
    type: typeof SET_USER_NAME_SUCCESS;
    message: string;
}

export interface SetUserNameError {
    type: typeof SET_USER_NAME_ERROR;
    error: string;
}

export interface ClearMessages {
    type: typeof CLEAR_MESSAGES;
}

export type ActionTypes = SetUserName | SetUserNameSuccess | SetUserNameError | SetLoading | ClearMessages;

export const actions = {
    addUserName:
        (userData: AddUserData): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });
                await api.put('UserProfile', userData);

                dispatch({
                    type: SET_USER_NAME_SUCCESS,
                    message: 'Имя добавлено',
                });
            } catch {
                dispatch({
                    type: SET_USER_NAME_ERROR,
                    error: 'Error',
                });
            }
        },
    updateUserName:
        (userData: AddUserData): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });
                await api.post('UserProfile/Update', userData);

                dispatch({
                    type: SET_USER_NAME_SUCCESS,
                    message: 'Имя добавлено',
                });
            } catch {
                dispatch({
                    type: SET_USER_NAME_ERROR,
                    error: 'Error',
                });
            }
        },
    clearMessages: () => ({ type: CLEAR_MESSAGES }),
};
