import React, { InputHTMLAttributes } from 'react';

import { Icon } from '../../Icon';
import { Label } from '../Label';

type RefType = string | ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null;

interface Option {
    label: string;
    value: string | number;
}

interface ToggleProps {
    name: string;
    label?: string;
    inputRef: RefType;
    defaultValue: number;
    firstOption: Option;
    secondOption: Option;
}

export const Toggle = (props: ToggleProps & InputHTMLAttributes<HTMLInputElement>) => {
    const { name, inputRef, firstOption, secondOption, defaultValue, label } = props;

    return (
        <>
            {label && (
                <div className="bottom-space-3x">
                    <Label labelText={label} labelFor={name} />
                </div>
            )}

            <label className="toggle">
                <input
                    id="first-option"
                    type="radio"
                    name={name}
                    defaultChecked={defaultValue === firstOption.value}
                    ref={inputRef}
                    value={firstOption.value}
                />
                <input
                    id="second-option"
                    type="radio"
                    name={name}
                    ref={inputRef}
                    defaultChecked={defaultValue === secondOption.value}
                    value={secondOption.value}
                />
                <span className="toggle-slider">
                    <label htmlFor="first-option" className="first-option">
                        {firstOption.label}
                    </label>
                    <label htmlFor="second-option" className="second-option">
                        {secondOption.label}
                    </label>
                    <Icon iconName="check-mark" />
                </span>
            </label>
        </>
    );
};
