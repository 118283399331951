import { TFunction } from 'i18next';
import moment from 'moment';
import React from 'react';
import { dateFormat } from '../../constants/date-format';
import { LanguageEnum } from '../../enums/language.enum';
import { getPartnerLink } from '../../utils/partner-link';
import { Button } from '../Button';

import { Grid, Row } from '../Grid';
import { EmptyListWarning } from '../EmptyListWarning';
import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';

export interface StudyGroupGridItemProps {
    id: number;
    name?: string;
    testLanguage?: LanguageEnum;
    testName: string;
    code: string;
    zipCode: string;
    dateStart: string;
    dateEnd: string;
    quantityTimesApplies?: number;
    leftQuantity?: number;
}

export interface StudyGroupGridProps {
    studyGroups: StudyGroupGridItemProps[];
    handleCodeClick?: (code: string) => void;
    handleLinkClick?: (code: string) => void;
    handleClickDelete?: (id: number) => void;
    handleClickEdit?: (id: number) => void;
    additionalClass?: string;
}

export const StudyGroupGrid = (props: StudyGroupGridProps) => {
    const { studyGroups, handleCodeClick, handleLinkClick, handleClickDelete, handleClickEdit } = props;

    const { t } = useTranslation('column-title');

    const getGridData = (): Row[] =>
        studyGroups.map((group) => ({
            code: {
                additionalClasses: 'link',
                value: group.code,
                handleClick: () => handleCodeClick && handleCodeClick(group.code),
            },
            partnerLink: {
                additionalClasses: 'link',
                value: 'Ссылка для партнера',
                handleClick: () =>
                    handleLinkClick && handleLinkClick(getPartnerLink(group.zipCode, group.testLanguage)),
            },
            name: group.name,
            test: group.testName,
            quantityTimesApplies: group.quantityTimesApplies,
            leftQuantity: group.leftQuantity,
            dateEnd: moment(group.dateEnd).format(dateFormat),
            dateStart: moment(group.dateStart).format(dateFormat),
            actions: {
                additionalClasses: 'cell-button-group',
                additionalComponent: (
                    <>
                        {handleClickEdit && (
                            <Button
                                pSize="none"
                                title="Изменить категорию"
                                handleClick={() => handleClickEdit(group.id)}
                            >
                                <Icon iconName="edit" />
                            </Button>
                        )}
                        {handleClickDelete && (
                            <Button
                                pSize="none"
                                title="Удалить категорию"
                                handleClick={() => handleClickDelete(group.id)}
                            >
                                <Icon iconName="delete" />
                            </Button>
                        )}
                    </>
                ),
            },
        }));

    if (!studyGroups.length) {
        return <EmptyListWarning />;
    }

    return (
        <Grid
            rows={getGridData()}
            columns={getColumns(!!handleCodeClick && !!handleLinkClick, !!handleClickDelete && !!handleClickEdit, t)}
        />
    );
};

const getColumns = (showCode: boolean, showAction: boolean, t: TFunction) => {
    let columns = {};

    if (showCode) {
        columns = {
            code: {
                value: t('code'),
                columnSize: '10',
            },
            partnerLink: {
                value: t('partner-link'),
                columnSize: '10',
            },
        };
    }

    columns = {
        ...columns,
        name: {
            value: t('name'),
            columnSize: showCode ? '15' : '25',
        },

        test: {
            value: t('test'),
            columnSize: showCode ? '15' : '25',
        },
        quantityTimesApplies: {
            value: t('participants-number'),
            columnSize: '10',
        },
        leftQuantity: {
            value: t('participants-left'),
            columnSize: '10',
        },
        dateStart: {
            value: t('session-start'),
            columnSize: '10',
        },
        dateEnd: {
            value: t('session-end'),
            columnSize: '10',
        },
    };

    if (showAction) {
        columns = {
            ...columns,
            actions: {
                value: '',
                columnSize: '5',
            },
        };
    }

    return columns;
};
