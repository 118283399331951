import { reducer as categoriesReducer, CategoriesState } from 'pages/categories/store/store';
import {
    reducer as adminAuthenticationReducer,
    AdminAuthenticationState,
} from 'authentication/AdminAuthenticationStore';
import { reducer as questionsReducer, QuestionsState } from 'pages/questions/store/store';
import { reducer as testReducer, TestsState } from 'pages/tests/store/store';
import { reducer as userTestReducer, UserTestState } from 'pages/user-test/store';
import { reducer as superAdminReducer, SuperAdminState } from 'pages/super-admin/store';
import { reducer as studyGroupsReducer, StudyGroupState } from 'pages/study-groups/store/store';
import { reducer as studyCentersReducer, StudyCenterState } from 'pages/study-centers/store/store';
import { reducer as attemptListReducer, AttemptsListState } from 'pages/attempts-list/store/store';
import { reducer as userInfoReducer, UserInfoState } from 'pages/info/store/store';
import { reducer as userCertificateReducer, UserCertificatesState } from 'pages/certificates/store/store';
import { reducer as usersJournalReducer, UsersJournalState } from 'pages/users-journal/store/store';
import {
    reducer as userAgreementsReducer,
    UserAgreementsState,
} from 'pages/user-agreements/user-agreements-list/store/store';
import {
    reducer as userPartnerAgreementsReducer,
    UserPartnerAgreementsState,
} from 'pages/user-agreements/user-partner-agreements-list/store/store';
import { reducer as usersReducer, UsersState } from 'pages/users/store/store';
import {
    reducer as userAuthenticationReducer,
    UserAuthenticationState,
} from '../authentication/UserAuthenticationStore';
import { studyCenterAdminReducer, StudyCenterAdminState } from '../pages/study-center-admin/store';
import {
    reducer as studyCentersUsersReducer,
    UsersState as StudyCentersUsersState,
} from 'pages/study-centers-users/store/store';
import {
    reducer as studyCentersAdminsReducer,
    AdminsState as StudyCentersAdminsState,
} from 'pages/study-centers-admins/store/store';
import {
    reducer as studyCenterStudentReducer,
    StudyCenterStudentState,
} from '../pages/study-center-student/store/store';
import { reducer as countriesReducer, CountriesState } from './countries/store';
import { reducer as appSettingsReducer, AppSettingsState } from './app-settings/store';
import { reducer as testStatisticsReducer, TestStatisticsState } from '../pages/test-statistic/store/store';

export interface ApplicationState extends StudyCenterAdminState {
    appSettings: AppSettingsState;
    categories?: CategoriesState;
    adminAuthentication?: AdminAuthenticationState;
    userAuthentication?: UserAuthenticationState;
    questions: QuestionsState;
    tests?: TestsState;
    userTest: UserTestState;
    studyGroups?: StudyGroupState;
    studyCenters?: StudyCenterState;
    superAdmin: SuperAdminState;
    attemptList: AttemptsListState;
    userInfo: UserInfoState;
    userCertificates: UserCertificatesState;
    usersJournal: UsersJournalState;
    userAgreements: UserAgreementsState;
    userPartnerAgreements: UserPartnerAgreementsState;
    users: UsersState;
    studyCentersUsers: StudyCentersUsersState;
    studyCentersAdmins: StudyCentersAdminsState;
    studyCenterStudent: StudyCenterStudentState;
    testStatistics: TestStatisticsState;
    // global data
    countries: CountriesState;
}

export const reducers = {
    appSettings: appSettingsReducer,
    categories: categoriesReducer,
    adminAuthentication: adminAuthenticationReducer,
    userAuthentication: userAuthenticationReducer,
    tests: testReducer,
    questions: questionsReducer,
    userTest: userTestReducer,
    studyGroups: studyGroupsReducer,
    studyCenters: studyCentersReducer,
    superAdmin: superAdminReducer,
    attemptList: attemptListReducer,
    userInfo: userInfoReducer,
    userCertificates: userCertificateReducer,
    usersJournal: usersJournalReducer,
    userAgreements: userAgreementsReducer,
    userPartnerAgreements: userPartnerAgreementsReducer,
    users: usersReducer,
    studyCentersUsers: studyCentersUsersReducer,
    studyCentersAdmins: studyCentersAdminsReducer,
    studyCenterStudent: studyCenterStudentReducer,
    testStatistics: testStatisticsReducer,

    countries: countriesReducer,

    ...studyCenterAdminReducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
