import {
    reducer as tcAuthenticationReducer,
    TCAdminAuthenticationState,
} from '../../authentication/TCAdminAuthenticationStore';
import {
    reducer as trainingGroupTcAdminReducer,
    TrainingGroupTCAdminState,
} from './training-group-tc-admin/store/store';
import { reducer as studyCentersUsersReducer, StudyCenterUsersState } from './study-centers-users-tc-admin/store/store';

export const studyCenterAdminReducer = {
    tcAuthentication: tcAuthenticationReducer,
    trainingGroupTcAdmin: trainingGroupTcAdminReducer,
    studyCenterUsersTcAdmin: studyCentersUsersReducer,
};

export interface StudyCenterAdminState {
    tcAuthentication?: TCAdminAuthenticationState;
    trainingGroupTcAdmin?: TrainingGroupTCAdminState;
    studyCenterUsersTcAdmin?: StudyCenterUsersState;
}
