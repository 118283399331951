import { Country } from '../../models/country';
import { getCountryTransKey } from '../../utils/get-country-trans-key';
import { AppThunkAction } from '../index';

export const SET_COUNTRIES = 'countries/SET_COUNTRIES';
export const SET_LOADING = 'countries/SET_LOADING';

export interface SetCountries {
    type: typeof SET_COUNTRIES;
    countries: Country[];
}

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

const lastId = 256;
export type ActionTypes = SetCountries | SetLoading;

export const actions = {
    getCountries: (): AppThunkAction<ActionTypes> => async (dispatch, getState) => {
        try {
            const {
                countries: { isLoaded, countries },
            } = getState();

            dispatch({
                type: SET_LOADING,
            });

            const countriesResult = countries;

            if (!isLoaded || !countriesResult?.length) {
                for (let i = 1; i <= lastId; i++) {
                    countriesResult.push({
                        id: i,
                        nameKey: getCountryTransKey(i),
                    });
                }

                dispatch({
                    type: SET_COUNTRIES,
                    countries: countriesResult,
                });
            }

            dispatch({
                type: SET_LOADING,
                isLoading: false,
            });
        } catch (e) {
            console.error(e);
            dispatch({
                type: SET_LOADING,
                isLoading: false,
            });
        }
    },
};
