import { Action, Reducer } from 'redux';
import { CertDirection } from '../../../enums/cert-direction';
import { LanguageEnum } from '../../../enums/language.enum';
import { ProductLKType } from '../../../enums/product-lk-type';
import { SelectOption } from '../../../models/select-option';
import { TrainingGroup } from '../../../models/training-group/training-group';

import {
    ActionTypes,
    ADD_STUDY_GROUP,
    CLEAR_MESSAGE_STUDY_GROUP,
    CLEAR_STUDY_GROUP,
    DELETE_STUDY_GROUP,
    ERROR_STUDY_GROUP,
    GET_STUDY_CENTERS,
    GET_STUDY_GROUP,
    GET_TESTS,
    LOAD_STUDY_GROUPS,
    SET_DIRECTION,
    SET_LANGUAGE,
    SET_LOADING,
    SET_STUDY_CENTER,
    SET_TEST_TYPE,
    SUCCESS_STUDY_GROUP,
    UPDATE_STUDY_GROUP,
} from './actions';

export interface StudyGroupState {
    studyGroups: TrainingGroup[];
    editableStudyGroup: TrainingGroup;
    isLoading: boolean;
    studyGroupSuccess: string;
    studyGroupError: string;
    tests: SelectOption[];
    studyCenters: SelectOption[];
    selectedDirectionId: CertDirection;
    selectedStudyCenterId?: number;
    selectedLanguage?: LanguageEnum;
    selectedTestType?: ProductLKType;
}

const initialValue: Readonly<StudyGroupState> = {
    editableStudyGroup: {} as TrainingGroup,
    isLoading: false,
    studyGroupSuccess: '',
    studyGroupError: '',
    tests: [],
    studyCenters: [],
    studyGroups: [],
    selectedDirectionId: CertDirection.CyberSecurity,
};

export const reducer: Reducer<StudyGroupState> = (
    state: StudyGroupState = initialValue,
    incomingAction: Action
): StudyGroupState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case LOAD_STUDY_GROUPS:
            return {
                ...state,
                studyGroups: action.studyGroups,
                isLoading: false,
            };
        case GET_STUDY_GROUP:
            return {
                ...state,
                editableStudyGroup: action.studyGroup,
                isLoading: false,
            };
        case GET_STUDY_CENTERS:
            return {
                ...state,
                studyCenters: action.studyCenters,
            };
        case GET_TESTS:
            return {
                ...state,
                tests: action.tests,
            };
        case ADD_STUDY_GROUP:
            return {
                ...state,
                isLoading: false,
                studyGroupSuccess: 'Учебная группа добавлена',
            };
        case DELETE_STUDY_GROUP:
            return {
                ...state,
                studyGroups: state.studyGroups.filter((x) => x.id !== action.studyGroupId),
                isLoading: false,
                studyGroupSuccess: 'Учебная группа удаленa',
            };
        case UPDATE_STUDY_GROUP:
            return {
                ...state,
                studyGroupSuccess: 'Учебная группа обновлена',
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        case SUCCESS_STUDY_GROUP:
            return {
                ...state,
                studyGroupSuccess: action.studyGroupSuccess,
            };
        case ERROR_STUDY_GROUP:
            return {
                ...state,
                studyGroupError: action.studyGroupError,
                isLoading: false,
            };
        case CLEAR_MESSAGE_STUDY_GROUP:
            return {
                ...state,
                studyGroupSuccess: '',
                studyGroupError: '',
            };
        case CLEAR_STUDY_GROUP:
            return {
                ...state,
                editableStudyGroup: initialValue.editableStudyGroup,
            };
        case SET_DIRECTION:
            return {
                ...state,
                selectedDirectionId: action.directionId,
            };
        case SET_STUDY_CENTER:
            return {
                ...state,
                selectedStudyCenterId: action.studyCenterId,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.language,
            };
        case SET_TEST_TYPE:
            return {
                ...state,
                selectedTestType: action.testType,
            };
        default:
            return state;
    }
};
