import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { useWindowWidth, sizes, mapSecondsToTimeString } from 'utils';
import { Attempt } from '../store/store';
import { AttemptsGrid, EmptyListWarning, Column } from 'components';
import { AttemptAccordion } from './AttemptAccordion';

interface AttemptsProps {
    attempts: Attempt[];
    setModalIsOpen: (value: boolean) => void;
    getAttemptDetailed: (value: number) => void;
}

export const Attempts = (props: AttemptsProps) => {
    const { attempts, setModalIsOpen, getAttemptDetailed } = props;
    const [t] = useTranslation(['attempts-list']);
    const width = useWindowWidth();

    if (!attempts.length) {
        return (
            <Column columnSize="100">
                <EmptyListWarning />
            </Column>
        );
    }

    return (
        <>
            {width > sizes.sm ? (
                <AttemptsGrid
                    attempts={getGridViews({
                        attempts,
                        t,
                        setModalIsOpen,
                        getAttemptDetailed,
                    })}
                />
            ) : (
                <div className="accordions">
                    {attempts.map((x, index) => (
                        <AttemptAccordion
                            key={`${x.testTitle}-${index}`}
                            attempt={x}
                            title={x.testTitle}
                            handleDetailsClick={() => {
                                setModalIsOpen(true);
                                getAttemptDetailed(x.passingTestId);
                            }}
                        />
                    ))}
                </div>
            )}
        </>
    );
};
interface GetGridViewProps {
    attempts: Attempt[];
    t: TFunction;
    getAttemptDetailed: (value: number) => void;
    setModalIsOpen: (value: boolean) => void;
}

const getGridViews = (props: GetGridViewProps) => {
    const { attempts, t, setModalIsOpen, getAttemptDetailed } = props;
    return attempts.map((x) => ({
        ...x,
        certificateData: getCertificateLink(x.isGiveCertificate, x.certificateLink, x.isFailure, x.passingTestId),
        spentSeconds: mapSecondsToTimeString(+x.spentSeconds, t),
        descriptionOfEvaluation: getAttemptResultMessage(x, t),
        handleDetailsClick: () => {
            getAttemptDetailed(x.passingTestId);
            setModalIsOpen(true);
        },
    }));
};

export const getCertificateLink = (
    isGiveCertificate: boolean,
    certificateLink: string | null,
    isFailure: boolean,
    testId: number
) => {
    if (isFailure) {
        return null;
    }
    if (certificateLink) {
        return {
            text: 'certificate',
            link: `/certificates/${testId}`,
        };
    }
    if (isGiveCertificate) {
        return {
            text: 'get-certificate',
            link: '/certificates/confirm-data',
        };
    }

    return null;
};

export const getAttemptResultMessage = (attempt: Attempt, t: TFunction) =>
    attempt.isFailure ? t('failure') : attempt.descriptionOfEvaluation;
