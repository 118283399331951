import { Label, ValidationMessage } from 'components/Form';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-dropdown-select';

export interface Item {
    id: string;
    name: string;
}

interface Props {
    isDisabled?: boolean;
    getArrAsync: (str?: string) => Promise<Item[]>;
    onChange: (val?: Item[]) => void;
    isRequired?: boolean;
    label?: string;
    id: string;
    name: string;
    additionalClasses?: string;
    labelField: string;
    errorMessage?: string;
    isMulty?: boolean;
    values: Item[];
    valueField: string;
}

export const AutoCompliteMany: FC<Props> = ({
    isDisabled,
    onChange,
    getArrAsync,
    isRequired,
    label,
    name,
    id,
    additionalClasses,
    labelField,
    errorMessage,
    isMulty,
    valueField,
    values,
}) => {
    const [arr, setArr] = useState<Item[]>([]);
    const [searchStr, setSearchStr] = useState<string>();
    const [timeout, setTimeoutRef] = useState<NodeJS.Timeout>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getArrAsync()
            .then((data) => {
                setArr(data);
            })
            .finally(() => {
                setLoading(false);
                onChange(values);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        if (timeout) {
            setTimeoutRef(undefined);
            clearTimeout(timeout);
        }
        setTimeoutRef(
            setTimeout(() => {
                getArrAsync(searchStr)
                    .then((data) => {
                        setArr(data);
                    })
                    .finally(() => {
                        setLoading(false);
                        if (timeout) {
                            setTimeoutRef(undefined);
                            clearTimeout(timeout);
                        }
                    });
            }, 400)
        );
    }, [searchStr]);

    const withError = errorMessage ? 'with-error' : '';
    const classes = `${additionalClasses} ${withError} dropdown-select multy-select`;

    return (
        <>
            {label && <Label labelText={label} labelFor="" />}
            <Select
                multi={isMulty}
                className={classes}
                disabled={isDisabled}
                onClearAll={() => setSearchStr('')}
                key={id}
                name={name}
                options={arr}
                values={values}
                onChange={onChange}
                required={isRequired}
                labelField={labelField}
                valueField={valueField}
                searchable
                clearable
                searchFn={(e) => {
                    setSearchStr(e.state.search);
                    return arr;
                }}
                loading={loading}
            />
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </>
    );
};
