import { LOGOUT_PATH } from 'global-constants';
import React, { FC, MouseEvent } from 'react';
import { Role } from '../../enums/roles';

import { Icon } from '../Icon';

export interface LogoutProps {
    handleLogout?: () => void;
    role?: Role;
}

export const Logout: FC<LogoutProps> = ({ role, handleLogout }) => {
    if (role === Role.SuperAdmin && handleLogout) {
        const handleClick = (e: MouseEvent<HTMLElement>): void => {
            e.preventDefault();
            handleLogout();
        };

        return (
            <a href="/#" className="logout" title="logout" onClick={handleClick}>
                <Icon iconName="logout" />
            </a>
        );
    }

    return (
        <a href={LOGOUT_PATH} className="logout" title="logout">
            <Icon iconName="logout" />
        </a>
    );
};
