import React, { useEffect, useState } from 'react';
import {
    Button,
    Column,
    Container,
    EmptyListWarning,
    GridItemProps,
    ModalWindow,
    TestGrid,
    TextInput,
    Title,
} from 'components';
import { useAlert } from 'react-alert';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationState } from 'store';
import { connectComponent, withLoading } from 'utils';
import SelectByEnum from '../../components/SelectByEnum/SelectByEnum';
import SelectDirection from '../../components/SelectDirection/SelectDirection';
import { CertDirection } from '../../enums/cert-direction';
import { ProductLKType } from '../../enums/product-lk-type';
import { TestShort } from '../../models/test/test-short';
import { actions } from './store/actions';
import { TestsState } from './store/store';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import { useUrlQueryParamsForGrid } from '../../hooks/useUrlQueryParamsForGrid';
import { useQuery } from '../../hooks';
import { AutoComplite, Item } from 'components/AutoComplite/AutoComplite';
import { getAreas } from 'pages/test-areas/services/TestAreaService';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect/useDebouncedEffect';
import { initTestSortArr, testSort, testSortArr } from 'types/sort.types';
import { activeColumn } from 'types/column.sort.type';
import { getGroups } from 'pages/productGroups/services/ProductGroupService';

type TestListProps = TestsState & typeof actions;

const sizeUrlParam = 'size';
const pageUrlParam = 'page';

const TestList = (props: TestListProps) => {
    const {
        init,
        tests,
        loadTests,
        testError,
        testSuccess,
        clearMessage,
        deleteTest,
        selectedDirectionId,
        selectedLanguage,
        selectedTestAreaId,
        selectedProductGroupId,
        setDirection,
        setLanguage,
        selectedTestType,
        setTestType,
        setTestArea,
        setProductGroup,
        getExcel,
    } = props;
    const alert = useAlert();
    const { pathname } = useLocation();
    const { push } = useHistory();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [testId, setTestId] = useState(-1);
    const queryParams = useQuery();
    const urlSize = +(queryParams.get(sizeUrlParam) as string);
    const urlPage = +(queryParams.get(pageUrlParam) as string);
    const [testGridItems, setTestGridItems] = useState<GridItemProps[]>([]);
    const [sortList, setSortList] = useState<testSortArr>(initTestSortArr);
    const [testName, setTestName] = useState('');

    const getActiveSort = (): activeColumn => {
        let result: activeColumn = null;

        Object.keys(sortList).map((key) => {
            const t = key as testSort;

            if (sortList[t].isActive) {
                result = {
                    ...sortList[t],
                    name: t,
                };
            }
        });

        return result;
    };

    const { pagination } = useUrlQueryParamsForGrid({
        total: tests.length,
        loadFn: (params) => {
            const { page, size } = params;
            getTestGridItems(tests, page, size);
        },
    });

    const setTests = () => {
        const filter = getActiveSort();
        loadTests({
            directionId: selectedDirectionId,
            testType: selectedDirectionId === CertDirection.ProductLK ? selectedTestType : undefined,
            language: selectedLanguage,
            testAreaId: selectedDirectionId === CertDirection.ProductLK ? selectedTestAreaId : undefined,
            productGroupId: selectedDirectionId === CertDirection.ProductLK ? selectedProductGroupId : undefined,
            testName: testName ?? null,
            sortFieldName: filter?.name,
            desc: filter?.desc,
        });
    };

    useEffect(() => {
        if (init) return;
        setTests();
    }, [
        loadTests,
        selectedDirectionId,
        selectedTestType,
        selectedLanguage,
        selectedTestAreaId,
        sortList,
        selectedProductGroupId,
    ]);

    useEffect(() => {
        if (testSuccess) {
            alert.success(testSuccess);
        } else if (testError) {
            alert.error(testError);
        }

        clearMessage();
    }, [testSuccess, testError, alert, clearMessage]);

    useEffect(() => {
        getTestGridItems(tests, urlPage, urlSize);
    }, [tests]);

    const handleClickEdit = (id: number) => {
        push(`${pathname}/edit/${id}`);
    };

    const handleClickDelete = (id: number) => {
        setTestId(id);
        changeModalState();
    };

    const handleClickAdd = () => {
        push(`${pathname}/add`);
    };
    const handleAreas = () => {
        push(`${pathname}/areas`);
    };
    const handleProductGroups = () => {
        push(`${pathname}/productGroups`);
    };

    const handelDeleteTest = (id: number) => {
        deleteTest(id);
        changeModalState();
    };

    const changeModalState = () => {
        setModalIsOpen(!modalIsOpen);
    };

    useDebouncedEffect(setTests, [testName], 400);

    const getTestGridItems = (items: TestShort[], page: number, size: number): void => {
        setTestGridItems(
            items.slice(page * size, (page + 1) * size).map((x) => ({
                key: x.id,
                id: x.id,
                name: x.title,
                questionCount: x.quantityQuestions as number,
                time: x.maxQuantityMinutes,
                linkHref: `${pathname}/edit/${x.id}`,
                testLanguage: x.testLanguage,
                testArea: x.testArea,
                certificationFinishDate: x.certificationFinishDate,
                certificationMonthCount: x.certificationMonthCount,
                isVersionActive: x.isVersionActive,
                creationDate: x.creationDate,
                certificationDirection: x.certificationDirection,
                productGroup: x.productGroup,
                testType: x.testType,
                editDate: x.editDate,
                handleClickDelete,
                handleClickEdit,
            }))
        );
    };

    const loadExcel = () => {
        const filter = getActiveSort();
        getExcel({
            directionId: selectedDirectionId,
            testType: selectedDirectionId === CertDirection.ProductLK ? selectedTestType : undefined,
            language: selectedLanguage,
            testAreaId: selectedDirectionId === CertDirection.ProductLK ? selectedTestAreaId : undefined,
            testName: testName ?? null,
            sortFieldName: filter?.name,
            desc: filter?.desc,
            productGroupId: selectedDirectionId === CertDirection.ProductLK ? selectedProductGroupId : undefined,
        });
    };

    const changeSort = (field: testSort) => {
        if (!initTestSortArr[field]) {
            console.error('sort is not valid');
            return;
        }

        Object.keys(sortList).map((key) => {
            const t = key as testSort;
            if (t === field) {
                if (!sortList[t].isActive)
                    sortList[t] = {
                        isActive: true,
                        desc: false,
                    };
                else if (sortList[t].isActive && !sortList[t].desc)
                    sortList[t] = {
                        isActive: true,
                        desc: true,
                    };
                else
                    sortList[t] = {
                        isActive: false,
                        desc: false,
                    };
            } else {
                sortList[t] = {
                    isActive: false,
                    desc: false,
                };
            }
        });

        setSortList({ ...sortList });
    };

    return (
        <Container>
            <ModalWindow
                isOpen={modalIsOpen}
                onCloseClick={changeModalState}
                modalMessage={getModalMessage(tests, testId)}
                confirmButtonText="Удалить"
                cancelButtonText="Отмена"
                onConfirmAction={() => handelDeleteTest(testId)}
            />
            <Column columnSize="50">
                <Title titleText="Тесты" />
            </Column>
            <Column columnSize="100" className="text-right">
                <Button buttonClass="primary" handleClick={loadExcel}>
                    Excel
                </Button>
                <Button buttonClass="primary" handleClick={handleAreas}>
                    Область применения
                </Button>
                <Button buttonClass="primary" handleClick={handleProductGroups}>
                    Продуктовые группы
                </Button>
                <Button buttonClass="primary" handleClick={handleClickAdd}>
                    Создать тест
                </Button>
            </Column>
            <Column columnSize="100" className="p-right-space-3x">
                <TextInput
                    id="search-field"
                    label="Название"
                    value={testName}
                    onChangeHandler={(e) => setTestName(e.target.value)}
                />
            </Column>
            <Column columnSize="50" className="p-right-space-3x">
                <SelectDirection selectedDirectionId={selectedDirectionId} onChange={setDirection} />
                {selectedDirectionId === CertDirection.ProductLK && (
                    <>
                        <div className="top-space-3x">
                            <SelectByEnum
                                selectedId={selectedTestType}
                                label="Выберите тип теста"
                                onChange={setTestType}
                                enumOptions={ProductLKType}
                                hasEmpty
                            />
                        </div>
                        <div className="top-space-3x">
                            <AutoComplite
                                label="Область применения"
                                getArrAsync={(s?: string) => getAreas(0, 20, s).then((d) => d.data.items)}
                                labelField="name"
                                name="testAreaId"
                                id="testAreaId"
                                onChange={(e: Item | undefined) => setTestArea(e?.id)}
                            />
                        </div>
                        <div className="top-space-3x">
                            <AutoComplite
                                label="Продуктовая группа"
                                getArrAsync={(s?: string) => getGroups(0, 20, s).then((d) => d.data.items)}
                                labelField="name"
                                name="productGroupId"
                                id="productGroupId"
                                onChange={(e: Item | undefined) => setProductGroup(e?.id)}
                            />
                        </div>
                    </>
                )}
            </Column>

            <Column columnSize="50" className="p-right-space-3x">
                <SelectLanguage selectedLanguage={selectedLanguage} onChange={setLanguage} hasEmpty />
            </Column>

            <Column columnSize="100" className="top-space-10x">
                {tests.length ? (
                    <TestGrid
                        items={testGridItems}
                        pagination={pagination}
                        changeSort={changeSort}
                        sortArr={sortList}
                        showLk={selectedDirectionId === CertDirection.ProductLK}
                    />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.tests,
    actions,
    component: withLoading(TestList),
});

const getModalMessage = (tests: TestShort[], testId: number) => {
    const currentTest = tests.find((x) => x.id === testId);
    if (currentTest && currentTest.studyGroups?.length) {
        return 'Вы уверены, что хотите удалить данный тест и все учебные группы, относящиеся к данному тесту?';
    }

    return 'Вы уверены, что хотите удалить данный тест?';
};
