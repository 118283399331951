import React from 'react';
import { connect } from 'react-redux';
import { Page, Container, Column, Title, Button, Paragraph } from 'components';
import { ApplicationState } from 'store';
import { useTitle, withLoading } from 'utils';
import { actions, AdminAuthenticationState } from '../../authentication/AdminAuthenticationStore';
import { ADMIN_LOGIN_PATH, ADMIN_LOGOUT_PATH } from '../../global-constants';
import UpdateAdminProfile from '../update-admin/UpdateAdminProfile';

type AdminLoginProps = typeof actions & AdminAuthenticationState;

const AdminLogin = (props: AdminLoginProps) => {
    const { admin } = props;
    const handleLogIn = () => {
        document.location.href = ADMIN_LOGIN_PATH;
    };
    const handleLogOut = () => {
        document.location.href = ADMIN_LOGOUT_PATH;
    };

    useTitle('Авторизация');

    return (
        <Page>
            <Container>
                <Column columnSize="100" className="text-left">
                    <Title titleText="Вход в портал администратора" />
                    {admin &&
                        (admin.lastName ? (
                            <Paragraph additionalClass="secondary">Дождитесь подтверждения администратора</Paragraph>
                        ) : (
                            <Paragraph additionalClass="secondary">
                                Заполните поля формы и дождитесь подтверждения администратора
                            </Paragraph>
                        ))}
                </Column>
                {admin && <UpdateAdminProfile />}
                <Column columnSize="100" className="text-left">
                    {!admin && (
                        <Button handleClick={handleLogIn} buttonClass="primary">
                            Войти
                        </Button>
                    )}

                    {admin && (
                        <Button handleClick={handleLogOut} buttonClass="primary">
                            Выход
                        </Button>
                    )}
                </Column>
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => state.adminAuthentication,
    actions
)(withLoading(AdminLogin) as () => JSX.Element);
