import { ResponsePagination } from '../../../models/response-pagination';
import { StudyCenterUser } from '../../../models/study-center-users/study-center-user';
import { GetUsersParams } from '../../../models/user/get-user-params';
import { AdminUserControlService } from '../../../services/admin-user-control.service';
import { AppThunkAction } from '../../../store';

export const SET_STUDY_CENTERS_USERS = 'users/SET_STUDY_CENTERS_USERS';
export const SET_LOADING = 'users/SET_LOADING';
export const DELETE_USER = 'users/DELETE_USER';
export const EDIT_USER_IS_PARTNER = 'users/EDIT_USER_IS_PARTNER';

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading: boolean;
}

export interface SetUsers {
    type: typeof SET_STUDY_CENTERS_USERS;
    studyCentersUsers: ResponsePagination<StudyCenterUser>;
}

export interface DeleteUser {
    type: typeof DELETE_USER;
    userId: string;
}

export interface EditUserIsPartner {
    type: typeof EDIT_USER_IS_PARTNER;
    userId: string;
    isPartner: boolean;
}

export type ActionTypes = SetLoading | SetUsers | DeleteUser | EditUserIsPartner;

export const actions = {
    getUsers:
        (params: GetUsersParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const users = (await AdminUserControlService.getStudyCenterUsers(params)).data;

                dispatch({
                    type: SET_STUDY_CENTERS_USERS,
                    studyCentersUsers: users,
                });
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    editIsPartner:
        (userId: string, isPartner: boolean): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                await AdminUserControlService.editUserExternalId(userId, isPartner);

                dispatch({
                    type: EDIT_USER_IS_PARTNER,
                    userId,
                    isPartner,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },

    deleteUser:
        (userId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                await AdminUserControlService.deleteUser(userId);

                dispatch({
                    type: DELETE_USER,
                    userId,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    deleteStudentStudyCenter:
        (userId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                await AdminUserControlService.deleteStudentStudyCenter(userId);

                dispatch({
                    type: DELETE_USER,
                    userId,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getExcel:
        (str?: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                await AdminUserControlService.getStudyCenterUsersXls(str);

                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
};
