import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';

interface HeaderNameProps {
    title?: string;
    titleClick?: () => void;
}

export const HeaderName: FC<HeaderNameProps> = ({ title, titleClick }) => {
    const [t] = useTranslation();

    const getElement = (children: ReactNode | string) => {
        if (!titleClick) {
            return (
                <Link to="/" title="Link Title" className="header-name__title">
                    {children}
                </Link>
            );
        }

        return (
            <Button buttonClass="link" pSize="none" className="header-name__title" handleClick={titleClick}>
                {children}
            </Button>
        );
    };

    return <div className="header-name">{getElement(title || t('header-name'))}</div>;
};
