import moment from 'moment';
import { TFunction } from 'i18next';

export const mapSecondsToTimeString = (spentSeconds: number, t: TFunction): string => {
    const timeString: string[] = [];
    const duration = moment.duration(spentSeconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours) {
        timeString.push(`${hours} ${t('hours')}.`);
    }

    if (minutes) {
        timeString.push(`${minutes} ${t('minutes')}.`);
    }

    if (seconds) {
        timeString.push(`${seconds} ${t('seconds')}.`);
    }

    return timeString.join(' ');
};
