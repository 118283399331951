import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Column } from '../Column';
import { Container } from '../Container';
import { Page } from '../Page';
import { Paragraph } from '../Paragraph';
import { Title } from '../Title';

interface TestPassedWithoutCert {
    hadCert: boolean;
    retryClick?: () => void;
    showCertClick: () => void;
}

const TestPassed: FC<TestPassedWithoutCert> = ({ hadCert, retryClick, showCertClick }) => {
    const { t } = useTranslation('user-test');

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <Column columnSize="50" className="block-center code-column">
                    <Title titleText={t('test-passed-title')} />
                    {hadCert && (
                        <Paragraph additionalClass="lead">
                            {t('test-passed-with-cert-text')}
                            <br />
                            <br />
                            <span className="link" onClick={showCertClick}>
                                {t('show-cert')}
                            </span>
                        </Paragraph>
                    )}
                    {retryClick && (
                        <Button buttonClass="primary" handleClick={retryClick}>
                            {t('retry-button')}
                        </Button>
                    )}
                </Column>
            </Container>
        </Page>
    );
};

export default TestPassed;
