import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
    actions as tcAuthenticationActions,
    TCAdminAuthenticationState,
} from '../../../authentication/TCAdminAuthenticationStore';
import { Button, Column, Container, EmptyListWarning, TextInput, Title } from '../../../components';
import StudyCenterUserGrid from '../../../components/StudyCenterUserGrid/StudyCenterUserGrid';
import { useUrlQueryParamsForGrid } from '../../../hooks/useUrlQueryParamsForGrid';
import { StudyCenterUser } from '../../../models/study-center-users/study-center-user';
import { ApplicationState } from '../../../store';
import { withLoading } from '../../../utils';
import { actions } from './store/actions';
import { StudyCenterUsersState } from './store/store';

type StudyCenterUserListProps = StudyCenterUsersState &
    TCAdminAuthenticationState &
    typeof actions &
    typeof tcAuthenticationActions;

const StudyCenterUserListTcAdmin: FC<StudyCenterUserListProps> = (props) => {
    const { admin, users, loadUsers } = props;
    const [t] = useTranslation('study-center');

    const {
        pagination,
        queryState: [inputQuery, setInputQuery],
    } = useUrlQueryParamsForGrid({
        total: users.count,
        loadFn: (params) =>
            loadUsers({
                page: params.page,
                size: params.size,
                email: params.query,
                studyCenterId: admin?.studyCenter.id as number,
            }),
    });

    const { push } = useHistory();
    const { pathname } = useLocation();

    const editUser = (user: StudyCenterUser) => {
        push(`${pathname}/edit/${user.id}`);
    };

    const viewUser = (user: StudyCenterUser) => {
        push(`${pathname}/view/${user.id}`);
    };

    const handleAddClick = () => {
        push(`${pathname}/add`);
    };

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={t('study-center-users-title')} />
            </Column>
            <Column columnSize="50">
                <TextInput
                    id="search-field"
                    label="Email"
                    value={inputQuery}
                    onChangeHandler={(e) => setInputQuery(e.target.value)}
                />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button buttonClass="primary" handleClick={handleAddClick}>
                    {t('add-user-btn')}
                </Button>
            </Column>

            <Column columnSize="100" className="top-space-10x">
                {users.items?.length ? (
                    <StudyCenterUserGrid
                        users={users.items}
                        onEmailClick={(item) => viewUser(item)}
                        actions={[
                            {
                                iconName: 'edit',
                                handler: editUser,
                            },
                        ]}
                        pagination={pagination}
                    />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

export default connect(
    ({ tcAuthentication, studyCenterUsersTcAdmin }: ApplicationState) => ({
        ...tcAuthentication,
        ...studyCenterUsersTcAdmin,
    }),
    {
        ...tcAuthenticationActions,
        ...actions,
    }
)(withLoading(StudyCenterUserListTcAdmin) as () => JSX.Element);
