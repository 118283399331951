export type ColumnSortProps = {
    isActive?: boolean;
    desc?: boolean;
};

export const initColumnProps: ColumnSortProps = {
    isActive: false,
    desc: false,
};

export type activeColumn = (ColumnSortProps & { name: string }) | null;
