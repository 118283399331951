import React, { useEffect, useState } from 'react';

import { Container, Title, Icon, Column, DetailsModal } from 'components';
import { UserCardGrid } from './UserCardGrid';
import { connect } from 'react-redux';
import { withLoading } from 'utils';
import { ApplicationState } from 'store';
import { actions } from '../store/actions';
import { UsersJournalState } from '../store/store';
import { useParams, useHistory, useLocation } from 'react-router-dom';

type UserCardProps = UsersJournalState & typeof actions;

const UserCard = (props: UserCardProps) => {
    const { getUserCardInfo, userCardInfo, getAttemptDetails, attemptDetailsModalOptions, attemptDetails } = props;
    const { email, journalUserInfoDetails: attempts, userName } = userCardInfo;
    const { userId } = useParams<{ userId: string }>();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (userId) {
            getUserCardInfo(userId);
        }
    }, [getUserCardInfo, userId]);

    return (
        <>
            <Container additionalClass="user-card">
                <Column columnSize="100">
                    <span
                        className="go-back"
                        onClick={() => {
                            if (userId) {
                                push(pathname.replace(`/${userId}`, ''));
                            }
                        }}
                    >
                        <Icon iconName="left-arrow" />
                        <span>Назад</span>
                    </span>
                </Column>
                <Title titleText={userName} />
                <Column className="user-card-mail" columnSize="100">
                    <Icon iconName="message" />
                    <span>{email}</span>
                </Column>
                <UserCardGrid
                    attempts={attempts}
                    getAttemptDetails={getAttemptDetails}
                    setModalIsOpen={setModalIsOpen}
                />
            </Container>
            {modalIsOpen && attemptDetailsModalOptions.resultByCategories.length && (
                <DetailsModal
                    title={`Подробнее (${attemptDetails.attemptNumber}/${attemptDetails.allAttempts})`}
                    details={attemptDetailsModalOptions}
                    handleCloseClick={() => setModalIsOpen(false)}
                    isOpen={modalIsOpen}
                />
            )}
        </>
    );
};

export default connect(
    (state: ApplicationState) => state.usersJournal,
    actions
)(withLoading(UserCard) as () => JSX.Element);
