import { CertDirection } from '../enums/cert-direction';
import { SelectOption } from '../models/select-option';

export const certDirectionOptions: SelectOption[] = [
    {
        value: CertDirection.ProductLK,
        label: 'cert-direction:productLK',
    },
    {
        value: CertDirection.CyberSecurity,
        label: 'cert-direction:cyber-security',
    },
];
