import { LanguageEnum } from '../enums/language.enum';
import { LanguageConfig, LanguageKeys } from '../models/language/language';

export const getActiveLanguages = (): LanguageConfig[] => Object.values(language).filter((lang) => lang.isUsed);

export const language: Record<LanguageKeys, LanguageConfig> = {
    en: {
        id: LanguageEnum.English,
        key: 'en',
        label: 'English',
        labelAdmin: 'English',
        isUsed: true,
    },
    ru: {
        id: LanguageEnum.Russian,
        key: 'ru',
        label: 'Русский',
        labelAdmin: 'Russian',
        isUsed: true,
    },
    de: {
        id: LanguageEnum.German,
        key: 'de',
        label: 'Deutsch',
        labelAdmin: 'German',
        isUsed: true,
    },
    it: {
        id: LanguageEnum.Italian,
        key: 'it',
        label: 'Italiano',
        labelAdmin: 'Italian',
        isUsed: true,
    },
    fr: {
        id: LanguageEnum.French,
        key: 'fr',
        label: 'Français',
        labelAdmin: 'French',
        isUsed: true,
    },
    es: {
        id: LanguageEnum.Spanish,
        key: 'es',
        label: 'Español',
        labelAdmin: 'Spanish',
        isUsed: true,
    },
    es_MX: {
        id: LanguageEnum.SpanishM,
        key: 'es_MX',
        label: 'Español (México)',
        labelAdmin: 'Spanish (Mexico)',
        isUsed: true,
    },
    pt_BR: {
        id: LanguageEnum.PortugueseB,
        key: 'pt_BR',
        label: 'Português (Brasil)',
        labelAdmin: 'Portuguese (Brazil)',
        isUsed: true,
    },
    ja: {
        id: LanguageEnum.Japanese,
        key: 'ja',
        label: '日本語',
        labelAdmin: 'Japanese',
        isUsed: true,
    },
    zh_CN: {
        id: LanguageEnum.ChineseS,
        key: 'zh_CN',
        label: '中文（简体）',
        labelAdmin: 'Chinese (Simplified)',
        isUsed: true,
    },
    ko: {
        id: LanguageEnum.Korean,
        key: 'ko',
        label: '한국어',
        labelAdmin: 'Korean',
        isUsed: true,
    },
};
