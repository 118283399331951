import React, { PropsWithChildren } from 'react';

interface TimerProps {
    secondsLeft: number | string;
    message?: string;
    additionalClasses?: string;
}

export const Timer = (props: PropsWithChildren<TimerProps>) => {
    const { additionalClasses, secondsLeft, message } = props;
    const classes = `user-test-timer ${additionalClasses ? additionalClasses : ''}`;

    return (
        <div className={classes}>
            <div className="timer-value">{secondsLeft}</div>
            {message && <div className="timer-message">{message}</div>}
        </div>
    );
};
