import React, { FC, useState } from 'react';
import { AddProductGroup, ProductGroup, UpdateProductGroup } from './models/ProductGroupsModels';
import { Button, Column, Icon, TextInput } from '../../components';
import ReactModal from 'react-modal';

interface Props {
    updateFunc?: (model: UpdateProductGroup) => void;
    updateModel?: ProductGroup;
    addFunc?: (model: AddProductGroup) => void;
    closeModal: () => void;
}

ReactModal.setAppElement('body');

export const AddModal: FC<Props> = ({ updateFunc, addFunc, updateModel, closeModal }) => {
    const [name, setName] = useState<string>(updateModel?.name ?? '');
    const modalTheme = 'standard';

    const submit = () => {
        if (!updateModel && addFunc) {
            addFunc({
                name,
            });
        } else if (updateFunc && updateModel) {
            updateFunc({
                id: updateModel.id,
                name,
            });
        }
        closeModal();
    };

    return (
        <Column columnSize="100">
            <ReactModal
                isOpen
                onRequestClose={closeModal}
                className={`details-modal react-modal react-modal-${modalTheme}`}
                overlayClassName={`react-modal-overlay react-modal-overlay-${modalTheme}`}
            >
                <div onClick={closeModal} className={`details-modal-close ${modalTheme}`}>
                    <Icon iconName="close" />
                </div>
                <Column columnSize="100">
                    <div className={`details-modal-title ${modalTheme}`}>{updateModel ? 'Обновить' : 'Добавить'}</div>
                </Column>
                <Column columnSize="100">
                    <TextInput
                        id="search-field"
                        label="Название"
                        value={name}
                        onChangeHandler={(e) => setName(e.target.value)}
                    />
                </Column>
                <Column columnSize="100">
                    <Button buttonClass="primary" handleClick={submit} isDisabled={!name || name.length > 100}>
                        Ok
                    </Button>
                </Column>
            </ReactModal>
        </Column>
    );
};
