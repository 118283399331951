import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container, Footer, Header, Page, SidebarMenu } from '../components';
import CertificatePage from '../pages/certificates/certificate-page/CertificatePage';
import StudentStudyCenterExpired from '../pages/study-center-student/Expired';
import StudentStudyCenterLogin from '../pages/study-center-student/Login';
import StudentStudyCenterStartTest from '../pages/study-center-student/StartTest';
import { actions } from '../pages/study-center-student/store/actions';
import { StudyCenterStudentState } from '../pages/study-center-student/store/store';
import { ApplicationState } from '../store';
import { connectComponent } from '../utils';

type UserTestLinkLayoutProps = StudyCenterStudentState;

const UserTestLinkLayout = (props: UserTestLinkLayoutProps) => {
    const { student } = props;
    const { path } = useRouteMatch();

    return (
        <>
            <SidebarMenu />
            <Header user={student} isVisibleLanguagePicker hideLogin />
            <Switch>
                <Page>
                    <Container>
                        <Route exact path={`${path}`} component={StudentStudyCenterLogin} />
                        <Route exact path={`${path}/start-test`} component={StudentStudyCenterStartTest} />
                        <Route path={`${path}/certificates/:id`}>
                            <CertificatePage isStudentStudyCenter />
                        </Route>
                        <Route
                            exact
                            path={`${path}/expired`}
                            component={() => <StudentStudyCenterExpired label={'link-is-expired'} />}
                        />
                        <Route
                            exact
                            path={`${path}/full`}
                            component={() => <StudentStudyCenterExpired label={'group-is-full'} />}
                        />
                    </Container>
                </Page>
            </Switch>
            <Footer />
        </>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyCenterStudent,
    component: UserTestLinkLayout,
    actions: actions,
});
