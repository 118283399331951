import { ResponsePagination } from '../../../../models/response-pagination';
import { AddStudyCenterUser } from '../../../../models/study-center-users/add-study-center-user';
import { GenerateUserLinkParams } from '../../../../models/study-center-users/generate-user-link-params';
import { GetStudyCenterUsersParams } from '../../../../models/study-center-users/get-study-center-users-params';
import { StudyCenterUser } from '../../../../models/study-center-users/study-center-user';
import { StudyCenterUserLink } from '../../../../models/study-center-users/study-center-user-link';
import { UpdateStudyCenterUser } from '../../../../models/study-center-users/update-study-center-user';
import { StudyCenterUsersService } from '../../../../services/study-center-users.service';
import { AppThunkAction } from '../../../../store';

export const LOAD_USERS = 'study-center-users-tc-admin/LOAD_USERS';
export const LOAD_USER = 'study-center-users-tc-admin/LOAD_USER';
export const GENERATE_LINK = 'study-center-users-tc-admin/GENERATE_LINK';
export const ADD_USER = 'study-center-users-tc-admin/ADD_USER';
export const UPDATE_USER = 'study-center-users-tc-admin/UPDATE_USER';
export const CLEAR_EDITABLE_USER = 'study-center-users-tc-admin/CLEAR_EDITABLE_USER';
export const SET_LOADING = 'study-center-users-tc-admin/SET_LOADING';
export const SET_ERROR = 'study-center-users-tc-admin/SET_ERROR';
export const ClEAR_MESSAGES = 'study-center-users-tc-admin/ClEAR_MESSAGES';

export interface LoadUser {
    type: typeof LOAD_USER;
    user: StudyCenterUser;
    userLinks: StudyCenterUserLink[];
}

export interface AddUser {
    type: typeof ADD_USER;
}

export interface UpdateUser {
    type: typeof UPDATE_USER;
}

export interface ClearEditableUser {
    type: typeof CLEAR_EDITABLE_USER;
}

export interface LoadUsers {
    type: typeof LOAD_USERS;
    users: ResponsePagination<StudyCenterUser>;
}

export interface GenerateLink {
    type: typeof GENERATE_LINK;
    userLink: StudyCenterUserLink;
}

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading: boolean;
}

export interface SetError {
    type: typeof SET_ERROR;
    error?: string;
}

export interface ClearMessages {
    type: typeof ClEAR_MESSAGES;
}

export type ActionTypes =
    | SetLoading
    | LoadUsers
    | LoadUser
    | SetError
    | UpdateUser
    | AddUser
    | ClearEditableUser
    | ClearMessages
    | GenerateLink;

export const actions = {
    loadUsers:
        (params: GetStudyCenterUsersParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const users = (await StudyCenterUsersService.getList(params)).data;

                dispatch({
                    type: LOAD_USERS,
                    users,
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                });
            }
        },
    loadUser:
        (id: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const user = (await StudyCenterUsersService.getById(id)).data;
                const userLinks = (await StudyCenterUsersService.getLinksByUserId(id)).data;

                dispatch({
                    type: LOAD_USER,
                    user,
                    userLinks,
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                });
            }
        },
    addUser:
        (params: AddStudyCenterUser): AppThunkAction<ActionTypes> =>
        (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                StudyCenterUsersService.addUser(params).then(
                    () => {
                        dispatch({
                            type: ADD_USER,
                        });
                    },
                    (e) => {
                        dispatch({
                            type: SET_ERROR,
                            error: convertError(e.response.data),
                        });
                    }
                );
            } catch {
                dispatch({
                    type: SET_ERROR,
                    error: 'info:error-message',
                });
            }
        },
    updateUser:
        (params: UpdateStudyCenterUser): AppThunkAction<ActionTypes> =>
        (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                StudyCenterUsersService.updateUser(params).then(
                    () => {
                        dispatch({
                            type: UPDATE_USER,
                        });
                    },
                    (e) => {
                        dispatch({
                            type: SET_ERROR,
                            error: convertError(e.response.data),
                        });
                    }
                );
            } catch {
                dispatch({
                    type: SET_ERROR,
                    error: 'info:error-message',
                });
            }
        },
    clearEditableUser: () => ({ type: CLEAR_EDITABLE_USER }),
    clearMessages: () => ({ type: ClEAR_MESSAGES }),
    generateLink:
        (params: GenerateUserLinkParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const userLink = (await StudyCenterUsersService.generateTempLink(params)).data;

                dispatch({
                    type: GENERATE_LINK,
                    userLink,
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                });
            }
        },
};

const convertError = (code: number): string => {
    switch (code) {
        case 1:
            return 'info:add-error-user-exists';
        case 2:
            return 'info:add-error-user-is-blocked';
        default:
            return 'info:error-message';
    }
};
