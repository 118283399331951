import { Action, Reducer } from 'redux';
import { Role } from '../../../enums/roles';
import { StudyCenterUser } from '../../../models/study-center-users/study-center-user';
import { StudyGroupTestCondition } from '../../../models/study-group-test-condition';
import {
    ActionTypes,
    APPROVE_AGREEMENTS,
    GET_CONDITIONS,
    GET_CONDITIONS_FAILED,
    GET_STUDENT,
    GET_STUDENT_FAILED,
    SET_LOADING,
    SET_TEST_PASSED,
    START_TEST,
} from './actions';

export type AuthStudyCenterUser = StudyCenterUser & {
    approvedAgreements: boolean;
    isStudentStudyCenter: boolean;
    role: Role.StudentStudyCenter;
    name: string;
    personalVersion: number;
    marketingVersion: number;
};

export interface StudyCenterStudentState {
    isLoading: boolean;
    student?: AuthStudyCenterUser;

    studyGroupTestCondition: StudyGroupTestCondition;
    passedTestCondition?: {
        passingTestId: string;
        isSf: boolean;
    };
    isTestStarted: boolean;
    isLinkExpired: boolean;
    isTestPassed: boolean;
    isFull: boolean;
    error?: string;
}

const initialState: Readonly<StudyCenterStudentState> = {
    isLoading: false,
    student: undefined,
    studyGroupTestCondition: {} as StudyGroupTestCondition,
    isTestStarted: false,
    isLinkExpired: false,
    isTestPassed: false,
    isFull: false,
    error: '',
};

export const reducer: Reducer<StudyCenterStudentState> = (
    state: StudyCenterStudentState = initialState,
    incomingAction: Action
): StudyCenterStudentState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case GET_STUDENT:
            return {
                ...state,
                student: action.student,
                isLoading: false,
            };
        case GET_STUDENT_FAILED:
            return {
                ...state,
                isLinkExpired: action.statusCode === 405,
                isFull: action.statusCode === 404 || action.statusCode === 412,
                error: action.error,
                isLoading: false,
            };
        case APPROVE_AGREEMENTS:
            return {
                ...state,
                student: {
                    ...(state.student as AuthStudyCenterUser),
                    approvedAgreements: true,
                    personalVersion: action.personalVersion
                        ? action.personalVersion
                        : state.student?.personalVersion ?? 1,
                    marketingVersion: action.marketingVersion
                        ? action.marketingVersion
                        : state.student?.marketingVersion ?? 1,
                },
                isLoading: false,
            };
        case GET_CONDITIONS:
            return {
                ...state,
                studyGroupTestCondition: action.studyGroupTestCondition,
                isLoading: false,
            };
        case SET_TEST_PASSED:
            return {
                ...state,
                passedTestCondition: action.testCondition,
            };
        case GET_CONDITIONS_FAILED:
            return {
                ...state,
                isFull: action.statusCode === 404 || action.statusCode === 412,
                isTestPassed: action.statusCode === 201,
                isLoading: false,
            };
        case START_TEST:
            return {
                ...state,
                isTestStarted: true,
                isLoading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        default:
            return state;
    }
};
