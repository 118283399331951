import { AppThunkAction } from 'store';
import { CertDirection } from '../../../enums/cert-direction';
import { ProductLKType } from '../../../enums/product-lk-type';
import { GetTestsParams } from '../../../models/test/get-tests-params';
import { Test } from '../../../models/test/test';
import { TestCategoryForm } from '../../../models/test/test-category-form';
import { TestShort } from '../../../models/test/test-short';
import { CategoryService } from '../../../services/category.service';
import { TestService } from '../../../services/test.service';
import { TestFormData } from '../test-form/TestForm';

import { mapTestFormDataToStore, mapToTestCategories } from './mappers';
import { LanguageEnum } from '../../../enums/language.enum';
import { DownloadFileService } from 'services/download-file.service';

export const LOAD_TESTS = 'tests/LOAD_TESTS';
export const ADD_TEST = 'tests/ADD_TEST';
export const UPDATE_TEST = 'tests/UPDATE_TEST';
export const GET_TEST = 'tests/GET_TEST';
export const GET_CATEGORIES = 'tests/GET_CATEGORIES';
export const SET_LOADING = 'tests/SET_LOADING';
export const SET_LOADING_CATEGORIES = 'tests/SET_LOADING_CATEGORIES';
export const CLEAR_EDITABLE_TEST = 'test/EDITABLE_TEST';
export const DELETE_TEST = 'test/DELETE_TEST';
export const SET_SUCCESS_TEST = 'test/SET_SUCCESS_TEST';
export const SET_ERROR_TEST = 'test/SET_ERROR_TEST';
export const CLEAR_MESSAGE_TEST = 'test/CLEAR_MESSAGE_TEST';
export const SET_DIRECTION = 'test/SET_DIRECTION';
export const SET_TEST_TYPE = 'test/SET_TEST_TYPE';
export const SET_TEST_AREA = 'test/SET_TEST_AREA';
export const SET_PRODUCT_GROUP = 'test/SET_PRODUCT_GROUP';
export const SET_LANGUAGE = 'test/SET_LANGUAGE';
export const CHECK_TEST_CATEGORY = 'test/CHECK_TEST_CATEGORY';
export const SET_QUANTITY_SELECTED_QUESTIONS = 'test/SET_QUANTITY_SELECTED_QUESTIONS';

export interface AddTest {
    type: typeof ADD_TEST;
    test: Test;
}

export interface LoadTests {
    type: typeof LOAD_TESTS;
    tests: TestShort[];
}

export interface GetTest {
    type: typeof GET_TEST;
    editableTest: Test;
}

export interface GetCategories {
    type: typeof GET_CATEGORIES;
    editableTestCategories: TestCategoryForm[];
}

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface SetLoadingCategories {
    type: typeof SET_LOADING_CATEGORIES;
    isLoading?: boolean;
}

export interface ClearEditableTest {
    type: typeof CLEAR_EDITABLE_TEST;
}

export interface DeleteTest {
    type: typeof DELETE_TEST;
    testId: number;
}

export interface UpdateTest {
    type: typeof UPDATE_TEST;
    test: Test;
}

export interface SetSuccessTest {
    type: typeof SET_SUCCESS_TEST;
    testSuccess: string;
}

export interface SetErrorTest {
    type: typeof SET_ERROR_TEST;
    testError: string;
}

export interface ClearMessageTest {
    type: typeof CLEAR_MESSAGE_TEST;
}

interface SetDirection {
    type: typeof SET_DIRECTION;
    directionId: CertDirection;
}

interface SetTestType {
    type: typeof SET_TEST_TYPE;
    testType: ProductLKType;
}

interface SetTestArea {
    type: typeof SET_TEST_AREA;
    testAreaId?: string;
}

interface SetProductGroup {
    type: typeof SET_PRODUCT_GROUP;
    productGroupId?: string;
}

interface SetLanguage {
    type: typeof SET_LANGUAGE;
    language: LanguageEnum;
}

interface CheckTestCategory {
    type: typeof CHECK_TEST_CATEGORY;
    id: number;
}

interface SetQuantitySelectedQuestions {
    type: typeof SET_QUANTITY_SELECTED_QUESTIONS;
    id: number;
    value: number;
}

export type ActionTypes =
    | AddTest
    | GetTest
    | GetCategories
    | SetLoadingCategories
    | LoadTests
    | SetLoading
    | ClearEditableTest
    | DeleteTest
    | UpdateTest
    | ClearMessageTest
    | SetErrorTest
    | SetSuccessTest
    | SetDirection
    | SetTestType
    | SetLanguage
    | CheckTestCategory
    | SetQuantitySelectedQuestions
    | SetTestArea
    | SetProductGroup;

export const actions = {
    loadTests:
        (params: GetTestsParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const tests = (await TestService.getList(params)).data;

                dispatch({
                    type: LOAD_TESTS,
                    tests,
                });
            } catch {
                dispatch({
                    type: SET_ERROR_TEST,
                    testError: 'Ошибка',
                });
            }
        },
    getTest:
        (id: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const test = (await TestService.getById(id)).data;

                dispatch({
                    type: GET_TEST,
                    editableTest: test,
                });
            } catch {
                dispatch({
                    type: SET_ERROR_TEST,
                    testError: 'Ошибка',
                });
            }
        },
    getCategories:
        (directionId: CertDirection): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            try {
                dispatch({
                    type: SET_LOADING_CATEGORIES,
                });

                const categories = (await CategoryService.getList(directionId)).data;
                const { tests } = getState();

                dispatch({
                    type: GET_CATEGORIES,
                    editableTestCategories: mapToTestCategories(categories, tests?.editableTest.testCategories || []),
                });
            } catch {
                dispatch({
                    type: SET_ERROR_TEST,
                    testError: 'Ошибка',
                });
            }
        },
    addTest:
        (testData: TestFormData): AppThunkAction<ActionTypes> =>
        (dispatch) => {
            const mappedTest = mapTestFormDataToStore(testData);
            dispatch({
                type: SET_LOADING,
                isLoading: true,
            });
            TestService.add(mappedTest)
                .then(
                    (data) => {
                        dispatch({
                            type: ADD_TEST,
                            test: data.data,
                        });
                    },
                    (e) => {
                        dispatch({
                            type: SET_ERROR_TEST,
                            testError: e?.response?.data || 'Ошибка',
                        });
                    }
                )
                .finally(() => {
                    dispatch({
                        type: SET_LOADING,
                        isLoading: false,
                    });
                });
        },
    deleteTest:
        (id: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                await TestService.delete(id);

                dispatch({
                    type: DELETE_TEST,
                    testId: id,
                });
            } catch (error) {
                dispatch({
                    type: SET_ERROR_TEST,
                    testError: 'Ошибка',
                });
            }
        },
    updateTest:
        (testData: TestFormData): AppThunkAction<ActionTypes> =>
        (dispatch, getState) => {
            const { tests } = getState();
            const mappedTest = { ...mapTestFormDataToStore(testData), id: testData.id };
            dispatch({
                type: SET_LOADING,
                isLoading: true,
            });
            TestService.update(mappedTest)
                .then(
                    (data) => {
                        const sourceFinishDate = tests?.editableTest?.certificationFinishDate ?? '';
                        const editFinishDate = testData?.certificationFinishDate ?? '';

                        const sourceMountCount = tests?.editableTest?.certificationMonthCount ?? undefined;
                        const editMountCount = isNaN(Number(testData?.certificationMonthCount))
                            ? undefined
                            : Number(testData?.certificationMonthCount);

                        const sourceIsActiveVersion = tests?.editableTest?.isActiveVersion ?? false;
                        const editIsActiveVersion = testData?.isActiveVersion ?? false;

                        const sourceCode = tests?.editableTest?.code ?? '';
                        const editCode = testData?.code ?? '';

                        const sourceVersion = tests?.editableTest?.version ?? '';
                        const editVersion = testData?.version ?? '';

                        const sourceArea = tests?.editableTest?.areas?.map((m) => m.id) ?? [];
                        const editArea = testData?.areasId ?? [];

                        const checkAreas =
                            sourceArea.length !== editArea.length ||
                            editArea.find((e) => sourceArea.findIndex((s) => s !== e) >= 0) !== undefined;

                        if (sourceFinishDate !== editFinishDate || sourceMountCount !== editMountCount)
                            dispatch({
                                type: SET_SUCCESS_TEST,
                                testSuccess: 'Успешное изменение срока действия сертификата для теста',
                            });
                        else if (
                            sourceIsActiveVersion !== editIsActiveVersion ||
                            sourceCode !== editCode ||
                            sourceVersion !== editVersion
                        )
                            dispatch({
                                type: SET_SUCCESS_TEST,
                                testSuccess: 'Успешное изменение актуальной версии теста',
                            });
                        else if (checkAreas)
                            dispatch({
                                type: SET_SUCCESS_TEST,
                                testSuccess: 'Успешное изменение области применения',
                            });
                        dispatch({
                            type: UPDATE_TEST,
                            test: data.data,
                        });
                    },
                    (e) => {
                        dispatch({
                            type: SET_ERROR_TEST,
                            testError: e?.response?.data || 'Невозможно сохранить изменения в тесте',
                        });
                    }
                )
                .finally(() => {
                    dispatch({
                        type: SET_LOADING,
                        isLoading: false,
                    });
                });
        },
    clearEditableTest: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        dispatch({
            type: CLEAR_EDITABLE_TEST,
        });
    },
    clearMessage: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        dispatch({
            type: CLEAR_MESSAGE_TEST,
        });
    },
    setDirection: (directionId: CertDirection) => ({
        type: SET_DIRECTION,
        directionId,
    }),
    setLanguage: (language: LanguageEnum) => ({
        type: SET_LANGUAGE,
        language,
    }),
    setTestType: (testType: ProductLKType) => ({
        type: SET_TEST_TYPE,
        testType,
    }),
    setTestArea: (testAreaId?: string) => ({
        type: SET_TEST_AREA,
        testAreaId,
    }),
    setProductGroup: (productGroupId?: string) => ({
        type: SET_PRODUCT_GROUP,
        productGroupId,
    }),
    checkTestCategory: (id: number) => ({
        type: CHECK_TEST_CATEGORY,
        id,
    }),
    setQuantitySelectedQuestions: (value: number, id: number) => ({
        type: SET_QUANTITY_SELECTED_QUESTIONS,
        id,
        value,
    }),
    getExcel:
        (params: GetTestsParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                await DownloadFileService.download('Test/TestsXls', { ...params });
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            } catch (e) {
                console.error(e);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
};
