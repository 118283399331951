import React from 'react';

import { Page } from 'components';
import TermsOfUse from './TermsOfUse';

const TermsOfUsePage = () => (
    <Page>
        <TermsOfUse />
    </Page>
);

export default TermsOfUsePage;
