import { Action, Reducer } from 'redux';
import { CountryBackend } from '../../../models/country';
import { Agreements } from '../../../models/agreements';
import { AllowCaptcha } from '../../../models/allow-captcha';
import { StudyCenter } from '../../../models/study-center';

import { ActionTypes, CLEAR_MESSAGES, SET_LOADING, SET_USER_NAME_ERROR, SET_USER_NAME_SUCCESS } from './actions';

export interface UserData extends AllowCaptcha {
    adminStudyCenter: StudyCenter;
    userName: string;
    middleName?: string;
    lastName: string;
    company: string;
    id: string;
    country: CountryBackend;
    studyCenter: StudyCenter[];
    isPartner: boolean;
    isStudentStudyCenter: boolean;
    approvedAgreements: boolean;
    personalVersion: number;
    marketingVersion: number;
    isBlocked: boolean;
    blockedReason: string;
}

export interface AddUserData extends Agreements, AllowCaptcha {
    countryId: number;
    userName: string;
    middleName?: string;
    lastName: string;
    company: string;
}

export interface UserInfoState {
    userNameSuccess: string;
    userNameError: string;
    isLoading: boolean;
}

const initialState: Readonly<UserInfoState> = {
    userNameSuccess: '',
    userNameError: '',
    isLoading: false,
};

export const reducer: Reducer<UserInfoState> = (
    state: UserInfoState = initialState,
    incomingAction: Action
): UserInfoState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case SET_USER_NAME_SUCCESS:
            return {
                ...state,
                userNameSuccess: action.message,
                isLoading: false,
            };
        case SET_USER_NAME_ERROR:
            return {
                ...state,
                userNameError: action.error,
                isLoading: false,
            };
        case CLEAR_MESSAGES: {
            return {
                ...state,
                userNameError: '',
                userNameSuccess: '',
            };
        }
        default:
            return state;
    }
};
