import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form-new';

import { Button, ModalWindow, Container } from 'components';
import { SubmitButton } from '../../submit-button/SubmitButton';

export interface QuestionViewButtonsProps {
    handleBack: () => void;
    handleFinish: () => void;
    isHavePrevQuestions: boolean;
    isLastQuestion: boolean;
    isDisabled?: boolean;
}

export const QuestionViewButtons = (props: QuestionViewButtonsProps) => {
    const { handleBack, handleFinish, isHavePrevQuestions, isLastQuestion, isDisabled } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [t] = useTranslation(['user-test']);
    const { formState } = useFormContext();
    const { isSubmitting } = formState;

    return (
        <>
            <div className="test-body-buttons">
                {!isLastQuestion && isHavePrevQuestions && (
                    <Button buttonClass="primary" handleClick={handleBack} isDisabled={isSubmitting}>
                        {t('back')}
                    </Button>
                )}
                <SubmitButton isDisabled={isDisabled} isSubmitting={isSubmitting}>
                    {isLastQuestion ? t('finish') : t('next')}
                </SubmitButton>
            </div>
            {!isLastQuestion && (
                <Button
                    buttonClass="link test-body-buttons__finish"
                    handleClick={() => setModalIsOpen(true)}
                    isDisabled={isSubmitting}
                >
                    {t('finish')}
                </Button>
            )}
            <Container>
                <ModalWindow
                    isOpen={modalIsOpen}
                    onCloseClick={() => setModalIsOpen(false)}
                    modalMessage={t('finish-message')}
                    size="big"
                    confirmButtonText={t('confirm-finish-button')}
                    cancelButtonText={t('cancel-finish-button')}
                    onConfirmAction={handleFinish}
                    additionalClass="modified finish-test-modal"
                />
            </Container>
        </>
    );
};
