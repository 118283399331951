import { Column, Container, Option, Title } from 'components';
import React, { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { ApplicationState } from 'store';
import { connectComponent, withLoading } from 'utils';
import { AdminAuthenticationState } from '../../authentication/AdminAuthenticationStore';
import UpdateAdminProfile from '../update-admin/UpdateAdminProfile';
import { actions, SuperAdminState } from './store';
import SuperAdminGrid from './SuperAdminGrid';

type SuperAdminProps = SuperAdminState &
    typeof actions & {
        selectData: Option[];
        auth: AdminAuthenticationState;
    };

const SuperAdmin = (props: SuperAdminProps) => {
    const { superAdminSuccess, superAdminError, getAllAdmins, clearMessage, clearStore, updateAccess, admins, auth } =
        props;

    const alert = useAlert();

    useEffect(() => {
        getAllAdmins();

        return () => {
            clearStore();
        };
    }, [clearStore, getAllAdmins]);

    useEffect(() => {
        if (superAdminSuccess) {
            alert.success(superAdminSuccess);
            clearMessage();
        }
    }, [alert, clearMessage, superAdminSuccess]);

    useEffect(() => {
        if (superAdminError) {
            alert.error(superAdminError);
            clearMessage();
        }
    }, [alert, clearMessage, superAdminError]);

    if (!auth.admin) {
        return null;
    }

    return (
        <Container additionalClass="super-admin-page">
            <Column columnSize="70" className="p-right-space-3x">
                <Title titleText="Администраторы" />

                <SuperAdminGrid currentAdminId={auth.admin.id} admins={admins} changeAdminApproved={updateAccess} />
            </Column>
            <Column columnSize="30" className="p-left-space-3x">
                <UpdateAdminProfile />
            </Column>
        </Container>
    );
};

const mapStateToProps = ({ superAdmin, adminAuthentication }: ApplicationState) => ({
    ...superAdmin,
    auth: adminAuthentication,
});

export default connectComponent({
    mapStateToProps,
    actions,
    component: withLoading(SuperAdmin),
});
