import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Checkbox } from 'components/Checkbox';

interface EditIsPartnerProps {
    isPartner: boolean;
    submit: (data: { isPartner: boolean }) => void;
    cancel?: () => void;
}

const EditIsPartner: FC<EditIsPartnerProps> = ({ isPartner, submit, cancel }) => {
    const methods = useForm<{ isPartner: boolean }>({ mode: 'onChange' });
    const { t } = useTranslation('info');
    const {
        register,
        formState: { isSubmitting, isValid },
        handleSubmit,
        setValue,
    } = methods;

    useEffect(() => {
        setValue('isPartner', isPartner);
    }, []);

    return (
        <form onSubmit={handleSubmit(submit)} autoComplete="off" className="info-form">
            <Checkbox label={t('partner')} id="isPartner2" inputRef={register} name="isPartner" />

            <div className="info-buttons d-flex justify-content-space-between">
                <Button type="submit" buttonClass="primary" isDisabled={isSubmitting || !isValid}>
                    {t('info:save-button')}
                </Button>

                {cancel && (
                    <Button buttonClass="primary" handleClick={cancel}>
                        {t('info:cancel')}
                    </Button>
                )}
            </div>
        </form>
    );
};

export default EditIsPartner;
