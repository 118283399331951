import React from 'react';

interface IconProps {
    iconName: string;
    className?: string;
}

export const Icon = (props: IconProps) => {
    const { iconName, className } = props;

    const classes = `icon icon-${iconName} ${className || ''}`;
    const href = `/assets/images/sprite.svg#${iconName}`;

    return (
        <svg className={classes}>
            <use href={href} />
        </svg>
    );
};
