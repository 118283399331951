import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Page } from 'components';
import { language } from '../../../constants/language';
import { LanguageKeys } from '../../../models/language/language';
import PrivacyStatement from './PrivacyStatement';
import PrivacyStatementRu from './PrivacyStatementRu';

const PrivacyBaseComponent = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language as LanguageKeys;
    const history = useHistory();

    const scrollIntoAgreement = (ref: HTMLHeadingElement | null, hash: string): void => {
        if (ref && history?.location.hash === hash) {
            ref.scrollIntoView();
            history.replace(history.location.pathname);
        }
    };

    return (
        <Page>
            {currentLanguage === language.ru.key ? (
                <PrivacyStatementRu scrollInto={scrollIntoAgreement} />
            ) : (
                <PrivacyStatement />
            )}
        </Page>
    );
};

export default PrivacyBaseComponent;
