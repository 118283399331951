import React from 'react';
import { BaseUser } from '../../models/base-user';

import { HeaderName } from '../HeaderName';
import { Person } from '../Person';
import { Icon, LanguagePicker } from 'components';
import { LOGIN_PATH } from 'global-constants';

interface HeaderProps {
    user?: BaseUser;
    isVisibleLanguagePicker?: boolean;
    hideLogin?: boolean;
    handleClick?: () => void;
    handleLogout?: () => void;
    headerTitle?: string;
    headerTitleClick?: () => void;
}

export const Header = (props: HeaderProps) => {
    const { user, isVisibleLanguagePicker, hideLogin, handleClick, handleLogout, headerTitle, headerTitleClick } =
        props;

    return (
        <header className="header">
            <HeaderName title={headerTitle} titleClick={headerTitleClick} />

            <div className="lang-and-logout">
                {isVisibleLanguagePicker && <LanguagePicker className="right-space-3x" />}
                {user ? (
                    <Person user={user} handleClick={handleClick} handleLogout={handleLogout} />
                ) : (
                    !hideLogin && (
                        <a href={LOGIN_PATH}>
                            <Icon iconName="no-logged-in" />
                        </a>
                    )
                )}
            </div>
        </header>
    );
};
