import React, { FC, useEffect, useState } from 'react';
import BaseDatePicker from 'react-date-picker';
import { getDateZeroTimeZoneISO } from '../../../utils/date';

import { Icon } from '../../Icon';
import { ValidationMessage } from '../ValidationMessage';

interface SelectDateProps {
    id?: string;
    name: string;
    label?: string;
    value?: string;
    onChange?: (date: string | string[]) => void;
    defaultValue?: string;
    errorMessage?: string;
    useZeroTime?: boolean;
    returnValue?: 'start' | 'end' | 'range';
}

export const DatePricker: FC<SelectDateProps> = (props) => {
    const { id, label, errorMessage, name, value, returnValue, useZeroTime, onChange } = props;

    const classes = `date-picker ${errorMessage ? 'error' : ''}`;
    const [pickerValue, setPickerValue] = useState<Date>();

    useEffect(() => {
        setPickerValue(value ? new Date(value) : new Date());
    }, [setPickerValue, value]);

    const getISOString = (date: Date): string => {
        if (useZeroTime) {
            return getDateZeroTimeZoneISO(date);
        }

        return date.toISOString();
    };
    const changeDate = (date: Date | Date[]) => {
        if (!onChange) {
            return;
        }

        if (date instanceof Date) {
            onChange(getISOString(date));
            return;
        }

        onChange([getISOString(date[0]), getISOString(date[1])]);
    };

    return (
        <fieldset className="form-group">
            {label && (
                <label htmlFor={id || name} className="form-label">
                    {label}
                </label>
            )}
            <div>
                <BaseDatePicker
                    className={classes}
                    value={pickerValue}
                    defaultValue={pickerValue}
                    onChange={changeDate}
                    calendarIcon={<Icon iconName="calendar-dropdown" />}
                    showLeadingZeros
                    clearIcon={null}
                    format="dd/MM/y"
                    returnValue={returnValue}
                />
            </div>
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </fieldset>
    );
};
