import React, { PropsWithChildren } from 'react';

interface ColumnProps {
    columnSize: string;
    className?: string;
}

export const ColumnFixed = (props: PropsWithChildren<ColumnProps>) => {
    const { columnSize, className, children } = props;

    return (
        <div className={`column ${className || ''}`} style={{ width: `${columnSize}px` }}>
            {children}
        </div>
    );
};
