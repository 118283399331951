import React, { FC, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import UserLinksGrid from '../../../components/UserLinksGrid/UserLinksGrid';
import { TrainingGroup } from '../../../models/training-group/training-group';
import { ApplicationState } from '../../../store';
import { connectComponent, saveToClipboard, withLoading } from '../../../utils';
import { TrainingGroupTCAdminState } from '../training-group-tc-admin/store/store';
import { actions as trainingGroupActions } from '../training-group-tc-admin/store/actions';
import { actions } from './store/actions';
import { StudyCenterUsersState } from './store/store';
import { KASPERSKY_MAIL } from 'global-constants';
import {
    actions as tcAuthenticationActions,
    TCAdminAuthenticationState,
} from '../../../authentication/TCAdminAuthenticationStore';
import {
    Button,
    Column,
    Container,
    EmptyListWarning,
    Loader,
    ModalWindow,
    RadioButton,
    Title,
} from '../../../components';

type StudyCenterViewUserTcAdminProps = {
    isTrainingLoading: boolean;
} & StudyCenterUsersState &
    TCAdminAuthenticationState &
    TrainingGroupTCAdminState &
    typeof tcAuthenticationActions &
    typeof trainingGroupActions &
    typeof actions;

const StudyCenterViewUserTcAdmin: FC<StudyCenterViewUserTcAdminProps> = (props) => {
    const {
        admin,
        isLoading,
        isTrainingLoading,
        editableUserLinks,
        editableUser,
        loadUser,
        trainingGroups,
        loadTrainingGroups,
        generateLink,
        successMessage,
        errorMessage,
        clearMessages,
    } = props;
    const alert = useAlert();
    const [t] = useTranslation(['study-center', 'info']);
    const { id } = useParams<{ id: string }>();

    const [isOpenGenerateLinkWindow, setIsOpenGenerateLinkWindow] = useState(false);
    const [selectedTrainingGroup, setSelectedTrainingGroup] = useState<number | null>(null);

    useEffect(() => {
        if (successMessage) {
            alert.success(t(successMessage));
        } else if (errorMessage) {
            alert.error(t(errorMessage, { mailAddress: KASPERSKY_MAIL }));
        }

        clearMessages();
    }, [successMessage, errorMessage, alert, clearMessages]);

    useEffect(() => {
        if (id) {
            loadUser(id);
            loadTrainingGroups(admin?.studyCenter.id as number);
        }
    }, [id, loadUser]);

    const openGenerateLinkWindow = () => {
        setIsOpenGenerateLinkWindow(true);
    };

    const closeGenerateWindow = () => {
        setIsOpenGenerateLinkWindow(false);
        setSelectedTrainingGroup(null);
    };

    const handleGenerateLink = async () => {
        if (!selectedTrainingGroup) {
            return;
        }
        await generateLink({ userId: id, trainingGroupId: selectedTrainingGroup });
        closeGenerateWindow();
    };

    const handleCopyClick = (text: string) => {
        saveToClipboard(text);
        alert.success(t('user-test-link-copied'));
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={`${t('user-test-links-title')} ${editableUser?.email}`} />

                <Button buttonClass="primary" handleClick={openGenerateLinkWindow}>
                    {t('new-test-link')}
                </Button>

                <div className="top-space-3x">
                    {editableUserLinks?.length ? (
                        <UserLinksGrid links={editableUserLinks} copyLink={handleCopyClick} />
                    ) : (
                        <EmptyListWarning />
                    )}
                </div>
            </Column>
            <ModalWindow
                isOpen={isOpenGenerateLinkWindow}
                size="big"
                onCloseClick={() => closeGenerateWindow()}
                onConfirmAction={() => handleGenerateLink()}
                confirmButtonText={t('info:add')}
                cancelButtonText={t('info:cancel')}
            >
                <TrainingGroupSelect
                    isLoading={isTrainingLoading}
                    items={trainingGroups}
                    onChange={setSelectedTrainingGroup}
                />
            </ModalWindow>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: ({ tcAuthentication, studyCenterUsersTcAdmin, trainingGroupTcAdmin }: ApplicationState) => ({
        ...tcAuthentication,
        ...studyCenterUsersTcAdmin,
        ...trainingGroupTcAdmin,
        isLoading: trainingGroupTcAdmin?.isLoading || tcAuthentication?.isLoading || studyCenterUsersTcAdmin?.isLoading,
    }),
    actions: {
        ...tcAuthenticationActions,
        ...trainingGroupActions,
        ...actions,
    },
    component: withLoading(StudyCenterViewUserTcAdmin),
});

interface TrainingGroupSelectProps {
    isLoading: boolean;
    items: TrainingGroup[];
    onChange: (id: number) => void;
}

const TrainingGroupSelect: FC<TrainingGroupSelectProps> = ({ isLoading, items, onChange }) => {
    const [t] = useTranslation('study-center');

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <Title titleText={t('select-training-group')} />
            {items.map((item) => (
                <RadioButton
                    radioName="studyGroupId"
                    key={item.id}
                    radioValue={item.id.toString()}
                    labelText={`${item.name} / ${item.testName}`}
                    onChangeHandler={(elem) => onChange(parseInt(elem.currentTarget.value))}
                />
            ))}
        </>
    );
};
