import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Container, Column, Title, DetailsModal, Page } from 'components';
import { AttemptsListState } from './store/store';
import { actions } from './store/actions';
import { ApplicationState } from 'store';
import { Attempts } from './attempts/Attempts';
import { useTitle, withLoading, useScrollToTop } from 'utils';

type AttemptListProps = AttemptsListState & typeof actions;

const AttemptsList = (props: AttemptListProps) => {
    const { attemptDetails, attempts, getAttemptDetailed, loadAttempts, isLoading } = props;
    const [t] = useTranslation(['attempts-list']);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    useScrollToTop();

    useEffect(() => {
        loadAttempts();
    }, [loadAttempts]);

    useTitle(t('page-title'));

    return (
        <Page>
            <Container>
                <Column columnSize="50">
                    <Title titleText={t('title')} />
                </Column>
                <Attempts attempts={attempts} setModalIsOpen={setModalIsOpen} getAttemptDetailed={getAttemptDetailed} />
                <DetailsModal
                    isOpen={!isLoading && modalIsOpen}
                    title={t('modal-title')}
                    handleCloseClick={() => setModalIsOpen(false)}
                    details={attemptDetails}
                />
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => state.attemptList,
    actions
)(withLoading(AttemptsList) as () => JSX.Element);
