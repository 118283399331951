import { SelectOption } from '../../../models/select-option';
import { TestShort } from '../../../models/test/test-short';
import { StudyCenter } from '../../../models/study-center';
import { StudyGroupFormData } from '../study-group-form/StudyGroupForm';

export const mapTestsToOptions = (tests: TestShort[]): SelectOption[] => [
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...tests?.map((x) => ({ label: x.title, value: x.id.toString() })),
];

export const mapCentersToOptions = (centers: StudyCenter[], withNoCenters?: boolean): SelectOption[] => {
    const result: SelectOption[] = centers?.map((x) => ({ label: x.name, value: x.id }));

    if (withNoCenters) {
        result.unshift({ label: 'Нет учебного центра', value: -1 });
    }

    return result;
};

export const mapFormValuesToPutData = (values: StudyGroupFormData) => ({
    name: values.name,
    quantityTrainingGroup: +values.quantityTrainingGroup,
    quantityTimesApplies: values.quantityTimesApplies ? +values.quantityTimesApplies : undefined,
    dateEnd: values.dateEndSession,
    dateStart: values.dateStartSession,
    studyCenterId: values.selectedStudyCenter ? +values.selectedStudyCenter : undefined,
});

export const mapFormValuesToPostData = (values: StudyGroupFormData) => ({
    ...mapFormValuesToPutData(values),
    testId: +values.selectedTest,
    studyCenterId: values.selectedStudyCenter ? +values.selectedStudyCenter : undefined,
});
