import React, { PropsWithChildren, useRef, useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';

import { AccordionProps } from './Accordion';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { expandingAnimation } from './expanding-animation';

export interface AccordionWithActionsProps extends AccordionProps {
    handleEditClick?: (event: React.MouseEvent<HTMLElement>) => void;
    handleDeleteClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const AccordionWithActions = (props: PropsWithChildren<AccordionWithActionsProps>) => {
    const { isExpanded, title, children, handleExpand, additionalClasses, handleEditClick, handleDeleteClick } = props;

    const ref = useRef(null);
    useEffect(() => {
        expandingAnimation(ref, isExpanded);
    }, [isExpanded]);

    return (
        <div className={`accordion with-actions ${additionalClasses ? additionalClasses : ''}`}>
            <div className="accordion-with-actions">
                <div className="accordion-header" onClick={handleExpand}>
                    <div className="d-flex align-items-center">
                        <div className={`${isExpanded ? 'showed' : 'hided'} d-flex align-items-center`}>
                            <Icon iconName="icon-expand" />
                        </div>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(title || ''),
                            }}
                        />
                    </div>
                </div>
                <div className="button-group">
                    {handleEditClick && (
                        <Button handleClick={handleEditClick}>
                            <Icon iconName="edit" />
                        </Button>
                    )}
                    {handleDeleteClick && (
                        <Button handleClick={handleDeleteClick}>
                            <Icon iconName="delete" />
                        </Button>
                    )}
                </div>
            </div>
            <div ref={ref} className={`accordion-content`}>
                <div>{children}</div>
            </div>
        </div>
    );
};
