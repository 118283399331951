import { Reducer, Action } from 'redux';

import {
    ActionTypes,
    GET_ALL_CERTIFICATES,
    SET_ERROR_CERTIFICATES,
    SET_SUCCESS_CERTIFICATES,
    SET_LOADING,
    GET_CERTIFICATE,
    CLEAR_CERTIFICATE_STATE,
} from './actions';
import { CertificationEnum } from 'enums/certificationEnum';
import { CertificationTypeFilter } from 'enums/certification-type';
import { CertificationDirection } from 'enums/certification.direction';

export interface GetCertificates {
    certificationType?: CertificationDirection;
    valid?: CertificationTypeFilter;
}

export interface UserCertificateListItem {
    sfId?: string;
    certificateId: number;
    testName: string;
    levelOfEvaluation: number;
    dateStartTest: string;
    isExpired: boolean;
    expiredDate?: string;
    typeOfCertificate: CertificationEnum;
}

export interface UserCertificate {
    certificateLink: string;
    imageOfCertificateLink: string;
    pageOfCertificateLink: string;
    expiredDate?: string;
    isExpired: boolean;
}

export interface UserCertificatesState {
    allCertificates: UserCertificateListItem[];
    allCertificatesSf: UserCertificateListItem[];
    certificate: UserCertificate;
    certificatesSuccess: string;
    certificatesError: string;
    isLoading: boolean;
}

const initialState: Readonly<UserCertificatesState> = {
    allCertificates: [],
    allCertificatesSf: [],
    certificatesSuccess: '',
    certificatesError: '',
    certificate: {} as UserCertificate,
    isLoading: true,
};

export const reducer: Reducer<UserCertificatesState> = (
    state: UserCertificatesState = initialState,
    incomingAction: Action
): UserCertificatesState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case GET_ALL_CERTIFICATES:
            return {
                ...state,
                allCertificates: action.allCertificates.sort(
                    (a, b) => Date.parse(b.dateStartTest) - Date.parse(a.dateStartTest)
                ),
                allCertificatesSf: action.allCertificatesSf.sort(
                    (a, b) => Date.parse(b.dateStartTest) - Date.parse(a.dateStartTest)
                ),
                isLoading: false,
            };
        case GET_CERTIFICATE:
            return {
                ...state,
                certificate: action.certificate,
                isLoading: false,
            };
        case SET_ERROR_CERTIFICATES:
            return {
                ...state,
                certificatesError: action.certificatesError,
            };
        case SET_SUCCESS_CERTIFICATES:
            return {
                ...state,
                certificatesSuccess: action.certificatesSuccess,
                isLoading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action?.isLoading ?? true,
            };
        case CLEAR_CERTIFICATE_STATE:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
