import React, { FC, useEffect } from 'react';
import { useAlert } from 'react-alert';
import SelectByEnum from '../../components/SelectByEnum/SelectByEnum';
import SelectCountry from '../../components/SelectCountry/SelectCountry';
import { Button, Column, Container, DatePricker, Label, ModalWindow, TextInput, Title } from '../../components';
import { CertDirection } from '../../enums/cert-direction';
import { UserFilterType } from '../../enums/user-filter-type';
import { useUrlQueryParamsForGrid } from '../../hooks/useUrlQueryParamsForGrid';
import { SelectOption } from '../../models/select-option';
import { TestStatisticParams, TestStatisticXlsParams } from '../../models/test/test-statistic';
import { connectComponent, withLoading } from '../../utils';
import TestStatisticsGrid from './TestStatisticsGrid';

import { ApplicationState } from '../../store';
import { actions } from './store/actions';
import { TestStatisticsState } from './store/store';
import TestAnswersStatistic from 'components/TestAnswersStatistic/TestAnswersStatistic';
import { UserTestStatistic } from 'models/test/user-test-statistic';
import { CertificationTypeFilter } from 'enums/certification-type';
import { SimpleLevelOfEvaluation } from 'enums/level-of-evaluation';
import { AutoCompliteForm, Item } from 'components/AutoComplite/AutoCompliteForm';
import { UpdateTestStatistic } from '../../models/test/update-test-statistic';

type TestStatisticsProps = TestStatisticsState &
    typeof actions & {
        selectionOptions: Item[];
    };

const TestStatistics: FC<TestStatisticsProps> = (props) => {
    const {
        getStatistic,
        getTests,
        getExcel,
        clearMessage,
        selectCountry,
        selectTest,
        selectDates,
        selectUserType,
        selectCertificationType,
        selectLevel,
        selectEmail,
        selectCertificationNumber,
        selectCompany,
        deletePassingTest,
        setLoading,
        testOptions,
        selectionOptions,
        statistics,
        statisticsTotalCount,
        selectedCountryId,
        selectedTestId,
        selectedUserType,
        selectedCertificationType,
        dateStart,
        dateEnd,
        selectedLevel,
        selectedCompany,
        selectedEmail,
        selectedCertificationNumber,
        error,
        success,
        getStatisticResult,
        closeModalResult,
        updateTestStatistic,
        currentTestResult,
        showModalResult,
    } = props;

    const alert = useAlert();

    const getParamsWithoutPagination = (): TestStatisticXlsParams => ({
        direction: CertDirection.ProductLK,
        start: dateStart,
        finish: dateEnd,
        userType: selectedUserType || undefined,
        testId: selectedTestId || undefined,
        countryId: selectedCountryId || undefined,
        certificationType: selectedCertificationType || undefined,
        level: selectedLevel || undefined,
    });

    const handleSearch = () => {
        getStatistic({
            ...getParams(),
            company: selectedCompany,
            email: selectedEmail,
            certificationNumber: selectedCertificationNumber,
        });
    };

    const { pagination } = useUrlQueryParamsForGrid({
        total: statisticsTotalCount,
        clearPaginationDeps: Object.values(getParamsWithoutPagination()),
        loadFn: handleSearch,
    });

    const getParams = (): TestStatisticParams => ({
        ...getParamsWithoutPagination(),
        page: pagination.page,
        size: pagination.size,
    });

    useEffect(() => {
        getTests();
    }, [getTests]);

    useEffect(() => {
        if (error) {
            alert.error(error);
        }
        if (success) {
            alert.success(success);
        }

        clearMessage();
    }, [alert, error, success, clearMessage]);

    const getSelectedValue = (arr: SelectOption[], value?: number | string): SelectOption | undefined => {
        if (!value) {
            return undefined;
        }

        const selected = arr.find((item) => item.value === value);
        if (selected) {
            return selected;
        }

        return undefined;
    };

    const deletePassingTestFun = (passingTestId: number) => {
        if (confirm('are you sure?')) {
            deletePassingTest(passingTestId, { ...getParams(), company: selectedCompany, email: selectedEmail });
        }
    };

    const changeStatistic = (newVal: UpdateTestStatistic, userId: string) => {
        updateTestStatistic(newVal, userId, {
            ...getParams(),
            company: selectedCompany,
            email: selectedEmail,
            certificationNumber: selectedCertificationNumber,
        });
    };

    return (
        <Container additionalClass="test-statistic">
            <ModalWindow onCloseClick={() => closeModalResult()} isOpen={showModalResult && !!currentTestResult}>
                <TestAnswersStatistic
                    statistic={currentTestResult as UserTestStatistic}
                    showDownload
                    showEdit
                    updateStatistic={changeStatistic}
                />
            </ModalWindow>
            <Column columnSize="50">
                <Title titleText="Результаты тестирования по продуктам ЛК" />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button
                    buttonClass="primary"
                    handleClick={() =>
                        getExcel({
                            ...getParamsWithoutPagination(),
                            company: selectedCompany,
                            email: selectedEmail,
                            certificationNumber: selectedCertificationNumber,
                        })
                    }
                >
                    Excel
                </Button>
            </Column>

            <Container additionalClass="container--items-end">
                {selectionOptions && selectionOptions.length !== 0 && (
                    <Column columnSize="20" className="p-right-space-3x">
                        <fieldset className="form-group">
                            <AutoCompliteForm
                                placeholder="Не выбрано"
                                label="Выберите тест"
                                dataArr={selectionOptions}
                                name="testSearchId"
                                id="testSearchId"
                                onChange={(e) => selectTest(e?.id as string)}
                                values={{
                                    id: getSelectedValue(testOptions, selectedTestId)?.value ?? '',
                                    name: getSelectedValue(testOptions, selectedTestId)?.label ?? '',
                                }}
                            />
                        </fieldset>
                    </Column>
                )}
                <Column columnSize="20" className="p-right-space-3x">
                    <fieldset className="form-group">
                        <Label labelText="Выберите тип пользователя" labelFor="" />
                        <SelectByEnum
                            hasEmpty
                            enumOptions={UserFilterType}
                            selectedId={selectedUserType}
                            onChange={selectUserType}
                        />
                    </fieldset>
                </Column>
                <Column columnSize="20" className="p-right-space-3x">
                    <fieldset className="form-group">
                        <Label labelText="Выберите страну" labelFor="" />
                        <SelectCountry hasEmpty selectedId={selectedCountryId} onChange={selectCountry} />
                    </fieldset>
                </Column>
                <Column columnSize="20" className="p-right-space-3x">
                    <Label labelText="Дата начала" labelFor="" />
                    <DatePricker
                        name="dateStart"
                        value={dateStart}
                        returnValue="start"
                        onChange={(date) => selectDates(date as string, dateEnd)}
                    />
                </Column>
                <Column columnSize="20" className="p-right-space-3x">
                    <Label labelText="Дата окончания" labelFor="" />
                    <DatePricker
                        name="dateEnd"
                        value={dateEnd}
                        returnValue="end"
                        onChange={(date) => selectDates(dateStart, date as string)}
                    />
                </Column>
                <Column columnSize="20" className="p-right-space-3x top-space-10x">
                    <fieldset className="form-group">
                        <Label labelText="Сертификат" labelFor="" />
                        <SelectByEnum
                            hasEmpty
                            enumOptions={CertificationTypeFilter}
                            selectedId={selectedCertificationType}
                            onChange={selectCertificationType}
                        />
                    </fieldset>
                </Column>
                <Column columnSize="20" className="p-right-space-3x top-space-10x">
                    <fieldset className="form-group">
                        <SelectByEnum
                            selectedId={selectedLevel}
                            label="Выберите уровень прохождения"
                            onChange={selectLevel}
                            enumOptions={SimpleLevelOfEvaluation}
                            hasEmpty
                        />
                    </fieldset>
                </Column>
                <Column columnSize="30" className="p-right-space-3x top-space-10x">
                    <TextInput
                        id="companyId"
                        label="Компания/ВУЗ"
                        value={selectedCompany}
                        onChangeHandler={(e) => selectCompany(e.target.value)}
                    />
                </Column>
                <Column columnSize="30" className="p-right-space-3x top-space-10x">
                    <TextInput
                        id="emailId"
                        label="Email"
                        value={selectedEmail}
                        onChangeHandler={(e) => selectEmail(e.target.value)}
                    />
                </Column>
                <Column columnSize="100" className="p-right-space-3x top-space-10x">
                    <TextInput
                        id="certificateNumber"
                        label="Номер сертификата"
                        value={selectedCertificationNumber}
                        onChangeHandler={(e) => selectCertificationNumber(e.target.value)}
                    />
                </Column>
                <Column columnSize="20" className="bottom-space-3x">
                    <Button buttonClass="primary" handleClick={handleSearch}>
                        Поиск
                    </Button>
                </Column>
            </Container>
            <Column columnSize="100" className="top-space-10x">
                <TestStatisticsGrid
                    update={handleSearch}
                    statistics={statistics}
                    pagination={pagination}
                    getStatisticResult={getStatisticResult}
                    deletePassingTest={deletePassingTestFun}
                    success={(message) => alert.success(message)}
                    error={(message) => alert.error(message)}
                    setLoading={setLoading}
                />
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: ({ testStatistics }: ApplicationState) => ({
        ...testStatistics,
        isLoading: testStatistics.isLoading,
        selectionOptions: testStatistics.testOptions.map((r) => ({ id: r.value, name: r.label })),
    }),
    actions,
    component: withLoading(TestStatistics),
});
