import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { ApplicationState } from 'store';
import { Container, Column, Title, Button, StudyGroupGrid, ModalWindow, EmptyListWarning } from 'components';
import { connectComponent, withLoading, saveToClipboard } from 'utils';
import SelectByEnum from '../../components/SelectByEnum/SelectByEnum';
import SelectDirection from '../../components/SelectDirection/SelectDirection';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import { CertDirection } from '../../enums/cert-direction';
import { ProductLKType } from '../../enums/product-lk-type';
import { StudyGroupState } from './store/store';
import { actions } from './store/actions';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';
import { DownloadFileService } from 'services/download-file.service';

type CategoryGroupListProps = StudyGroupState & typeof actions;

const StudyGroupList = (props: CategoryGroupListProps) => {
    const {
        loadStudyGroups,
        studyGroups,
        deleteStudyGroup,
        studyGroupError,
        studyGroupSuccess,
        clearMessage,
        selectedDirectionId,
        setDirection,
        selectedStudyCenterId,
        setStudyCenter,
        studyCenters,
        getStudyCenters,
        selectedTestType,
        setTestType,
        selectedLanguage,
        setLanguage,
    } = props;
    const alert = useAlert();
    const { pathname } = useLocation();
    const { push } = useHistory();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [studyGroupId, setStudyGroupId] = useState(0);

    const handleClickAdd = () => {
        push(`${pathname}/add`);
    };

    const handleClickGetExcel = async () => {
        DownloadFileService.download(`/TrainingGroup/Excel`, {
            studyCenterId: selectedStudyCenterId || undefined,
            testType: selectedDirectionId === CertDirection.ProductLK ? selectedTestType : undefined,
            directionId: selectedDirectionId,
            testLanguage: selectedLanguage ?? undefined,
        });
    };

    const handleClickEdit = (id: number) => {
        push(`${pathname}/edit/${id}`);
    };

    useEffect(() => {
        getStudyCenters(true);
    }, [getStudyCenters]);

    useEffect(() => {
        loadStudyGroups({
            studyCenterId: selectedStudyCenterId || undefined,
            testType: selectedDirectionId === CertDirection.ProductLK ? selectedTestType : undefined,
            directionId: selectedDirectionId,
            testLanguage: selectedLanguage ?? undefined,
        });
    }, [loadStudyGroups, selectedDirectionId, selectedTestType, selectedStudyCenterId, selectedLanguage]);

    useEffect(() => {
        if (studyGroupSuccess) {
            alert.success(studyGroupSuccess);
        } else if (studyGroupError) {
            alert.error(studyGroupError);
        }

        clearMessage();
    }, [alert, clearMessage, studyGroupError, studyGroupSuccess]);

    const handleClickDelete = (id: number) => {
        setStudyGroupId(id);
        changeModalState();
    };

    const handelDeleteStudyGroup = (id: number) => {
        deleteStudyGroup(id);
        changeModalState();
    };

    const copyCodeClick = (text: string) => {
        saveToClipboard(text);
        alert.success('Промокод скопирован');
    };

    const copyPartnerLinkClick = (text: string) => {
        saveToClipboard(text);
        alert.success('Ссылка скопирована');
    };

    const changeModalState = () => {
        setModalIsOpen(!modalIsOpen);
    };

    return (
        <Container>
            <ModalWindow
                isOpen={modalIsOpen}
                onCloseClick={changeModalState}
                modalMessage={'Вы действительно хотите удалить учебную группу?'}
                confirmButtonText="Удалить"
                cancelButtonText="Отмена"
                size="big"
                onConfirmAction={() => handelDeleteStudyGroup(studyGroupId)}
            />
            <Column columnSize="50">
                <Title titleText="Учебные группы" />
            </Column>
            <Column columnSize="25" className="text-left">
                <Button buttonClass="primary" handleClick={handleClickAdd}>
                    Создать учебную группу
                </Button>
            </Column>
            <Column columnSize="25" className="text-right">
                <Button buttonClass="primary" handleClick={handleClickGetExcel}>
                    Excel
                </Button>
            </Column>
            <Column columnSize="25" className="p-right-space-3x">
                <SelectDirection selectedDirectionId={selectedDirectionId} onChange={setDirection} />

                {selectedDirectionId === CertDirection.ProductLK && (
                    <div className="top-space-3x">
                        <SelectByEnum
                            selectedId={selectedTestType}
                            label="Выберите тип теста"
                            onChange={setTestType}
                            enumOptions={ProductLKType}
                            hasEmpty
                        />
                    </div>
                )}
            </Column>
            <Column columnSize="25" className="p-right-space-3x p-left-space-3x">
                <SelectLanguage hasEmpty onChange={setLanguage} selectedLanguage={selectedLanguage} />
            </Column>
            <Column columnSize="50" className="p-left-space-3x">
                <AutoCompliteForm
                    placeholder="Не выбрано"
                    label="Учебный центр"
                    dataArr={studyCenters.map((r) => ({ id: r.value, name: r.label }))}
                    name="selectedStudyCenterId"
                    id="selectedStudyCenterId"
                    onChange={(e) => setStudyCenter(e?.id as number)}
                    values={selectedStudyCenterId}
                />
            </Column>
            <Column columnSize="100" className="top-space-10x">
                {studyGroups.length ? (
                    <StudyGroupGrid
                        studyGroups={studyGroups}
                        handleCodeClick={copyCodeClick}
                        handleLinkClick={copyPartnerLinkClick}
                        handleClickEdit={handleClickEdit}
                        handleClickDelete={handleClickDelete}
                    />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyGroups,
    component: withLoading(StudyGroupList),
    actions: actions,
});
