import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Attempt } from 'pages/attempts-list/store/store';

export type AttemptsGridRowProps = Attempt & {
    handleDetailsClick: () => void;
    certificateData: {
        text: string;
        link: string;
    } | null;
};

export const AttemptsGridRow = (props: AttemptsGridRowProps) => {
    const {
        testTitle,
        dateStart,
        spentSeconds,
        attemptNo,
        quantityPoints,
        maxQuantityPoints,
        descriptionOfEvaluation,
        handleDetailsClick,
        passingTestId,
        certificateData,
    } = props;
    const [t] = useTranslation(['attempts-list']);
    const { push } = useHistory();

    const handleCertificateLinkClick = () => {
        if (certificateData) {
            push(certificateData.link, { passingTestId });
        }
    };

    return (
        <div className="attempt-grid-row">
            <h4 className="attempt-grid-row-name">{testTitle}</h4>
            <div className="cell">{dateStart}</div>
            <div className="cell">{spentSeconds}</div>
            <div className="cell">{attemptNo}</div>
            <div className="cell">
                <div>
                    {quantityPoints} / {maxQuantityPoints}
                </div>
                <div className="details" onClick={handleDetailsClick}>
                    {t('details')}
                </div>
            </div>
            <div className="attempt-grid-row-result">
                <div>{descriptionOfEvaluation}</div>
                {certificateData && (
                    <div className="details" onClick={handleCertificateLinkClick}>
                        {t(certificateData.text)}
                    </div>
                )}
            </div>
        </div>
    );
};
