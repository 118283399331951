import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Title, Paragraph, Button, Container } from 'components';

interface QuitTestProps {
    isStudentStudyCenter?: boolean;
    clearTest: () => void;
    getStudyGroup: () => void;
}

export const QuitTest = (props: QuitTestProps) => {
    const { isStudentStudyCenter, clearTest, getStudyGroup } = props;
    const [t] = useTranslation(['user-test']);
    const { push } = useHistory();
    return (
        <Container>
            <div className="quit-test">
                <Title titleText={t('quit-test')} />
                <Paragraph additionalClass="quit-message">
                    {t('quit-test-message')}
                    <br />
                    {t('additional-quit-test-message')}
                </Paragraph>
                {isStudentStudyCenter ? (
                    <Button
                        buttonClass="primary"
                        handleClick={() => {
                            window.location.href = '';
                        }}
                    >
                        {t('translation:go-to-main')}
                    </Button>
                ) : (
                    <Button
                        buttonClass="primary"
                        handleClick={async () => {
                            await clearTest();
                            push('/test');
                            await getStudyGroup();
                        }}
                    >
                        {t('try-again')}
                    </Button>
                )}
            </div>
        </Container>
    );
};
