import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';
import { Link } from '../Link';
import { KASPERSKY_MAIL, PRIVACY_STATEMENT_FULL_PATH, TERMS_OF_USAGE_FULL_URL } from 'global-constants';

export const Footer = () => {
    const [t] = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-logo">
                <Icon iconName="icon-kaspersky" />
                <span>{t('footer-kaspersky')}</span>
            </div>
            <div className="footer-contact">
                <Link linkHref={`/${PRIVACY_STATEMENT_FULL_PATH}`} target="_blank">
                    {t('footer-policy')}
                </Link>
                <Link linkHref={`/${TERMS_OF_USAGE_FULL_URL}`} target="_blank">
                    {t('footer-tou')}
                </Link>
                <span className="link" onClick={() => window.Cookiebot.renew()}>
                    {t('footer-cookies')}
                </span>
                <span>
                    {t('footer-contact')}
                    <Link additionalClass="left-space" linkHref={`mailto:${KASPERSKY_MAIL}`}>
                        {KASPERSKY_MAIL}
                    </Link>
                </span>
            </div>
        </footer>
    );
};
