import { RouterTab } from '../models/router-tab';

export const studyCenterRouterTabs: RouterTab[] = [
    {
        path: 'centers',
        title: 'Центры',
    },
    {
        path: 'users',
        title: 'Клиенты УЦ',
    },
    {
        path: 'admins',
        title: 'Сотрудники УЦ',
    },
    {
        path: 'agreements',
        title: 'Соглашения клиентов УЦ',
    },
];
