import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import StudyCenters from '../study-centers/StudyCenters';
import Error404 from 'pages/error-404/Error404';
import StudyCentersUsersPage from '../study-centers-users/StudyCentersUsersPage';
import { RouterTabs } from '../../components/RouterTabs/RouterTabs';
import { studyCenterRouterTabs } from '../../constants/study-centers-routes';
import { RouterTab } from 'models/router-tab';
import StudyCentersAdminPage from '../study-centers-admins/StudyCentersAdminPage';
import StudyCenterUserAgreements from '../study-center-user-agreements/StudyCenterUserAgreements';

const StudyCentersPage = () => {
    const { path } = useRouteMatch();

    const getTabsWithPaths = (tabs: RouterTab[]) =>
        tabs.map((tab) => ({
            ...tab,
            path: `${path}/${tab.path}`,
        }));

    return (
        <>
            <RouterTabs tabs={getTabsWithPaths(studyCenterRouterTabs)} />

            <Switch>
                <Route path={`${path}/centers`}>
                    <StudyCenters />
                </Route>
                <Route path={`${path}/users/`}>
                    <StudyCentersUsersPage />
                </Route>
                <Route path={`${path}/admins/`}>
                    <StudyCentersAdminPage />
                </Route>
                <Route path={`${path}/agreements/`}>
                    <StudyCenterUserAgreements />
                </Route>
                <Redirect exact from={path} to={`${path}/centers`} />
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default StudyCentersPage;
