import React, { FC, ReactNode } from 'react';
import { Icon } from '../../../components';

import './DirectionCard.scss';
import { LOGIN_PATH } from '../../../global-constants';

interface DirectionCardProgram {
    iconName: string;
    text: string;
}

interface DirectionCardProps {
    title: string;
    subtitle: string;
    cardNumber: number;
    programs: DirectionCardProgram[];
    bottomText: string | ReactNode;
    programListTitle?: string;
    actionBtnText: string;
}

const DirectionCard: FC<DirectionCardProps> = (props) => {
    const { title, subtitle, cardNumber, programs, bottomText, programListTitle, actionBtnText } = props;

    return (
        <div className="cert-program">
            <div className="cert-program__number">{cardNumber}</div>
            <div className="cert-program__top">
                <h2 className="cert-program__title">{title}</h2>
                <p>{subtitle}</p>
            </div>
            {programListTitle && <h2 className="cert-program__title">{programListTitle}</h2>}
            <ul className="cert-program__list">
                {programs.map((item, index) => (
                    <li className="cert-program__item" key={index}>
                        <Icon iconName={item.iconName} className="cert-program__icon" />
                        {item.text}
                    </li>
                ))}
            </ul>
            <div className="cert-program__bottom">
                <div className="cert-program__bottom-text">{bottomText}</div>
                <div className="text-center">
                    <a href={LOGIN_PATH} className="cert-program__cert-btn">
                        {actionBtnText}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DirectionCard;
