import { Label, ValidationMessage } from 'components/Form';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-dropdown-select';

export interface Item {
    id: string | number;
    name: string;
}

interface Props {
    isDisabled?: boolean;
    dataArr: Item[];
    onChange: (val?: Item) => void;
    values?: Item | string | number;
    label?: string;
    id: string;
    name: string;
    additionalClasses?: string;
    errorMessage?: string;
    placeholder: string;
}

export const AutoCompliteForm: FC<Props> = ({
    isDisabled,
    label,
    name,
    id,
    onChange,
    values,
    additionalClasses,
    errorMessage,
    dataArr,
    placeholder,
}) => {
    const [searchStr, setSearchStr] = useState<string>();
    const data = dataArr.filter((r) => r.name.toUpperCase().includes((searchStr ?? '').toUpperCase()));
    const val = !values
        ? []
        : Number.isInteger(values) || typeof values === 'string' || values instanceof String
        ? [dataArr.find((r) => r.id === values) as Item]
        : !(values as Item).id
        ? []
        : [values as Item];

    useEffect(() => {}, [searchStr]);

    const withError = errorMessage ? 'with-error' : '';
    const classes = `${additionalClasses} ${withError} dropdown-select`;

    return (
        <>
            {label && <Label labelText={label} labelFor={id} />}
            <Select
                className={classes}
                placeholder={placeholder}
                disabled={isDisabled}
                onClearAll={() => setSearchStr('')}
                key={id}
                name={name}
                options={data}
                onChange={(e) => (e && e.length > 0 ? onChange(e[0]) : onChange(undefined))}
                values={val}
                labelField="name"
                valueField="id"
                searchable
                clearable
                multi={false}
                searchFn={(e) => {
                    setSearchStr(e.state.search);
                    return data;
                }}
            />
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </>
    );
};
