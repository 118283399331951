import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridAction } from '../../models/grid-action';
import { StudyCenterUser } from '../../models/study-center-users/study-center-user';
import { getCountryTransKey } from '../../utils/get-country-trans-key';
import { Button } from '../Button';
import { Grid, Row } from '../Grid';
import { Icon } from '../Icon';
import { PaginationProps } from '../Pagination/Pagination';
import moment from 'moment';
import { dateTimeFormat } from '../../constants/date-format';
import { Checkbox } from 'components/Checkbox';

interface StudyCenterUserGridProps {
    users: StudyCenterUser[];
    pagination?: PaginationProps;
    actions?: GridAction[];
    onEmailClick?: (user: StudyCenterUser) => void;
}

const StudyCenterUserGrid: FC<StudyCenterUserGridProps> = ({ users, pagination, actions, onEmailClick }) => {
    const [t] = useTranslation(['column-title', 'countries', 'info']);

    const getGridData = (): Row[] =>
        users.map((user) => ({
            email: {
                additionalClasses: 'link break-word',
                handleClick: () => onEmailClick && onEmailClick(user),
                value: user.email,
            },
            name: {
                additionalClasses: 'break-word',
                additionalComponent: <>{`${user?.lastName ?? ''} ${user?.userName ?? ''} ${user?.middleName ?? ''}`}</>,
            },
            company: {
                value: user.company,
            },
            country: t(getCountryTransKey(user?.country?.id)) as string,
            creationDate: {
                value: moment(user.creationDate).format(dateTimeFormat),
            },
            lastLoginDate: {
                value: moment(user.lastLoginDate).format(dateTimeFormat),
            },
            isPartner: {
                additionalComponent: <Checkbox isChecked={user?.isPartner ?? false} id="isPartner" isDisabled />,
            },
            actions: {
                additionalClasses: 'cell-actions',
                additionalComponent: (
                    <>
                        {actions?.map((action) => (
                            <Button handleClick={() => action.handler(user)} pSize="small" key={action.iconName}>
                                <Icon iconName={action.iconName} />
                            </Button>
                        ))}
                    </>
                ),
            },
        }));

    return <Grid columns={getColumns(t)} rows={getGridData()} pagination={pagination} />;
};

export default StudyCenterUserGrid;

const getColumns = (t: TFunction) => ({
    email: {
        value: t('column-title:email'),
        columnSize: '15',
    },
    name: {
        value: t('column-title:user-full-name'),
        columnSize: '15',
    },
    company: {
        value: t('column-title:company'),
        columnSize: '15',
    },
    country: {
        value: t('column-title:country'),
        columnSize: '10',
    },
    creationDate: {
        value: t('column-title:date-reg'),
        columnSize: '15',
    },
    lastLoginDate: {
        value: t('column-title:last-login-date'),
        columnSize: '15',
    },
    isPartner: {
        value: t('info:partner'),
        columnSize: '7',
    },
    actions: {
        value: '',
        columnSize: '10',
    },
});
