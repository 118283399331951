import React, { ReactNode } from 'react';
import Pagination, { PaginationProps } from '../Pagination/Pagination';
import { Column } from '../Column';

export interface Columns {
    [key: string]: {
        columnSize: string | number;
        value: string | number;
    };
}

export type Cell = string | number;

export interface ExtendedCell {
    value?: Cell;
    align?: 'start' | 'end' | 'center';
    additionalComponent?: ReactNode;
    additionalClasses?: string;
    handleClick?: () => void;
}

export interface Row {
    [key: string]: ExtendedCell | Cell | undefined;
}

export interface GridProps {
    columns: Columns;
    rows: Row[];
    pagination?: PaginationProps;
}

export const Grid = (props: GridProps) => {
    const { columns, rows, pagination } = props;

    return (
        <>
            <div className="grid">
                <div className="grid-header">
                    {Object.values(columns).map((x, index) => (
                        <Column className="space-between" key={`${x.value}-${index}`} columnSize={`${x.columnSize}`}>
                            {x.value}
                        </Column>
                    ))}
                </div>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className="grid-row">
                        {Object.keys(columns).map((key, cellIndex) => (
                            <Column
                                key={`${key}-${cellIndex}`}
                                className="space-between"
                                columnSize={`${columns[key].columnSize}`}
                            >
                                {getCell(row[key])}
                            </Column>
                        ))}
                    </div>
                ))}
            </div>

            {pagination?.size && <Pagination {...pagination} />}
        </>
    );
};

const getCell = (cellValue: Cell | ExtendedCell | undefined) => {
    if (cellValue && typeof cellValue === 'object') {
        const { additionalClasses, handleClick, value, additionalComponent, align } = cellValue as ExtendedCell;
        const classes = `cell ${additionalClasses ? additionalClasses : ''} cell-${align || 'stretch'}`;

        return (
            <div className={classes} onClick={handleClick}>
                {value}
                {additionalComponent}
            </div>
        );
    }

    return <div className="cell">{cellValue}</div>;
};
