import api from '../api';
import { CertDirection } from '../enums/cert-direction';
import { AddCategory } from '../models/category/add-category';
import { Category } from '../models/category';
import { UpdateCategory } from '../models/category/update-category';
import { ResponsePagination } from 'models/response-pagination';
import { CategoryShortModel } from 'models/category.short.model';

export class CategoryService {
    static getList = (directionId?: CertDirection) => api.get<Category[]>(`Category`, { params: { directionId } });
    static getListPage = (page: number, size: number, directionId?: CertDirection) =>
        api.get<ResponsePagination<Category>>(`Category/GetPagination`, { params: { directionId, page, size } });
    static getById = (id: number) => api.get<Category>(`Category/${id}`);
    static getByTestId = (id: number) => api.get<CategoryShortModel[]>(`Category/byTestId?testId=${id}`);

    static addCategory = (category: AddCategory) => {
        const formData = new FormData();
        formData.append('name', category.name);
        formData.append('directionId', category.directionId.toString());
        formData.append('iconFile', category.iconFile as Blob);

        return api.post<number>('Category', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    static updateCategory = (category: UpdateCategory) => {
        const formData = new FormData();
        formData.append('id', category.id.toString());
        formData.append('name', category.name);

        if (category.iconFile) {
            formData.append('iconFile', category.iconFile);
        }

        return api.put<void>('Category', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    static deleteCategory = (id: number) => api.delete<number>(`Category/${id}`);
}
