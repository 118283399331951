import React from 'react';
import { useTranslation } from 'react-i18next';

import { AttemptsGridRowProps, AttemptsGridRow } from './AttemptsGridRow';

export const AttemptsGrid = (props: { attempts: AttemptsGridRowProps[] }) => {
    const { attempts } = props;
    const [t] = useTranslation(['attempts-list']);

    return (
        <div className="attempt-grid">
            <div className="attempt-grid-header">
                <h4 className="attempt-grid-header-name">{t('test')}</h4>
                <span className="cell">{t('date')}</span>
                <span className="cell">{t('time-taken')}</span>
                <span className="cell">{t('attempt')}</span>
                <span className="cell">{t('score')}</span>
                <span className="attempt-grid-header-result">{t('result')}</span>
            </div>
            {attempts.map((x, index) => (
                <AttemptsGridRow key={`${x.testTitle}-${index}`} {...x} />
            ))}
        </div>
    );
};
