import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouterTab } from '../../models/router-tab';

export interface RouterTabsProps {
    tabs: RouterTab[];
}

export const RouterTabs = (props: RouterTabsProps) => {
    const { tabs } = props;

    return (
        <div className="router-tabs">
            {tabs.map((tab: RouterTab) => (
                <NavLink
                    key={tab.path}
                    exact={tab.exact}
                    className="router-tabs-item"
                    activeClassName="active"
                    to={tab.path}
                >
                    {tab.title}
                </NavLink>
            ))}
        </div>
    );
};
