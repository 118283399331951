import api from '../../../api';
import { ReportQuestion } from '../models/ReportQuestion';

export class ReportServices {
    static getRequest = (action: string, testId: number, categoryId: number | null) => {
        let request = `Report/${action}?testId=${testId}`;

        if (categoryId) request = `${request}&categoryId=${categoryId}`;

        return request;
    };
    static getQuestions = (testId: number, categoryId: number | null) =>
        api.get<ReportQuestion[]>(ReportServices.getRequest('questions', testId, categoryId));
}
