import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserAuthenticationState } from '../../../authentication/UserAuthenticationStore';

import { actions, UserTestState, LevelOfEvaluation } from '../store';
import { ApplicationState } from 'store';
import { theme, useTheme, withLoading } from 'utils';
import { QuitTest } from './quit-test';
import { TestResultIcon } from './user-test-result/test-result-icon/TestResultIcon';
import { InfoResult } from './user-test-result/info-result/InfoResult';
import { Page } from 'components';

type TestFinishProps = UserTestState & UserAuthenticationState & typeof actions;

const TestFinish = (props: TestFinishProps) => {
    const {
        testResult,
        isTestQuit,
        clearTest,
        clearStore,
        code,
        zipCoded,
        user,
        getTestResult,
        getTrainingGroup,
        testResultDetails,
        testStatistic,
        isLoading,
        currentPassingTest,
    } = props;
    const { id } = currentPassingTest;
    const { push } = useHistory();
    useTheme(theme.light);

    useEffect(() => {
        if (!id) {
            push('/test');
            return;
        }

        getTestResult(id);
    }, [id, getTestResult, push]);

    if (isLoading || !testResult) {
        return null;
    }

    const isSuccess = testResult.levelOfEvaluation !== LevelOfEvaluation.Fail;

    return (
        <Page additionalClass="page-content-center">
            {!isSuccess && isTestQuit ? (
                <QuitTest
                    isStudentStudyCenter={user?.isStudentStudyCenter}
                    clearTest={clearTest}
                    getStudyGroup={() => {
                        code && getTrainingGroup(code, false, zipCoded);
                    }}
                />
            ) : (
                <div className="test-result-wrapper">
                    <TestResultIcon
                        quantityPoints={testResult.quantityPoints}
                        maxQuantityPoints={testResult.maxQuantityPoints}
                        isSuccess={isSuccess}
                        descriptionOfEvaluation={testResult.descriptionOfEvaluation}
                    />
                    <InfoResult
                        isStudentStudyCenter={user?.isStudentStudyCenter}
                        isGiveCertificate={testResult.isGiveCertificate}
                        certDirection={testResult.direction}
                        isSuccess={isSuccess}
                        title={testResult.descriptionOfEvaluation}
                        clearStore={clearStore}
                        testStatistic={testStatistic}
                        testResultDetails={testResultDetails}
                        passingTestId={id}
                    />
                </div>
            )}
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => ({
        ...state.userTest,
        ...state.userAuthentication,
    }),
    actions
)(withLoading(TestFinish) as () => JSX.Element);
