export const KASPERSKY_MAIL = 'certification@kaspersky.com';
export const KASPERSKY_PRIVACY_POLICY = 'https://www.kaspersky.com/web-privacy-policy';
export const KASPERSKY_EDU_MAIL = 'edu@kaspersky.com';
export const KASPERSKY_TWITTER_RU = 'https://twitter.com/Kaspersky_ru';
export const KASPERSKY_TWITTER = ' https://twitter.com/kaspersky';
export const KASPERSKY_FACEBOOK = ' https://www.facebook.com/Kaspersky';
export const KASPERSKY_ACADEMY_RU = 'https://academy.kaspersky.ru/';
export const KASPERSKY_ACADEMY = 'https://academy.kaspersky.com/';
export const KASPERSKY_SUPPORT_CERT = 'https://support.kaspersky.ru/learning/certification-centers';
export const POLICIES = 'policies';
export const PRIVACY_STATEMENT = 'privacy-statement';
export const PRIVACY_STATEMENT_FULL_PATH = `${POLICIES}/${PRIVACY_STATEMENT}`;
export const DATA_PROCESS_HASH = '#personal-data-process';
export const SC_DATA_PROCESS_HASH = '#sc-personal-data-process';
export const MARKETING_PROCESS_HASH = '#marketing-data-process';
export const PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH = `${POLICIES}/${PRIVACY_STATEMENT}${DATA_PROCESS_HASH}`;
export const PRIVACY_STATEMENT_DATA_PROCESS_SC_FULL_PATH = `${POLICIES}/${PRIVACY_STATEMENT}${SC_DATA_PROCESS_HASH}`;
export const PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH = `${POLICIES}/${PRIVACY_STATEMENT}${MARKETING_PROCESS_HASH}`;
export const TERMS_OF_USAGE = 'terms-of-use';
export const TERMS_OF_USAGE_FULL_URL = `${POLICIES}/${TERMS_OF_USAGE}`;
export const TERMS_OF_USE = 'terms-of-use';
export const TERMS_OF_USE_FULL_PATH = `${POLICIES}/${TERMS_OF_USE}`;
export const FREE_PROMOCODE = 'X!@j386$Z1$k1Z';

export const DELETE_ACCOUNT_PATH = '/api/UserProfile/confirmDeleteProfile';
export const LOGIN_PATH = '/api/UserAuthorization/login';
export const CHANGE_PASSWORD_PATH = '/api/UserAuthorization/changePassword';
export const LOGOUT_PATH = '/api/UserAuthorization/logout';
export const ADMIN_LOGIN_PATH = '/api/AdminAccount/login';
export const ADMIN_LOGOUT_PATH = '/api/AdminAccount/logout';
export const ADMIN_STUDY_LOGIN_PATH = '/api/AdminStudyCenter/login';
export const ADMIN_STUDY_LOGOUT_PATH = '/api/AdminStudyCenter/logout';
export const ADMIN_STUDY_DELETE_PATH = '/api/AdminStudyCenter/confirmDeleteProfile';
export const STUDENT_STUDY_CENTER_LOGIN_PATH = '/api/StudentStudyCenter/login/{tempLinkId}';
export const STUDENT_STUDY_CENTER_LOGOUT_PATH = '/api/StudentStudyCenter/logout';
export const PARTNER_LOGIN_PATH = 'api/Partner/login/{code}/{lang}/{zipCoded}';

export const MIN_TEXT_INPUT_LENGTH = 1;
export const MAX_USER_NAME_LENGTH = 40;
export const MAX_USER_COMPANY_LENGTH = 255;
export const MAX_EMAIL_LENGTH = 255;
export const MAX_USER_FULL_NAME_LENGTH = MAX_USER_NAME_LENGTH * 3 + 2;
export const STUDENT_SFDC_ID_LENGTH = 18;
export const STUDENT_SFDC_REG = /^([a-zA-Z0-9]{18})$/;

export const PATH_TO_CATEGORY_ICON_DEFAULT = '/assets/images/category-icon-default.svg';
