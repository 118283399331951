import React, { FC } from 'react';
import { Icon } from '../Icon';
import DOMPurify from 'isomorphic-dompurify';

import './QuestionAnswer.scss';

interface QuestionAnswerProps {
    isRight: boolean;
    isSelected: boolean;
    text: string;
}

const QuestionAnswer: FC<QuestionAnswerProps> = ({ isRight, isSelected, text }) => {
    const modifier = isSelected ? (isRight ? 'right' : 'error') : '';

    return (
        <div className={'statistic-question-answer ' + (modifier ? `statistic-question-answer--${modifier}` : '')}>
            <div className="statistic-question-answer__icon">
                {modifier && <Icon iconName={modifier === 'error' ? 'close' : 'check-mark-2'} />}
            </div>
            <div
                className="statistic-question-answer__text"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text),
                }}
            />
        </div>
    );
};

export default QuestionAnswer;
