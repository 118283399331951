import { RouterTab } from '../models/router-tab';

export const userTestResultRoutes: RouterTab[] = [
    {
        path: 'cybersecurity',
        title: 'Кибербезопасность',
    },
    {
        path: 'product-lk',
        title: 'Продукты ЛК',
    },
    {
        path: 'sf-results',
        title: 'Исторические данные',
    },
    {
        path: 'additional',
        title: 'Дополнительные сертификаты',
    },
];
