import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Column, Title, Select, Label, Icon, Option, DetailsModal, TextInput, Button } from 'components';
import { language } from '../../../constants/language';
import { UsersJournalState } from '../store/store';
import { actions } from '../store/actions';
import { ApplicationState } from 'store';
import { withLoading } from 'utils';
import { JournalGrid } from './JournalGrid';
import SelectLanguage from 'components/SelectLanguage/SelectLanguage';
import { LanguageEnum } from 'enums/language.enum';
import SelectByEnum from 'components/SelectByEnum/SelectByEnum';
import { LevelOfEvaluation } from 'enums/level-of-evaluation';
import { CertificationTypeFilter } from 'enums/certification-type';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';
import { useAlert } from 'react-alert';

type JournalProps = UsersJournalState & typeof actions;

const Journal = (props: JournalProps) => {
    const {
        testsOptions,
        selectedTestOption,
        selectedTest,
        selectedTrainingGroup,
        trainingGroupsOptions,
        selectedTrainingGroupOption,
        attempts,
        attemptDetails,
        attemptDetailsModalOptions,
        getTests,
        setSelectedTest,
        getAttempts,
        setSelectedTrainingGroup,
        getAttemptDetails,
        getExcel,
        search,
        deletePassingTest,
        setLoading,
    } = props;
    const alert = useAlert();
    const { duration, language: selectedTestLanguage, quantityAttempt, quantityQuestions } = selectedTest || {};
    const { dateEnd, dateStart, quantityPlaces } = selectedTrainingGroup || {};
    const { i18n } = useTranslation(['attempts-list']);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [selectedLanguage, setLanguage] = useState<LanguageEnum | undefined>();
    const [selectedLevelOfEvaluation, setLevelOfEvaluation] = useState<LevelOfEvaluation | undefined>();
    const [selectedCertificationTypeFilter, setCertificationTypeFilter] = useState<
        CertificationTypeFilter | undefined
    >();
    const [company, setCompany] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        getTests(selectedLanguage, selectedLevelOfEvaluation, selectedCertificationTypeFilter, email, company);
        i18n.changeLanguage(language.ru.key);
    }, [getTests, i18n, selectedLanguage]);

    useEffect(() => {
        if (selectedTrainingGroupOption?.value ?? false) {
            getAttempts(+selectedTrainingGroupOption.value, {
                level: selectedLevelOfEvaluation,
                status: selectedCertificationTypeFilter,
                email,
                company,
            });
        }
    }, [getAttempts, selectedTrainingGroupOption.value]);

    const handleSelectTest = (selectedOptions: Option[]) => {
        const selectedTestId = +selectedOptions[0]?.value;
        if (!isNaN(selectedTestId) && selectedTestId !== 0 && selectedTest?.testId !== selectedTestId) {
            setSelectedTest(selectedTestId);
        }
    };

    const handleSelectTrainingGroup = (selectedOptions: Option[]) => {
        const selectedTrainingGroupId = +selectedOptions[0]?.value;
        if (!isNaN(selectedTrainingGroupId) && selectedTrainingGroup?.trainingGroupId !== selectedTrainingGroupId) {
            setSelectedTrainingGroup(selectedTrainingGroupId);
        }
    };

    const handleSearch = () => {
        search({
            level: selectedLevelOfEvaluation,
            status: selectedCertificationTypeFilter,
            email,
            company,
        });
    };
    const handleExcel = () => {
        getExcel({
            level: selectedLevelOfEvaluation,
            status: selectedCertificationTypeFilter,
            email,
            company,
        });
    };

    const deletePassingTestFun = (passingTestId: number) => {
        if (confirm('are you sure?')) {
            deletePassingTest(passingTestId, {
                level: selectedLevelOfEvaluation,
                status: selectedCertificationTypeFilter,
                email,
                company,
            });
        }
    };

    return (
        <>
            <Container additionalClass="journal">
                <Column columnSize="100">
                    <Title titleText="Результаты тестирования по кибербезопасности" />
                </Column>
                <Container additionalClass="container--items-end">
                    <Column columnSize="20" className="p-right-space-3x">
                        <SelectLanguage selectedLanguage={selectedLanguage} onChange={setLanguage} hasEmpty />
                    </Column>
                    <Column columnSize="20" className="p-right-space-3x">
                        <AutoCompliteForm
                            placeholder="Не выбрано"
                            label="Выберите тест"
                            dataArr={testsOptions.map((r) => ({ id: r.value, name: r.label }))}
                            name="testSearchId"
                            id="testSearchId"
                            onChange={(e) => handleSelectTest([{ label: e?.name ?? '', value: e?.id ?? '' }])}
                            values={{
                                id: selectedTestOption?.value ?? '',
                                name: selectedTestOption?.label ?? '',
                            }}
                        />
                    </Column>
                    <Column columnSize="20" className="p-right-space-3x">
                        <Label labelText="Выберите учебную группу" labelFor="" />
                        <Select
                            options={trainingGroupsOptions}
                            onChange={handleSelectTrainingGroup}
                            values={[selectedTrainingGroupOption]}
                        />
                    </Column>
                    <Column columnSize="20" className="p-right-space-3x">
                        <SelectByEnum
                            selectedId={selectedLevelOfEvaluation}
                            label="Выберите уровень прохождения"
                            onChange={setLevelOfEvaluation}
                            enumOptions={LevelOfEvaluation}
                            hasEmpty
                        />
                    </Column>
                    <Column columnSize="20" className="p-right-space-3x">
                        <SelectByEnum
                            selectedId={selectedCertificationTypeFilter}
                            label="Статус сертификата"
                            onChange={setCertificationTypeFilter}
                            enumOptions={CertificationTypeFilter}
                            hasEmpty
                        />
                    </Column>
                    <Column columnSize="50" className="p-right-space-3x top-space-10x">
                        <TextInput
                            id="companyId"
                            label="Компания/ВУЗ"
                            value={company}
                            onChangeHandler={(e) => setCompany(e.target.value)}
                        />
                    </Column>
                    <Column columnSize="50" className="p-right-space-3x top-space-10x">
                        <TextInput
                            id="emailId"
                            label="Email"
                            value={email}
                            onChangeHandler={(e) => setEmail(e.target.value)}
                        />
                    </Column>
                    <Column columnSize="20" className="text-right bottom-space-3x">
                        <Button buttonClass="primary" handleClick={handleSearch}>
                            Поиск
                        </Button>
                    </Column>
                </Container>

                <Column className="journal-selected-data" columnSize="100">
                    <Icon iconName="column-with-stripes" />
                    <div className="journal-study-group-and-test-data">
                        <div className="journal-data">
                            <div className="journal-data-description">
                                Число мест в сессии: <span>{quantityPlaces}</span>
                            </div>
                            <div className="journal-data-description">
                                Начало действия: <span>{dateStart}</span>
                            </div>
                            <div className="journal-data-description">
                                Конец действия: <span>{dateEnd}</span>
                            </div>
                            <div className="journal-data-description">
                                Локализация: <span>{selectedTestLanguage}</span>
                            </div>
                        </div>
                        <div className="journal-data">
                            <div className="journal-data-description">
                                Длительность: <span>{duration}</span>
                            </div>
                            <div className="journal-data-description">
                                Количество вопросов: <span>{quantityQuestions}</span>
                            </div>
                            <div className="journal-data-description">
                                Максимальное число попыток: <span>{quantityAttempt}</span>
                            </div>
                        </div>
                        <Button buttonClass="btn btn-primary journal-download-link" handleClick={handleExcel}>
                            Excel
                        </Button>
                    </div>
                </Column>
                <JournalGrid
                    update={handleSearch}
                    attempts={attempts}
                    success={(message) => alert.success(message)}
                    error={(message) => alert.error(message)}
                    setLoading={setLoading}
                    getAttemptDetails={getAttemptDetails}
                    setModalIsOpen={setModalIsOpen}
                    deletePassingTest={deletePassingTestFun}
                />
            </Container>
            {modalIsOpen && attemptDetailsModalOptions.resultByCategories.length && (
                <DetailsModal
                    title={`${attemptDetails.userName} (${attemptDetails.attemptNumber}/${attemptDetails.allAttempts})`}
                    details={attemptDetailsModalOptions}
                    handleCloseClick={() => setModalIsOpen(false)}
                    isOpen={modalIsOpen}
                />
            )}
        </>
    );
};

export default connect(
    (state: ApplicationState) => state.usersJournal,
    actions
)(withLoading(Journal) as () => JSX.Element);
