import { Action, Reducer } from 'redux';
import { AppSettings } from '../../models/app-settings';
import { AppSettingsService } from '../../services/app-settings.service';
import { AppThunkAction } from '../index';

export interface AppSettingsState extends AppSettings {
    isLoading: boolean;
}

const GET_SETTINGS = 'app-settings/GET_SETTINGS';

interface GetSettings {
    type: typeof GET_SETTINGS;
    settings: AppSettings;
}

type SettingsActionTypes = GetSettings;

export const actions = {
    getSettings: (): AppThunkAction<SettingsActionTypes> => async (dispatch) => {
        try {
            const settings = (await AppSettingsService.getAppSetting()).data;

            dispatch({
                type: GET_SETTINGS,
                settings,
            });
        } catch {
            console.error('get settings error');
        }
    },
};

const initialState: Readonly<AppSettingsState> = {
    isLoading: true,
    siteKey: '',
    personalVersion: 1,
    marketingVersion: 1,
};

export const reducer: Reducer<AppSettingsState> = (
    state: AppSettingsState = initialState,
    incomingAction: Action
): AppSettingsState => {
    const action = incomingAction as SettingsActionTypes;

    switch (action.type) {
        case GET_SETTINGS: {
            return {
                ...state,
                ...action.settings,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};
