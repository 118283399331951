import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';
import { AdminAuthenticationState, actions } from 'authentication/AdminAuthenticationStore';
import { Header, Page, SidebarMenu, Loader, SessionExpired } from 'components';
import Error404 from 'pages/error-404/Error404';
import { useTranslation } from 'react-i18next';
import { ADMIN_LOGOUT_PATH } from 'global-constants';
import { useScrollToTop } from 'utils';
import { adminRouting } from '../constants/admin-routing';
import { language } from '../constants/language';
import { Role } from '../enums/roles';
import { RoutingConfig } from '../models/routing-config';
import AdminLogin from '../pages/admin-login/AdminLogin';
import SessionService from '../services/session.service';

type AdminLayoutProps = AdminAuthenticationState & typeof actions;

const AdminLayout = (props: AdminLayoutProps) => {
    const { loadAdmin, admin, isSessionEnd, isLoading, actionAfterEndSessionTime, maxNoActiveTime } = props;

    const getAdminRouting = (path: string): RoutingConfig[] =>
        adminRouting.map((item) => ({
            ...item,
            menuItemHref: `${path}/${item.menuItemHref}`,
        }));

    const { path } = useRouteMatch();
    const { push } = useHistory();
    const { i18n } = useTranslation();
    const [navItems] = useState<RoutingConfig[]>(getAdminRouting(path));

    useScrollToTop();

    useEffect(() => {
        let timer: SessionService;

        if (admin && maxNoActiveTime && !isSessionEnd) {
            timer = new SessionService({
                timeout: maxNoActiveTime,
                onTimeout: actionAfterEndSessionTime,
            });
        }

        return () => {
            timer?.cleanUp();
        };
    }, [admin, isSessionEnd, actionAfterEndSessionTime]);

    const handleLogout = () => {
        document.location.href = ADMIN_LOGOUT_PATH;
    };

    useEffect(() => {
        document.documentElement.lang = language.ru.key;
        i18n.changeLanguage(language.ru.key);
    }, [i18n]);

    useEffect(() => {
        if (!admin) {
            loadAdmin();
        }
    }, [loadAdmin, admin]);

    if (isLoading) {
        return <Loader />;
    }

    if (!admin || !admin.lastName) {
        return (
            <Switch>
                <Route exact path={`${path}`} component={AdminLogin} />
                <Redirect path={`${path}/*`} to={`${path}`} />
            </Switch>
        );
    }

    if (admin?.role === Role.SuperAdmin) {
        return (
            <>
                {admin && (
                    <SessionExpired
                        goToLogin={handleLogout}
                        isSessionEnd={isSessionEnd}
                        maxNoActiveTime={maxNoActiveTime}
                    />
                )}
                <SidebarMenu navItems={navItems} />
                <Header
                    user={admin}
                    handleLogout={handleLogout}
                    headerTitleClick={() => push(navItems[0].menuItemHref)}
                    handleClick={() => {
                        const profileElem = navItems.find((item) => item.id === 'profile') || navItems[0];
                        push(profileElem.menuItemHref);
                    }}
                />
                <Page>
                    <Switch>
                        <Redirect exact from={path} to={navItems[0].menuItemHref} />
                        {navItems.map((item) => (
                            <Route key={item.menuItemHref} path={item.menuItemHref}>
                                {item.component}
                            </Route>
                        ))}
                        <Route path={`${path}/*`} component={Error404} />
                    </Switch>
                </Page>
            </>
        );
    }

    return (
        <Switch>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default connect(
    (state: ApplicationState) => state.adminAuthentication,
    actions
)(AdminLayout as () => JSX.Element);
