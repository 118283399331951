import { AppThunkAction } from 'store';

import { AgreementsQuery } from '../../../../models/agreements-query';
import { UserAgreement } from '../../../../models/agreements/user-argeements';
import { ResponsePagination } from '../../../../models/response-pagination';
import { AgreementsService } from '../../../../services/agreements.service';

export const GET_AGREEMENTS = 'user-agreements/GET_AGREEMENTS';
export const SET_LOADING = 'user-agreements/SET_LOADING';
export const SET_ERROR_AGREEMENTS_MESSAGE = 'user-agreements/SET_ERROR_TEST';

export interface GetAgreements {
    type: typeof GET_AGREEMENTS;
    agreements: ResponsePagination<UserAgreement>;
}

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface SetErrorMessage {
    type: typeof SET_ERROR_AGREEMENTS_MESSAGE;
    agreementsError: string;
}

export type ActionTypes = SetLoading | GetAgreements | SetErrorMessage;

export const actions = {
    getAgreements:
        (params: AgreementsQuery): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const agreements = (await AgreementsService.getAgreementsUser(params)).data;

                dispatch({
                    type: GET_AGREEMENTS,
                    agreements,
                });
            } catch {
                dispatch({
                    type: SET_ERROR_AGREEMENTS_MESSAGE,
                    agreementsError: 'Ошибка',
                });
            }
        },
};
