import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { ApplicationState } from 'store';
import { actionCreators } from './store/actions';
import { connectComponent, withLoading } from 'utils';
import { CategoryStoreProps, CategoryForm, FormValue } from './category-form/CategoryForm';

type CategoriesFormEditProps = CategoryStoreProps;

const CategoriesFormEdit = (props: CategoriesFormEditProps) => {
    const { getCategory, editCategory, categorySuccess, clearMessageCategory, clearCategoryForEdit } = props;
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();
    const alert = useAlert();

    useEffect(() => {
        if (categorySuccess) {
            alert.success(categorySuccess);
            clearMessageCategory();
            push(pathname.replace(`/edit/${id}`, ''));
        }
    }, [categorySuccess, alert, clearMessageCategory, pathname, id, push]);

    useEffect(() => {
        getCategory(parseInt(id, 10));
        return () => {
            clearCategoryForEdit();
        };
    }, [id, getCategory, clearCategoryForEdit]);

    const handleClickCancel = (): void => {
        push(pathname.replace(`/edit/${id}`, ''));
    };

    const onSubmit = async ({ name, file }: FormValue) => {
        await editCategory({
            id: parseInt(id as string, 10),
            name,
            iconFile: file?.[0],
        });
    };

    return (
        <CategoryForm {...props} title="Изменить категорию" onSubmit={onSubmit} handleClickCancel={handleClickCancel} />
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => {
        if (state.categories) {
            return state.categories;
        }
    },
    actions: actionCreators,
    component: withLoading(CategoriesFormEdit),
});
