import React from 'react';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import { RouterTabs } from '../../components/RouterTabs/RouterTabs';
import { userTestResultRoutes } from '../../constants/user-test-result-routes';
import { RouterTab } from '../../models/router-tab';

import TestStatistics from '../test-statistic/TestStatistics';
import UsersJournal from '../users-journal/UsersJournal';
import SfResults from '../sf-results/sf-results';
import AdditionalCertificateComponent from 'pages/additional/additional-certificates';

const UserTestResult = () => {
    const { path } = useRouteMatch();
    return (
        <>
            <RouterTabs tabs={getTabsWithPaths(userTestResultRoutes, path)} />
            <Switch>
                <Route path={`${path}/cybersecurity`} component={UsersJournal} />
                <Route exact path={`${path}/product-lk`} component={TestStatistics} />
                <Route exact path={`${path}/sf-results`} component={SfResults} />
                <Route exact path={`${path}/additional`} component={AdditionalCertificateComponent} />
                <Redirect path={`${path}/*`} to={`${path}/cybersecurity`} />
                <Redirect path={`${path}`} to={`${path}/cybersecurity`} />
            </Switch>
        </>
    );
};

export default UserTestResult;

const getTabsWithPaths = (tabs: RouterTab[], path: string) =>
    tabs.map((tab) => ({
        ...tab,
        path: `${path}/${tab.path}`,
    }));
