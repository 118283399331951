import api from '../api';
import { GetCertificates, UserCertificate, UserCertificateListItem } from 'pages/certificates/store/store';

export class AllSertificateService {
    static getSfCertificate = (id: string) => api.get<UserCertificate>(`UserCertificate/salesforce/${id}`);
    static getCertificate = (id: string) => api.get<UserCertificate>(`UserCertificate/${id}`);
    static getSfCertificates = (val: GetCertificates) =>
        api.get<UserCertificateListItem[]>('UserCertificate/salesforce', { params: { ...val } });
    static getCertificates = (val: GetCertificates) =>
        api.get<UserCertificateListItem[]>('UserCertificate', { params: { ...val } });
}
