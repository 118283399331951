import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Column, Title } from '../../components';

interface StudentStudyCenterExpiredProps {
    label: string;
}

const StudentStudyCenterExpired = (props: StudentStudyCenterExpiredProps) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { label } = props;

    const goToMainPage = () => {
        push('/');
    };

    return (
        <Column columnSize="50" className="block-center">
            <Title titleText={t(label)} />
            <Button buttonClass="primary" handleClick={() => goToMainPage()}>
                {t('go-to-main')}
            </Button>
        </Column>
    );
};

export default StudentStudyCenterExpired;
