import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Title, DetailsModal, ValidationMessage, ModalWindow } from 'components';
import { TestResultDetails } from 'pages/user-test/store';
import TestAnswersStatistic from '../../../../../components/TestAnswersStatistic/TestAnswersStatistic';
import { UserTestStatistic } from '../../../../../models/test/user-test-statistic';
import { CertDirection } from 'enums/cert-direction';

interface InfoResultProps {
    isSuccess: boolean;
    clearStore: () => void;
    title: string;
    testResultDetails?: TestResultDetails;
    testStatistic?: UserTestStatistic;
    isGiveCertificate: boolean;
    certDirection: CertDirection;
    passingTestId?: number;
    isStudentStudyCenter?: boolean;
}

export const InfoResult = (props: InfoResultProps) => {
    const {
        isSuccess,
        clearStore,
        testResultDetails,
        testStatistic,
        title,
        isGiveCertificate,
        passingTestId,
        isStudentStudyCenter,
        certDirection,
    } = props;
    const { push } = useHistory();
    const { state } = useLocation<{ isTimeExpired: boolean }>();
    const [t] = useTranslation(['user-test']);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleBackToMenu = () => {
        clearStore();
        isStudentStudyCenter ? push('/') : push('/test');
    };

    const getBackButton = () => (
        <Button buttonClass="primary" handleClick={handleBackToMenu}>
            {isStudentStudyCenter ? t('translation:go-to-main') : t('back-to-menu')}
        </Button>
    );

    return (
        <div className="test-result-information">
            {state?.isTimeExpired && (
                <div className="time-passed-message">
                    <ValidationMessage>{t('end-time-message')}</ValidationMessage>
                </div>
            )}
            {(isStudentStudyCenter || certDirection === CertDirection.ProductLK) && testStatistic && (
                <ModalWindow onCloseClick={() => setModalIsOpen(false)} isOpen={modalIsOpen} theme="modified-darkened">
                    <TestAnswersStatistic statistic={testStatistic} showDownload={false} showEdit={false} />
                </ModalWindow>
            )}
            {isSuccess ? (
                <>
                    {!isStudentStudyCenter && testResultDetails && (
                        <DetailsModal
                            additionalClasses="test-result-information-modal"
                            details={testResultDetails}
                            isOpen={modalIsOpen}
                            handleCloseClick={() => setModalIsOpen(false)}
                            title={t('modal-title')}
                            theme="modified-darkened"
                        />
                    )}
                    <Title titleText={title} />
                    <p className="result-text">{t('highest-result-message')}</p>
                    <div className="link result-link" onClick={() => setModalIsOpen(true)}>
                        {t('show-details-results')}
                    </div>
                    {isGiveCertificate ? (
                        <Button
                            buttonClass="primary"
                            handleClick={() => {
                                clearStore();
                                push('/certificates/confirm-data', { passingTestId });
                            }}
                        >
                            {t('get-certificate')}
                        </Button>
                    ) : (
                        getBackButton()
                    )}
                </>
            ) : (
                <>
                    {(isStudentStudyCenter || certDirection === CertDirection.ProductLK) && (
                        <div className="link result-link" onClick={() => setModalIsOpen(true)}>
                            {t('show-details-results')}
                        </div>
                    )}

                    <p className="result-text">{t('failed-result-message')}</p>
                    {getBackButton()}
                </>
            )}
        </div>
    );
};
