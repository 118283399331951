import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

import { Column } from '../Column';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

interface ModalWindowProps {
    isOpen: boolean;
    size?: 'big' | 'small';
    confirmButtonText?: string;
    cancelButtonText?: string;
    additionalClass?: string;
    onCloseClick: () => void;
    onConfirmAction?: (e: React.MouseEvent<HTMLElement>) => void;
    theme?: 'standard' | 'modified' | 'modified-darkened';
    modalMessage?: string;
    children?: ReactNode;
}

ReactModal.setAppElement('body');

export const ModalWindow = (props: ModalWindowProps) => {
    const {
        isOpen,
        size = 'small',
        onCloseClick,
        modalMessage,
        cancelButtonText,
        confirmButtonText,
        onConfirmAction,
        additionalClass,
        theme = 'standard',
        children,
    } = props;

    const closeModal = () => {
        onCloseClick();
    };

    const classesModal = `react-modal react-modal-size-${size} ${
        additionalClass ? `react-modal-${additionalClass}` : `react-modal-${theme}`
    }`;

    const classesOverlay = `react-modal-overlay ${
        additionalClass ? `react-modal-overlay-${additionalClass}` : `react-modal-overlay-${theme}`
    }`;

    return (
        <Column columnSize="100">
            <ReactModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className={classesModal}
                overlayClassName={classesOverlay}
            >
                <div onClick={closeModal} className={`details-modal-close ${theme}`}>
                    <Icon iconName="close" />
                </div>
                <Column columnSize="100">
                    {modalMessage && <Paragraph additionalClass={'lead'}>{modalMessage}</Paragraph>}
                    {children && children}
                </Column>
                <Column columnSize="100" className={'text-center'}>
                    {confirmButtonText && (
                        <Button handleClick={onConfirmAction} buttonClass={'primary'}>
                            {confirmButtonText}
                        </Button>
                    )}
                    {cancelButtonText && (
                        <Button handleClick={closeModal} buttonClass={'secondary'}>
                            {cancelButtonText}
                        </Button>
                    )}
                </Column>
            </ReactModal>
        </Column>
    );
};
