import React, { FC, useEffect, useState } from 'react';
import { Container, Column, Title, Button, Paragraph, Checkbox } from 'components';
import { useTitle } from 'utils';
import { AutoComplite } from 'components/AutoComplite/AutoComplite';
import { getCertificates, getUser, getUsers, moveCertificates } from './services/api';
import { UserShort } from './models/user-short';
import { UserFull } from './models/user-full';
import { Certificate } from './models/certificate';
import { MoveCertificate as DataModel } from './models/move-certificate';
import { UserCard } from './user-card';
import moment from 'moment';
import Pagination from 'components/Pagination/Pagination';
import { useAlert } from 'react-alert';

import './user-card.scss';

const MoveCertificate: FC = () => {
    const title = 'Переместить сертификаты';
    useTitle(title);

    const alert = useAlert();

    const [userFromShort, setUserFromShort] = useState<UserShort>();
    const [userFromFull, setUserFromFull] = useState<UserFull>();
    const [certificatesFrom, setCertificatesFrom] = useState<Certificate[]>([]);

    const [userToShort, setUserToShort] = useState<UserShort>();
    const [userToFull, setUserToFull] = useState<UserFull>();

    const [page, setPage] = useState(0);

    const size = 100;

    useEffect(() => {
        if (!userFromShort) {
            setUserFromFull(undefined);
            setCertificatesFrom([]);
            return;
        }
        Promise.all([getCertificates(userFromShort.id), getUser(userFromShort.id)]).then(([certs, user]) => {
            setCertificatesFrom(certs.data);
            setUserFromFull(user.data);
        });
    }, [userFromShort]);

    useEffect(() => {
        if (!userToShort) {
            setUserToFull(undefined);
            return;
        }
        Promise.all([getCertificates(userToShort.id), getUser(userToShort.id)]).then(([_, user]) => {
            setUserToFull(user.data);
        });
    }, [userToShort]);

    const checkCertificate = (index: number) => {
        setCertificatesFrom([
            ...certificatesFrom.slice(0, index),
            {
                ...certificatesFrom[index],
                isChecked: !certificatesFrom[index].isChecked,
            },
            ...certificatesFrom.slice(index + 1),
        ]);
    };

    const showUserTo = () => userFromFull && certificatesFrom && certificatesFrom.length !== 0;

    const showSendButton = () => showUserTo() && userToFull;

    const selectAll = () => {
        setCertificatesFrom([
            ...certificatesFrom.map((val) => ({
                ...val,
                isChecked: true,
            })),
        ]);
    };

    const deSelectAll = () => {
        setCertificatesFrom([
            ...certificatesFrom.map((val) => ({
                ...val,
                isChecked: false,
            })),
        ]);
    };

    const sendCertificate = () => {
        if (userToFull?.isBlocked) {
            alert.error(`Пользователь ${userToShort?.name} - заблокирован`, { timeout: 10000 });
            return;
        }
        if (userFromFull?.isBlocked) {
            alert.error(`Пользователь ${userFromShort?.name} - заблокирован`, { timeout: 10000 });
            return;
        }
        const data: DataModel = {
            fromUserId: userFromFull!.id,
            toUserId: userToFull!.id,
            innerCertificatesId: certificatesFrom
                .filter((cert) => !cert.fromSf && cert.isChecked)
                .map((cert) => cert.id),
            sfCertificatesId: certificatesFrom.filter((cert) => cert.fromSf && cert.isChecked).map((cert) => cert.id),
        };

        if (data.innerCertificatesId.length === 0 && data.sfCertificatesId.length === 0) {
            alert.error(`Не выбран ни один сертификат для переноса`, { timeout: 10000 });
            return;
        }

        moveCertificates(data)
            .then(
                () => Promise.all([getCertificates(userFromShort!.id), getUser(userFromShort!.id)]),
                () => {
                    alert.error(`Что-то пошло не так при передаче данных о сертификатах`, { timeout: 10000 });
                }
            )
            .then(
                (d) => {
                    if (!d) throw new Error('something wrong');

                    setPage(0);
                    setCertificatesFrom(d[0].data);
                    setUserFromFull(d[1].data);

                    alert.success(`Сертификаты от ${userFromShort?.name} перенесены к ${userToShort?.name}`, {
                        timeout: 10000,
                    });
                },
                () => {
                    alert.error(`Что-то пошло не так при получении данных о пользователе ${userFromShort?.name}`, {
                        timeout: 10000,
                    });
                }
            );
    };

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={title} />
            </Column>
            <Column columnSize="100">
                <AutoComplite
                    label="От кого"
                    getArrAsync={(s?: string) => getUsers(s).then((d) => d.data.items)}
                    labelField="name"
                    name="fromShortId"
                    id="fromUserId"
                    onChange={(e: UserShort | undefined) => setUserFromShort(e)}
                />
            </Column>
            {userFromFull && <UserCard user={userFromFull} />}
            {certificatesFrom && certificatesFrom.length !== 0 && (
                <>
                    <Column columnSize="100" className="top-space-3x">
                        <Button buttonClass="primary" pSize="small" handleClick={selectAll}>
                            Выделить всё
                        </Button>
                        <Button buttonClass="primary" pSize="small" handleClick={deSelectAll}>
                            Снять выделенное
                        </Button>
                    </Column>
                    <Column columnSize="100" className="certificates">
                        {certificatesFrom.map(
                            (val, index) =>
                                index >= page * size &&
                                index < page * size + size && (
                                    <Container additionalClass="certificate-card" key={val.id}>
                                        <Column columnSize="100">
                                            <fieldset className="form-group">
                                                <Checkbox
                                                    id={`certId-${val.id}`}
                                                    label={val.testName}
                                                    isChecked={val.isChecked}
                                                    handleChange={() => checkCertificate(index)}
                                                />
                                            </fieldset>
                                        </Column>
                                        <Column columnSize="50">
                                            <Paragraph additionalClass="top-space-3x">
                                                Дата создания: {moment(val.creationDate).format('DD/MM/YYYY')}
                                            </Paragraph>
                                        </Column>

                                        {val.expirationDate && (
                                            <Column columnSize="50">
                                                <Paragraph additionalClass="top-space-3x">
                                                    Дата окончания: {moment(val.expirationDate).format('DD/MM/YYYY')}
                                                </Paragraph>
                                            </Column>
                                        )}
                                    </Container>
                                )
                        )}
                    </Column>
                </>
            )}
            {showUserTo() && (
                <Column columnSize="100">
                    <Pagination
                        size={size}
                        page={page}
                        total={certificatesFrom.length}
                        handleChange={(page) => setPage(page)}
                    />
                </Column>
            )}
            {showUserTo() && (
                <>
                    <Column columnSize="100" className="top-space-3x">
                        <AutoComplite
                            label="Кому"
                            getArrAsync={(s?: string) => getUsers(s).then((d) => d.data.items)}
                            labelField="name"
                            name="toShortId"
                            id="toUserId"
                            onChange={(e: UserShort | undefined) => setUserToShort(e)}
                        />
                    </Column>
                    {userToFull && <UserCard user={userToFull} />}
                    {showSendButton() && (
                        <Button buttonClass="primary" handleClick={sendCertificate}>
                            Отправить
                        </Button>
                    )}
                </>
            )}
        </Container>
    );
};

export default MoveCertificate;
