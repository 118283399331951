import { Action, Reducer } from 'redux';
import { TrainingGroup } from '../../../../models/training-group/training-group';
import { ActionTypes, ERROR_TRAINING_GROUPS, LOAD_TRAINING_GROUPS, SET_LOADING } from './actions';

export interface TrainingGroupTCAdminState {
    isLoading: boolean;
    students: [];
    errorTrainingGroups: string;
    trainingGroups: TrainingGroup[];
}

const initialValue: Readonly<TrainingGroupTCAdminState> = {
    isLoading: false,
    students: [],
    trainingGroups: [],
    errorTrainingGroups: '',
};

export const reducer: Reducer<TrainingGroupTCAdminState> = (
    state: TrainingGroupTCAdminState = initialValue,
    incomingAction: Action
): TrainingGroupTCAdminState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case LOAD_TRAINING_GROUPS:
            return {
                ...state,
                trainingGroups: action.trainingGroups,
                isLoading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case ERROR_TRAINING_GROUPS:
            return {
                ...state,
                errorTrainingGroups: action.error || 'error',
            };
        default:
            return state;
    }
};
