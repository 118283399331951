import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Column, Container, Title } from '../../components';

import { StudyCenterState } from './store/store';
import { actions } from './store/actions';
import { ApplicationState } from '../../store';
import { StudyCenterForm } from './study-center-form/StudyCenterForm';
import { connectComponent, withLoading } from 'utils';

type StudyCenterFormProps = StudyCenterState & typeof actions;

const StudyCenterFormAdd = (props: StudyCenterFormProps) => {
    const { addStudyCenter, clearEditableStudyCenter, studyCenterSuccess, studyCenterError, clearMessage } = props;
    const { push } = useHistory();
    const { pathname } = useLocation();
    const alert = useAlert();

    useEffect(() => {
        clearEditableStudyCenter();
    }, [clearEditableStudyCenter]);

    const goBack = useCallback(() => {
        push(pathname.replace('/add', ''));
    }, [push, pathname]);

    useEffect(() => {
        if (studyCenterSuccess) {
            alert.success(studyCenterSuccess);
            goBack();
        } else if (studyCenterError) {
            alert.error(studyCenterError);
        }

        clearMessage();
    }, [alert, clearMessage, studyCenterError, goBack, studyCenterSuccess]);

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText="Создать учебный центр" />
            </Column>
            <Column columnSize="100">
                <StudyCenterForm {...props} onSubmit={addStudyCenter} goBack={goBack} />
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyCenters,
    actions,
    component: withLoading(StudyCenterFormAdd),
});
