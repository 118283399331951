import React, { useState } from 'react';
import { useTitle } from 'utils';
import { useUrlQueryParamsForGrid } from 'hooks/useUrlQueryParamsForGrid';
import { ResponsePagination } from 'models/response-pagination';
import { dateTimeFormat } from '../../constants/date-format';
import { AddProductGroup, ProductGroup, UpdateProductGroup } from './models/ProductGroupsModels';
import {
    addGroup,
    deleteGroup,
    getGroups,
    updateGroup,
    checkBeforeDelete,
    addExcel,
} from './services/ProductGroupService';
import { useAlert } from 'react-alert';
import {
    Button,
    Column,
    Container,
    EmptyListWarning,
    Grid,
    Icon,
    Loader,
    Row,
    TextInput,
    Title,
    UploadButton,
} from '../../components';
import moment from 'moment';
import { DownloadFileService } from 'services/download-file.service';
import { AddModal } from './AddModal';

const ProductGroups = () => {
    useTitle('Продуктовые группы');
    const alert = useAlert();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | undefined>();
    const [groups, setGroups] = useState<ResponsePagination<ProductGroup>>({ count: 0, items: [] });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [updateModel, setUpdateModel] = useState<ProductGroup | undefined>(undefined);
    const getGroupsFunc = (page: number, size: number, str?: string) => {
        getGroups(page, size, str).then((data) => {
            setGroups(data.data);

            if (data.data.items?.length === 0 && page !== 0) {
                setPage(page - 1);
            }
        });
    };
    const {
        pagination,
        queryState: [search, setSearch],
        setPage,
    } = useUrlQueryParamsForGrid({
        total: groups.count,
        loadFn: ({ page, size, query }) => getGroupsFunc(page, size, query),
    });
    const deleteClick = (id: string) => {
        if (window.confirm('Вы уверены?')) {
            setLoading(true);
            checkBeforeDelete(id).then((data) => {
                if (
                    data.data.exists &&
                    window.confirm(
                        'Даннная продуктовая группа используется в тестах или исторических сертификатах. Вы всё ещё уверены?'
                    )
                ) {
                    deleteGroup(id)
                        .then(
                            () => {
                                getGroupsFunc(pagination.page, pagination.size, search);
                                alert.info('Успех удаления');
                            },
                            () => alert.error('Ошибка удаления')
                        )
                        .finally(() => {
                            setLoading(false);
                        });
                } else if (!data.data.exists) {
                    deleteGroup(id)
                        .then(
                            () => {
                                getGroupsFunc(pagination.page, pagination.size, search);
                                alert.info('Успех удаления');
                            },
                            () => alert.error('Ошибка удаления')
                        )
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            });
        }
    };
    const getGridData = (): Row[] =>
        groups.items.map((item) => ({
            ...item,
            creationDate: moment(item.creationDate).format(dateTimeFormat),
            updateActions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => handleClickUpdate(item)}>
                        <Icon iconName="edit" />
                    </Button>
                ),
            },
            actions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => deleteClick(item.id)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
            },
        }));
    const getExcel = () => {
        DownloadFileService.download(`ProductGroup/xls?str=${search}`);
    };
    const downloadList = () => {
        if (!file) {
            alert.error('Вы не указали файл');
            return;
        }
        setLoading(true);
        addExcel(file)
            .then(
                () => {
                    alert.info('Данные успешно добавлены');
                    getGroupsFunc(pagination.page, pagination.size, search);
                },
                () => {
                    alert.error(
                        'Ошибка. Возможно названия не уникальны или уже существуют или формат файла не валиден'
                    );
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };
    const handleClickUpdate = (model: ProductGroup) => {
        setUpdateModel(model);
        setShowModal(true);
    };
    const handleClickAdd = () => {
        setUpdateModel(undefined);
        setShowModal(true);
    };
    const updateHandle = (model: UpdateProductGroup) => {
        setLoading(true);
        updateGroup(model)
            .then(
                (data) => {
                    const index = groups.items.findIndex((r) => r.id === model.id);
                    if (index < 0) return;

                    groups.items[index] = data.data;

                    setGroups({
                        ...groups,
                    });
                    alert.info('Успех обновления');
                },
                () => {
                    alert.error('Ошибка. Возможно данная группа уже существует');
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };
    const addHandle = (model: AddProductGroup) => {
        setLoading(true);
        addGroup(model)
            .then(
                (data) => {
                    setGroups({
                        count: groups.count + 1,
                        items: [data.data, ...groups.items],
                    });
                    alert.info('Успех добавления');
                },
                () => {
                    alert.error('Ошибка. Возможно данная группа уже существует');
                }
            )
            .finally(() => setLoading(false));
    };
    return (
        <>
            {loading && <Loader isBlock />}
            <Container>
                <Column columnSize="100">
                    <Title titleText="Продуктовые группы" />
                </Column>
                <Column columnSize="100" className="text-right bottom-space-6x">
                    <fieldset className="d-flex align-items-left" style={{ float: 'left', flexDirection: 'column' }}>
                        <legend
                            style={{
                                padding: '0px 10px',
                            }}
                        >
                            Импорт excel шаблона
                        </legend>
                        <UploadButton
                            type="file"
                            name="file"
                            setValue={(_, files) => setFile(files ? files[0] : undefined)}
                            accept=".xlsx"
                        />
                        <Button buttonClass="primary" handleClick={downloadList} isDisabled={!file}>
                            Начать загрузку выбранного файла
                        </Button>
                        <a className="link top-space-10x" href="/office/ProductGroup.xlsx">
                            Скачать шаблон excel
                        </a>
                    </fieldset>
                </Column>
                <Column columnSize="60">
                    <TextInput
                        id="search-field"
                        label="Название"
                        value={search}
                        onChangeHandler={(e) => setSearch(e.target.value)}
                    />
                </Column>
                <Column columnSize="20" className="text-right">
                    <Button buttonClass="primary" handleClick={handleClickAdd}>
                        Создать
                    </Button>
                </Column>
                <Column columnSize="20" className="text-right">
                    <Button buttonClass="primary" handleClick={getExcel}>
                        Экспорт в excel
                    </Button>
                </Column>
                <Column columnSize="100">
                    {groups.items?.length ? (
                        <Grid rows={getGridData()} columns={columns} pagination={pagination} />
                    ) : (
                        <EmptyListWarning />
                    )}
                </Column>
                {showModal && (
                    <AddModal
                        closeModal={() => setShowModal(false)}
                        updateModel={updateModel}
                        updateFunc={updateHandle}
                        addFunc={addHandle}
                    />
                )}
            </Container>
        </>
    );
};

export default ProductGroups;

const columns = {
    id: {
        value: 'id',
        columnSize: '20',
    },
    name: {
        value: 'Название',
        columnSize: '40',
    },
    creationDate: {
        value: 'Дата создания',
        columnSize: '20',
    },
    updateActions: {
        value: '',
        columnSize: '10',
    },
    actions: {
        value: '',
        columnSize: '10',
    },
};
