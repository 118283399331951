import { ResponsePagination } from 'models/response-pagination';
import { Action, Reducer } from 'redux';
import { CertDirection } from '../../../enums/cert-direction';
import { Category } from '../../../models/category';
import {
    ADD_CATEGORY,
    CategoryActionTypes,
    CLEAR_CATEGORY_FOR_EDIT,
    CLEAR_MESSAGE_CATEGORY,
    DELETE_CATEGORY,
    ERROR_CATEGORY,
    GET_CATEGORY,
    LOAD_CATEGORIES,
    SET_DIRECTION,
    SET_LOADING,
    SUCCESS_CATEGORY,
    UPDATE_CATEGORY,
} from './actions';

export interface CategoriesState {
    isLoading: boolean;
    categories: ResponsePagination<Category>;
    categoryForEdit?: Category;
    categoryError: string;
    categorySuccess: string;
    selectedDirectionId: CertDirection;
}

const initialState: Readonly<CategoriesState> = {
    isLoading: false,
    categories: { items: [], count: 0 },
    categoryError: '',
    categorySuccess: '',
    selectedDirectionId: CertDirection.CyberSecurity,
};

export const reducer: Reducer<CategoriesState> = (
    state: CategoriesState = initialState,
    incomingAction: Action
): CategoriesState => {
    const action = incomingAction as CategoryActionTypes;
    switch (action.type) {
        case LOAD_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
                isLoading: false,
            };

        case GET_CATEGORY:
            return {
                ...state,
                categoryForEdit: action.category,
                isLoading: false,
            };

        case ADD_CATEGORY:
            return {
                ...state,
                categories: {
                    items: [action.category, ...state.categories.items],
                    count: state.categories.count + 1,
                },
                categorySuccess: 'Категория добавлена.',
                isLoading: false,
            };

        case UPDATE_CATEGORY: {
            const index = state.categories.items.findIndex((r) => r.id === action.category.id);
            state.categories.items[index] = { ...action.category };
            return {
                ...state,
                categories: {
                    count: state.categories.count,
                    items: [...state.categories.items],
                },
                isLoading: false,
            };
        }
        case DELETE_CATEGORY:
            return {
                ...state,
                categories: {
                    count: state.categories.count,
                    items: state.categories.items.filter((category) => category.id !== action.categoryId),
                },
                isLoading: false,
            };
        case ERROR_CATEGORY:
            return {
                ...state,
                categoryError: action.categoryError,
                isLoading: false,
            };
        case CLEAR_MESSAGE_CATEGORY:
            return {
                ...state,
                categoryError: '',
                categorySuccess: '',
            };
        case SUCCESS_CATEGORY:
            return {
                ...state,
                categorySuccess: action.categorySuccess,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case CLEAR_CATEGORY_FOR_EDIT: {
            return {
                ...state,
                categoryForEdit: undefined,
            };
        }
        case SET_DIRECTION: {
            return {
                ...state,
                selectedDirectionId: action.directionId,
            };
        }
        default:
            return state;
    }
};
