import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { ApplicationState } from 'store';
import { Container, Column, Title, Button, EmptyListWarning, Row, Grid, Link, Icon } from 'components';
import { connectComponent, withLoading } from 'utils';
import { StudyCenterState } from './store/store';
import { actions } from './store/actions';

type CategoryCenterListProps = StudyCenterState & typeof actions;

const StudyCenterList = (props: CategoryCenterListProps) => {
    const { loadStudyCenters, studyCenters, studyCenterError, studyCenterSuccess, clearMessage } = props;
    const { pathname } = useLocation();
    const alert = useAlert();
    const { push } = useHistory();

    const handleClickAdd = () => {
        push(`${pathname}/add`);
    };

    const handleClickEdit = (id: number) => {
        push(`${pathname}/edit/${id}`);
    };

    useEffect(() => {
        loadStudyCenters();
    }, [loadStudyCenters]);

    useEffect(() => {
        if (studyCenterSuccess) {
            alert.success(studyCenterSuccess);
        } else if (studyCenterError) {
            alert.error(studyCenterError);
        }

        clearMessage();
    }, [alert, clearMessage, studyCenterError, studyCenterSuccess]);

    const getGridData = (): Row[] =>
        studyCenters.map((item) => ({
            name: {
                value: item.name,
                additionalClasses: 'link',
                handleClick: () => {
                    handleClickEdit(item.id);
                },
            },
            action: {
                align: 'end',
                additionalComponent: (
                    <div className="d-flex align-items-center text-right">
                        <Link
                            linkHref="#"
                            linkTitle="Изменить учебный центр"
                            handleClick={() => handleClickEdit(item.id)}
                        >
                            <Icon iconName="edit" />
                        </Link>
                    </div>
                ),
            },
        }));

    return (
        <Container>
            <Column columnSize="50">
                <Title titleText="Учебные центры" />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button buttonClass="primary" handleClick={handleClickAdd}>
                    Создать учебный центр
                </Button>
            </Column>
            <Column columnSize="100">
                {studyCenters?.length ? <Grid rows={getGridData()} columns={columns} /> : <EmptyListWarning />}
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyCenters,
    component: withLoading(StudyCenterList),
    actions: actions,
});

const columns = {
    name: {
        value: 'Название',
        columnSize: '80',
    },
    action: {
        value: '',
        columnSize: '20',
    },
};
