import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../constants/date-format';
import { GridAction } from '../../models/grid-action';
import { StudyCenterUser } from '../../models/study-center-users/study-center-user';
import { getCountryTransKey } from '../../utils/get-country-trans-key';
import { Icon, Button, Row, Grid, Checkbox } from '../index';
import { PaginationProps } from '../Pagination/Pagination';

interface UsersGridProps {
    users: StudyCenterUser[];
    actions?: GridAction[];
    showAgreementsClick?: (email: string) => void;
    pagination?: PaginationProps;
    showReason: (str: string) => void;
}

const StudyCentersUsersGrid = (props: UsersGridProps) => {
    const { users, actions, showAgreementsClick, pagination, showReason } = props;
    const { t } = useTranslation('countries');

    const isExpire = (date: string): boolean => {
        const now = moment(new Date());
        const start = moment(date);

        const duration = moment.duration(now.diff(start));

        return duration.asYears() > 5;
    };

    const getGridData = (): Row[] =>
        users.map((user) => ({
            email: showAgreementsClick
                ? {
                      additionalClasses: 'link',
                      value: user.email,
                      handleClick: () => showAgreementsClick(user.email),
                  }
                : user.email,
            name: `${user?.lastName ?? ''} ${user?.userName ?? ''} ${user?.middleName ?? ''}`,
            studyCenter: user?.studyCenter?.map((r) => r.name)?.join(', ') ?? '',
            countryName: t(getCountryTransKey(user?.country?.id)) as string,
            company: user.company,
            creationDate: moment(user.creationDate).format(dateTimeFormat),
            lastLoginDate: {
                value: moment(user.lastLoginDate).format(dateTimeFormat),
                additionalClasses: isExpire(user.lastLoginDate as string) ? 'danger-text' : '',
            },
            externalId: user.externalId,
            isPartner: {
                additionalComponent: <Checkbox isChecked={user?.isPartner ?? false} id="isPartner" isDisabled />,
            },
            blocked: {
                additionalClasses: 'cell-actions',
                additionalComponent: (
                    <div>
                        {user.isBlocked && (
                            <Button handleClick={() => showReason(user.blockedReason)} pSize="small">
                                <Icon iconName="lock" />
                            </Button>
                        )}
                    </div>
                ),
            },
            actions: {
                additionalClasses: 'cell-actions',
                additionalComponent: (
                    <>
                        {actions?.map((action) => (
                            <Button handleClick={() => action.handler(user)} pSize="small" key={action.iconName}>
                                <Icon iconName={action.iconName} />
                            </Button>
                        ))}
                    </>
                ),
            },
        }));

    return <Grid rows={getGridData()} columns={columns} pagination={pagination} />;
};

export default StudyCentersUsersGrid;

const columns = {
    email: {
        value: 'Email',
        columnSize: '10',
    },
    name: {
        value: 'ФИО',
        columnSize: '15',
    },
    studyCenter: {
        value: 'Учебный центр',
        columnSize: '10',
    },
    countryName: {
        value: 'Страна',
        columnSize: '10',
    },
    company: {
        value: 'Компания/ВУЗ',
        columnSize: '10',
    },
    creationDate: {
        value: 'Дата регистрации',
        columnSize: '10',
    },
    lastLoginDate: {
        value: 'Дата последнего входа',
        columnSize: '10',
    },
    externalId: {
        value: 'SFDC Id',
        columnSize: '7',
    },
    isPartner: {
        value: 'Партнёр',
        columnSize: '5',
    },
    blocked: {
        value: 'Блокировка',
        columnSize: '3',
    },
    actions: {
        value: '',
        columnSize: '10',
    },
};
