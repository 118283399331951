import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { TextInput } from 'components';
import { TestCategoryForm } from '../../../../../models/test/test-category-form';
import { MAX_ASSESSMENT_DESCRIPTION_LENGTH, MIN_ASSESSMENT_INPUT_VALUE } from '../../constants';

interface InputProps {
    label: string;
    defaultValue: string | number;
    fieldName: string;
}

interface AssessmentInputProps {
    assessment: InputProps;
    description: InputProps;
    prevAssessmentValue?: string;
    nextAssessmentValue?: string;
    categories: TestCategoryForm[];
}

export const AssessmentInput = (props: AssessmentInputProps) => {
    const { register, errors, setValue, formState, triggerValidation } = useFormContext();
    const { prevAssessmentValue, nextAssessmentValue, description, assessment, categories } = props;
    const { isSubmitted } = formState;
    const [t] = useTranslation('admin-validation');

    useEffect(() => {
        if (description.defaultValue) {
            setValue(description.fieldName, description.defaultValue);
        }
        if (assessment.defaultValue) {
            setValue(assessment.fieldName, assessment.defaultValue);
        }
    }, [assessment.defaultValue, assessment.fieldName, description.defaultValue, description.fieldName, setValue]);

    useEffect(() => {
        if (isSubmitted) {
            triggerValidation(assessment.fieldName);
        }
    }, [assessment.fieldName, isSubmitted, triggerValidation, categories]);

    return (
        <div className="inputs">
            <TextInput
                id={assessment.fieldName}
                name={assessment.fieldName}
                inputRef={register({
                    required: {
                        value: true,
                        message: t('required'),
                    },
                    min: {
                        value: getMinAssessmentValue(prevAssessmentValue),
                        message: t('incorrect-input'),
                    },
                    validate: (value) =>
                        getMaxAssessmentValidationMessage(value, categories, t, nextAssessmentValue) || true,
                })}
                label={assessment.label}
                type="number"
                errorMessage={errors[assessment.fieldName]?.message}
            />
            <TextInput
                id={description.fieldName}
                name={description.fieldName}
                label={description.label}
                inputRef={register({
                    required: {
                        value: true,
                        message: t('required'),
                    },
                    maxLength: {
                        value: MAX_ASSESSMENT_DESCRIPTION_LENGTH,
                        message: t('max-length', {
                            maxLength: MAX_ASSESSMENT_DESCRIPTION_LENGTH,
                        }),
                    },
                })}
                errorMessage={errors[description.fieldName]?.message}
            />
        </div>
    );
};

const getMinAssessmentValue = (prevAssessmentValue?: string) => {
    if (!prevAssessmentValue || isNaN(+prevAssessmentValue)) {
        return MIN_ASSESSMENT_INPUT_VALUE;
    }

    return +prevAssessmentValue + 1;
};

const getMaxAssessmentValidationMessage = (
    value: string,
    categories: TestCategoryForm[],
    t: TFunction,
    nextAssessmentValue?: string
) => {
    const numberValue = +value;

    const sum = (acc: number, cur: TestCategoryForm) =>
        !(cur?.quantitySelectedQuestions ?? false) ? acc : acc + +cur.quantitySelectedQuestions;

    const quantitySelectedQuestions = categories?.filter((x) => x.checked).reduce(sum, 0);

    if (numberValue > quantitySelectedQuestions) {
        return t('max-point');
    }

    if (!nextAssessmentValue || isNaN(+nextAssessmentValue)) {
        return '';
    }

    return numberValue >= +nextAssessmentValue + 1 ? t('incorrect-input') : '';
};
