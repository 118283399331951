import api from '../api';
import { AgreementsQuery } from '../models/agreements-query';
import { UserAgreement } from '../models/agreements/user-argeements';
import { ResponsePagination } from '../models/response-pagination';
import { StudyCenterUserAgreements } from '../models/study-center-user-agreements';

export class AgreementsService {
    // users and study center admins
    static getAgreementsUser = (params: AgreementsQuery) =>
        api.get<ResponsePagination<UserAgreement>>(`Agreement`, { params });

    static getAgreementsPartner = (params: AgreementsQuery) =>
        api.get<ResponsePagination<UserAgreement>>(`Agreement/getAgreementsPartner`, { params });

    static getAgreementsStudyCenter = (params: AgreementsQuery) =>
        api.get<ResponsePagination<StudyCenterUserAgreements>>(`Agreement/getAgreementsStudyCenter`, { params });
}
