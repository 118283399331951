import React, { useEffect, useState, memo } from 'react';
import moment from 'moment';

import { Timer } from 'components';
import { useInterval } from 'utils';

interface TestTimerProps {
    totalSeconds: number;
    handleEndTime: () => void;
}

const propsAreEqual = (prevProps: TestTimerProps, nextProps: TestTimerProps) =>
    prevProps?.totalSeconds === nextProps?.totalSeconds;

export const TestTimer = memo((props: TestTimerProps) => {
    const getFinish = () => moment(new Date()).add({ seconds: totalSeconds });

    const { totalSeconds, handleEndTime } = props;
    const [secondsLeft, setSecondsLeft] = useState(totalSeconds * 1000);
    const [finishDate, setFinishDate] = useState(getFinish());

    useEffect(() => {
        const finish = getFinish();
        const start = moment(new Date());
        const diff = finish.diff(start);

        setSecondsLeft(diff);
        setFinishDate(finish);
    }, [totalSeconds]);

    useInterval(() => {
        const start = moment(new Date());
        const diff = finishDate.diff(start);

        setSecondsLeft(diff);
    });

    useEffect(() => {
        if (secondsLeft <= 0) {
            handleEndTime();
        }
    }, [secondsLeft, handleEndTime]);

    return (
        <Timer secondsLeft={moment.utc(secondsLeft).format('HH:mm:ss')} additionalClasses="desktop-user-test-timer" />
    );
}, propsAreEqual);
