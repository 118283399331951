import React, { PropsWithChildren } from 'react';

interface ColumnProps {
    columnSize: string;
    className?: string;
}

export const Column = (props: PropsWithChildren<ColumnProps>) => {
    const { columnSize, className, children } = props;

    return <div className={`column column-${columnSize} ${className || ''}`}>{children}</div>;
};
