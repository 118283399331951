import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { actions as actionsCountries } from '../../store/countries/actions';
import { CountriesState } from '../../store/countries/store';
import { Label } from '../Form';
import { Option, Select } from '../Select';

type SelectProps = {
    selectedId?: number;
    label?: string;
    hasEmpty?: boolean;
    onChange: (id: number) => void;
    additionalClasses?: string;
    isDisabled?: boolean;
    searchable?: boolean;
} & typeof actionsCountries &
    CountriesState;

const SelectCountry: FC<SelectProps> = (props) => {
    const {
        selectedId,
        label,
        hasEmpty,
        onChange,
        isDisabled,
        additionalClasses,
        searchable,
        countries,
        getCountries,
    } = props;

    const [t, i18n] = useTranslation(['countries', 'info']);
    const { language } = i18n;
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    useEffect(() => {
        const options: Option[] = countries
            .map((country) => ({
                label: t(country.nameKey),
                value: country.id,
            }))
            .sort((a, b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

        if (hasEmpty) {
            options.unshift({
                label: t('info:empty-select'),
                value: '',
            });
        }

        setOptions(options);
    }, [language, t]);

    if (!options.length) {
        return null;
    }

    const getValues = (): Option => {
        let result;

        if (selectedId) {
            result = options.find((item) => item.value === selectedId);
        }

        return result || options[0];
    };

    return (
        <>
            {label && <Label labelText={t(label)} labelFor="" />}
            <Select
                searchable={searchable}
                additionalClasses={additionalClasses}
                isDisabled={isDisabled}
                values={[getValues()]}
                options={options}
                onChange={([select]) => onChange(select?.value as number)}
            />
        </>
    );
};

// @ts-ignore
export default connect(({ countries }: ApplicationState) => countries, actionsCountries)(SelectCountry);
