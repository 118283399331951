import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { StudyGroupState } from './store/store';
import { actions } from './store/actions';
import { ApplicationState } from '../../store';
import { StudyGroupForm, StudyGroupFormData } from './study-group-form/StudyGroupForm';
import { connectComponent, withLoading } from 'utils';

type StudyGroupFormProps = StudyGroupState & typeof actions;

const StudyGroupFormEdit = (props: StudyGroupFormProps) => {
    const { updateStudyGroup, getStudyGroup, getStudyCenters, studyGroupSuccess, studyGroupError, clearMessage } =
        props;
    const alert = useAlert();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        getStudyCenters();
    }, [getStudyCenters]);

    useEffect(() => {
        getStudyGroup(+id);
    }, [getStudyGroup, id]);

    const goBack = useCallback(() => {
        push(pathname.replace(`/edit/${id}`, ''));
    }, [push, pathname, id]);

    useEffect(() => {
        if (studyGroupSuccess) {
            alert.success(studyGroupSuccess);
            goBack();
        } else if (studyGroupError) {
            alert.error(studyGroupError);
        }

        clearMessage();
    }, [alert, clearMessage, studyGroupError, goBack, studyGroupSuccess]);

    const handleSubmit = async (test: StudyGroupFormData) => {
        if (id) {
            await updateStudyGroup({ ...test, id: +id });
        }
    };

    return (
        <StudyGroupForm
            {...props}
            title="Редактировать учебную группу"
            onSubmit={handleSubmit}
            goBack={goBack}
            id={id}
        />
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyGroups,
    actions,
    component: withLoading(StudyGroupFormEdit),
});
