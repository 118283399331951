import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { Container, Column, Title, Paragraph, Link, Page } from 'components';
import { useHistory } from 'react-router-dom';
import TestPassed from '../../../components/TestPassed/TestPassed';
import { useQuery } from '../../../hooks';
import { actions, UserTestState, UserTestErrors } from '../store';
import { ApplicationState } from 'store';
import CorrectCode from './correct-code/CorrectCode';
import IncorrectCode from './incorrect-code/IncorrectCode';
import { KASPERSKY_MAIL } from 'global-constants';
import { withLoading, useTitle } from 'utils';
import { EnterCodeForm } from './EnterCodeForm';

type EnterCodeProps = UserTestState &
    typeof actions & {
        isIncorrectCode: boolean;
        isTestPassed: boolean;
    };

const EnterCode = (props: EnterCodeProps) => {
    const {
        getTrainingGroup,
        clearStudyGroup,
        clearMessage,
        studyGroupDescription,
        isIncorrectCode,
        isTestPassed,
        passedTestCondition,
        zipCoded,
    } = props;
    const [t] = useTranslation(['user-test', 'validation']);
    const urlCode = useQuery().get('code');
    const zc = (useQuery().get('zipCoded')?.toLowerCase() === 'true' ?? false) || zipCoded;
    const { push } = useHistory();

    useTitle(t('enter-code-page-title'));

    useEffect(() => {
        if (urlCode) {
            getTrainingGroup(urlCode, false, zc);
        }
    }, [getTrainingGroup]);

    const clearTestPassedInfo = () => {
        clearStudyGroup();
        clearMessage();
    };

    if (isTestPassed) {
        return (
            <TestPassed
                hadCert={!!passedTestCondition?.passingTestId}
                showCertClick={() => {
                    push(
                        `certificates/${passedTestCondition?.passingTestId}${
                            passedTestCondition?.isSf ? '?is_sf=true' : ''
                        }`
                    );
                    clearTestPassedInfo();
                }}
                retryClick={clearTestPassedInfo}
            />
        );
    }

    if (isIncorrectCode) {
        return <IncorrectCode />;
    }

    if (studyGroupDescription) {
        return <CorrectCode />;
    }

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <Column columnSize="100" className="block-center code-column">
                    <Title titleText={t('title')} />
                    <Paragraph additionalClass="lead">
                        <Trans>{t('message')}</Trans>
                        <Link linkHref={`mailto:${KASPERSKY_MAIL}`}>{KASPERSKY_MAIL}</Link>
                    </Paragraph>
                    <EnterCodeForm
                        handleSubmitForm={async (values) => {
                            await getTrainingGroup(values.code, false);
                        }}
                    />
                </Column>
            </Container>
        </Page>
    );
};

const mapStateToProps = ({ userTest }: ApplicationState) => {
    const { userTestError } = userTest;

    return {
        ...userTest,
        isIncorrectCode: userTestError.status && userTestError.status !== UserTestErrors.PassingTestNotFound,
        isTestPassed: userTestError?.status === '201',
    };
};

export default connect(mapStateToProps, actions)(withLoading(EnterCode) as () => JSX.Element);
