import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Column, Paragraph } from 'components';

export const EmptyListWarning = () => {
    const [t] = useTranslation(['translation']);
    return (
        <Column columnSize="50" className="d-flex top-space-10x">
            <Icon iconName="empty-list" />
            <Paragraph additionalClass="secondary">{t('empty-list')}</Paragraph>
        </Column>
    );
};
