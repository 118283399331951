import { TFunction } from 'i18next';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../constants/date-format';
import { LevelOfEvaluation } from '../../enums/level-of-evaluation';
import { StudyCenterUserLink } from '../../models/study-center-users/study-center-user-link';
import { Grid, Row } from '../Grid';

interface UserLinksGridProps {
    links: StudyCenterUserLink[];
    copyLink: (text: string) => void;
}
const UserLinksGrid: FC<UserLinksGridProps> = ({ links, copyLink }) => {
    const [t] = useTranslation(['column-title', 'study-center', 'level-of-evaluation']);

    const getExpiredDateValue = (link: StudyCenterUserLink): string => {
        if (link.isPassed) {
            const testResult = link.result;

            if (!testResult) {
                return t('study-center:user-test-link-activated');
            }

            return (
                `${t('study-center:user-test-link-activated')}\n` +
                `${t('study-center:user-test-link-date-activated')}:\n${moment(testResult.startDate).format(
                    dateTimeFormat
                )}\n` +
                (testResult.isFinished
                    ? `${t('study-center:user-test-link-test-duration')}: ${testResult.durationString}`
                    : '')
            );
        }

        if (link.isExpired) {
            return `${t('study-center:user-test-link-expired')} ${moment(link.expiredDate).format(dateTimeFormat)}`;
        }

        return `${t('study-center:user-test-link-expire-date')}: ${moment(link.expiredDate).format(dateTimeFormat)}`;
    };

    const getLink = (link: StudyCenterUserLink) => {
        const authLink = `${window.location.origin}/user-test-link/${link.id}`;

        return (
            <div className="link break-word" onClick={() => copyLink(authLink)}>
                {authLink}
            </div>
        );
    };

    const getTestInfo = (link: StudyCenterUserLink) => {
        if (!link.result) {
            return getLink(link);
        }

        const testResult = link.result;

        if (!testResult.isFinished) {
            return t('study-center:user-test-link-test-in-progress');
        }

        const evaluationTransKey = 'level-of-evaluation:' + LevelOfEvaluation[link.result.levelOfEvaluation];
        return (
            <div className="d-flex align-items-center justify-content-space-between">
                {t('study-center:user-test-link-completed')}. {t('study-center:user-test-link-test-evaluation')}:{' '}
                {t(evaluationTransKey)}
            </div>
        );
    };

    const getGridData = (): Row[] =>
        links.map((link) => ({
            testName: {
                additionalClasses: 'break-word',
                value: `${link.trainingGroupName} / ${link.trainingGroupTestName}`,
            },
            link: {
                additionalComponent: getTestInfo(link),
            },
            expiredDate: {
                additionalClasses: 'pre-wrap',
                value: getExpiredDateValue(link),
            },
        }));

    return <Grid columns={getColumns(t)} rows={getGridData()} />;
};

export default UserLinksGrid;

const getColumns = (t: TFunction) => ({
    testName: {
        value: `${t('column-title:training-group')} / ${t('column-title:test-name')}`,
        columnSize: '20',
    },
    link: {
        value: t('column-title:test-info'),
        columnSize: '50',
    },
    expiredDate: {
        value: '',
        columnSize: '20',
    },
});
