import { Button, Column, Container, Label, ValidationMessage } from 'components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerData } from '../answers';
import { ANSWERS_LETTERS, MAX_ANSWER_LENGTH, MAX_QUESTION_DESCRIPTION_LENGTH } from '../constants';
import { Question, QuestionOption } from '../store/store';
import { Answer } from './Answer';
import Editor from 'react-simple-wysiwyg';

export type QuestionFormData = {
    questionTitle: string;
    answers: QuestionOption[];
};

interface QuestionFormProps {
    handleClickCancel: () => void;
    questionForEdit: Question;
    onSubmit: (model: QuestionFormData) => void;
    updateTempData: (question: Question) => void;
}

export const QuestionInnerForm = (props: QuestionFormProps) => {
    const { handleClickCancel, questionForEdit, onSubmit, updateTempData } = props;

    const [t] = useTranslation('admin-validation');

    const [init, setInit] = useState<boolean>(true);

    const getAnswer = (index: number): AnswerData => {
        const letter = ANSWERS_LETTERS.split('')[index];

        return {
            label: `${letter}.`,
            index,
        };
    };

    const notValidTitle = () =>
        (!questionForEdit.title || questionForEdit.title.length > MAX_QUESTION_DESCRIPTION_LENGTH) && !init;

    const notValidAnswersText = () =>
        questionForEdit.questionOptions.some((r) => !r.text || r.text.length > MAX_ANSWER_LENGTH) && !init;
    const notValidAnswersTurn = () => !questionForEdit.questionOptions.some((r) => r.isRight) && !init;

    const notValidSomeThing = () => notValidAnswersTurn() || notValidAnswersText() || notValidTitle();

    const setTitle = (val?: string) => {
        updateTempData({
            ...questionForEdit,
            title: val,
        });
    };

    const [sendData, setSendData] = useState<Question>();

    useEffect(() => {
        if (sendData && !notValidSomeThing()) {
            onSubmit({ questionTitle: sendData.title as string, answers: sendData.questionOptions });
        }
    }, [sendData]);

    const preSubmit = () => {
        setInit(false);

        setSendData({ ...questionForEdit });
    };

    const deleteAnswer = (index: number) => {
        const newAnswers = [
            ...questionForEdit.questionOptions.slice(0, index),
            ...questionForEdit.questionOptions.slice(index + 1),
        ];
        updateTempData({
            ...questionForEdit,
            questionOptions: newAnswers,
        });
    };
    const addAnswer = () => {
        const newQuestion: QuestionOption = { id: undefined, text: undefined, isRight: false };
        updateTempData({
            ...questionForEdit,
            questionOptions: [...questionForEdit.questionOptions, newQuestion],
        });
    };

    const changeChecked = (index: number, newVal: boolean) => {
        questionForEdit.questionOptions[index].isRight = newVal;

        updateTempData({
            ...questionForEdit,
            questionOptions: [...questionForEdit.questionOptions],
        });
    };
    const changeText = (index: number, newVal?: string) => {
        questionForEdit.questionOptions[index].text = newVal;

        updateTempData({
            ...questionForEdit,
            questionOptions: [...questionForEdit.questionOptions],
        });
    };

    return (
        <Container>
            <Column columnSize="100" className="top-space-3x">
                <fieldset className="form-group">
                    <label htmlFor="questionTitle" className="form-label bottom-space-3x d-flex">
                        Текст вопроса
                    </label>
                    <Editor
                        value={questionForEdit.title}
                        onChange={(e) => setTitle((e.target as { value?: string }).value)}
                    />
                    {notValidTitle() && <ValidationMessage>{t('required')}</ValidationMessage>}
                </fieldset>
            </Column>
            <Column columnSize="100">
                <div className="form-group">
                    <div>
                        <Label labelText="Варианты ответов" />
                    </div>

                    {questionForEdit.questionOptions.map((x, index) => (
                        <Answer
                            key={x.id || index}
                            answer={getAnswer(index)}
                            defaultText={x.text}
                            defaultChecked={x.isRight}
                            deleteQuestionAvailable={questionForEdit.questionOptions.length > 2}
                            deleteAnswer={deleteAnswer}
                            changeChecked={changeChecked}
                            changeText={changeText}
                        />
                    ))}

                    {notValidAnswersTurn() && (
                        <div className="bottom-space-3x">
                            <ValidationMessage>{t('choose-correct-answer')}</ValidationMessage>
                        </div>
                    )}
                    {notValidAnswersText() && (
                        <div className="bottom-space-3x">
                            <ValidationMessage>
                                {`${t('required')} - ${t('max-length', { maxLength: MAX_ANSWER_LENGTH })}`}
                            </ValidationMessage>
                        </div>
                    )}
                    {questionForEdit.questionOptions.length < 10 && (
                        <Button buttonClass="secondary" handleClick={addAnswer}>
                            Добавить ответ
                        </Button>
                    )}
                </div>
            </Column>
            <Button buttonClass="primary" type="submit" handleClick={preSubmit} isDisabled={notValidSomeThing()}>
                Сохранить
            </Button>
            <Button handleClick={handleClickCancel} buttonClass="secondary">
                Отмена
            </Button>
        </Container>
    );
};
