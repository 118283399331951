import Pagination, { PaginationProps } from '../Pagination/Pagination';
import React from 'react';

import { CategoryGridItem, CategoryListItemProps } from './CategoryGridItem';

export interface CategoryGridItems {
    categoryListItems: CategoryListItemProps[];
    pagination?: PaginationProps;
}

export const CategoryGrid = (props: CategoryGridItems) => {
    const { categoryListItems, pagination } = props;

    return (
        <ul className="category-grid">
            <li className="category-grid-header">
                <div className="category-grid-header-name">Название категории</div>
                <div className="category-grid-header-quantity">Кол-во вопросов</div>
            </li>
            {categoryListItems.map((category, index) => (
                <CategoryGridItem key={`${category.id}-${index}`} {...category} />
            ))}
            {pagination?.size && <Pagination {...pagination} />}
        </ul>
    );
};
