import React from 'react';

import { InternalLink, Link } from '../../Link';
import { Icon } from '../../Icon';
import { LanguageEnum } from 'enums/language.enum';
import moment from 'moment';
import { dateTimeFormat } from 'constants/date-format';
import { CertDirection } from 'enums/cert-direction';
import { ProductLKType } from 'enums/product-lk-type';

export type GridItemProps = {
    id: number;
    name: string;
    linkHref: string;
    time: number;
    questionCount: string | number;
    testArea?: string;
    productGroup?: string;
    certificationMonthCount?: number;
    certificationFinishDate?: string;
    testLanguage: LanguageEnum;
    isVersionActive: boolean;
    creationDate: string;
    certificationDirection: CertDirection;
    testType?: ProductLKType;
    editDate: string;
    handleClickDelete(id: number): void;
    handleClickEdit(id: number): void;
};

export const TestGridItem = (
    props: GridItemProps & {
        showLk: boolean;
    }
) => {
    const {
        name,
        linkHref,
        id,
        time,
        questionCount,
        testLanguage,
        testArea,
        productGroup,
        certificationFinishDate,
        certificationMonthCount,
        isVersionActive,
        creationDate,
        editDate,
        testType,
        showLk,
        handleClickDelete,
        handleClickEdit,
    } = props;

    return (
        <li className="test-grid-item">
            <div className="d-flex align-items-center text-left test-grid-item-name">
                <InternalLink linkHref={linkHref}>{name}</InternalLink>
            </div>
            <div className="d-flex align-items-center">
                <div className="test-grid-item-time cell">{time} мин</div>
                <div className="test-grid-item-time cell">{moment(creationDate).format(dateTimeFormat)}</div>
                <div className="test-grid-item-time cell">{moment(editDate).format(dateTimeFormat)}</div>
                <div className="test-grid-item-language cell">{LanguageEnum[testLanguage]}</div>
                <div className="test-grid-item-quantity cell">{questionCount}</div>
                <div className="test-grid-item-month-count cell">{certificationMonthCount}</div>
                <div className="test-grid-item-finish-date cell">
                    {certificationFinishDate && moment(certificationFinishDate).format(dateTimeFormat)}
                </div>
                {showLk && (
                    <>
                        <div className="test-grid-item-test-area cell">{testArea}</div>
                        <div className="test-grid-item-product-group cell">{productGroup}</div>
                        <div className="test-grid-item-active-version cell">
                            {isVersionActive ? (
                                <Icon key="check-mark-2" iconName="check-mark-2" />
                            ) : (
                                <Icon key="close" iconName="close" />
                            )}
                        </div>
                        <div className="test-grid-item-test-type cell">{testType && ProductLKType[testType]}</div>
                    </>
                )}
                <Link
                    linkHref="#"
                    linkTitle="Изменить тест"
                    handleClick={() => {
                        handleClickEdit(id);
                    }}
                >
                    <Icon iconName="edit" />
                </Link>
                <Link
                    linkHref="#"
                    linkTitle="Удалить тест"
                    handleClick={() => {
                        handleClickDelete(id);
                    }}
                >
                    <Icon iconName="delete" />
                </Link>
            </div>
        </li>
    );
};
