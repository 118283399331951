import moment from 'moment';

import { ServerAttempt } from './store';
import { LevelOfEvaluation } from 'pages/user-test/store';

export const mapServerAttemptsToAttempts = (attempts: ServerAttempt[]) =>
    attempts.map((x) => ({
        ...x,
        dateStart: moment(x.dateStart).format('L'),
        isFailure: x.levelOfEvaluation === LevelOfEvaluation.Fail,
    }));
