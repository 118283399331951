import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

export interface PaginationProps {
    page: number;
    size: number;
    total: number;
    handleChange: (page: number, size: number) => void;
}

const Pagination: FC<PaginationProps> = ({ page, size, total, handleChange }) => (
    <ReactPaginate
        nextLabel={'>'}
        previousLabel={'<'}
        forcePage={page}
        className={'grid-pagination'}
        pageCount={Math.ceil(total / size) || 1}
        onPageChange={(selectedItem) => handleChange(selectedItem.selected, size)}
    />
);

export default Pagination;
