import api from '../api';
import { User } from '../authentication/UserAuthenticationStore';
import { ResponsePagination } from '../models/response-pagination';
import { GetUsersParams } from '../models/user/get-user-params';
import { UserHistorySf } from '../models/user/user-history-sf';
import { DownloadFileService } from './download-file.service';

export class UsersService {
    static getUsers = (params: GetUsersParams) =>
        api.get<ResponsePagination<User>>(`AdminControlling/GetUsers`, {
            params,
        });

    static getExcel = (search?: string) =>
        DownloadFileService.download('AdminControlling/xls', {
            search,
        });

    static deleteUser = (id: string) => api.delete<void>(`AdminControlling/DeleteUser/${id}`);

    static getUsersHistorySF = (params: GetUsersParams) =>
        api.get<ResponsePagination<UserHistorySf>>(`AdminPartner`, {
            params,
        });

    static getExcelHistorySF = (params: GetUsersParams) =>
        DownloadFileService.download('AdminPartner/xls', { ...params });
}
