import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'components';

const PrivacyStatement: FC = () => {
    const [t] = useTranslation(['privacy-statement', 'translation']);

    return (
        <>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-privacy')}</h2>
                <div className="privacy-content">
                    <h2 className="privacy-title">{t('subtitle-privacy-controller')}</h2>
                    <p>{t('text-privacy-controller')}</p>
                    <p>
                        {t('text-privacy-controller-email')}{' '}
                        <Link linkHref={t('support-path')} target="_blank">
                            {t('support-path')}
                        </Link>
                        .
                    </p>
                    <h2 className="privacy-title">{t('subtitle-privacy-personal')}</h2>
                    <p>{t('text-privacy-personal')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('process-reason')}</h2>
                <div className="privacy-content">
                    <p>{t('process-reason-list-title')}</p>
                    <ul className="privacy-content-list">
                        <li>{t('process-reason-list-item1')}</li>
                        <li>{t('process-reason-list-item2')}</li>
                        <li>{t('process-reason-list-item3')}</li>
                        <li>{t('process-reason-list-item4')}</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-categories')}</h2>
                <div className="privacy-content">
                    <p>{t('categories-prelist')}</p>
                    <ul className="privacy-content-list">
                        <li>{t('categories-list-email')}</li>
                        <li>{t('categories-list-name')}</li>
                        <li>{t('categories-list-country')}</li>
                        <li>{t('categories-list-company')}</li>
                        <li>{t('categories-list-certification')}</li>
                    </ul>
                    <p>{t('categories-afterlist')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-legal-basis')}</h2>
                <div className="privacy-content">
                    <p>{t('legal-basis-prelist')}</p>
                    <ul className="privacy-content-list">
                        <li>{t('legal-basis-list-data')}</li>
                        <li>{t('legal-basis-list-consent')}</li>
                        <li>{t('legal-basis-list-specific')}</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-sharing')}</h2>
                <div className="privacy-content">
                    <p>{t('sharing-pre-list')}</p>
                    <ul className="privacy-content-list">
                        <li>{t('sharing-list-strict')}</li>
                        <li>{t('sharing-list-yandex')}</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-transfer')}</h2>
                <div className="privacy-content">
                    <p>{t('transfer-text-country')}</p>
                    <p>{t('transfer-text-appropriate')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-how-long-keep')}</h2>
                <div className="privacy-content">
                    <p>{t('how-long-keep-text')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-automated')}</h2>
                <div className="privacy-content">
                    <p>{t('automated-text')}</p>
                </div>
            </div>

            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-rights-data')}</h2>
                <div className="privacy-content">
                    <p>{t('rights-data-prelist')}</p>
                    <ul className="privacy-content-list">
                        <li>
                            <span className="bold space-right">{t('rights-data-list-informed-strong')}</span>
                            {t('rights-data-list-informed')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-access-strong')}</span>
                            {t('rights-data-list-access')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-rectification-strong')}</span>
                            {t('rights-data-list-rectification')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-erasure-strong')}</span>
                            {t('rights-data-list-erasure')}
                        </li>
                        <li>
                            <span className="bold space-right">
                                {t('rights-data-list-restriction-processing-strong')}
                            </span>
                            {t('rights-data-list-restriction-processing')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-portability-strong')}</span>
                            {t('rights-data-list-portability')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-object-strong')}</span>
                            {t('rights-data-list-object')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-withdraw-strong')}</span>
                            {t('rights-data-list-withdraw')}
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-data-list-complain-strong')}</span>
                            {t('rights-data-list-complain')}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-exercise')}</h2>
                <div className="privacy-content">
                    <p>
                        {t('exercise-contact')}{' '}
                        <Link linkHref={t('support-path')} target="_blank">
                            {t('exercise-contact-link')}
                        </Link>
                    </p>
                    <p>{t('exercise-specific')}</p>
                    <p>{t('exercise-respond')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-complains')}</h2>
                <div className="privacy-content">
                    <p>
                        {t('complaints-text-contact')}{' '}
                        <Link linkHref={t('support-path')} target="_blank">
                            {t('complaints-text-link')}
                        </Link>
                    </p>
                    <p>{t('complaints-text-depend')}</p>
                    <p>
                        {t('complaints-text-address')},{' '}
                        <Link linkHref={`mailto:${t('kaspersky-info')}`}>{t('kaspersky-info')}</Link>,{' '}
                        {t('contacts-phone-number')}.
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrivacyStatement;
