import { Action, Reducer } from 'redux';
import { AppThunkAction } from 'store';

import api from 'api';
import { Role } from '../enums/roles';
import { UserState } from '../enums/user-state';
import { LOGOUT_PATH } from '../global-constants';
import { BaseUser } from '../models/base-user';
import { UserData } from '../pages/info/store/store';
import { CountryBackend } from '../models/country';
import { StudyCenter } from '../models/study-center';

const USER_MAX_NO_ACTIVE_TIME = 14400;

export interface UserAuthenticationState {
    isSessionEnd: boolean;
    maxNoActiveTime: number;
    isLoading: boolean;
    user?: User;
    loginError: string;
    redirect: boolean;
}

export interface User extends BaseUser {
    company: string;
    country: CountryBackend;
    approved: UserState;
    captchaCode?: string;
    studyCenter: StudyCenter[];
    isPartner: boolean;
    isStudentStudyCenter: boolean;
    approvedAgreements: boolean;
    personalVersion: number;
    marketingVersion: number;
    isBlocked: boolean;
    blockedReason: string;
    adminStudyCenter: StudyCenter;
}

const LOGIN = 'user-authentication/LOGIN';
const LOGIN_ERROR = 'user-authentication/LOGIN_ADMIN_ERROR';
const LOGOUT = 'user-authentication/LOGOUT';
const SET_LOADING = 'user-authentication/SET_LOADING';
const SET_REDIRECT = 'user-authentication/SET_REDIRECT';
const CLEAR_USER = 'user-authentication/CLEAR_USER';
const CLEAR_ERROR = 'user-authentication/CLEAR_ERROR';
const SET_IS_SESSION_END = 'user-authentication/SET_IS_SESSION_END';

interface LoginError {
    type: typeof LOGIN_ERROR;
    errorMessage: string;
}

interface Login {
    type: typeof LOGIN;
    user: User;
}

interface Logout {
    type: typeof LOGOUT;
}

interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

interface SetRedirect {
    type: typeof SET_REDIRECT;
}

interface ClearUser {
    type: typeof CLEAR_USER;
}

interface ClearError {
    type: typeof CLEAR_ERROR;
}

interface SetIsSessionEnd {
    type: typeof SET_IS_SESSION_END;
    isSessionEnd: boolean;
}

type AuthenticationActionTypes =
    | LoginError
    | Login
    | Logout
    | SetLoading
    | ClearUser
    | ClearError
    | SetIsSessionEnd
    | SetRedirect;

const loadUserWithoutSetLoading = (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
    const user = (await api.get<UserData>('UserProfile')).data;
    dispatch({
        type: LOGIN,
        user: {
            role: Role.Student,
            name: user.userName,
            email: '',
            approved: UserState.Approve,
            ...user,
        },
    });
};

export const actions = {
    loadUser: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch, getState) => {
        try {
            dispatch({
                type: SET_LOADING,
            });
            await loadUserWithoutSetLoading()(dispatch, getState);
        } catch (error) {
            dispatch({
                type: SET_LOADING,
                isLoading: false,
            });
            dispatch({
                type: SET_REDIRECT,
            });
        }
    },
    loadUserWithoutSetLoading,
    logoutUser: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        dispatch({
            type: SET_LOADING,
        });

        await api.get<void>(LOGOUT_PATH.replace('/api', ''));
        dispatch({
            type: LOGOUT,
        });
    },
    setLoading: (isLoading: boolean) => ({ type: SET_LOADING, isLoading }),
    clearUser: () => ({ type: CLEAR_USER }),
    clearError: () => ({ type: CLEAR_ERROR }),
    actionAfterEndSessionTime: (): AppThunkAction<AuthenticationActionTypes> => async (dispatch) => {
        dispatch({
            type: SET_IS_SESSION_END,
            isSessionEnd: true,
        });
        await api.get<void>(LOGOUT_PATH.replace('/api', ''));
    },
};

const initialState: Readonly<UserAuthenticationState> = {
    isSessionEnd: false,
    isLoading: true,
    loginError: '',
    maxNoActiveTime: USER_MAX_NO_ACTIVE_TIME,
    redirect: false,
};

export const reducer: Reducer<UserAuthenticationState> = (
    state: UserAuthenticationState = initialState,
    incomingAction: Action
): UserAuthenticationState => {
    const action = incomingAction as AuthenticationActionTypes;
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.user,
                loginError: '',
                isLoading: false,
                isSessionEnd: false,
                maxNoActiveTime: USER_MAX_NO_ACTIVE_TIME,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.errorMessage,
                isLoading: false,
            };
        case LOGOUT:
            return {
                ...state,
                user: undefined,
                isLoading: false,
                loginError: '',
            };
        case CLEAR_USER: {
            return {
                ...state,
                user: undefined,
            };
        }
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                loginError: '',
            };
        case SET_IS_SESSION_END:
            return {
                ...state,
                isSessionEnd: action.isSessionEnd,
            };
        case SET_REDIRECT:
            return {
                ...state,
                redirect: true,
            };
        default:
            return state;
    }
};
