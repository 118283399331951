import React from 'react';
import { useTranslation } from 'react-i18next';

import { TestQuestion } from '../../store';

interface ShowQuestionsButtonProps {
    showQuestions: boolean;
    handleClick: () => void;
    passingTestQuestions: TestQuestion[];
    currentQuestion: TestQuestion;
}

export const ShowQuestionsButton = (props: ShowQuestionsButtonProps) => {
    const { handleClick, passingTestQuestions, currentQuestion, showQuestions } = props;
    const [t] = useTranslation(['user-test']);

    return (
        <div className={`block-test-button ${showQuestions ? 'active' : ''}`} onClick={handleClick}>
            {`${t('question')} `}
            <span>{passingTestQuestions.indexOf(currentQuestion) + 1}</span>/<span>{passingTestQuestions.length}</span>
        </div>
    );
};
