import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTitle } from '../../../utils';
import Error404 from '../../error-404/Error404';
import StudyCenterAddOrEditUserTcAdmin from './StudyCenterAddOrEditUserTcAdmin';
import StudyCenterUserListTcAdmin from './StudyCenterUserListTcAdmin';
import StudyCenterViewUserTcAdmin from './StudyCenterViewUserTcAdmin';

const StudyCentersUsersTCAdminPage = () => {
    const { path } = useRouteMatch();
    useTitle('Студенты учебного центра');

    return (
        <Switch>
            <Route exact path={path}>
                <StudyCenterUserListTcAdmin />
            </Route>
            <Route exact path={`${path}/add`}>
                <StudyCenterAddOrEditUserTcAdmin />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <StudyCenterAddOrEditUserTcAdmin />
            </Route>
            <Route exact path={`${path}/view/:id`}>
                <StudyCenterViewUserTcAdmin />
            </Route>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default StudyCentersUsersTCAdminPage;
