import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';

import { ValidationMessage } from '../ValidationMessage';
import { useTranslation } from 'react-i18next';

interface FileProps {
    link: string;
    name?: string;
}

interface UploadButtonProps {
    name: string;
    setValue: (name: string, value: File[]) => void;
    type: 'image' | 'file';
    accept?: string;
    errorMessage?: string;
    defaultValue?: FileProps;
}

export const UploadButton = (props: UploadButtonProps) => {
    const { name, setValue, accept, errorMessage, type, defaultValue } = props;
    const [t] = useTranslation('admin-validation');
    const [file, setFile] = useState<FileProps | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState<string | undefined>();
    const isHaveUploadedImage = !!file?.link;

    useEffect(() => {
        if (defaultValue) {
            setFile(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
            setSuccess(undefined);
        }
    }, [errorMessage]);

    const handleChange = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (acceptedFiles.length) {
                const [acceptedFile] = acceptedFiles;
                setValue(name, acceptedFiles);
                setFile({
                    link: URL.createObjectURL(acceptedFile),
                    name: acceptedFile.name,
                });
                setError(undefined);
                setSuccess('Файл загружен');
            } else if (fileRejections.length) {
                setError(t('incorrect-format'));
                setSuccess(undefined);
            }
        },
        [name, setValue, t]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleChange,
        accept,
    });

    const uploadedClass = isHaveUploadedImage ? `uploaded-${type}` : `empty-${type}`;
    const classes = `upload-button ${uploadedClass} ${isDragActive ? 'drag-active' : ''} `;

    return (
        <fieldset className="form-group">
            <div className="d-flex">
                <div {...getRootProps()} className={classes}>
                    <input {...getInputProps()} name={name} />
                    {isHaveUploadedImage && type === 'image' && <img src={file?.link} alt="icon" />}
                </div>
                {file?.name && <span className="form-label upload-file-name">{file.name}</span>}
            </div>
            {error ? (
                <div className="upload-error">
                    <ValidationMessage>{error}</ValidationMessage>
                </div>
            ) : (
                success && <div className="upload-success">{success}</div>
            )}
        </fieldset>
    );
};
