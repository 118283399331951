import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Column, Container, Loader, StudyGroupGrid, Title } from '../../../components';
import { ApplicationState } from '../../../store';
import {
    actions as tcAuthenticationActions,
    TCAdminAuthenticationState,
} from '../../../authentication/TCAdminAuthenticationStore';
import { actions as trainingGroupTcAdminActions } from './store/actions';
import { TrainingGroupTCAdminState } from './store/store';

type TrainingGroupTcAdminProps = TCAdminAuthenticationState &
    typeof tcAuthenticationActions &
    TrainingGroupTCAdminState &
    typeof trainingGroupTcAdminActions;

const TrainingGroupListTCAdmin: FC<TrainingGroupTcAdminProps> = (props) => {
    const { admin, loadTrainingGroups, trainingGroups, isLoading } = props;

    const { t } = useTranslation('study-center');

    useEffect(() => {
        if (admin) {
            loadTrainingGroups(admin.studyCenter.id);
        }
    }, [admin, loadTrainingGroups]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={t('training-groups')} />
            </Column>
            <Column columnSize="100">
                <StudyGroupGrid studyGroups={trainingGroups} />
            </Column>
        </Container>
    );
};

export default connect(
    ({ tcAuthentication, trainingGroupTcAdmin }: ApplicationState) => ({
        ...tcAuthentication,
        ...trainingGroupTcAdmin,
        isLoading: tcAuthentication?.isLoading || trainingGroupTcAdmin?.isLoading,
    }),
    {
        ...trainingGroupTcAdminActions,
        ...tcAuthenticationActions,
    }
)(TrainingGroupListTCAdmin as () => JSX.Element);
