import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph, Icon } from 'components';
import { CertificationEnum } from 'enums/certificationEnum';

interface CertificateProps {
    testName: string;
    date: string;
    handleClick: () => void;
    certificateGrade: number;
    isExpired: boolean;
    expiredDate?: string;
    typeOfCertificate: CertificationEnum;
}

enum levelOfEvaluation {
    lowest = 3,
    middle = 4,
    highest = 5,
}

const Certificate = (props: CertificateProps) => {
    const { testName, date, certificateGrade, handleClick, isExpired, expiredDate, typeOfCertificate } = props;
    const [t] = useTranslation(['certificates']);

    const getImagePath = (): string => {
        const path = '/assets/certificates/';
        if (typeOfCertificate === CertificationEnum.CyberSecurity && certificateGrade === levelOfEvaluation.highest)
            return `${path}${CertificationEnum[typeOfCertificate]}With.png`;

        return `${path}${CertificationEnum[typeOfCertificate]}.png`;
    };

    const getAdditionalClass = (): string => {
        if (typeOfCertificate === CertificationEnum.PartnerOnBoardingAccomplishment) return 'small';

        return '';
    };

    return (
        <div className="certificate-container" onClick={handleClick}>
            <div className="certificate-upper">
                <div>
                    <Paragraph additionalClass="secondary-title">{`${t('certificate')}:`}</Paragraph>
                    <Paragraph additionalClass="lead-title">{testName}</Paragraph>
                </div>
                <img src={getImagePath()} className={getAdditionalClass()} />
            </div>
            <div className="certificate-footer">
                <div className="column">
                    <Paragraph additionalClass="secondary-title">{`${t('releaseDate')}:`}</Paragraph>
                    <Paragraph additionalClass="lead-common">{`${date}`}</Paragraph>
                </div>
                {expiredDate && (
                    <div className="column">
                        {isExpired && <Paragraph additionalClass="secondary-title">{`${t('expired')}:`}</Paragraph>}
                        {!isExpired && <Paragraph additionalClass="secondary-title">{`${t('validTill')}:`}</Paragraph>}

                        <Paragraph additionalClass={`lead-common ${isExpired && 'paragraph-danger'}`}>
                            {expiredDate}
                        </Paragraph>
                    </div>
                )}
            </div>
            <div className="preshow-certificate">
                <Icon iconName="eye" />
            </div>
        </div>
    );
};

export default Certificate;
