import React from 'react';
import BaseSelect from 'react-dropdown-select';

import { ValidationMessage } from '../Form';

import './Select.scss';

export interface Option {
    label: string;
    value: string | number;
}

interface SelectProps {
    values: Option[];
    onChange: (() => void) | ((value: Option[]) => void);
    options: Option[];
    searchable?: boolean;
    additionalClasses?: string;
    errorMessage?: string;
    name?: string;
    isDisabled?: boolean;
    portal?: boolean;
}

export const Select = (props: SelectProps) => {
    const {
        onChange,
        searchable,
        values,
        options = [],
        additionalClasses = '',
        errorMessage,
        name,
        isDisabled,
        portal,
    } = props;

    const withError = errorMessage ? 'with-error' : '';
    const classes = `${additionalClasses} ${withError} dropdown-select`;

    if (!options?.length) {
        return null;
    }

    return (
        <>
            <BaseSelect
                backspaceDelete={false}
                portal={portal ? document.body : undefined}
                name={name}
                options={options}
                onChange={onChange}
                values={values}
                className={classes}
                searchable={!!searchable}
                dropdownGap={0}
                disabled={isDisabled}
            />
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </>
    );
};
