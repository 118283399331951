import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Error404 from 'pages/error-404/Error404';
import StudyCenterFormAdd from './StudyCenterFormAdd';
import StudyCenterFormEdit from './StudyCenterFormEdit';
import StudyCenterList from './StudyCenterList';
import { useTitle } from 'utils';

const StudyCenters = () => {
    const { path } = useRouteMatch();
    useTitle('Учебные Центры');

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <StudyCenterList />
                </Route>
                <Route exact path={`${path}/add`}>
                    <StudyCenterFormAdd />
                </Route>
                <Route exact path={`${path}/edit/:id`}>
                    <StudyCenterFormEdit />
                </Route>
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default StudyCenters;
