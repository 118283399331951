import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Paragraph, Title, Column, Link, Button, Page } from 'components';

import { UserTestState, actions } from '../../store';
import { ApplicationState } from 'store';
import { withLoading } from 'utils';
import { KASPERSKY_MAIL } from 'global-constants';

type IncorrectCodeProps = UserTestState & typeof actions;

const IncorrectCode = (props: IncorrectCodeProps) => {
    const { clearMessage, userTestError } = props;

    const [t] = useTranslation(['user-test']);

    if (userTestError.status === '412') {
        return (
            <Container>
                <Paragraph additionalClass="danger">{t('412-error')}</Paragraph>
            </Container>
        );
    }

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <Column columnSize="50" className="block-center code-column">
                    <Title titleText={t('incorrect-title')} />
                    <Paragraph additionalClass="lead">
                        {t('incorrect-message')}
                        <Link linkHref={`mailto:${KASPERSKY_MAIL}`}>{KASPERSKY_MAIL}</Link>
                    </Paragraph>
                    <Button type="submit" buttonClass="primary" handleClick={clearMessage}>
                        {t('retry-button')}
                    </Button>
                </Column>
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => state.userTest,
    actions
)(withLoading(IncorrectCode) as () => JSX.Element);
