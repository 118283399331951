import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'normalize.css';
import './styles/index.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';
import { configureApi } from './api';
import './i18n';
import svg4everybody from 'svg4everybody';
import { Provider as AlertProvider, AlertProviderProps, positions, transitions, types } from 'react-alert';

import App from './App';
import { Loader, AlertTemplateBasic } from 'components';

const store = configureStore();
configureApi(store);
svg4everybody({
    polyfill: true,
});

const options: AlertProviderProps = {
    template: AlertTemplateBasic,
    position: positions.BOTTOM_RIGHT,
    timeout: 3000,
    transition: transitions.FADE,
    type: types.ERROR,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Suspense fallback={<Loader />}>
        <Provider store={store}>
            <AlertProvider {...options}>
                <App />
            </AlertProvider>
        </Provider>
    </Suspense>
);
