import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { ApplicationState } from 'store';
import { actionCreators } from './store/actions';
import { connectComponent, withLoading } from 'utils';
import { CategoryStoreProps, CategoryForm, FormValue } from './category-form/CategoryForm';

type CategoriesFormAddProps = CategoryStoreProps;

const CategoriesFormAdd = (props: CategoriesFormAddProps) => {
    const { addCategory, categorySuccess, clearMessageCategory, clearCategoryForEdit } = props;
    const alert = useAlert();
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (categorySuccess) {
            alert.success(categorySuccess);
            clearMessageCategory();
            history.push(pathname.replace('/add', ''));
        }
    }, [categorySuccess, alert, clearMessageCategory, pathname, history]);

    useEffect(
        () => () => {
            clearCategoryForEdit();
        },
        [clearCategoryForEdit]
    );

    const onSubmit = async ({ name, file, directionId }: FormValue) => {
        await addCategory({ name, iconFile: file?.[0], directionId });
    };

    const handleClickCancel = (): void => {
        history.push(pathname.replace('/add', ''));
    };

    return (
        <CategoryForm {...props} title="Создать категорию" onSubmit={onSubmit} handleClickCancel={handleClickCancel} />
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => {
        if (state.categories) {
            return state.categories;
        }
    },
    actions: actionCreators,
    component: withLoading(CategoriesFormAdd),
});
