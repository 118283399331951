import React, { useEffect, MouseEvent, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { ApplicationState } from 'store';
import SelectDirection from '../../components/SelectDirection/SelectDirection';
import { CategoriesState } from './store/store';
import { actionCreators } from './store/actions';
import { useQuery } from '../../hooks';
import {
    Container,
    Column,
    Title,
    Button,
    CategoryListItemProps,
    CategoryGridItems,
    CategoryGrid,
    ModalWindow,
    EmptyListWarning,
} from 'components';
import { connectComponent, withLoading } from 'utils';
import { useUrlQueryParamsForGrid } from 'hooks/useUrlQueryParamsForGrid';

type CategoriesListProps = CategoriesState & typeof actionCreators;

const CategoriesList = (props: CategoriesListProps) => {
    const {
        loadCategories,
        deleteCategory,
        categorySuccess,
        clearMessageCategory,
        categories,
        selectedDirectionId,
        setDirection,
    } = props;
    const alert = useAlert();
    const { pathname } = useLocation();
    const history = useHistory();
    const [init, setInit] = useState(true);

    const sizeUrlParam = 'size';
    const pageUrlParam = 'page';
    const queryParams = useQuery();
    const urlSize = +(queryParams.get(sizeUrlParam) as string);
    const urlPage = +(queryParams.get(pageUrlParam) as string);

    const { pagination, setPage } = useUrlQueryParamsForGrid({
        total: categories.count,
        loadFn: (params) => {
            const { page, size } = params;
            loadCategories(page, size, selectedDirectionId);
        },
    });

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [categoryId, setCategoryId] = useState(-1);

    const pathToCategoryQuestion = pathname.replace('categories', 'questions');

    const getModalMessage = (): string => {
        const currentCategory = categories.items.find((category) => category.id === categoryId);
        if (currentCategory?.quantityQuestions && currentCategory?.quantityQuestions > 0) {
            return 'Вы уверены, что хотите удалить данную категорию и все вопросы, относящиеся к данной категории?';
        }

        return 'Вы уверены, что хотите удалить данную категорию?';
    };

    const onModalClose = () => {
        setModalIsOpen(false);
    };

    const onModalOpen = (e: MouseEvent<HTMLElement>, id: number): void => {
        setModalIsOpen(true);
        setCategoryId(id);
    };

    useEffect(() => {
        if (!init) {
            if (urlPage !== 0) {
                setPage(0);
            } else {
                loadCategories(urlPage, urlSize, selectedDirectionId);
            }
            return;
        }
        setInit(false);
    }, [selectedDirectionId]);

    useEffect(() => {
        if (categorySuccess) {
            alert.success(categorySuccess);
            clearMessageCategory();
        }
    }, [categorySuccess, alert, clearMessageCategory]);

    const goToAddCategoryEven = (): void => {
        history.push(`${pathname}/add`);
    };

    const goToEditCategoryEven = (e: MouseEvent<HTMLElement>, id: number): void => {
        e.preventDefault();
        history.push(`${pathname}/edit/${id}`);
    };

    const deleteCategoryEven = (e: MouseEvent<HTMLElement>, id: number): void => {
        e.preventDefault();
        deleteCategory(id);
        setModalIsOpen(false);
    };

    const getCategoriesListItems = (props: CategoriesListProps): CategoryGridItems =>
        ({
            categoryListItems: props.categories.items.map(
                (category) =>
                    ({
                        key: category.id,
                        id: category.id,
                        name: category.name,
                        iconFileLink: category.iconFileLink,
                        linkToQuestions: `${pathToCategoryQuestion}?categoryId=${category.id}&directionId=${selectedDirectionId}`,
                        handleClickEdit: goToEditCategoryEven,
                        handleClickDelete: onModalOpen,
                        quantityQuestions: category.quantityQuestions,
                    } as CategoryListItemProps)
            ),
        } as CategoryGridItems);

    return (
        <Container>
            <ModalWindow
                isOpen={modalIsOpen}
                onCloseClick={onModalClose}
                modalMessage={getModalMessage()}
                confirmButtonText="Удалить"
                cancelButtonText="Отмена"
                onConfirmAction={(e) => deleteCategoryEven(e, categoryId)}
            />
            <Column columnSize="50">
                <Title titleText="Категории" />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button buttonClass="primary" handleClick={goToAddCategoryEven}>
                    Создать категорию
                </Button>
            </Column>
            <Column columnSize="50">
                <SelectDirection selectedDirectionId={selectedDirectionId} onChange={setDirection} />
            </Column>
            <Column columnSize="100" className="top-space-10x">
                {categories && categories.count ? (
                    <CategoryGrid {...getCategoriesListItems(props)} pagination={pagination} />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.categories,
    component: withLoading(CategoriesList),
    actions: actionCreators,
});
