import api from 'api';
import { ResponsePagination } from 'models/response-pagination';
import { AddArea, TestArea, UpdateArea } from '../models/AreaModels';

export const addArea = (model: AddArea): Promise<{ data: TestArea }> => api.post('/TestArea/', model);
export const updateArea = (model: UpdateArea): Promise<{ data: TestArea }> => api.put('/TestArea/', model);
export const deleteArea = (ind: string): Promise<void> =>
    api.delete('/TestArea/', {
        params: {
            id: ind,
        },
    });
export const getAreas = (page: number, size: number, str?: string): Promise<{ data: ResponsePagination<TestArea> }> =>
    api.get('/TestArea/', {
        params: {
            page,
            size,
            str,
        },
    });
