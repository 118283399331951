import React, { MouseEvent, PropsWithChildren } from 'react';

interface LinkProps {
    additionalClass?: string;
    target?: '_self' | '_blank' | string;
    linkTitle?: string;
    linkHref: string;
    isDownload?: boolean;
    theme?: 'landing';
    handleClick?(e: MouseEvent<HTMLElement>): void;
}

export const Link = (props: PropsWithChildren<LinkProps>) => {
    const { additionalClass, target, linkTitle, linkHref, handleClick, children, isDownload, theme } = props;
    const classes = `link ${additionalClass ? additionalClass : ''} ${theme ? 'link--' + theme : ''}`;

    return (
        <a
            href={linkHref}
            title={linkTitle}
            className={classes}
            onClick={handleClick}
            target={target || '_self'}
            download={isDownload}
        >
            {children}
        </a>
    );
};
