import StudentStudyCenterAgreementsNew from 'components/StudentStudyCenterAgreements/StudentStudyCenterAgreementsNew';
import React, { FC, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { AppSettingsState } from 'store/app-settings/store';
import { Loader } from '../../components';
import StudentStudyCenterAgreements from '../../components/StudentStudyCenterAgreements/StudentStudyCenterAgreements';
import TestInstruction from '../../components/TestInstruction/TestInstruction';
import TestPassed from '../../components/TestPassed/TestPassed';
import { ApplicationState } from '../../store';
import { connectComponent, withLoading } from '../../utils';
import { actions } from './store/actions';
import { StudyCenterStudentState } from './store/store';

type StudentStudyCenterStartTest = StudyCenterStudentState &
    typeof actions & {
        settings: AppSettingsState;
    };

const StudentStudyCenterStartTest: FC<StudentStudyCenterStartTest> = (props) => {
    const {
        student,
        isLoading,
        studyGroupTestCondition,
        error,
        isTestStarted,
        isLinkExpired,
        isTestPassed,
        passedTestCondition,
        startTest,
        getStudent,
        approveAgreements,
        getConditions,
        isFull,
        settings,
    } = props;

    const { replace } = useHistory();
    const { url } = useRouteMatch();
    const { linkId } = useParams<{ linkId: string }>();
    const { push } = useHistory();

    useEffect(() => {
        getStudent(linkId);
    }, [getStudent, linkId]);

    useEffect(() => {
        if (student?.approvedAgreements) {
            getConditions(linkId);
        }
    }, [student]);

    useEffect(() => {
        if (!error) {
            return;
        }

        if (isLinkExpired) {
            replace(url.replace('start-test', 'expired'));
            return;
        }

        if (isFull) {
            replace(url.replace('start-test', 'full'));
            return;
        }

        replace('/404');
    }, [error, isLinkExpired, isFull, isTestPassed]);

    useEffect(() => {
        if (isTestStarted) {
            replace('/test');
        }
    }, [isTestStarted]);

    if (isLoading) {
        return <Loader />;
    }

    if (isTestPassed) {
        return (
            <TestPassed
                hadCert={!!passedTestCondition?.passingTestId}
                showCertClick={() => {
                    push(
                        url.replace('start-test', `certificates/${passedTestCondition?.passingTestId}`) +
                            (passedTestCondition?.isSf ? '?is_sf=true' : '')
                    );
                }}
            />
        );
    }

    if (!student?.approvedAgreements) {
        return (
            <StudentStudyCenterAgreements
                submit={approveAgreements}
                personalVersion={settings.personalVersion}
                marketingVersion={settings.marketingVersion}
            />
        );
    }

    if (
        student?.approvedAgreements &&
        (student.marketingVersion < settings.marketingVersion || student.personalVersion < settings.personalVersion)
    ) {
        return (
            <StudentStudyCenterAgreementsNew
                submit={approveAgreements}
                personalVersion={settings.personalVersion}
                marketingVersion={settings.marketingVersion}
                currentUserMarketingVersion={student.marketingVersion}
                currentUserPersonalVersion={student.personalVersion}
            />
        );
    }
    return <TestInstruction {...studyGroupTestCondition} startTest={() => startTest(linkId)} />;
};

export default connectComponent({
    mapStateToProps: ({ studyCenterStudent, appSettings }: ApplicationState) => ({
        ...studyCenterStudent,
        settings: appSettings,
    }),
    component: withLoading(StudentStudyCenterStartTest),
    actions: actions,
});
