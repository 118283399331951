import { Action, Reducer } from 'redux';
import { ResponsePagination } from '../../../../models/response-pagination';
import { StudyCenterUser } from '../../../../models/study-center-users/study-center-user';
import { StudyCenterUserLink } from '../../../../models/study-center-users/study-center-user-link';
import {
    ActionTypes,
    ADD_USER,
    CLEAR_EDITABLE_USER,
    ClEAR_MESSAGES,
    GENERATE_LINK,
    LOAD_USER,
    LOAD_USERS,
    SET_ERROR,
    SET_LOADING,
    UPDATE_USER,
} from './actions';

export interface StudyCenterUsersState {
    users: ResponsePagination<StudyCenterUser>;
    editableUser?: StudyCenterUser;
    editableUserLinks: StudyCenterUserLink[];
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}

const initialState: Readonly<StudyCenterUsersState> = {
    isLoading: false,
    editableUser: undefined,
    editableUserLinks: [],
    errorMessage: '',
    successMessage: '',
    users: {
        count: 0,
        items: [],
    },
};

export const reducer: Reducer<StudyCenterUsersState> = (
    state: StudyCenterUsersState = initialState,
    incomingAction: Action
): StudyCenterUsersState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case LOAD_USERS:
            return {
                ...state,
                isLoading: false,
                users: action.users,
            };
        case LOAD_USER:
            return {
                ...state,
                isLoading: false,
                editableUserLinks: action.userLinks,
                editableUser: action.user,
            };
        case GENERATE_LINK: {
            return {
                ...state,
                isLoading: false,
                successMessage: 'study-center:link-added-success',
                editableUserLinks: [action.userLink, ...state.editableUserLinks],
            };
        }
        case ADD_USER:
            return {
                ...state,
                successMessage: 'study-center:add-student-success',
                isLoading: false,
            };
        case UPDATE_USER:
            return {
                ...state,
                editableUser: undefined,
                editableUserLinks: [],
                successMessage: 'study-center:edit-student-success',
                isLoading: false,
            };
        case CLEAR_EDITABLE_USER: {
            return {
                ...state,
                editableUser: undefined,
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMessage: action.error || 'Internal server error',
            };
        }
        case ClEAR_MESSAGES: {
            return {
                ...state,
                errorMessage: '',
                successMessage: '',
            };
        }
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        default:
            return state;
    }
};
