import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-au';

import Certificate from './Certificate';
import { Title, EmptyListWarning, Page, Column, Container, Label, Button } from 'components';
import { ApplicationState } from 'store';
import { actions } from '../store/actions';
import { UserCertificatesState, UserCertificateListItem } from '../store/store';
import { withLoading } from 'utils';
import { useLocation } from 'react-router-dom';
import SelectByEnum from 'components/SelectByEnum/SelectByEnum';
import { CertificationTypeFilter } from 'enums/certification-type';
import { CertificationDirection } from 'enums/certification.direction';

type UserCertificatesProps = UserCertificatesState & typeof actions;

const AllCertificates = (props: UserCertificatesProps) => {
    const [validSearch, setValidSearch] = useState<CertificationTypeFilter>();
    const [certificaationTypeSearch, setCertificationTypeSearch] = useState<CertificationDirection>();

    const { getAllCertificates, allCertificates, allCertificatesSf } = props;
    const { pathname } = useLocation();

    const [t, i18next] = useTranslation(['certificates']);

    const getCertificateDate = (date?: string) => {
        if (!date) return '';

        return moment(date).locale(i18next.language).format('DD/MM/YYYY');
    };

    useEffect(() => {
        getAllCertificates({ certificationType: certificaationTypeSearch, valid: validSearch });
    }, [getAllCertificates]);

    const openCertificatePage = (certificate: UserCertificateListItem) => {
        const path = certificate.sfId
            ? `${pathname}/${certificate.sfId}?is_sf=true`
            : `${pathname}/${certificate.certificateId}`;

        window.open(path, '_blank');
    };

    const onSubmit = () => {
        getAllCertificates({ certificationType: certificaationTypeSearch, valid: validSearch });
    };

    return (
        <Page>
            <Container>
                <Column columnSize="100">
                    <Title titleText={t('all-certificates-page-title')} />
                </Column>
                <Column columnSize="100" className="container container--items-center search-field">
                    <fieldset className="form-group ">
                        <Label labelText={t('certificationStatus')} labelFor="" />
                        <SelectByEnum
                            hasEmpty
                            enumOptions={CertificationTypeFilter}
                            onChange={(val) => setValidSearch(val)}
                        />
                    </fieldset>
                    <fieldset className="form-group ">
                        <Label labelText={t('certificateDirection')} labelFor="" />
                        <SelectByEnum
                            hasEmpty
                            enumOptions={CertificationDirection}
                            onChange={(val) => setCertificationTypeSearch(val)}
                        />
                    </fieldset>
                    <Button buttonClass="primary" handleClick={onSubmit}>
                        {t('search')}
                    </Button>
                </Column>
                <Column columnSize="100">
                    {allCertificates.length || allCertificatesSf.length ? (
                        <div className="all-certificates">
                            {[...allCertificates, ...allCertificatesSf].map((x) => (
                                <Certificate
                                    isExpired={x.isExpired}
                                    expiredDate={getCertificateDate(x.expiredDate)}
                                    testName={x.testName}
                                    date={getCertificateDate(x.dateStartTest)}
                                    certificateGrade={x.levelOfEvaluation}
                                    key={!x.certificateId ? x.sfId : x.certificateId}
                                    handleClick={() => openCertificatePage(x)}
                                    typeOfCertificate={x.typeOfCertificate}
                                />
                            ))}
                        </div>
                    ) : (
                        <EmptyListWarning />
                    )}
                </Column>
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => state.userCertificates,
    actions
)(withLoading(AllCertificates) as () => JSX.Element);
