import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FooterLanding, HeaderLanding } from 'components';
import { ApplicationState } from 'store';
import { actions as userAuthenticationActions, UserAuthenticationState } from 'authentication/UserAuthenticationStore';
import { KASPERSKY_ACADEMY, KASPERSKY_ACADEMY_RU, KASPERSKY_SUPPORT_CERT } from '../../global-constants';
import { LanguageKeys } from '../../models/language/language';
import { useTheme } from '../../utils';
import DirectionCard from './DirectionCard/DirectionCard';

import ImageBgLeft from '../../images/landing/top_left.png';
import ImageBgRight from '../../images/landing/top_right.png';

import './Landing.scss';

type LandingProps = UserAuthenticationState & typeof userAuthenticationActions;

const Landing = (props: LandingProps) => {
    const { user, logoutUser } = props;
    const [t, i18n] = useTranslation(['landing']);
    const currentLanguage = i18n.language as LanguageKeys;

    useTheme('theme-landing');

    return (
        <div className="landing-page-main-container">
            <main className="landing-page">
                <img src={ImageBgLeft} className="landing-page__bg-image landing-page__bg-image--top-left" alt="" />
                <img src={ImageBgRight} className="landing-page__bg-image landing-page__bg-image--top-right" alt="" />

                <HeaderLanding user={user} handleLogout={logoutUser} />

                <div className="landing-container landing-page__wrap">
                    <div className="landing-cert-info">
                        <div className="landing-cert-info__top">
                            <h1 className="landing-page__title">
                                <Trans>{t('intro-title')}</Trans>
                            </h1>
                            <div className="landing-cert-info__text">{t('cert-info-text')}</div>
                        </div>
                        <div className="landing-cert-info__bottom">
                            <h2 className="landing-page__subtitle">{t('cert-info-subtitle')}</h2>
                            <div className="landing-cert-info__list">
                                <div className="landing-cert-info__item">
                                    <span>{t('cert-info-item-1')}</span>
                                </div>
                                <div className="landing-cert-info__item">
                                    <span>{t('cert-info-item-2')}</span>
                                </div>
                                <div className="landing-cert-info__item">
                                    <span>{t('cert-info-item-3')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DirectionCard
                        cardNumber={1}
                        title={t('direction-lk-title')}
                        subtitle={t('direction-lk-subtitle')}
                        programs={[
                            { text: t('direction-lk-program-1'), iconName: 'sales-specialist' },
                            { text: t('direction-lk-program-2'), iconName: 'certified-professional' },
                            { text: t('direction-lk-program-3'), iconName: 'system-engineer' },
                        ]}
                        actionBtnText={t('get-certified-btn')}
                        bottomText={
                            <>
                                <Trans>{t('direction-lk-bottom-text-1')}</Trans>
                                <Trans
                                    i18nKey="landing:direction-lk-bottom-text-2"
                                    components={{
                                        centerlink: (
                                            <a
                                                href={KASPERSKY_SUPPORT_CERT}
                                                className="text-link"
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ),
                                    }}
                                />
                            </>
                        }
                    />

                    <DirectionCard
                        cardNumber={2}
                        title={t('direction-cyber-security-title')}
                        subtitle={t('direction-cyber-security-subtitle')}
                        programs={[
                            { text: t('direction-cyber-security-program-1'), iconName: 'academic-cap' },
                            { text: t('direction-cyber-security-program-2'), iconName: 'chemical-bond' },
                            { text: t('direction-cyber-security-program-3'), iconName: 'certification' },
                        ]}
                        programListTitle={t('direction-cyber-security-program-title')}
                        actionBtnText={t('get-certified-btn')}
                        bottomText={
                            <Trans
                                i18nKey="landing:direction-cyber-security-bottom-text-1"
                                components={{
                                    academylink: (
                                        <a
                                            href={currentLanguage === 'ru' ? KASPERSKY_ACADEMY_RU : KASPERSKY_ACADEMY}
                                            className="text-link"
                                            target="_blank"
                                            rel="noreferrer"
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                </div>
                <FooterLanding />
            </main>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => state.userAuthentication,
    userAuthenticationActions
)(Landing as () => JSX.Element);
