import React, { useMemo, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MIN_NAME_TRAINING_CENTER_LENGTH } from './constants';
import { StudyCenterState } from '../store/store';
import { actions } from '../store/actions';
import { Button, TextInput } from 'components';

interface FormProps {
    onSubmit: (value: StudyCenterFormData) => void;
    goBack: () => void;
}

export interface StudyCenterFormData {
    name: string;
}

type StudyCenterFormProps = StudyCenterState & typeof actions & FormProps;

export const StudyCenterForm = (props: StudyCenterFormProps) => {
    const { goBack, onSubmit, editableStudyCenter } = props;
    const methods = useForm<StudyCenterFormData>({
        defaultValues: useMemo(() => editableStudyCenter, [editableStudyCenter]),
    });
    const { t } = useTranslation('validation');

    const { register, handleSubmit, errors, formState, reset } = methods;
    const { isSubmitting } = formState;

    useEffect(() => {
        reset(editableStudyCenter);
    }, [editableStudyCenter]);

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="form-group">
                    <TextInput
                        name="name"
                        id="name"
                        label="Название"
                        type="text"
                        inputRef={register({
                            minLength: {
                                value: MIN_NAME_TRAINING_CENTER_LENGTH,
                                message: t('validation:min-length', {
                                    minLength: MIN_NAME_TRAINING_CENTER_LENGTH,
                                }),
                            },
                            required: {
                                value: true,
                                message: t('validation:required-field'),
                            },
                        })}
                        errorMessage={errors.name?.message as string}
                    />
                </div>
                <div className="top-space-3x">
                    <Button buttonClass="primary" type="submit" isDisabled={isSubmitting}>
                        Сохранить
                    </Button>
                    <Button handleClick={goBack} buttonClass="secondary">
                        Отмена
                    </Button>
                </div>
            </form>
        </FormContext>
    );
};
