import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Error404 from 'pages/error-404/Error404';
import TestList from './TestList';
import TestFormAdd from './TestFormAdd';
import TestFormEdit from './TestFormEdit';
import { useTitle } from 'utils';
import TestAreaList from 'pages/test-areas/TestAreaList';
import ProductGroups from 'pages/productGroups/ProductGroups';

const Tests = () => {
    const { path } = useRouteMatch();

    useTitle('Тесты');

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <TestList />
                </Route>
                <Route exact path={`${path}/add`}>
                    <TestFormAdd />
                </Route>
                <Route exact path={`${path}/edit/:id`}>
                    <TestFormEdit />
                </Route>
                <Route exact path={`${path}/areas`}>
                    <TestAreaList />
                </Route>
                <Route exact path={`${path}/productGroups`}>
                    <ProductGroups />
                </Route>
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default Tests;
