import React, { useEffect, useCallback } from 'react';
import { Switch, useRouteMatch, Route, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TFunction } from 'i18next';

import { ApplicationState } from 'store';
import { Header, SidebarMenu, Footer, Loader, MainNavItemProps, SessionExpired } from 'components';
import UserTest from 'pages/user-test/UserTest';
import AttemptsList from 'pages/attempts-list/AttemptsList';
import Certificates from 'pages/certificates/Certificates';
import Info from 'pages/info/Info';
import Error404 from 'pages/error-404/Error404';
import { actions as userAuthenticationActions, UserAuthenticationState } from 'authentication/UserAuthenticationStore';
import ConfirmTermsOfUse from 'pages/info/enter-name/ConfirmTermsOfUse';
import ConfirmTermsOfUseOnlyAgreements from 'pages/info/enter-name/ConfirmTermsOfUseOnlyAgreements';
import { UserTestState, actions, TestQuestion } from 'pages/user-test/store';
import { useActionWithLoading } from 'utils';
import { STUDENT_STUDY_CENTER_LOGOUT_PATH } from '../global-constants';
import SessionService from '../services/session.service';
import MainLayout from './MainLayout';
import ConfirmTermsOfUseOnlyAgreementsNewVersion from 'pages/info/enter-name/ConfirmTermsOfUseOnlyAgreementsNewVersion';

type UserLayoutProps = UserAuthenticationState &
    UserTestState &
    typeof userAuthenticationActions &
    typeof actions & {
        isTestPassed: boolean;
        notAnsweredQuestion?: TestQuestion;
        isNeedRedirectToLogin: boolean;
        isUserLoading: boolean;
        logoutStudentStudyCenter: boolean;
        isUserProfileCompleted: boolean;
        showOnlyAgreementsForPartner: boolean;
        showNewVersionAgreements: boolean;
    };

const UserLayout = (props: UserLayoutProps) => {
    const {
        user,
        logoutUser,
        loadUser,
        getPassingTest,
        clearMessage,
        isTestPassed,
        notAnsweredQuestion,
        isTestFinish,
        isLoading,
        isNeedRedirectToLogin,
        isUserLoading,
        logoutStudentStudyCenter,
        isUserProfileCompleted,
        showOnlyAgreementsForPartner,
        maxNoActiveTime,
        isSessionEnd,
        actionAfterEndSessionTime,
        redirect,
        showNewVersionAgreements,
    } = props;
    const [t] = useTranslation();
    const { path } = useRouteMatch();
    const { pathname } = useLocation();
    const { push } = useHistory();
    const { isLoading: isTestLoading } = useActionWithLoading(getPassingTest);

    useEffect(() => {
        loadUser();
    }, [loadUser]);

    useEffect(() => {
        if (redirect) {
            window.location.href = `/api/UserAuthorization/loginInto?redirectUrl=${encodeURIComponent(
                window.location.href
            )}`;
        }
    }, [redirect]);

    useEffect(() => {
        let timer: SessionService;

        if (user && maxNoActiveTime && !isSessionEnd) {
            timer = new SessionService({
                timeout: maxNoActiveTime,
                onTimeout: actionAfterEndSessionTime,
            });
        }

        return () => {
            timer?.cleanUp();
        };
    }, [user, isSessionEnd, actionAfterEndSessionTime]);

    useEffect(() => {
        const testFinishPath = '/test/finish';
        const passingTestPathRegExp = /\/test\/passing\/\d+/;

        if (
            isTestPassed &&
            notAnsweredQuestion &&
            !pathname.match(passingTestPathRegExp) &&
            !pathname.match(testFinishPath)
        ) {
            clearMessage();
            push(`/test/passing/${notAnsweredQuestion.id}`);
        }
    }, [clearMessage, isTestPassed, notAnsweredQuestion, push, pathname]);

    const rootPath = path === '/' ? '' : path;
    const handleLogout = useCallback(async () => {
        await logoutUser();
        push(rootPath);
    }, [logoutUser, push, rootPath]);

    // logout for study center student if not active tests
    useEffect(() => {
        if (logoutStudentStudyCenter) {
            window.location.href = STUDENT_STUDY_CENTER_LOGOUT_PATH;
        }
    }, [logoutStudentStudyCenter]);

    if (isNeedRedirectToLogin || isUserLoading || isTestLoading || logoutStudentStudyCenter) {
        return <Loader />;
    }

    if (!user) {
        return <MainLayout />;
    }

    const navItems = generateNavItems(rootPath, t, isUserProfileCompleted);

    return (
        <>
            {user && (
                <SessionExpired
                    goToLogin={handleLogout}
                    isSessionEnd={isSessionEnd}
                    maxNoActiveTime={maxNoActiveTime}
                />
            )}
            <SidebarMenu navItems={navItems} />
            <Header
                user={user}
                handleLogout={handleLogout}
                isVisibleLanguagePicker
                handleClick={() => {
                    if (!isTestPassed || isTestFinish) {
                        push(`${rootPath}/info`);
                    }
                }}
            />
            {!isLoading && showNewVersionAgreements ? (
                <Switch>
                    <Route path={`${rootPath}/*`} component={ConfirmTermsOfUseOnlyAgreementsNewVersion} />
                </Switch>
            ) : !isLoading && showOnlyAgreementsForPartner ? (
                <Switch>
                    <Route path={`${rootPath}/*`} component={ConfirmTermsOfUseOnlyAgreements} />
                </Switch>
            ) : !isLoading && !isUserProfileCompleted ? (
                <Switch>
                    <Route path={`${rootPath}/*`} component={ConfirmTermsOfUse} />
                </Switch>
            ) : (
                <Switch>
                    <Route path={`${rootPath}/test`} component={UserTest} />,
                    <Route exact path={`${rootPath}/attempts-list`} component={AttemptsList} />
                    <Route path={`${rootPath}/certificates`} component={Certificates} />
                    <Route exact path={`${rootPath}/info`} component={Info} />
                    <Route path={`${rootPath}/*`} component={Error404} />
                </Switch>
            )}
            <Footer />
        </>
    );
};

export default connect(
    ({ userAuthentication, userTest, appSettings }: ApplicationState) => {
        const user = userAuthentication?.user;

        return {
            ...userAuthentication,
            ...userTest,
            isUserProfileCompleted: !!(
                user &&
                user.country &&
                user.userName &&
                user.lastName &&
                user.company &&
                user.approvedAgreements
            ),
            showOnlyAgreementsForPartner: !!(
                user &&
                user.isPartner &&
                user.country &&
                user.userName &&
                user.lastName &&
                user.company &&
                !user.approvedAgreements
            ),
            showNewVersionAgreements:
                user &&
                user.country &&
                user.userName &&
                user.lastName &&
                user.company &&
                user.approvedAgreements &&
                (user!.personalVersion < appSettings.personalVersion ||
                    user!.marketingVersion < appSettings.marketingVersion),
            notAnsweredQuestion: userTest.currentPassingTest.passingTestQuestions.find(
                (x) => !x.selectedQuestionOptionsId.length
            ),
            isTestPassed: !userTest.isLoading && !userTest.isTestFinish && (userTest?.currentPassingTest?.id ?? false),
            isUserLoading: userAuthentication?.isLoading,
            // logoutStudentStudyCenter: user?.isStudentStudyCenter && userTest.userTestError.status,
        };
    },
    { ...actions, ...userAuthenticationActions }
)(UserLayout as () => JSX.Element);

const generateNavItems = (path: string, t: TFunction, isUserProfileCompleted: boolean): MainNavItemProps[] => {
    const navItems = [
        {
            menuItemHref: `${path}/info`,
            menuItemIcon: 'menu-group',
            menuItemText: t('menu-item-info'),
        },
    ];

    if (isUserProfileCompleted) {
        navItems.unshift(
            {
                menuItemHref: `${path}/test`,
                menuItemIcon: 'menu-test',
                menuItemText: t('menu-item-start-test'),
            },
            {
                menuItemHref: `${path}/attempts-list`,
                menuItemIcon: 'menu-stats',
                menuItemText: t('menu-item-results'),
            },
            {
                menuItemHref: `${path}/certificates`,
                menuItemIcon: 'menu-cert',
                menuItemText: t('menu-item-certificates'),
            }
        );
    }

    return navItems;
};
