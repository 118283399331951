import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ResultIcon } from '../../../../../images/result-icon.svg';

interface TestResultIconProps {
    quantityPoints: number;
    maxQuantityPoints: number;
    descriptionOfEvaluation: string;
    isSuccess: boolean;
}

export const TestResultIcon = (props: TestResultIconProps) => {
    const { quantityPoints, maxQuantityPoints, descriptionOfEvaluation, isSuccess } = props;
    const [t] = useTranslation(['user-test']);
    const classes = `result-block ${!isSuccess ? 'done' : ''}`;
    const defaultRate = 100;
    const [rate, setRate] = useState(defaultRate);

    useEffect(() => {
        const successRate = getSuccessRate(quantityPoints, maxQuantityPoints);
        if (!isNaN(successRate)) {
            const intervalId = setInterval(() => {
                setRate((value) => (value !== successRate ? value - 1 : value));
            }, 10);

            return () => {
                clearInterval(intervalId);
                setRate(defaultRate);
            };
        }
    }, [maxQuantityPoints, quantityPoints]);

    return (
        <div className="test-result-value">
            <div className={classes} data-test={rate}>
                <ResultIcon />
                <div className="result-information">
                    <div className="result-text">{t('your-result')}</div>
                    <div className="result-value">
                        <span className="result-value-scored">{quantityPoints}</span>/
                        <span className="result-value-total">{maxQuantityPoints}</span>
                    </div>
                    <div className="result-text result-text-name">{descriptionOfEvaluation}</div>
                </div>
            </div>
        </div>
    );
};

const getSuccessRate = (quantityPoints: number, maxQuantityPoints: number) =>
    Math.round(100 - (quantityPoints / maxQuantityPoints) * 100);
