import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import EditIsPartner from '../../components/EditExternalId/EditExternalId';
import { StudyCenterUser } from '../../models/study-center-users/study-center-user';
import { connectComponent, withLoading } from '../../utils';
import StudyCentersUsersGrid from '../../components/StudyCentersUsersGrid/StudyCentersUsersGrid';
import { useUrlQueryParamsForGrid } from '../../hooks/useUrlQueryParamsForGrid';
import { actions, UsersState } from './store';
import { ApplicationState } from '../../store';
import { Button, Column, Container, EmptyListWarning, ModalWindow, TextInput, Title } from '../../components';
import { useAlert } from 'react-alert';

type StudyCentersUsersProps = UsersState & typeof actions;

const StudyCentersUsersPage = (props: StudyCentersUsersProps) => {
    const { users, getUsers, deleteStudentStudyCenter, getExcel, editIsPartner } = props;
    const alert = useAlert();
    const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false);
    const [userDeleteId, setUserDeleteId] = useState('');
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [editUser, setEditUser] = useState<StudyCenterUser>();

    const {
        pagination,
        queryState: [email, setEmail],
    } = useUrlQueryParamsForGrid({
        total: users.count,
        loadFn: (params) => {
            getUsers({ page: params.page, size: params.size, email: params.query });
        },
    });

    const { push } = useHistory();
    const { pathname } = useLocation();

    const onDelete = (userId: string) => {
        setUserDeleteId(userId);
        setIsRemoveConfirmOpen(!isRemoveConfirmOpen);
    };

    const closeEditModal = () => {
        setEditUser(undefined);
        setIsEditOpen(false);
    };

    const onEdit = (user: StudyCenterUser) => {
        setEditUser(user);
        setIsEditOpen(true);
    };

    const showAgreementsClick = (email: string) => {
        push(`${pathname.replace('/users', '/agreements')}?query=${email}`);
    };

    const showReason = (str: string) => {
        alert.info(str, { timeout: 10000 });
    };

    return (
        <Container>
            <ModalWindow
                isOpen={isRemoveConfirmOpen}
                onCloseClick={() => setIsRemoveConfirmOpen(!isRemoveConfirmOpen)}
                modalMessage="Вы действительно хотите удалить пользователя учебного центра?"
                confirmButtonText="Удалить"
                cancelButtonText="Отмена"
                onConfirmAction={() => {
                    setIsRemoveConfirmOpen(false);
                    deleteStudentStudyCenter(userDeleteId);
                }}
            />

            <ModalWindow
                size="big"
                isOpen={isEditOpen}
                onCloseClick={closeEditModal}
                modalMessage="Изменить партнёр <-> не партнёр"
            >
                {editUser && (
                    <EditIsPartner
                        isPartner={editUser.isPartner}
                        submit={({ isPartner }) => {
                            editIsPartner(editUser.id, isPartner);
                            closeEditModal();
                        }}
                        cancel={closeEditModal}
                    />
                )}
            </ModalWindow>

            <Column columnSize="100">
                <Title titleText="Клиенты учебных центров" />
            </Column>

            <Column columnSize="50">
                <TextInput
                    id="search-field"
                    label="Email"
                    value={email}
                    onChangeHandler={(e) => setEmail(e.target.value)}
                />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button buttonClass="primary" handleClick={() => getExcel(email)}>
                    Excel
                </Button>
            </Column>
            <Column columnSize="100" className="top-space-10x">
                {users.items.length ? (
                    <StudyCentersUsersGrid
                        showReason={showReason}
                        users={users.items}
                        actions={[
                            {
                                iconName: 'edit',
                                handler: (user: StudyCenterUser) => onEdit(user),
                            },
                            {
                                iconName: 'delete',
                                handler: (user: StudyCenterUser) => onDelete(user.id),
                            },
                        ]}
                        showAgreementsClick={showAgreementsClick}
                        pagination={pagination}
                    />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

const mapStateToProps = ({ studyCentersUsers }: ApplicationState) => ({
    ...studyCentersUsers,
});

export default connectComponent({
    mapStateToProps,
    actions,
    component: withLoading(StudyCentersUsersPage),
});
