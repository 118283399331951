import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { AddStudyCenterUser } from '../models/study-center-users/add-study-center-user';
import { GenerateUserLinkParams } from '../models/study-center-users/generate-user-link-params';
import { GetStudyCenterUsersParams } from '../models/study-center-users/get-study-center-users-params';
import { StudyCenterUser } from '../models/study-center-users/study-center-user';
import { StudyCenterUserLink } from '../models/study-center-users/study-center-user-link';
import { UpdateStudyCenterUser } from '../models/study-center-users/update-study-center-user';

export class StudyCenterUsersService {
    static getList = (params: GetStudyCenterUsersParams) =>
        api.get<ResponsePagination<StudyCenterUser>>(`AdminStudyCenter/GetStudentsOfStudyCenter`, { params });

    static getById = (id: string) =>
        api.get<StudyCenterUser>(`AdminStudyCenter/GetStudentOfStudyCenterById`, { params: { id } });

    static getLinksByUserId = (id: string) => api.get<StudyCenterUserLink[]>(`AdminStudyCenter/GetLinksByUserId/${id}`);

    static generateTempLink = (params: GenerateUserLinkParams) =>
        api.post<StudyCenterUserLink>(`AdminStudyCenter/GenerateTempLink`, params);

    static addUser = (user: AddStudyCenterUser) => api.post<void>(`AdminStudyCenter/AddStudentOfStudyCenter`, user);

    static updateUser = (user: UpdateStudyCenterUser) =>
        api.put<void>(`AdminStudyCenter/UpdateStudentOfStudyCenter`, user);
}
