import { AppThunkAction } from 'store';
import { CertDirection } from '../../../enums/cert-direction';
import { AddCategory } from '../../../models/category/add-category';
import { Category } from '../../../models/category';
import { UpdateCategory } from '../../../models/category/update-category';
import { CategoryService } from '../../../services/category.service';
import { ResponsePagination } from 'models/response-pagination';

export const LOAD_CATEGORIES = 'categories/LOAD_CATEGORIES';
export const GET_CATEGORY = 'categories/GET_CATEGORY';
export const ADD_CATEGORY = 'categories/ADD_CATEGORY';
export const UPDATE_CATEGORY = 'categories/UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'categories/DELETE_CATEGORY';
export const ERROR_CATEGORY = 'categories/ERROR_CATEGORY';
export const SUCCESS_CATEGORY = 'categories/SUCCESS_CATEGORY';
export const CLEAR_MESSAGE_CATEGORY = 'categories/CLEAR_MESSAGE_CATEGORY';
export const SET_LOADING = 'categories/SET_LOADING';
export const CLEAR_CATEGORY_FOR_EDIT = 'categories/CLEAR_CATEGORY_FOR_EDIT';
export const SET_DIRECTION = 'categories/SET_DIRECTION';

interface LoadCategoriesAction {
    type: typeof LOAD_CATEGORIES;
    categories: ResponsePagination<Category>;
}

interface GetCategoriesAction {
    type: typeof GET_CATEGORY;
    category: Category;
}

interface AddCategoryAction {
    type: typeof ADD_CATEGORY;
    category: Category;
}

interface UpdateCategoryAction {
    type: typeof UPDATE_CATEGORY;
    category: Category;
}

interface DeleteCategoryAction {
    type: typeof DELETE_CATEGORY;
    categoryId: number;
}

interface ErrorCategoryAction {
    type: typeof ERROR_CATEGORY;
    categoryError: string;
}

interface SuccessCategoryAction {
    type: typeof SUCCESS_CATEGORY;
    categorySuccess: string;
}

interface ClearMessageCategoryAction {
    type: typeof CLEAR_MESSAGE_CATEGORY;
}

interface SetLoadingAction {
    type: typeof SET_LOADING;
}

interface ClearCategoryForEdit {
    type: typeof CLEAR_CATEGORY_FOR_EDIT;
}

interface SetDirection {
    type: typeof SET_DIRECTION;
    directionId: CertDirection;
}

export type CategoryActionTypes =
    | LoadCategoriesAction
    | GetCategoriesAction
    | AddCategoryAction
    | UpdateCategoryAction
    | DeleteCategoryAction
    | ErrorCategoryAction
    | SuccessCategoryAction
    | ClearMessageCategoryAction
    | SetLoadingAction
    | ClearCategoryForEdit
    | SetDirection;

export const actionCreators = {
    loadCategories:
        (page: number, size: number, directionId?: number): AppThunkAction<CategoryActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const resp = await CategoryService.getListPage(page, size, directionId);

                dispatch({
                    type: LOAD_CATEGORIES,
                    categories: resp.data,
                });
            } catch {
                dispatch({
                    type: ERROR_CATEGORY,
                    categoryError: 'Ошибка',
                });
            }
        },
    getCategory:
        (id: number): AppThunkAction<CategoryActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                dispatch({
                    type: CLEAR_CATEGORY_FOR_EDIT,
                });

                const category = (await CategoryService.getById(id)).data;

                dispatch({
                    type: GET_CATEGORY,
                    category,
                });
            } catch {
                dispatch({
                    type: ERROR_CATEGORY,
                    categoryError: 'Ошибка',
                });
            }
        },
    addCategory:
        (categoryItem: AddCategory): AppThunkAction<CategoryActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const categoryId = (await CategoryService.addCategory(categoryItem)).data;
                const category = (await CategoryService.getById(categoryId)).data;

                dispatch({
                    type: ADD_CATEGORY,
                    category,
                });
            } catch {
                dispatch({
                    type: ERROR_CATEGORY,
                    categoryError: 'Ошибка',
                });
            }
        },
    editCategory:
        (category: UpdateCategory): AppThunkAction<CategoryActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await CategoryService.updateCategory(category);

                dispatch({
                    type: SUCCESS_CATEGORY,
                    categorySuccess: 'Категория изменена.',
                });
            } catch {
                dispatch({
                    type: ERROR_CATEGORY,
                    categoryError: 'Ошибка',
                });
            }
        },
    deleteCategory:
        (categoryId: number): AppThunkAction<CategoryActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await CategoryService.deleteCategory(categoryId);

                dispatch({
                    type: DELETE_CATEGORY,
                    categoryId,
                });

                dispatch({
                    type: SUCCESS_CATEGORY,
                    categorySuccess: 'Категория удалена.',
                });
            } catch {
                dispatch({
                    type: ERROR_CATEGORY,
                    categoryError: 'Ошибка',
                });
            }
        },
    clearMessageCategory: (): AppThunkAction<CategoryActionTypes> => async (dispatch) => {
        dispatch({
            type: CLEAR_MESSAGE_CATEGORY,
        });
    },
    clearCategoryForEdit: () => ({ type: CLEAR_CATEGORY_FOR_EDIT }),
    setDirection: (directionId: CertDirection): CategoryActionTypes => ({
        type: SET_DIRECTION,
        directionId,
    }),
};
