import React, { useState } from 'react';

import { EmptyListWarning, Column, Button, Icon, GridFixed, RowFixed } from 'components';
import { Attempt } from '../store/store';
import { useHistory, useLocation } from 'react-router-dom';
import { enumToCode } from 'utils/lang';
import {
    CertificationType,
    ChangeUserNameOnCertificate,
} from 'components/ChangeUserNameOnCertificate/ChangeUserNameOnCertificate';
import moment from 'moment';
import { dateTimeFormat } from 'constants/date-format';

const columns = {
    userName: {
        value: 'ФИО',
        columnSize: '200',
    },
    email: {
        value: 'Email',
        columnSize: '200',
    },
    attemptNumber: {
        value: '# Попытки',
        columnSize: '200',
    },
    attemptDate: {
        value: 'Дата создания',
        columnSize: '200',
    },
    editDate: {
        value: 'Дата редактирования',
        columnSize: '200',
    },
    duration: {
        value: 'Время',
        columnSize: '200',
    },
    statistics: {
        value: 'Статистика',
        columnSize: '200',
    },
    levelOfEvaluationDescription: {
        value: 'Результат',
        columnSize: '200',
    },
    certification: {
        value: 'Сертификат',
        columnSize: '200',
    },
    company: {
        value: 'Компания/ВУЗ',
        columnSize: '200',
    },
    language: {
        value: 'Язык',
        columnSize: '200',
    },
    certificateId: {
        value: 'Ска-ть',
        columnSize: '200',
    },
    isDeleted: {
        value: 'Анул-ван',
        columnSize: '200',
    },
    deleteCertificateId: {
        value: 'Удалить',
        columnSize: '100',
    },
    edit: {
        value: 'Ред-ть',
        columnSize: '100',
    },
};

export const JournalGrid = (props: {
    attempts: Attempt[];
    getAttemptDetails: (passingTestId: number) => void;
    setModalIsOpen: (value: boolean) => void;
    deletePassingTest: (passingTestId: number) => void;
    success: (message: string) => void;
    error: (message: string) => void;
    setLoading: (loading: boolean) => void;
    update: () => void;
}) => {
    const { attempts, getAttemptDetails, setModalIsOpen, deletePassingTest, success, error, setLoading, update } =
        props;
    const { push } = useHistory();
    const { pathname } = useLocation();

    const [modalIsOpenChangeName, setModalIsOpenChangeName] = useState(false);
    const [userId, setUserId] = useState('');
    const [certificateId, setCertificateId] = useState('');

    const getGridData = (): RowFixed[] =>
        attempts.map((x) => ({
            ...x,
            userName: {
                value: x.userName,
                additionalClasses: 'link',
                handleClick: () => {
                    push(`${pathname}/${x.userId}`);
                },
            },
            statistics: {
                value: `${x.resultPoint}/${x.allPoint}`,
                additionalComponent: (
                    <div
                        className="details"
                        onClick={() => {
                            getAttemptDetails(x.passingTestId);
                            setModalIsOpen(true);
                        }}
                    >
                        Подробнее
                    </div>
                ),
            },
            attemptNumber: `${x.attemptNumber}/${x.allAttempts}`,
            attemptDate: {
                value: x.attemptDate ? moment(x.attemptDate).format(dateTimeFormat) : '',
            },
            editDate: {
                value: x.editDate ? moment(x.editDate).format(dateTimeFormat) : '',
            },
            isCertificateExists: {
                value: x.isCertificateExists + '',
            },
            isCertificateActive: {
                value: x.isCertificateActive + '',
            },
            certification: {
                value:
                    x.isCertificateExists && x.isCertificateActive
                        ? 'Действительный'
                        : x.isCertificateExists && !x.isCertificateActive
                        ? 'Истёк'
                        : 'Отсутствует',
            },
            language: {
                value: enumToCode(x.language),
            },
            certificateId: {
                additionalComponent:
                    !x.certificateId || x.isDeleted ? (
                        ''
                    ) : (
                        <a
                            className="link"
                            href={`/api/GetCertificate/${x.certificateId}/${x.userId}/certificate.pdf`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Download
                        </a>
                    ),
            },
            deleteCertificateId: {
                additionalComponent: x.isClientStudyCenter && (
                    <Button pSize="none" handleClick={() => deletePassingTest(x.passingTestId)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
                align: 'start',
            },
            isClientStudyCenter: false + '',
            isDeleted: x.isDeleted + '',
            edit: {
                additionalComponent: x.certificateId && !x.isDeleted && (
                    <Button
                        pSize="none"
                        handleClick={() => {
                            setUserId(x.userId);
                            setCertificateId(x.certificateId as string);
                            setModalIsOpenChangeName(true);
                        }}
                    >
                        <Icon iconName="edit" />
                    </Button>
                ),
                align: 'start',
            },
        }));

    return (
        <>
            <Column columnSize="100">
                {attempts.length ? <GridFixed rows={getGridData()} columns={columns} /> : <EmptyListWarning />}
            </Column>
            {modalIsOpenChangeName && (
                <ChangeUserNameOnCertificate
                    update={update}
                    isOpen={modalIsOpenChangeName}
                    close={() => setModalIsOpenChangeName(false)}
                    type={CertificationType.Portal}
                    userId={userId}
                    id={certificateId}
                    success={success}
                    error={error}
                    setLoading={setLoading}
                />
            )}
        </>
    );
};
