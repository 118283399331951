import React, { useState } from 'react';

import { ValidationMessage } from '../ValidationMessage';

export type RefType =
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;

interface TextInputProps {
    id: string;
    name?: string;
    label?: string;
    type?: 'text' | 'password' | 'email' | 'number' | 'hidden';
    errorMessage?: string;
    value?: string;
    defaultValue?: string | number;
    inputRef?: RefType;
    placeholder?: string;
    autocomplete?: 'off' | 'on';
    onChangeHandler?(event: React.ChangeEvent<HTMLInputElement>): void;
    onKeyDownHandler?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

export const TextInput = (props: TextInputProps) => {
    const {
        id,
        name,
        label,
        type,
        errorMessage,
        value,
        defaultValue,
        inputRef,
        onChangeHandler,
        onKeyDownHandler,
        placeholder,
        autocomplete,
    } = props;
    const classes = errorMessage ? 'form-text-input with-errors' : 'form-text-input';

    const [inputValue, setInputValue] = useState<string | number | undefined>(defaultValue);

    return (
        <fieldset className="form-group">
            <label htmlFor={`${id}`} className="form-label">
                {label}
            </label>
            <input
                id={`${id}`}
                name={name}
                value={value}
                defaultValue={defaultValue}
                className={classes}
                type={type || 'text'}
                ref={inputRef}
                data-at-value={inputValue || ''}
                onChange={(event) => {
                    setInputValue(event.target.value);

                    if (onChangeHandler) {
                        return onChangeHandler(event);
                    }
                }}
                onKeyDown={onKeyDownHandler}
                placeholder={placeholder}
                autoComplete={autocomplete}
            />
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </fieldset>
    );
};
