import React from 'react';
import { MainNavItem, MainNavItemProps } from './MainNavItem';

export const MainNav = ({ navItems }: { navItems: MainNavItemProps[] }) => (
    <nav className="main-nav">
        <ul className="menu-nav">
            {navItems.map((item) => (
                <MainNavItem key={item.menuItemText} {...item} />
            ))}
        </ul>
    </nav>
);
