import React, { FC } from 'react';
import moment from 'moment';
import { dateTimeFormat } from '../../constants/date-format';
import { useUrlQueryParamsForGrid } from '../../hooks/useUrlQueryParamsForGrid';
import { actions, UsersState } from './store';
import { ApplicationState } from '../../store';
import { connectComponent, withLoading } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    Checkbox,
    Column,
    Container,
    EmptyListWarning,
    Grid,
    Icon,
    Row,
    TextInput,
    Title,
} from '../../components';
import { useAlert } from 'react-alert';

type UsersProps = UsersState & typeof actions;

const UsersList: FC<UsersProps> = (props) => {
    const { users, getUsers, deleteUser, getExcel, changeIsPartner } = props;
    const { push } = useHistory();
    const { pathname } = useLocation();
    const alert = useAlert();

    const {
        pagination,
        queryState: [email, setEmail],
    } = useUrlQueryParamsForGrid({
        total: users.count,
        loadFn: ({ page, size, query }) =>
            getUsers({
                page,
                size,
                email: query,
            }),
    });

    const deleteUserClick = async (index: number) => {
        if (window.confirm('Вы уверены?')) {
            await deleteUser(index, users);
            alert.success(`Пользователь успешно удалён`, {
                timeout: 10000,
            });
        }
    };

    const showAgreementsClick = (email: string, isPartner: boolean) => {
        const agrPage = isPartner ? 'partners' : 'users';
        push(`${pathname.replace('/users', `/agreements/${agrPage}`)}?query=${email}`);
    };

    const isDangerous = (date: string): boolean => {
        const now = moment(new Date());
        const start = moment(date);

        const duration = moment.duration(now.diff(start));

        return duration.asYears() > 5;
    };

    const showReason = (str: string) => {
        alert.info(str, { timeout: 10000 });
    };

    const getGridData = (): Row[] =>
        users.items.map((user, index) => ({
            email: {
                additionalClasses: 'link',
                value: user.email,
                handleClick: () => showAgreementsClick(user.email, user.isPartner),
            },
            name: `${user?.lastName ?? ''} ${user?.userName ?? ''} ${user?.middleName ?? ''}`,
            creationDate: moment(user.creationDate).format(dateTimeFormat),
            lastLoginDate: {
                value: moment(user.lastLoginDate).format(dateTimeFormat),
                additionalClasses: isDangerous(user.lastLoginDate as string) ? 'danger-text' : '',
            },
            partner: {
                additionalComponent: (
                    <fieldset className="form-group">
                        <Checkbox
                            id={`is_partner_user_-${user.id}`}
                            isChecked={user.isPartner}
                            handleChange={() => changeIsPartner(user.id, !user.isPartner)}
                        />
                    </fieldset>
                ),
            },
            blocked: {
                additionalClasses: 'cell-actions',
                additionalComponent: (
                    <div>
                        {user.isBlocked && (
                            <Button handleClick={() => showReason(user.blockedReason)} pSize="small">
                                <Icon iconName="lock" />
                            </Button>
                        )}
                    </div>
                ),
            },
            actions: {
                title: 'Удалить',
                additionalComponent: (
                    <Button pSize="none" handleClick={() => deleteUserClick(index)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
            },
        }));

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText="Пользователи" />
            </Column>
            <Column columnSize="50">
                <TextInput
                    id="search-field"
                    label="Email"
                    value={email}
                    onChangeHandler={(e) => setEmail(e.target.value)}
                />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button buttonClass="primary" handleClick={() => getExcel(email)}>
                    Excel
                </Button>
            </Column>
            <Column columnSize="100">
                {users.items.length ? (
                    <Grid rows={getGridData()} columns={columns} pagination={pagination} />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

const mapStateToProps = ({ users }: ApplicationState) => ({
    ...users,
});

export default connectComponent({
    mapStateToProps,
    actions,
    component: withLoading(UsersList),
});

const columns = {
    email: {
        value: 'Email',
        columnSize: '20',
    },
    name: {
        value: 'ФИО',
        columnSize: '20',
    },
    partner: {
        value: 'Партнер',
        columnSize: '5',
    },
    creationDate: {
        value: 'Дата регистрации',
        columnSize: '20',
    },
    lastLoginDate: {
        value: 'Дата последнего входа',
        columnSize: '20',
    },
    blocked: {
        value: 'Блокировка',
        columnSize: '5',
    },
    actions: {
        value: '',
        columnSize: '10',
    },
};
