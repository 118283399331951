import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Container, Page, Button, LanguagePicker } from 'components';

export const DeletedAccountInfo = () => {
    const [t] = useTranslation('info');
    const { push } = useHistory();
    return (
        <>
            <header className="header-landing deleted-account-info-header">
                <LanguagePicker />
            </header>
            <Page additionalClass="page-content-center deleted-account-info-page">
                <Container additionalClass="deleted-account-info">
                    <div className="deleted-account-info-content">
                        <div className="deleted-account-info-text">{t('deleted-account-info')}</div>
                        <Button buttonClass="primary" handleClick={() => push('/')}>
                            {t('go-to-homepage')}
                        </Button>
                    </div>
                </Container>
            </Page>
        </>
    );
};
