import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { AppSettingsState } from '../../store/app-settings/store';
import { Loader } from '../Loader';

//@ts-ignore
window.recaptchaOptions = { ...window.recaptchaOptions, useRecaptchaNet: true };

type CaptchaProps = AppSettingsState;

const Captcha = forwardRef<ReCAPTCHA, CaptchaProps>(({ siteKey, isLoading }, captchaRef) =>
    !isLoading ? <ReCAPTCHA ref={captchaRef} size="invisible" badge="bottomright" sitekey={siteKey} /> : <Loader />
);

export default connect(({ appSettings }: ApplicationState) => appSettings, null, null, { forwardRef: true })(Captcha);
