import { Action, Reducer } from 'redux';
import { User } from '../../../authentication/UserAuthenticationStore';
import {
    ActionTypes,
    APPROVE_STUDY_CENTERS_ADMIN,
    DELETE_STUDY_CENTERS_ADMIN,
    SET_LOADING,
    SET_STUDY_CENTERS_ADMINS,
} from './actions';

export interface GetAdmins {
    email?: string;
    page: number;
    size: number;
}

export interface UserPaginator {
    items: User[];
    count: number;
}

export interface AdminsState {
    users: UserPaginator;
    isLoading: boolean;
}

const initialState: Readonly<AdminsState> = {
    isLoading: true,
    users: {
        items: [],
        count: 0,
    },
};

export const reducer: Reducer<AdminsState> = (
    state: AdminsState = initialState,
    incomingAction: Action
): AdminsState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_STUDY_CENTERS_ADMINS:
            return {
                ...state,
                users: action.studyCentersAdmins,
                isLoading: false,
            };

        case DELETE_STUDY_CENTERS_ADMIN:
            return {
                ...state,
                users: {
                    items: state.users.items.filter((user) => user.id !== action.userId),
                    count: state.users.count - 1,
                },
                isLoading: false,
            };
        case APPROVE_STUDY_CENTERS_ADMIN:
            return {
                ...state,
                users: {
                    ...state.users,
                    items: state.users.items.map((u: User) => {
                        if (u.id === action.id) {
                            return {
                                ...u,
                                approved: action.approved,
                            };
                        }

                        return u;
                    }),
                },
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};
