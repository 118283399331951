import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AllCertificates from './all-certificates/AllCertificates';
import ConfirmCertificateData from './confirm-certificate-data/ConfirmCertificateData';
import CertificatePage from './certificate-page/CertificatePage';
import Error404 from 'pages/error-404/Error404';
import { useTitle, useScrollToTop } from 'utils';

const Certificates = () => {
    const { path } = useRouteMatch();
    const [t] = useTranslation(['certificates']);
    useTitle(t('title'));
    useScrollToTop();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <AllCertificates />
            </Route>
            <Route exact path={`${path}/confirm-data`}>
                <ConfirmCertificateData />
            </Route>
            <Route exact path={`${path}/:id`}>
                <CertificatePage />
            </Route>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default Certificates;
