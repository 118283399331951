import { useEffect, useState } from 'react';

export const useActionWithLoading = (action: () => void) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        (async () => {
            try {
                await action();
            } finally {
                setIsLoading(false);
            }
        })();
    }, [action]);

    return { isLoading };
};
