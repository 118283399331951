import React from 'react';

import { AutoSizeTextarea, Icon, Switch } from 'components';
import { AnswerData } from '../answers';

import './Answer.scss';

interface AnswerProps {
    answer: AnswerData;
    deleteQuestionAvailable: boolean;
    deleteAnswer: (index: number) => void;
    defaultText?: string;
    defaultChecked?: boolean;
    changeChecked: (index: number, newVal: boolean) => void;
    changeText: (index: number, newVal?: string) => void;
}

export const Answer = (props: AnswerProps) => {
    const { answer, defaultChecked, defaultText, deleteAnswer, deleteQuestionAvailable, changeChecked, changeText } =
        props;

    return (
        <>
            <div className="answer__container">
                <div className="answer__switch">
                    <Switch
                        defaultChecked={defaultChecked}
                        isSmall
                        label={answer.label}
                        onChange={() => changeChecked(answer.index, !defaultChecked)}
                    />
                </div>
                <AutoSizeTextarea
                    id={`answers[${answer.index}].text`}
                    value={defaultText}
                    handleChange={(e) => changeText(answer.index, e.target.value)}
                />
                {deleteQuestionAvailable && (
                    <div className="answer__delete" onClick={() => deleteAnswer(answer.index)}>
                        <Icon iconName="close" />
                    </div>
                )}
            </div>
        </>
    );
};
