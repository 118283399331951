import { Action, Reducer } from 'redux';
import { UserAgreement } from '../../../../models/agreements/user-argeements';

import { ActionTypes, GET_AGREEMENTS, SET_LOADING } from './actions';

export interface UserPartnerAgreementsState {
    isLoading: boolean;
    agreements: UserAgreement[];
    agreementsCount: number;
}

const initialState: Readonly<UserPartnerAgreementsState> = {
    isLoading: false,
    agreements: [],
    agreementsCount: 0,
};

export const reducer: Reducer<UserPartnerAgreementsState> = (
    state: UserPartnerAgreementsState = initialState,
    incomingAction: Action
): UserPartnerAgreementsState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case GET_AGREEMENTS:
            return {
                ...state,
                isLoading: false,
                agreements: action.agreements.items,
                agreementsCount: action.agreements.count,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        default:
            return state;
    }
};
