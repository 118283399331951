import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTitle } from '../../../utils';
import TrainingGroupListTCAdmin from './TrainingGroupListTCAdmin';

const TrainingGroupTCAdminPage: FC = () => {
    const { path } = useRouteMatch();
    const { t } = useTranslation('study-center');

    useTitle(t('training-groups'));

    return (
        <Switch>
            <Route exact path={path}>
                <TrainingGroupListTCAdmin />
            </Route>
        </Switch>
    );
};

export default TrainingGroupTCAdminPage;
