import React from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from 'components/Icon';

export interface MainNavItemProps {
    menuItemHref: string;
    menuItemIcon: string;
    menuItemText: string;
}

export const MainNavItem = (props: MainNavItemProps) => {
    const { menuItemHref, menuItemIcon, menuItemText } = props;

    return (
        <li className="menu-nav-item">
            <NavLink to={menuItemHref} className="menu-nav-link link">
                <span className="menu-nav-icon">
                    <Icon iconName={menuItemIcon} />
                </span>
                <span className="menu-nav-text">{menuItemText}</span>
            </NavLink>
        </li>
    );
};
