interface SessionServiceProps {
    timeout: number;
    onTimeout: () => void;
}

class SessionService {
    private readonly eventHandler: () => void;
    private readonly onTimeout: () => void;
    private readonly timeout: number;
    // @ts-ignore
    private timeLeft: number;
    // @ts-ignore
    private interval: NodeJS.Timer;

    constructor({ timeout, onTimeout }: SessionServiceProps) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            this.timeLeft--;

            if (this.timeLeft <= 0) {
                this.onTimeout();
                this.cleanUp();
            }
        }, 1000);
    }

    updateExpiredTime() {
        this.timeLeft = this.timeout;
    }

    tracker() {
        window.addEventListener('mousemove', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
        window.addEventListener('keydown', this.eventHandler);
    }

    cleanUp() {
        clearInterval(this.interval);
        window.removeEventListener('mousemove', this.eventHandler);
        window.removeEventListener('scroll', this.eventHandler);
        window.removeEventListener('keydown', this.eventHandler);
    }
}

export default SessionService;
