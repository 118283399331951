import { CertificationTypeFilter } from 'enums/certification-type';
import { SimpleLevelOfEvaluation } from 'enums/level-of-evaluation';
import { UserTestStatistic } from 'models/test/user-test-statistic';
import { CertDirection } from '../../../enums/cert-direction';
import { UserFilterType } from '../../../enums/user-filter-type';
import { ResponsePagination } from '../../../models/response-pagination';
import { SelectOption } from '../../../models/select-option';
import { TestStatisticParams, TestStatistic, TestStatisticXlsParams } from '../../../models/test/test-statistic';
import { TestService } from '../../../services/test.service';
import { AppThunkAction } from '../../../store';
import { UpdateTestStatistic } from '../../../models/test/update-test-statistic';
import api from 'api';

export const GET_STATISTIC = 'study-center-statistic/GET_STATISTIC';
export const CLOSE_STATISTIC_RESULT = 'study-center-statistic/CLOSE_STATISTIC_RESULT';
export const GET_STATISTIC_RESULT = 'study-center-statistic/GET_STATISTIC_RESULT';
export const GET_TESTS = 'study-center-statistic/GET_TESTS';
export const SELECT_COUNTRY = 'study-center-statistic/SELECT_COUNTRY';
export const SELECT_TEST = 'study-center-statistic/SELECT_TEST';
export const SELECT_DATES = 'study-center-statistic/SELECT_DATES';
export const SELECT_USER_TYPE = 'study-center-statistic/SELECT_USER_TYPE';
export const SELECT_CERTIFICATION_TYPE = 'study-center-statistic/SELECT_CERTIFICATION_TYPE';
export const SET_LOADING = 'study-center-statistic/SET_LOADING';
export const SET_ERROR = 'study-center-statistic/SET_ERROR';
export const SET_SUCCESS = 'study-center-statistic/SET_SUCCESS';
export const CLEAR_ERROR = 'study-center-statistic/CLEAR_ERROR';
export const SET_LEVEL = 'study-center-statistic/SET_LEVEL';
export const SET_COMPANY = 'study-center-statistic/SET_COMPANY';
export const SET_EMAIL = 'study-center-statistic/SET_EMAIL';
export const SET_CERTIFICATION_NUMBER = 'study-center-statistic/SET_CERTIFICATION_NUMBER';

interface setLevelOfEvaluation {
    type: typeof SET_LEVEL;
    level?: SimpleLevelOfEvaluation;
}

interface setCompany {
    type: typeof SET_COMPANY;
    company?: string;
}

interface setEmail {
    type: typeof SET_EMAIL;
    email?: string;
}

interface setCertificationNumber {
    type: typeof SET_CERTIFICATION_NUMBER;
    certificationNumber?: string;
}

interface CloseModalResult {
    type: typeof CLOSE_STATISTIC_RESULT;
}

interface GetStatisticResult {
    type: typeof GET_STATISTIC_RESULT;
    testResult?: UserTestStatistic;
}

interface GetStatistic {
    type: typeof GET_STATISTIC;
    statistics: ResponsePagination<TestStatistic>;
}

interface GetTests {
    type: typeof GET_TESTS;
    testOptions: SelectOption[];
}

interface SelectCountry {
    type: typeof SELECT_COUNTRY;
    selectedCountryId?: number;
}

interface SelectTest {
    type: typeof SELECT_TEST;
    selectedTestId?: string;
}

interface SelectDates {
    type: typeof SELECT_DATES;
    dateStart?: string;
    dateEnd?: string;
}

interface SelectUserType {
    type: typeof SELECT_USER_TYPE;
    selectedUserType?: UserFilterType;
}

interface SelectCertificationType {
    type: typeof SELECT_CERTIFICATION_TYPE;
    selectedCertificationType?: CertificationTypeFilter;
}

interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

interface SetError {
    type: typeof SET_ERROR;
    error: string;
}

interface SetSuccess {
    type: typeof SET_SUCCESS;
    success: string;
}

interface ClearError {
    type: typeof CLEAR_ERROR;
}

export type ActionTypes =
    | GetStatistic
    | GetTests
    | SelectCountry
    | SelectTest
    | SelectDates
    | SelectUserType
    | SetLoading
    | SetError
    | SetSuccess
    | ClearError
    | GetStatisticResult
    | CloseModalResult
    | SelectCertificationType
    | setLevelOfEvaluation
    | setCompany
    | setEmail
    | setCertificationNumber;

export const actions = {
    setLoading:
        (loading: boolean): AppThunkAction<ActionTypes> =>
        (dispatch) => {
            dispatch({
                type: SET_LOADING,
                isLoading: loading,
            });
        },
    deletePassingTest:
        (passingTestId: number, params: TestStatisticParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });
                await api.delete(`AdminControlling/DeletePassingTest/${passingTestId}`);
                const statistics = (await TestService.getStatistic(params)).data;

                dispatch({
                    type: GET_STATISTIC,
                    statistics,
                });
            } catch (e) {
                console.warn(e);
            } finally {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    closeModalResult: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        dispatch({
            type: CLOSE_STATISTIC_RESULT,
        });
    },
    getStatisticResult:
        (passingTestId: number, userId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const testResult = (await TestService.getStatisticForUser(passingTestId, userId)).data;

                dispatch({
                    type: GET_STATISTIC_RESULT,
                    testResult: {
                        ...testResult,
                        passingTestId,
                        userId,
                    },
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    updateTestStatistic:
        (newVal: UpdateTestStatistic, userId: string, params: TestStatisticParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await TestService.updateStatistic(newVal);

                const testResult = (await TestService.getStatisticForUser(newVal.passingId, userId)).data;

                dispatch({
                    type: GET_STATISTIC_RESULT,
                    testResult: {
                        ...testResult,
                        passingTestId: newVal.passingId,
                        userId,
                    },
                });

                const statistics = (await TestService.getStatistic(params)).data;

                dispatch({
                    type: GET_STATISTIC,
                    statistics,
                });
                dispatch({
                    type: SET_SUCCESS,
                    success: 'Данные успешно отредактированы',
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    getStatistic:
        (params: TestStatisticParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const statistics = (await TestService.getStatistic(params)).data;

                dispatch({
                    type: GET_STATISTIC,
                    statistics,
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                    error: 'Ошибка',
                });
            }
        },
    getTests: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        const tests = (await TestService.getList({ directionId: CertDirection.ProductLK })).data;

        const testOptions = tests.map((item) => ({
            label: item.title,
            value: item.id,
        }));

        dispatch({
            type: GET_TESTS,
            testOptions,
        });
    },
    selectCountry: (selectedCountryId: number) => ({
        type: SELECT_COUNTRY,
        selectedCountryId,
    }),
    selectTest: (selectedTestId: string) => ({
        type: SELECT_TEST,
        selectedTestId,
    }),
    selectUserType: (selectedUserType: UserFilterType) => ({
        type: SELECT_USER_TYPE,
        selectedUserType,
    }),
    selectCertificationType: (selectedCertificationType: CertificationTypeFilter) => ({
        type: SELECT_CERTIFICATION_TYPE,
        selectedCertificationType,
    }),
    selectDates: (dateStart?: string, dateEnd?: string) => ({
        type: SELECT_DATES,
        dateStart,
        dateEnd,
    }),
    getExcel:
        (params: TestStatisticXlsParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                await TestService.getStatisticXls(params);
            } catch {
                dispatch({
                    type: SET_ERROR,
                    error: 'Ошибка',
                });
            } finally {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    clearMessage: () => ({
        type: CLEAR_ERROR,
    }),
    selectLevel: (level: SimpleLevelOfEvaluation) => ({
        type: SET_LEVEL,
        level,
    }),
    selectCompany: (company: string) => ({
        type: SET_COMPANY,
        company,
    }),
    selectEmail: (email: string) => ({
        type: SET_EMAIL,
        email,
    }),
    selectCertificationNumber: (certificationNumber: string) => ({
        type: SET_CERTIFICATION_NUMBER,
        certificationNumber,
    }),
};
