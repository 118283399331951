import React from 'react';

import { StudyCenterUser } from '../../../../models/study-center-users/study-center-user';
import { Label } from 'components';

interface StudentListProps {
    title: string;
    students: StudyCenterUser[];
    additionalClass?: string;
}

export const StudentList = (props: StudentListProps) => {
    const { students, title, additionalClass } = props;
    return (
        <div className={`${additionalClass ? additionalClass : ''}`}>
            <div className="student-list-label">
                <Label labelText={title} labelFor="student-list" />
            </div>
            <div className="student-list">
                {students.map((x, index) => (
                    <div key={`${x.userName}-${x.email}-${index}`} className="student-list-row">
                        <span className="student-list-cell">{x.userName}</span>
                        <span className="student-list-cell">{x.email}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
