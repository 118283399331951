import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components';
import { STUDENT_STUDY_CENTER_LOGIN_PATH } from '../../global-constants';

const StudentStudyCenterLogin = () => {
    const { linkId } = useParams<{ linkId: string }>();

    useEffect(() => {
        window.location.href = STUDENT_STUDY_CENTER_LOGIN_PATH.replace('{tempLinkId}', linkId);
    }, [linkId]);

    return (
        <>
            <Loader />
        </>
    );
};

export default StudentStudyCenterLogin;
