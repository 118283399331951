import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import { connectComponent } from '../../../utils';
import { actions } from './store/actions';
import { useAlert } from 'react-alert';
import { StudyCenterUsersState } from './store/store';
import {
    actions as tcAuthenticationActions,
    TCAdminAuthenticationState,
} from '../../../authentication/TCAdminAuthenticationStore';
import { Column, Container, Loader, Title } from '../../../components';
import StudyCenterUserForm from '../../../components/StudyCenterUserForm/StudyCenterUserForm';
import { AddStudyCenterUser } from '../../../models/study-center-users/add-study-center-user';
import { KASPERSKY_MAIL } from 'global-constants';

type StudyCenterUserListProps = StudyCenterUsersState &
    TCAdminAuthenticationState &
    typeof tcAuthenticationActions &
    typeof actions;

const StudyCenterAddOrEditUserTcAdmin: FC<StudyCenterUserListProps> = (props) => {
    const {
        admin,
        isLoading,
        editableUser,
        loadUser,
        addUser,
        updateUser,
        clearEditableUser,
        successMessage,
        errorMessage,
        clearMessages,
    } = props;
    const alert = useAlert();
    const [t] = useTranslation(['study-center', 'info']);
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();
    const { pathname } = useLocation();

    const goBack = () => {
        if (id) {
            push(pathname.replace(`/edit/${id}`, ''));
        } else {
            push(pathname.replace(`/add`, ''));
        }
    };

    useEffect(() => {
        if (id) {
            loadUser(id);
        } else {
            clearEditableUser();
        }
    }, [id, loadUser]);

    useEffect(() => {
        if (successMessage) {
            alert.success(t(successMessage));
            goBack();
        } else if (errorMessage) {
            alert.error(t(errorMessage, { mailAddress: KASPERSKY_MAIL }), {
                timeout: 10000,
            });
        }

        clearMessages();
    }, [successMessage, errorMessage, alert, clearMessages, goBack]);

    const submitForm = async (values: AddStudyCenterUser) => {
        if (id) {
            updateUser({ ...values, id, externalId: editableUser?.externalId });
            return;
        }

        addUser(values);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Container>
            <Column columnSize="50">
                <Title titleText={id ? t('edit-user-title') : t('add-user-title')} />

                <StudyCenterUserForm
                    studyCenterId={admin?.studyCenter.id as number}
                    user={id ? editableUser : undefined}
                    submitForm={submitForm}
                />
            </Column>
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: ({ tcAuthentication, studyCenterUsersTcAdmin }: ApplicationState) => ({
        ...tcAuthentication,
        ...studyCenterUsersTcAdmin,
        isLoading: tcAuthentication?.isLoading || studyCenterUsersTcAdmin?.isLoading,
    }),
    actions: {
        ...tcAuthenticationActions,
        ...actions,
    },
    component: StudyCenterAddOrEditUserTcAdmin as () => JSX.Element,
});
