import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Column } from '../Column';
import { Icon } from '../Icon';
import api from 'api';
import { TextInput } from 'components/Form';
import { Button } from 'components/Button';

export enum CertificationType {
    Portal = 1,
    History = 2,
}

interface Props {
    isOpen: boolean;
    close: () => void;
    update: () => void;
    type: CertificationType;
    userId: string;
    id: string;
    success: (message: string) => void;
    error: (message: string) => void;
    setLoading: (loading: boolean) => void;
}

ReactModal.setAppElement('body');

export const ChangeUserNameOnCertificate = (props: Props) => {
    const { isOpen, close, success, error, setLoading, id, userId, type, update } = props;
    const [newName, setNewName] = useState('');
    const [isChanged, setIsChanged] = useState(false);

    const isValid = () => newName && newName.length <= 122;

    const sendNewName = () => {
        setLoading(true);
        api.put('CertificateChangeData', {
            id,
            userId,
            newName,
            type,
        })
            .then(
                () => {
                    success('вы поменяли имя на сертификате');
                    close();
                },
                () => {
                    error('ошибка при изменении имени на сертификате');
                }
            )
            .finally(() => {
                setLoading(false);
                update();
            });
    };

    return (
        <Column columnSize="100">
            <ReactModal
                isOpen={isOpen}
                onRequestClose={close}
                className={`change_name react-modal react-modal-standard`}
                overlayClassName={`react-modal-overlay react-modal-overlay-standard`}
            >
                <div onClick={close} className={`change_name-modal-close`}>
                    <Icon iconName="close" />
                </div>
                <Column columnSize="100">
                    <div className={`change_name-modal-title`}>Поменять имя на сертификате</div>
                </Column>
                <Column columnSize="100">
                    <TextInput
                        defaultValue={newName}
                        label={'Новое имя'}
                        onChangeHandler={(val) => {
                            setNewName(val.target.value);
                            setIsChanged(true);
                        }}
                        id="new_certificate_owner"
                        errorMessage={
                            !isValid() && isChanged
                                ? 'Обязательное поле и должно быть меньше или равно 122 символам*'
                                : ''
                        }
                    />
                </Column>
                <Column columnSize="100">
                    <Button type="submit" buttonClass="primary" isDisabled={!isValid()} handleClick={sendNewName}>
                        Сохранить
                    </Button>
                </Column>
            </ReactModal>
        </Column>
    );
};
