import React, { FC, MouseEvent, PropsWithChildren } from 'react';

interface ButtonProps {
    className?: string;
    buttonClass?: 'primary' | 'secondary' | 'link' | 'additional' | string;
    type?: 'submit' | 'reset' | 'button';
    pSize?: 'big' | 'small' | 'none';
    title?: string;
    isDisabled?: boolean;
    isLoading?: boolean;

    handleClick?(e: MouseEvent<HTMLElement>): void;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = (props) => {
    const {
        buttonClass,
        handleClick,
        children,
        type,
        isDisabled,
        pSize = 'big',
        title,
        isLoading,
        className = '',
    } = props;
    const classes = `${className} btn btn-${buttonClass ? buttonClass : ''} ${pSize ? 'btn-pSize-' + pSize : ''}`;

    return (
        <button
            type={type || 'button'}
            title={title}
            className={classes}
            onClick={handleClick}
            disabled={isDisabled || isLoading}
        >
            {isLoading && <div className="btn-loader" />} {children}
        </button>
    );
};
