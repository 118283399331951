import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Footer, Header, SidebarMenu } from 'components';
import { PRIVACY_STATEMENT, TERMS_OF_USE } from 'global-constants';
import PrivacyStatementBase from './privacy-statement/PrivacyBaseComponent';
import TermsOfUse from './terms-of-use/TermsOfUsePage';

const ApplicationPolicies = () => {
    const { path } = useRouteMatch();
    const rootPath = path === '/' ? '' : path;

    return (
        <>
            <SidebarMenu />
            <Header isVisibleLanguagePicker hideLogin />
            <Switch>
                <Route exact path={`${rootPath}/${PRIVACY_STATEMENT}`} component={PrivacyStatementBase} />
                <Route exact path={`${rootPath}/${TERMS_OF_USE}`} component={TermsOfUse} />
            </Switch>
            <Footer />
        </>
    );
};

export default ApplicationPolicies;
