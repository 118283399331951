import React, { FC } from 'react';
import moment from 'moment';
import { certificationNames } from '../../constants/certification-names';
import { dateTimeFormat } from '../../constants/date-format';
import { CertificationEnum } from '../../enums/certificationEnum';
import { LanguageEnum } from '../../enums/language.enum';
import { useUrlQueryParamsForGrid } from '../../hooks/useUrlQueryParamsForGrid';
import { actions, UsersState } from './store';
import { ApplicationState } from '../../store';
import { connectComponent, withLoading } from '../../utils';
import { Button, Column, Container, EmptyListWarning, Grid, Row, TextInput, Title } from '../../components';

type UsersProps = UsersState & typeof actions;

const UsersSFList: FC<UsersProps> = (props) => {
    const { usersHistorySf: users, isLoadingExcel, getUsersHistorySF, getExcelHistorySF } = props;

    const {
        pagination,
        queryState: [email, setEmail],
    } = useUrlQueryParamsForGrid({
        total: users.count,
        loadFn: ({ page, size, query }) =>
            getUsersHistorySF({
                page,
                size,
                email: query,
            }),
    });

    const getGridData = (): Row[] =>
        users.items.map((user) => ({
            ...user,
            email: {
                additionalComponent: (
                    <>
                        <div>{user.email}</div>
                        <div>{user.userName}</div>
                    </>
                ),
            },
            creationDate: moment(user.creationDate).format(dateTimeFormat),
            expirationDate: moment(user.expirationDate).format(dateTimeFormat),
            language: LanguageEnum[user.language],
            certificationType: certificationNames[CertificationEnum[user.certificationType]],
        }));

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText="Исторические данные из SalesForce" />
            </Column>
            <Column columnSize="50">
                <TextInput
                    id="search-field"
                    label="Email"
                    value={email}
                    onChangeHandler={(e) => setEmail(e.target.value)}
                />
            </Column>
            <Column columnSize="50" className="text-right">
                <Button
                    isLoading={isLoadingExcel}
                    buttonClass="primary"
                    handleClick={() => getExcelHistorySF({ size: pagination.size, page: pagination.page })}
                >
                    Excel
                </Button>
            </Column>
            <Column columnSize="100">
                {users.items.length ? (
                    <Grid rows={getGridData()} columns={columns} pagination={pagination} />
                ) : (
                    <EmptyListWarning />
                )}
            </Column>
        </Container>
    );
};

const mapStateToProps = ({ users }: ApplicationState) => ({
    ...users,
});

export default connectComponent({
    mapStateToProps,
    actions,
    component: withLoading(UsersSFList),
});

const columns = {
    email: {
        value: 'Email / ФИО',
        columnSize: '15',
    },
    testName: {
        value: 'Название теста',
        columnSize: '15',
    },
    creationDate: {
        value: 'Дата успешного прохождения теста',
        columnSize: '7',
    },
    certificationNumber: {
        value: 'Номер сертификата',
        columnSize: '10',
    },
    companyName: {
        value: 'Компания',
        columnSize: '10',
    },
    country: {
        value: 'Страна',
        columnSize: '10',
    },
    language: {
        value: 'Язык сертификата',
        columnSize: '7',
    },
    certificationType: {
        value: 'Название шаблона сертификата',
        columnSize: '10',
    },
    expirationDate: {
        value: 'Дата окончания действия сертификата',
        columnSize: '7',
    },
    examCode: {
        value: 'Код экзамена',
        columnSize: '7',
    },
};
