import React from 'react';
import { VKShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { useTranslation } from 'react-i18next';
import { LanguageKeys } from '../../../models/language/language';
import { Icon } from 'components';

interface ShareButtonsProps {
    pageOfCertificateLink: string;
    isDisabled: boolean;
}

export const ShareButtons = (props: ShareButtonsProps) => {
    const { pageOfCertificateLink, isDisabled } = props;
    const shareUrl = `${window.location.origin}${pageOfCertificateLink}`;
    const disabled = isDisabled || !pageOfCertificateLink;
    const disabledStyles = { opacity: 0.7, cursor: 'default' };

    const { i18n } = useTranslation();
    const currentLanguage = i18n.language as LanguageKeys;

    return (
        <div className="d-flex social-networks">
            <VKShareButton
                disabledStyle={disabledStyles}
                disabled={disabled}
                url={shareUrl}
                className="social-network-button"
            >
                <Icon iconName="vk-logo" />
            </VKShareButton>
            {currentLanguage !== 'ru' && (
                <FacebookShareButton
                    disabledStyle={disabledStyles}
                    disabled={disabled}
                    url={shareUrl}
                    className="social-network-button"
                >
                    <Icon iconName="facebook-logo" />
                </FacebookShareButton>
            )}
            <TwitterShareButton
                disabledStyle={disabledStyles}
                disabled={disabled}
                url={shareUrl}
                className="social-network-button"
            >
                <Icon iconName="twitter-logo" />
            </TwitterShareButton>
            <LinkedinShareButton
                disabledStyle={disabledStyles}
                disabled={disabled}
                url={shareUrl}
                className="social-network-button"
            >
                <Icon iconName="linkedin-logo" />
            </LinkedinShareButton>
        </div>
    );
};
