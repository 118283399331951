import React from 'react';

interface LabelProps {
    labelFor?: string;
    labelText: string;
    additionalClasses?: string;
}

export const Label = (props: LabelProps) => {
    const { labelFor, additionalClasses } = props;

    return (
        <label htmlFor={labelFor} className={`form-label ${additionalClasses ? additionalClasses : ''}`}>
            {props.labelText}
        </label>
    );
};
