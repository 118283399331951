import React, { FC, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { EmptyListWarning, Icon, Button, GridFixed, RowFixed } from '../../components';
import { PaginationProps } from '../../components/Pagination/Pagination';
import { TestStatistic } from '../../models/test/test-statistic';
import { enumToCode } from 'utils/lang';
import {
    CertificationType,
    ChangeUserNameOnCertificate,
} from 'components/ChangeUserNameOnCertificate/ChangeUserNameOnCertificate';
import './TestStatisticsGrid.scss';
import moment from 'moment';
import { dateTimeFormat } from 'constants/date-format';

interface TestStatisticsGridProps {
    statistics: TestStatistic[];
    pagination: PaginationProps;
    getStatisticResult: (passingTestId: number, userId: string) => void;
    deletePassingTest: (passingTestId: number) => void;
    success: (message: string) => void;
    error: (message: string) => void;
    setLoading: (loading: boolean) => void;
    update: () => void;
}

const TestStatisticsGrid: FC<TestStatisticsGridProps> = ({
    statistics,
    pagination,
    getStatisticResult,
    deletePassingTest,
    success,
    error,
    setLoading,
    update,
}) => {
    const [t] = useTranslation(['column-title']);
    const [modalIsOpenChangeName, setModalIsOpenChangeName] = useState(false);
    const [userId, setUserId] = useState('');
    const [certificateId, setCertificateId] = useState('');
    const getGridData = (): RowFixed[] =>
        statistics.map((item) => ({
            ...item,
            isPassed: {
                additionalComponent: (
                    <div className="study-center-test-statistics-grid__status-icon">
                        {item.isPassed ? (
                            <Icon key="check-mark-2" iconName="check-mark-2" />
                        ) : (
                            <Icon key="close" iconName="close" />
                        )}
                    </div>
                ),
            },
            attemptCreationDate: {
                value: item.attemptCreationDate ? moment(item.attemptCreationDate).format(dateTimeFormat) : '',
            },
            editDate: {
                value: item.editDate ? moment(item.editDate).format(dateTimeFormat) : '',
            },
            attemptCount: {
                value: `${item.attemptNumber}/${item.attemptCount}`,
                align: 'center',
            },
            certificationCode: {
                value: item.certificationCode,
                align: 'center',
            },
            userFullName: {
                additionalClasses: 'link',
                additionalComponent: (
                    <div>
                        {item.userEmail} <br /> <br /> {item.userFullName}
                    </div>
                ),
                handleClick: () => getStatisticResult(item.passingTestId, item.userId),
            },
            isCertificateExists: {
                value: item.isCertificateExists + '',
                align: 'center',
            },
            isCertificateActive: {
                value: item.isCertificateActive + '',
                align: 'center',
            },
            certification: {
                value:
                    item.isCertificateExists && item.isCertificateActive
                        ? 'Действительный'
                        : item.isCertificateExists && !item.isCertificateActive
                        ? 'Истёк'
                        : 'Отсутствует',
                align: 'center',
            },
            language: {
                value: enumToCode(item.language),
            },
            certificateId: {
                additionalComponent:
                    !item.certificateId || item.isDeleted ? (
                        ''
                    ) : (
                        <a
                            className="link"
                            href={`/api/GetCertificate/${item.certificateId}/${item.userId}/certificate.pdf`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Download
                        </a>
                    ),
            },
            isPartner: {
                value: item.isPartner ? 'Partner' : 'Is not',
            },
            deleteCertificateId: {
                additionalComponent: item.isClientStudyCenter && (
                    <Button pSize="none" handleClick={() => deletePassingTest(item.passingTestId)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
                align: 'start',
            },
            isClientStudyCenter: false + '',
            isDeleted: item.isDeleted + '',
            edit: {
                additionalComponent: item.certificateId && !item.isDeleted && (
                    <Button
                        pSize="none"
                        handleClick={() => {
                            setUserId(item.userId);
                            setCertificateId(item.certificateId as string);
                            setModalIsOpenChangeName(true);
                        }}
                    >
                        <Icon iconName="edit" />
                    </Button>
                ),
                align: 'start',
            },
        }));

    return statistics.length ? (
        <>
            <GridFixed columns={getColumns(t)} rows={getGridData()} pagination={pagination} />
            {modalIsOpenChangeName && (
                <ChangeUserNameOnCertificate
                    update={update}
                    isOpen={modalIsOpenChangeName}
                    close={() => setModalIsOpenChangeName(false)}
                    type={CertificationType.Portal}
                    userId={userId}
                    id={certificateId}
                    success={success}
                    error={error}
                    setLoading={setLoading}
                />
            )}
        </>
    ) : (
        <EmptyListWarning />
    );
};

export default TestStatisticsGrid;

const getColumns = (t: TFunction) => ({
    isPassed: {
        value: '',
        columnSize: '50',
    },
    userFullName: {
        value: `${t('column-title:email')}/${t('column-title:user-full-name')}`,
        columnSize: '200',
    },
    userCompanyName: {
        value: t('column-title:company'),
        columnSize: '200',
    },
    userCountryName: {
        value: t('column-title:country'),
        columnSize: '200',
    },
    trainingCenterName: {
        value: t('column-title:study-center'),
        columnSize: '200',
    },
    trainingGroupName: {
        value: t('column-title:training-group'),
        columnSize: '200',
    },
    testName: {
        value: t('column-title:test-name'),
        columnSize: '200',
    },
    attemptCount: {
        value: t('column-title:attempt-test-count'),
        columnSize: '200',
    },
    attemptCreationDate: {
        value: t('column-title:attempt-test-date'),
        columnSize: '200',
    },
    editDate: {
        value: t('column-title:attempt-edit-date'),
        columnSize: '200',
    },
    duration: {
        value: t('column-title:attempt-test-duration'),
        columnSize: '200',
    },
    userExternalId: {
        value: 'SFDC Id',
        columnSize: '200',
    },
    certificateExternalId: {
        value: 'Certificate\nSFDC Id',
        columnSize: '200',
    },
    certificationCode: {
        value: t('column-title:cert-code'),
        columnSize: '200',
    },
    certification: {
        value: 'Сертификат',
        columnSize: '200',
    },
    language: {
        value: 'Язык',
        columnSize: '200',
    },
    isPartner: {
        value: 'Партнёр',
        columnSize: '200',
    },
    certificateId: {
        value: 'Скачать',
        columnSize: '200',
    },
    isDeleted: {
        value: 'Анул-ван',
        columnSize: '200',
    },
    deleteCertificateId: {
        value: 'Удалить',
        columnSize: '100',
    },
    edit: {
        value: 'Ред-ть',
        columnSize: '100',
    },
});
