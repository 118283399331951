import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { RouterTabs } from '../../components/RouterTabs/RouterTabs';
import { agreementsRoutesTabs } from '../../constants/agreements-routes';
import { useTitle } from '../../utils';
import { getRouteTabWithPath } from '../../utils/get-route-tab-with-path';
import Error404 from '../error-404/Error404';
import UserAgreementsList from './user-agreements-list/UserAgreementsList';
import UserPartnerAgreementsList from './user-partner-agreements-list/UserPartnerAgreementsList';

const UserAgreements: FC = () => {
    const { path } = useRouteMatch();
    useTitle('Соглашения');

    return (
        <>
            <RouterTabs tabs={getRouteTabWithPath(agreementsRoutesTabs, path)} />

            <Switch>
                <Route path={`${path}/partners`}>
                    <UserPartnerAgreementsList />
                </Route>

                <Route path={`${path}/users`}>
                    <UserAgreementsList />
                </Route>

                <Redirect path={`${path}`} to={`${path}/users`} />
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default UserAgreements;
