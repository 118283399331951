import { TFunction } from 'i18next';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Row } from '../../components';
import { dateTimeFormat } from '../../constants/date-format';
import { Admin, UpdateAccess } from './store';

interface SuperAdminGridProps {
    admins: Admin[];
    currentAdminId: string;
    changeAdminApproved: (data: UpdateAccess) => void;
}

const SuperAdminGrid: FC<SuperAdminGridProps> = ({ admins }) => {
    const { t } = useTranslation('column-title');
    const getGridData = (): Row[] =>
        admins.map((admin) => ({
            userFullName: `${admin.lastName} ${admin.name} ${admin.middleName}`,
            email: admin.email,
            creationDate: moment(admin.creationDate).format(dateTimeFormat),
        }));

    return <Grid columns={getColumns(t)} rows={getGridData()} />;
};

export default SuperAdminGrid;

const getColumns = (t: TFunction) => ({
    userFullName: {
        value: t('column-title:user-full-name'),
        columnSize: '30',
    },
    email: {
        value: t('column-title:email'),
        columnSize: '30',
    },
    creationDate: {
        value: t('column-title:date-reg'),
        columnSize: '20',
    },
});
