import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form-new';

import { UserTestState, actions, UserTestErrors, getNextQuestion, TestQuestion, getPrevQuestion } from '../store';
import { ApplicationState } from '../../../store';
import { QuestionView } from './question-view/QuestionView';
import { TestTimer } from './test-timer/TestTimer';
import { withLoading, Theme, theme } from 'utils';
import { TestQuestionsList } from './test-questions-list';
import { ShowQuestionsButton } from './show-questions-button/ShowQuestionsButton';
import { Page } from 'components';

interface OwnProps {
    changeLightTheme: (newTheme: Theme) => void;
}

export type PassingTestProps = UserTestState &
    typeof actions &
    OwnProps & {
        questionsWithoutAnswer: TestQuestion[];
        questionsWithoutAnswerLength: number;
        isGoToResult: boolean;
        prevQuestion?: TestQuestion;
        nextQuestion?: TestQuestion;
    };

export const PassingTest = (props: PassingTestProps) => {
    const {
        currentPassingTest,
        currentQuestion,
        setCurrentQuestion,
        finishTest,
        questionsWithoutAnswer,
        isGoToResult,
        changeLightTheme,
        isLoading,
    } = props;
    const { passingTestQuestionOptions, questionTitle, id: currentQuestionId } = currentQuestion || {};
    const { passingTestQuestions, secondsLeft } = currentPassingTest;
    const { questionId } = useParams<{ questionId: string }>();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const methods = useForm();
    const { reset } = methods;
    const [showQuestionNumbers, setShowQuestionNumbers] = useState(false);

    const goToResult = useCallback(
        (state?: Record<string, unknown>) => {
            push(pathname.replace(`passing/${questionId}`, `finish`), {
                ...state,
            });
        },
        [pathname, push, questionId]
    );

    const switchToQuestion = useCallback(
        (newQuestionId: number | string) => {
            if (questionId && newQuestionId) {
                push(pathname.replace(questionId, newQuestionId.toString()));
                reset();
            }
        },
        [pathname, push, questionId, reset]
    );

    useEffect(() => {
        if (questionsWithoutAnswer.length && questionId) {
            const isValidId = questionsWithoutAnswer.find((x) => x.id.toString() === questionId);
            const firstQuestionId = questionsWithoutAnswer[0]?.id?.toString();
            const currentQuestionIndex = currentPassingTest.passingTestQuestions.findIndex(
                (x) => x.id.toString() === questionId
            );
            const nextQuestion =
                currentQuestionIndex + 1 >= currentPassingTest.passingTestQuestions.length
                    ? questionsWithoutAnswer[0]
                    : currentPassingTest.passingTestQuestions[currentQuestionIndex + 1];

            if (isValidId && +questionId !== currentQuestionId) {
                setCurrentQuestion(+questionId);
            } else if (!isValidId && firstQuestionId) {
                switchToQuestion(nextQuestion.id);
            }
        }
    }, [currentQuestionId, questionId, questionsWithoutAnswer, setCurrentQuestion, switchToQuestion]);

    useEffect(() => {
        if (isGoToResult) {
            goToResult();
        }
    }, [goToResult, isGoToResult]);

    useEffect(() => {
        if (showQuestionNumbers) {
            return () => changeLightTheme(theme.light);
        }
    }, [changeLightTheme, showQuestionNumbers]);

    const handleQuestionView = (): void => {
        setShowQuestionNumbers(!showQuestionNumbers);
    };

    const handleEndTime = useCallback(() => {
        finishTest();
        goToResult({ isTimeExpired: true });
    }, [finishTest, goToResult]);

    if (isGoToResult || isLoading || !questionsWithoutAnswer.length) {
        return null;
    }

    return (
        <Page>
            <div className="block-test">
                <TestTimer handleEndTime={handleEndTime} totalSeconds={secondsLeft} />
                <FormProvider {...methods}>
                    <div className="block-test-wrap">
                        <ShowQuestionsButton
                            showQuestions={showQuestionNumbers}
                            handleClick={handleQuestionView}
                            currentQuestion={currentQuestion}
                            passingTestQuestions={passingTestQuestions}
                        />
                        {showQuestionNumbers ? (
                            <TestQuestionsList
                                questions={passingTestQuestions}
                                currentQuestionId={currentQuestion.id}
                                switchToQuestion={switchToQuestion}
                                handleQuestionView={handleQuestionView}
                            />
                        ) : (
                            <QuestionView
                                {...props}
                                questionTitle={questionTitle}
                                questionOptions={passingTestQuestionOptions}
                                switchToQuestion={switchToQuestion}
                            />
                        )}
                    </div>
                </FormProvider>
            </div>
        </Page>
    );
};

const mapStateToProps = ({ userTest }: ApplicationState, ownProps: OwnProps) => {
    const { isTestFinish, userTestError, currentPassingTest, currentQuestion } = userTest;
    const questionsWithoutAnswer =
        currentPassingTest.passingTestQuestions.filter((x) => !x.selectedQuestionOptionsId.length) || [];

    return {
        ...userTest,
        ...ownProps,
        questionsWithoutAnswer,
        isGoToResult: isTestFinish || userTestError.status === UserTestErrors.PassingTestNotFound,
        nextQuestion: getNextQuestion(questionsWithoutAnswer, currentQuestion?.id),
        prevQuestion: getPrevQuestion(questionsWithoutAnswer, currentQuestion?.id),
    };
};

export default connect(mapStateToProps, actions)(withLoading(PassingTest) as () => JSX.Element);
