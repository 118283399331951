import React from 'react';
import { RefType } from '../../Form';

type RadioButtonProps = {
    labelText: string;
    radioName: string;
    radioValue: string;
    radioRef?: RefType;
    isDisabled?: boolean;
    additionalClasses?: string;
    defaultChecked?: boolean;
    onChangeHandler?(event: React.FormEvent<HTMLInputElement>): void;
};

export const RadioButton = ({
    labelText,
    radioName,
    radioValue,
    radioRef,
    isDisabled,
    additionalClasses,
    defaultChecked,
    onChangeHandler,
}: RadioButtonProps) => (
    <label className={`radio-button ${additionalClasses || ''}`}>
        {labelText}
        <input
            disabled={isDisabled}
            type="radio"
            name={radioName}
            value={radioValue}
            ref={radioRef}
            defaultChecked={defaultChecked}
            onChange={onChangeHandler}
        />
        <span className="checkmark" />
    </label>
);
