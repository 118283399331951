import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CategoriesList from './CategoriesList';
import CategoriesFormAdd from './CategoriesFormAdd';
import CategoriesFormEdit from './CategoriesFormEdit';
import Error404 from 'pages/error-404/Error404';
import { useTitle } from 'utils';

const Categories = () => {
    const { path } = useRouteMatch();

    useTitle('Категории');

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <CategoriesList />
                </Route>
                <Route exact path={`${path}/add`}>
                    <CategoriesFormAdd />
                </Route>
                <Route exact path={`${path}/edit/:id`}>
                    <CategoriesFormEdit />
                </Route>
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default Categories;
