import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectorOptions = {
    order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],

    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupQuerystring: 'lang',

    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],

    htmlTag: document.documentElement,

    checkWhitelist: true,
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: detectorOptions,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
