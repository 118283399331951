import { AppThunkAction } from '../../../store';

import { Attempt, AttemptDetails, ServerAttempt } from './store';
import api from '../../../api';
import { mapServerAttemptsToAttempts } from './mappers';

export const LOAD_ATTEMPTS = 'attempts-list/LOAD_ATTEMPTS';
export const GET_ATTEMPT_DETAILS = 'attempts-list/GET_ATTEMPT_DETAILS';
export const SET_LOADING = 'attempts-list/SET_LOADING';

export interface LoadAttempts {
    type: typeof LOAD_ATTEMPTS;
    attempts: Attempt[];
}

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface GetAttemptDetails {
    type: typeof GET_ATTEMPT_DETAILS;
    attemptDetails: AttemptDetails;
}

export type ActionTypes = LoadAttempts | SetLoading | GetAttemptDetails;

export const actions = {
    loadAttempts: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        try {
            dispatch({
                type: SET_LOADING,
            });
            const serverAttempts = (await api.get<ServerAttempt[]>('ResultTest')).data;
            const attempts = mapServerAttemptsToAttempts(serverAttempts);

            dispatch({
                type: LOAD_ATTEMPTS,
                attempts: attempts.reverse(),
            });
        } catch {
            dispatch({
                type: SET_LOADING,
                isLoading: false,
            });
        }
    },
    getAttemptDetailed:
        (id: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });
                const attemptDetails = (await api.get<AttemptDetails>(`ResultTest/${id}`)).data;

                dispatch({
                    type: GET_ATTEMPT_DETAILS,
                    attemptDetails,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
};
