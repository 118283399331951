import React, { FC } from 'react';
import { UserFull } from './models/user-full';
import { Column, Container, Paragraph } from 'components';

interface Props {
    user: UserFull;
}

export const UserCard: FC<Props> = ({ user }) => (
    <Container additionalClass="user-card">
        <Column columnSize="50">
            {user.firstName && <Paragraph additionalClass="">Имя: {user.firstName}</Paragraph>}
            {user.lastName && <Paragraph additionalClass="">Фамилия: {user.lastName}</Paragraph>}
        </Column>
        <Column columnSize="50">
            {user.middleName && <Paragraph additionalClass="">Отчество: {user.middleName}</Paragraph>}
            {user.company && <Paragraph additionalClass="">Компания: {user.company}</Paragraph>}
        </Column>
        <Column columnSize="100">
            {user.country && <Paragraph additionalClass="">Страна: {user.country}</Paragraph>}
            {user.isBlocked && <Paragraph additionalClass="danger">Причина блокировки: {user.blockReason}</Paragraph>}
        </Column>
    </Container>
);
