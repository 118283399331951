import React from 'react';
import DOMPurify from 'dompurify';
import { useFormContext, Controller } from 'react-hook-form-new';

import { QuestionOption } from '../../../store';
import { ANSWERS_LETTERS } from 'pages/questions/constants';
import { QUESTION_INPUT_NAME } from '../QuestionView';

type QuestionOptionProps = {
    options: QuestionOption[];
};

export const QuestionOne = (props: QuestionOptionProps) => {
    const { options } = props;
    const { formState, control } = useFormContext();
    const { isSubmitting } = formState;

    return (
        <Controller
            name={QUESTION_INPUT_NAME}
            control={control}
            disabled={isSubmitting}
            render={({ field: { onChange, ...props } }) => (
                <>
                    {options.map((opt, index) => (
                        <div key={opt.questionOptionId} className="question-item-wrap">
                            <label className="question-item">
                                <input
                                    type={'radio'}
                                    className="question-item-input"
                                    {...props}
                                    onChange={(e) => onChange(e.target.value)}
                                    value={opt.questionOptionId}
                                />
                                <span className="question-item-value">{ANSWERS_LETTERS.split('')[index]}</span>
                                <span
                                    className="question-item-text"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(opt.questionOptionText),
                                    }}
                                />
                            </label>
                        </div>
                    ))}
                </>
            )}
        />
    );
};
