import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom';

import { ApplicationState } from 'store';
import { Header, Page, SidebarMenu, Loader, SessionExpired, Footer } from 'components';
import Error404 from 'pages/error-404/Error404';
import { ADMIN_STUDY_LOGOUT_PATH } from 'global-constants';
import { useScrollToTop } from 'utils';
import { LanguageKeys } from '../models/language/language';
import {
    actions as tcAuthenticationActions,
    TCAdminAuthenticationState,
} from '../authentication/TCAdminAuthenticationStore';
import { tcAdminRouting } from '../constants/tc-admin-routing';
import { Role } from '../enums/roles';
import { UserState } from '../enums/user-state';
import { RoutingConfig } from '../models/routing-config';
import AdminLoginTC from '../pages/study-center-admin/admin-login-tc/AdminLoginTC';
import SessionService from '../services/session.service';
import { AppSettingsState } from 'store/app-settings/store';
import AdminNewVersion from 'pages/study-center-admin/admin-new-version/AdminNewVersion';

type AdminLayoutProps = TCAdminAuthenticationState &
    typeof tcAuthenticationActions & {
        settings: AppSettingsState;
    };

const TCAdminLayout = (props: AdminLayoutProps) => {
    const {
        loadAdmin,
        admin,
        isLoading,
        isSessionEnd,
        maxNoActiveTime,
        actionAfterEndSessionTime,
        redirect_error,
        settings,
    } = props;

    const { path } = useRouteMatch();
    const { push } = useHistory();
    const [t, i18n] = useTranslation(['translation', 'study-center', 'menu-titles']);
    const currentLanguage = i18n.language as LanguageKeys;
    const [navItems, setNavItems] = useState<RoutingConfig[]>([]);

    useEffect(() => {
        if (redirect_error) {
            window.location.href = `/api/AdminStudyCenter/loginInto?redirectUrl=${encodeURIComponent(
                window.location.href
            )}`;
        }
    }, [redirect_error]);

    useScrollToTop();

    useEffect(() => {
        let timer: SessionService;

        if (admin && maxNoActiveTime && !isSessionEnd) {
            timer = new SessionService({
                timeout: maxNoActiveTime,
                onTimeout: actionAfterEndSessionTime,
            });
        }

        return () => {
            timer?.cleanUp();
        };
    }, [admin, isSessionEnd, actionAfterEndSessionTime]);

    const handleLogout = () => {
        document.location.href = ADMIN_STUDY_LOGOUT_PATH;
    };

    useEffect(() => {
        if (!admin) {
            loadAdmin();
        }
    }, [loadAdmin, admin]);

    useEffect(() => {
        setNavItems(getRouting(path, t));
    }, [currentLanguage, t]);

    if (isLoading) {
        return <Loader />;
    }

    const showLogin = !admin || !admin.lastName || !admin.studyCenter || admin.approved !== UserState.Approve;
    const showNewVersion =
        !showLogin &&
        admin &&
        admin.approvedAgreements &&
        (admin.personalVersion < settings.personalVersion || admin.marketingVersion < settings.marketingVersion);

    if (showLogin) {
        return (
            <>
                <SidebarMenu />
                <Header isVisibleLanguagePicker hideLogin headerTitle={t('header-name')} />
                <Switch>
                    <Route exact path={`${path}`} component={AdminLoginTC} />
                    <Redirect path={`${path}/*`} to={`${path}`} />
                </Switch>
                <Footer />
            </>
        );
    }

    if (showNewVersion) {
        return <AdminNewVersion />;
    }

    if (admin?.role === Role.AdminStudyCenter) {
        return (
            <>
                {admin && (
                    <SessionExpired
                        goToLogin={handleLogout}
                        isSessionEnd={isSessionEnd}
                        maxNoActiveTime={maxNoActiveTime}
                    />
                )}

                <SidebarMenu navItems={navItems} />
                <Header
                    user={admin}
                    isVisibleLanguagePicker
                    headerTitle={`${t('header-name')}. ${t('study-center:study-center')}: ${admin.studyCenter.name}`}
                    handleLogout={handleLogout}
                    headerTitleClick={() => push(navItems[0].menuItemHref)}
                    handleClick={() => {
                        const profileElem = navItems.find((item) => item.id === 'profile') || navItems[0];
                        push(profileElem.menuItemHref);
                    }}
                />
                <Page>
                    <Switch>
                        <Redirect exact from={path} to={navItems[0].menuItemHref} />
                        {navItems.map((item) => (
                            <Route key={item.menuItemHref} path={item.menuItemHref}>
                                {item.component}
                            </Route>
                        ))}
                        <Route path={`${path}/*`} component={Error404} />
                    </Switch>
                </Page>

                <Footer />
            </>
        );
    }

    return (
        <Switch>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default connect(
    ({ tcAuthentication, appSettings }: ApplicationState) => ({
        ...tcAuthentication,
        settings: appSettings,
    }),
    tcAuthenticationActions
)(TCAdminLayout as () => JSX.Element);

const getRouting = (path: string, t: TFunction): RoutingConfig[] =>
    tcAdminRouting.map((item) => ({
        ...item,
        menuItemText: t(item.menuItemText),
        menuItemHref: `${path}/${item.menuItemHref}`,
    }));
