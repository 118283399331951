export enum LanguageEnum {
    English = 0,
    Russian = 1,
    German = 2,
    Italian = 3,
    French = 4,
    Spanish = 5,
    SpanishM = 6,
    PortugueseB = 7,
    Japanese = 8,
    ChineseS = 9,
    Korean = 10,
}
