import { CertDirection } from '../../../enums/cert-direction';
import { Test, TrainingGroup, Attempt, AttemptDetails, UserCardInfo } from './store';
import { AppThunkAction } from 'store';
import api from 'api';
import { LanguageEnum } from 'enums/language.enum';
import { LevelOfEvaluation } from 'enums/level-of-evaluation';
import { CertificationTypeFilter } from 'enums/certification-type';
import { DownloadFileService } from '../../../services/download-file.service';

export const SET_SELECTED_TEST = 'userJournal/SET_SELECTED_TEST';
export const SET_SELECTED_TRAINING_GROUP = 'userJournal/SET_SELECTED_TRAINING_GROUP';
export const SET_LOADING = 'userJournal/SET_LOADING';
export const SET_TESTS = 'userJournal/SET_TESTS';
export const CLEAR_INFO = 'userJournal/CLEAR_INFO';
export const SET_STUDY_GROUPS = 'userJournal/SET_STUDY_GROUPS';
export const SET_ATTEMPTS = 'userJournal/SET_ATTEMPTS';
export const SET_ATTEMPT_DETAILS = 'userJournal/SET_ATTEMPT_DETAILS';
export const SET_USER_CARD_INFO = 'userJournal/SET_USER_CARD_INFO';
export const SEARCH_INFO = 'userJournal/SEARCH_INFO';

interface Search {
    level: LevelOfEvaluation | undefined;
    status: CertificationTypeFilter | undefined;
    company: string | undefined;
    email: string | undefined;
}

interface ClearInfo {
    type: typeof CLEAR_INFO;
}

interface SetSelectedTest {
    type: typeof SET_SELECTED_TEST;
    selectedTestId: number;
    trainingGroups: TrainingGroup[];
}

interface SetSelectedTrainingGroup {
    type: typeof SET_SELECTED_TRAINING_GROUP;
    selectedTrainingGroupId: number;
}

interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

interface SetTests {
    type: typeof SET_TESTS;
    tests: Test[];
    selectedTest: Test;
    trainingGroups: TrainingGroup[];
    selectedTrainingGroup: TrainingGroup;
}

interface SetAttempts {
    type: typeof SET_ATTEMPTS;
    attempts: Attempt[];
}

interface SetAttemptDetails {
    type: typeof SET_ATTEMPT_DETAILS;
    attemptDetails: AttemptDetails;
}

interface SetUserCardInfo {
    type: typeof SET_USER_CARD_INFO;
    userCardInfo: UserCardInfo;
}

export type ActionTypes =
    | SetSelectedTest
    | SetSelectedTrainingGroup
    | SetLoading
    | SetTests
    | SetAttempts
    | SetAttemptDetails
    | SetUserCardInfo
    | ClearInfo;

export const actions = {
    setLoading:
        (loading: boolean): AppThunkAction<ActionTypes> =>
        (dispatch) => {
            dispatch({
                type: SET_LOADING,
                isLoading: loading,
            });
        },
    deletePassingTest:
        (passingTestId: number, values: Search): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            const { usersJournal } = getState();
            try {
                dispatch({
                    type: SET_LOADING,
                });
                await api.delete(`AdminControlling/DeletePassingTest/${passingTestId}`);
                const attempts = (
                    await api.get<Attempt[]>(`Journal/${usersJournal.selectedTrainingGroup.trainingGroupId}`, {
                        params: { ...values },
                    })
                ).data;

                dispatch({
                    type: SET_ATTEMPTS,
                    attempts,
                });
            } catch (e) {
                console.warn(e);
            } finally {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getExcel:
        (params: Search): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                const { usersJournal } = getState();
                await DownloadFileService.download(
                    `Journal/Export/${usersJournal.selectedTrainingGroup.trainingGroupId}`,
                    {
                        ...params,
                    }
                );
            } catch (error) {
                console.error(error);
            } finally {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    search:
        (values: Search): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            const { usersJournal } = getState();
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const attempts = (
                    await api.get<Attempt[]>(`Journal/${usersJournal.selectedTrainingGroup.trainingGroupId}`, {
                        params: { ...values },
                    })
                ).data;

                dispatch({
                    type: SET_ATTEMPTS,
                    attempts,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });

                dispatch({
                    type: SET_ATTEMPTS,
                    attempts: [],
                });
            }
        },
    getTests:
        (
            language: LanguageEnum | undefined,
            level: LevelOfEvaluation | undefined,
            status: CertificationTypeFilter | undefined,
            email: string | undefined,
            company: string | undefined
        ): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const { usersJournal } = getState();
                const tests = (
                    await api.get<Test[]>('Journal', { params: { directionId: CertDirection.CyberSecurity, language } })
                ).data;
                const selectedTest = tests.find((x) => x.testId === usersJournal.selectedTest.testId) || tests[0];
                const trainingGroups = (
                    await api.get<TrainingGroup[]>(`Journal/GetTrainingGroups/${selectedTest?.testId}`)
                ).data;
                const selectedTrainingGroup =
                    trainingGroups.find(
                        (x) => x.trainingGroupId === usersJournal.selectedTrainingGroup.trainingGroupId
                    ) || trainingGroups[0];

                if (selectedTrainingGroup.trainingGroupId === usersJournal.selectedTrainingGroupOption.value) {
                    const attempts = (
                        await api.get<Attempt[]>(`Journal/${selectedTrainingGroup.trainingGroupId}`, {
                            params: {
                                level,
                                status,
                                email,
                                company,
                            },
                        })
                    ).data;

                    dispatch({
                        type: SET_ATTEMPTS,
                        attempts,
                    });
                }

                dispatch({
                    type: SET_TESTS,
                    tests,
                    trainingGroups,
                    selectedTest,
                    selectedTrainingGroup,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: CLEAR_INFO,
                });
            }
        },
    setSelectedTest:
        (testId: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const trainingGroups = (await api.get<TrainingGroup[]>(`Journal/GetTrainingGroups/${testId}`)).data;

                dispatch({
                    type: SET_SELECTED_TEST,
                    selectedTestId: testId,
                    trainingGroups,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    setSelectedTrainingGroup: (selectedTrainingGroupId: number) => ({
        type: SET_SELECTED_TRAINING_GROUP,
        selectedTrainingGroupId,
    }),
    getAttempts:
        (trainingGroupId: number, values: Search): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const attempts = (
                    await api.get<Attempt[]>(`Journal/${trainingGroupId}`, {
                        params: { ...values },
                    })
                ).data;

                dispatch({
                    type: SET_ATTEMPTS,
                    attempts,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });

                dispatch({
                    type: SET_ATTEMPTS,
                    attempts: [],
                });
            }
        },
    getAttemptDetails:
        (passingTestId: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const attemptDetails = (await api.get<AttemptDetails>(`Journal/GetDetailPassingTest/${passingTestId}`))
                    .data;

                dispatch({
                    type: SET_ATTEMPT_DETAILS,
                    attemptDetails,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getUserCardInfo:
        (userId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const userCardInfo = (await api.get<UserCardInfo>(`Journal/GetUserInfo/${userId}`)).data;

                dispatch({
                    type: SET_USER_CARD_INFO,
                    userCardInfo,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
};
