import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { certDirectionOptions } from '../../constants/cert-direction-options';
import { CertDirection } from '../../enums/cert-direction';
import { Label } from '../Form';
import { Option, Select } from '../Select';

interface SelectDirectionProps {
    selectedDirectionId: CertDirection;
    onChange: (id: CertDirection) => void;
    additionalClasses?: string;
    isDisabled?: boolean;
}

const SelectDirection: FC<SelectDirectionProps> = ({
    selectedDirectionId,
    onChange,
    isDisabled,
    additionalClasses,
}) => {
    const [t, i18n] = useTranslation('cert-direction');
    const { language } = i18n;

    const [directionOptions, setDirectionOptions] = useState<Option[]>([]);

    useEffect(() => {
        setDirectionOptions(
            certDirectionOptions.map((item) => ({
                ...item,
                label: t(item.label),
            }))
        );
    }, [language, t]);

    if (!directionOptions.length) {
        return null;
    }

    return (
        <>
            <Label labelText={t('cert-direction:select-label')} labelFor="directionList" />
            <Select
                additionalClasses={additionalClasses}
                isDisabled={isDisabled}
                name="directionList"
                values={[directionOptions.find((item) => item.value === selectedDirectionId) as Option]}
                options={directionOptions}
                onChange={([select]) => onChange(select.value as CertDirection)}
            />
        </>
    );
};

export default SelectDirection;
