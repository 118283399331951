import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CertDirection } from '../../enums/cert-direction';
import { KASPERSKY_MAIL } from '../../global-constants';
import { StudyGroupTestCondition } from '../../models/study-group-test-condition';
import { Button } from '../Button';
import { Column } from '../Column';
import { Link } from '../Link';
import { Paragraph } from '../Paragraph';
import { Title } from '../Title';
import TestInstructionCyberSecurity from './TestInstructionCyberSecurity';
import TestInstructionProductLK from './TestInstructionProductLK';

import './TestInstruction.scss';

interface TestInstructionProps extends StudyGroupTestCondition {
    startTest: () => void;
    cancelTest?: () => void;
}

const TestInstruction: FC<TestInstructionProps> = (props) => {
    const { attemptLeft, startTest, cancelTest, directionId } = props;

    const [t] = useTranslation(['user-test-correct-code', 'user-test']);

    return (
        <Column columnSize="50" className="block-center code-column">
            {attemptLeft && attemptLeft > 0 ? (
                <>
                    {directionId === CertDirection.ProductLK ? (
                        <TestInstructionProductLK {...props} />
                    ) : (
                        <TestInstructionCyberSecurity {...props} />
                    )}

                    <Button type="submit" buttonClass="primary" handleClick={startTest}>
                        {t('user-test:start-button')}
                    </Button>
                    {cancelTest && (
                        <Button buttonClass="primary" handleClick={cancelTest}>
                            {t('user-test:cancel-button')}
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <Title titleText="Ooops!" />
                    <Paragraph additionalClass="lead">
                        {t('attempts-over')}
                        <Link linkHref={`mailto:${KASPERSKY_MAIL}`} additionalClass="success">
                            {KASPERSKY_MAIL}
                        </Link>
                    </Paragraph>
                    {cancelTest && (
                        <Button type="submit" buttonClass="primary" handleClick={cancelTest}>
                            {t('user-test:attempts-over-button')}
                        </Button>
                    )}
                </>
            )}
        </Column>
    );
};

export default TestInstruction;
