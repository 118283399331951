import React, { useEffect, useState } from 'react';
import { ReportQuestion } from './models/ReportQuestion';
import { Button, Column, Container, Label, Option, Select } from 'components';
import SelectLanguage from 'components/SelectLanguage/SelectLanguage';
import { LanguageEnum } from 'enums/language.enum';
import SelectDirection from 'components/SelectDirection/SelectDirection';
import { CertDirection } from 'enums/cert-direction';
import { TestShort } from 'models/test/test-short';
import { CategoryShortModel } from 'models/category.short.model';
import Pagination from 'components/Pagination/Pagination';
import { TestService } from 'services/test.service';
import { useAlert } from 'react-alert';
import { CategoryService } from 'services/category.service';
import { DownloadFileService } from 'services/download-file.service';
import { ReportServices } from './services/report.services';
import { actionCreators } from './store/actions';
import { QuestionsState } from './store/store';
import DOMPurify from 'isomorphic-dompurify';

import './QuestionStatistic.scss';
import { ApplicationState } from 'store';
import { connectComponent, withLoading } from 'utils';

type QuestionsListProps = typeof actionCreators & QuestionsState;

const QuestionStatistic = (props: QuestionsListProps) => {
    const { setLoading } = props;
    const alert = useAlert();
    const testIsNotSelectedList = { label: 'тест не выбран', value: 0 };
    const testIsNotSelected = { title: 'тест не выбран', id: 0 } as TestShort;
    const categoryIsNotSelectedList = { id: 0, name: 'категория не выбрана' };
    const categoryIsNotSelected = { value: 0, label: 'категория не выбрана' };

    const pageSize = 20;
    const [page, setPage] = useState(0);
    const [data, setData] = useState<
        {
            item: ReportQuestion;
            idx: number;
        }[]
    >([]);

    const pagginator = () => data.filter((_, index) => index >= page * pageSize && index < page * pageSize + pageSize);

    const [selectedLanguage, setSelectedLanguage] = useState<LanguageEnum>(LanguageEnum.Russian);
    const [selectedDirectionId, setDirection] = useState<CertDirection>(CertDirection.ProductLK);

    const [tests, setTests] = useState<TestShort[]>([testIsNotSelected]);
    const [selectedTest, setSelectedTest] = useState<Option>(testIsNotSelectedList);

    const [categories, setCategories] = useState<CategoryShortModel[]>([categoryIsNotSelectedList]);
    const [selectedCategory, setSelectedCategory] = useState<Option>(categoryIsNotSelected);

    const getTests = () => {
        TestService.getList({
            directionId: selectedDirectionId,
            language: selectedLanguage,
        }).then(
            (d) => {
                setTests([testIsNotSelected, ...d.data]);
            },
            () => {
                alert.error('backend error', { timeout: 5000 });
            }
        );
    };

    useEffect(() => {
        if (window.scrollY > 400) window.scrollTo(0, 300);
    }, [page]);

    useEffect(() => {
        setTests([testIsNotSelected]);
        setSelectedTest(testIsNotSelectedList);

        setCategories([categoryIsNotSelectedList]);
        setSelectedCategory(categoryIsNotSelected);

        getTests();
    }, [selectedLanguage, selectedDirectionId]);

    useEffect(() => {
        setData([]);
        setSelectedCategory(categoryIsNotSelected);
        if (selectedTest.value !== 0) {
            CategoryService.getByTestId(selectedTest.value as number).then(
                (d) => {
                    setCategories([categoryIsNotSelectedList, ...d.data]);
                },
                () => {
                    alert.error('backend error', { timeout: 5000 });
                }
            );
        }
    }, [selectedTest]);

    useEffect(() => {
        setData([]);
        setPage(0);
    }, [selectedCategory]);

    const getQuestions = () => {
        if (selectedTest.value === 0) {
            alert.error('тест не выбран');
            return;
        }

        setLoading();
        ReportServices.getQuestions(
            selectedTest.value as number,
            selectedCategory.value === 0 ? null : (selectedCategory.value as number)
        )
            .then(
                (d) => {
                    setData(d.data.map((d, idx) => ({ item: d, idx: idx + 1 })));
                },
                () => {
                    alert.error('backend error', { timeout: 5000 });
                }
            )
            .finally(() => setLoading(false));
    };
    const getExcelQuestions = () => {
        if (selectedTest.value === 0) {
            alert.error('тест не выбран');
            return;
        }
        setLoading();
        DownloadFileService.download(
            ReportServices.getRequest(
                'questionsExcel',
                selectedTest.value as number,
                selectedCategory.value === 0 ? null : (selectedCategory.value as number)
            )
        ).finally(() => setLoading(false));
    };

    return (
        <Container>
            <Column columnSize="50" className="p-right-space-3x">
                <SelectDirection selectedDirectionId={selectedDirectionId} onChange={setDirection} />
                <SelectLanguage selectedLanguage={selectedLanguage} onChange={setSelectedLanguage} />
                <Column columnSize="100" className="p-right-space-3x">
                    <Label labelText="Выберите тест" labelFor="" />
                    <Select
                        options={tests.map((t) => ({ label: t.title, value: t.id }))}
                        onChange={(v) => setSelectedTest(v[0])}
                        values={[selectedTest]}
                    />
                </Column>
                <Column columnSize="100" className="p-right-space-3x">
                    <Label labelText="Выберите категорию" labelFor="" />
                    <Select
                        options={categories.map((t) => ({ label: t.name, value: t.id }))}
                        onChange={(v) => setSelectedCategory(v[0])}
                        values={[selectedCategory]}
                    />
                </Column>
                <Column columnSize="100" className="p-right-space-3x top-space-10x">
                    <Button buttonClass="primary" handleClick={getQuestions}>
                        Поиск
                    </Button>
                    <Button buttonClass="primary" handleClick={getExcelQuestions}>
                        Excel
                    </Button>
                </Column>
            </Column>
            {pagginator().length !== 0 && (
                <Column columnSize="100" className="p-right-space-3x top-space-10x question-list-statistic">
                    <ul className="question_list">
                        {pagginator().map((d) => (
                            <li key={`question_${d.item.id}`}>
                                <span className="column-data">
                                    {d.idx}. &nbsp;
                                    <span
                                        className="column-text"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(d.item.text),
                                        }}
                                    />
                                    <span className="statistic">{`${d.item.percentOfRightAnswer}% / ${d.item.countOfShowed}`}</span>
                                </span>
                                {d.item.reportAnswers && (
                                    <ul>
                                        {d.item.reportAnswers.map((a) => (
                                            <li key={`answer_${a.id}`}>
                                                <span className="column-data">
                                                    <span className={`column-text ${a.isRight && 'is-right'}`}>
                                                        {a.text}
                                                    </span>
                                                    <span className="statistic">{a.selectedCount}</span>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </Column>
            )}

            {data.length !== 0 && (
                <Column columnSize="100" className="p-right-space-3x top-space-10x">
                    <Pagination page={page} size={pageSize} total={data.length} handleChange={(d) => setPage(d)} />
                </Column>
            )}
        </Container>
    );
};

const mapStateToProps = ({ questions }: ApplicationState) => ({ ...questions });

export default connectComponent({
    mapStateToProps,
    actions: actionCreators,
    component: withLoading(QuestionStatistic),
});
