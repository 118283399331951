export enum MultilanguageLanguageEnum {
    En = 0,
    Ru = 1,
    De = 2,
    It = 3,
    Fr = 4,
    Es = 5,
    EsM = 6,
    Pt = 7,
    Ja = 8,
    Zh = 9,
    Ko = 10,
}
