export enum CertificationEnum {
    CyberSecurity = 1,
    Professional = 2,
    SystemEngineer = 3,
    SalesSpecialist = 4,
    PartnerOnBoardingAccomplishment = 5,
    SolutionSpecialist = 6,
    CertifiedSalesEngineer = 7,
    AccomplishmentSales = 8,
    AccomplishmentTechnical = 9,
}
