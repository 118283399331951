import React, { PropsWithChildren, useRef, useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';

import { Icon } from 'components';
import { expandingAnimation } from './expanding-animation';

export interface AccordionProps {
    title?: string;
    isExpanded: boolean;
    handleExpand: () => void;
    additionalClasses?: string;
}

export const Accordion = (props: PropsWithChildren<AccordionProps>) => {
    const { isExpanded, title, children, handleExpand, additionalClasses } = props;

    const ref = useRef(null);
    useEffect(() => {
        expandingAnimation(ref, isExpanded);
    }, [isExpanded]);

    return (
        <div className={`accordion ${additionalClasses ? additionalClasses : ''}`}>
            <div className="accordion-header" onClick={handleExpand}>
                <div className="d-flex align-items-center">
                    <div className={`${isExpanded ? 'showed' : 'hided'} d-flex align-items-center`}>
                        <Icon iconName="icon-expand" />
                    </div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(title || ''),
                        }}
                    />
                </div>
            </div>
            <div ref={ref} className={`accordion-content ${isExpanded ? 'expanded' : ''}`}>
                {children}
            </div>
        </div>
    );
};
