import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import { ValidationMessage } from '../ValidationMessage';

interface AutoSizeTextareaProps {
    id: string;
    name?: string;
    label?: string;
    errorMessage?: string;
    value?: string;
    defaultValue?: string | number;
    inputRef?: React.Ref<HTMLTextAreaElement>;
    handleChange?(event: React.ChangeEvent<HTMLTextAreaElement>): void;
}

export const AutoSizeTextarea = (props: AutoSizeTextareaProps) => {
    const { id, name, label, errorMessage, value, defaultValue, inputRef, handleChange } = props;
    const classes = errorMessage ? 'form-auto-size-text-area with-errors' : 'form-auto-size-text-area';

    return (
        <fieldset className="form-group">
            {label && (
                <label htmlFor={id} className="form-label">
                    {label}
                </label>
            )}
            <TextareaAutosize
                id={id}
                name={name}
                ref={inputRef}
                className={classes}
                value={value}
                defaultValue={defaultValue}
                onChange={handleChange}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            />
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </fieldset>
    );
};
