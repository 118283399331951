import { Action, Reducer } from 'redux';
import { CertDirection } from '../../../enums/cert-direction';
import { Category } from '../../../models/category';
import { defaultAnswers } from '../answers';

import {
    ADD_ITEM_QUESTION_LIST,
    CLEAR_QUESTION_MESSAGES,
    CLOSE_EDIT_QUESTION_FORM,
    EDIT_QUESTION_FORM,
    EDIT_QUISTION,
    GET_ALL_CATEGORY_QUESTIONS,
    GET_QUESTION,
    HIDE_ALL_OPTIONS,
    LOAD_CATEGORIES,
    QuestionsActionTypes,
    SET_CATEGORY,
    SET_DIRECTION,
    SET_LOADING,
    SET_QUESTION_ERROR,
    SET_QUESTION_SUCCESS,
    SHOW_ALL_OPTIONS,
    SHOW_HIDE_OPTIONS,
    UPDATE_QUESTION_LIST,
    UPDATE_TEMP_DATA,
} from './actions';

export interface QuestionsState {
    isLoading: boolean;
    questions: Question[];
    categories: Category[];
    selectedCategoryId?: number;
    selectedDirectionId: CertDirection;
    questionError: string;
    questionSuccess: string;
    questionsToggle: QuestionsToggle[];
    isAllExpanded: boolean;
}

export interface QuestionsToggle {
    id?: number;
    isToggle: boolean;
}

export interface QuestionOption {
    id?: number;
    text?: string;
    isRight: boolean;
}

export interface Question {
    id?: number;
    title?: string;
    categoryId?: number;
    directionId?: CertDirection;
    isExpanded?: boolean;
    isEdit?: Question;
    questionOptions: QuestionOption[];
}

export interface QuestionOne {
    id?: number;
    title?: string;
    questionOptions: QuestionOption[];
}

export interface AddQuestion {
    directionId: CertDirection;
    categoryId?: number;
    questions: Array<Question>;
}

export interface EditOneQuestion {
    categoryId: number;
    question: QuestionOne;
}

const initialState: Readonly<QuestionsState> = {
    isLoading: false,
    questions: [],
    categories: [],
    questionError: '',
    questionSuccess: '',
    questionsToggle: [] as QuestionsToggle[],
    isAllExpanded: false,
    selectedDirectionId: CertDirection.CyberSecurity,
};

export const reducer: Reducer<QuestionsState> = (
    state: QuestionsState = initialState,
    incomingAction: Action
): QuestionsState => {
    const action = incomingAction as QuestionsActionTypes;
    switch (action.type) {
        case EDIT_QUESTION_FORM: {
            const index = state.questions.findIndex((r) => r.id === action.questionId);
            if (index >= 0) {
                state.questions[index].isEdit = { ...state.questions[index] };
            }
            return {
                ...state,
                questions: [...state.questions],
            };
        }
        case UPDATE_TEMP_DATA:
            return {
                ...state,
                questions: state.questions.map((val, index) =>
                    index === action.index ? { ...val, isEdit: { ...action.question } } : val
                ),
            };
        case CLOSE_EDIT_QUESTION_FORM: {
            if (action.questionId) {
                const index = state.questions.findIndex((r) => r.id === action.questionId);
                if (index >= 0) {
                    state.questions[index].isEdit = undefined;
                    return {
                        ...state,
                        questions: [...state.questions],
                    };
                }
            }

            return {
                ...state,
                questions: [...state.questions.slice(0, action.index), ...state.questions.slice(action.index + 1)],
            };
        }
        case GET_ALL_CATEGORY_QUESTIONS: {
            const questions = action.questions.map((item: Question) => ({
                isExpanded: false,
                isEdit: undefined,
                ...item,
            }));
            return {
                ...state,
                isLoading: false,
                questions,
            };
        }
        case GET_QUESTION:
            return {
                ...state,
                isLoading: false,
            };
        case LOAD_CATEGORIES: {
            let selectedCategoryId = undefined;

            if (action.categories.length) {
                const selectedCategory =
                    action.categories.find((item) => item.id === state.selectedCategoryId) || action.categories[0];
                selectedCategoryId = selectedCategory.id;
            }

            return {
                ...state,
                categories: action.categories,
                selectedCategoryId,
                isLoading: false,
            };
        }
        case SET_CATEGORY:
            return {
                ...state,
                selectedCategoryId: action.categoryId,
            };
        case SET_DIRECTION:
            return {
                ...state,
                selectedDirectionId: action.directionId,
            };
        case SET_QUESTION_SUCCESS:
            return {
                ...state,
                questionSuccess: action.message,
                isLoading: false,
            };
        case SET_QUESTION_ERROR:
            return {
                ...state,
                questionError: action.error,
                isLoading: false,
            };
        case EDIT_QUISTION:
            return {
                ...state,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.value ?? true,
            };
        case SHOW_HIDE_OPTIONS: {
            const questions = [...state.questions];
            const question = questions.find((x) => x.id === action.id);
            if (question) {
                question.isExpanded = !question.isExpanded;
                const isAllExpanded = questions.every((x) => x.isExpanded);
                return {
                    ...state,
                    isAllExpanded,
                    questions,
                };
            }
            return state;
        }
        case SHOW_ALL_OPTIONS: {
            const questionsToShow = action.expandedQuestions.map((question) => ({ ...question, isExpanded: true }));
            return {
                ...state,
                isAllExpanded: true,
                questions: questionsToShow,
            };
        }
        case HIDE_ALL_OPTIONS: {
            const questionsToHide = action.expandedQuestions.map((question) => ({ ...question, isExpanded: false }));
            return {
                ...state,
                isAllExpanded: false,
                questions: questionsToHide,
            };
        }
        case UPDATE_QUESTION_LIST: {
            const newQuestion = action.question;
            newQuestion.isExpanded = true;

            return {
                ...state,
                isLoading: false,
                questions: state.questions.map((item, index) => (index === action.index ? action.question : item)),
            };
        }
        case CLEAR_QUESTION_MESSAGES:
            return { ...state, questionSuccess: '', questionError: '' };
        case ADD_ITEM_QUESTION_LIST: {
            const newItem = {
                id: undefined,
                title: '',
                isEdit: undefined as undefined | Question,
                questionOptions: [...defaultAnswers],
            };
            newItem.isEdit = { ...newItem };
            state.questions.unshift(newItem);

            return {
                ...state,
                questions: [...state.questions],
            };
        }
        default:
            return state;
    }
};
