import { Store, bindActionCreators } from 'redux';
import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import { actions as adminActions } from 'authentication/AdminAuthenticationStore';
import { actions as tcAdminActions } from 'authentication/TCAdminAuthenticationStore';
import { actions as userActions } from 'authentication/UserAuthenticationStore';

export const HttpStatuses = {
    Status200: 200,
    Status307: 307,
    Status400: 400,
    Status401: 401,
    Status403: 403,
    Status500: 500,
    Status503: 503,
    Status599: 599,
    Status404: 404,
};

const axiosConfig: AxiosRequestConfig = {
    baseURL: '/api/',
    headers: {
        'Content-Type': 'application/json',
    },
};

const api: AxiosInstance = axios.create(axiosConfig);

const responseErrorHandler = (error: AxiosError, clearUser: () => void): Promise<AxiosError> => {
    if (error.response) {
        switch (error.response.status) {
            case HttpStatuses.Status401: {
                clearUser();
                return Promise.reject(error);
            }
            case HttpStatuses.Status404: {
                return Promise.reject(error);
            }
            default:
                return Promise.reject(error);
        }
    }

    return Promise.reject(error);
};

export const configureApi = (store: Store): void => {
    const { clearAdmin, clearUser, clearTcAdmin } = bindActionCreators(
        { ...adminActions, ...userActions, ...tcAdminActions },
        store.dispatch
    );

    api.interceptors.response.use(
        (response) => response,
        (error) =>
            responseErrorHandler(error, () => {
                clearAdmin();
                clearUser();
                clearTcAdmin();
            })
    );
};

export default api;
