import React from 'react';
import { BaseUser } from '../../models/base-user';

import { LanguagePicker } from '../SidebarMenu';
import { Person } from '../Person';
import { Icon } from '../Icon';
import { useHistory } from 'react-router-dom';
import { LOGIN_PATH } from 'global-constants';

import Logo from '../../images/kas-logo.svg';

import './HeaderLanding.scss';

interface HeaderLandingProps {
    user?: BaseUser;
    handleLogout: () => void;
}

export const HeaderLanding = (props: HeaderLandingProps) => {
    const { handleLogout, user } = props;
    const { push } = useHistory();
    return (
        <header className="header-landing">
            <div className="header-landing__wrap landing-container">
                <div className="header-landing__logo">
                    <img src={Logo} alt="kaspersky logo" />
                </div>

                <div className="header-landing__login-and-lang">
                    <div className="header-landing__login">
                        {user ? (
                            <Person handleLogout={handleLogout} handleClick={() => push('/info')} user={user} />
                        ) : (
                            <a href={LOGIN_PATH}>
                                <Icon iconName="no-logged-in" />
                            </a>
                        )}
                    </div>

                    <LanguagePicker portal />
                </div>
            </div>
        </header>
    );
};
