import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    KASPERSKY_FACEBOOK,
    KASPERSKY_MAIL,
    KASPERSKY_TWITTER,
    KASPERSKY_TWITTER_RU,
    PRIVACY_STATEMENT_FULL_PATH,
    TERMS_OF_USAGE_FULL_URL,
} from 'global-constants';
import Logo from '../../images/kas-logo.svg';
import { LanguageKeys } from '../../models/language/language';
import { Icon } from '../Icon';

export const FooterLanding = () => {
    const [t, i18n] = useTranslation();
    const currentLanguage = i18n.language as LanguageKeys;

    return (
        <footer className="footer-landing">
            <div className="landing-container footer-landing__wrap">
                <div className="footer-landing__logo">
                    <img src={Logo} alt="kaspersky logo" />
                </div>
                <div className="footer-landing__info">
                    <div className="footer-landing__copy">{t('footer-kaspersky')}</div>
                    <div className="footer-landing__menu">
                        <Link
                            className="footer-landing-menu__item"
                            to={`/${PRIVACY_STATEMENT_FULL_PATH}`}
                            target="_blank"
                        >
                            {t('footer-policy')}
                        </Link>
                        <Link className="footer-landing-menu__item" to={`/${TERMS_OF_USAGE_FULL_URL}`} target="_blank">
                            {t('footer-tou')}
                        </Link>
                        <div className="footer-landing-menu__item" onClick={() => window.Cookiebot.renew()}>
                            {t('footer-cookies')}
                        </div>
                    </div>
                </div>
                <div className="footer-social">
                    <div className="footer-social__text">{t('footer-contact')}</div>
                    {currentLanguage !== 'ru' && (
                        <a className="footer-social__item" href={KASPERSKY_FACEBOOK} target="_blank" rel="noreferrer">
                            <Icon iconName="social-facebook" />
                        </a>
                    )}
                    <a
                        className="footer-social__item"
                        href={currentLanguage === 'ru' ? KASPERSKY_TWITTER_RU : KASPERSKY_TWITTER}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Icon iconName="social-twitter" />
                    </a>
                    <a
                        className="footer-social__item"
                        href={`mailto:${KASPERSKY_MAIL}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Icon iconName="social-email" />
                    </a>
                </div>
            </div>
        </footer>
    );
};
