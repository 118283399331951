import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { Container, Title, Button, Paragraph, Page } from 'components';
import { ApplicationState } from 'store';
import { actions as userAuthenticationActions, UserAuthenticationState } from 'authentication/UserAuthenticationStore';
import { actions } from '../store/actions';
import { useTheme, theme, withLoading } from 'utils';
import EditCertificateData from './EditCertificateData';
import { UserTestState } from 'pages/user-test/store';

type ConfirmCertificateDataProps = UserAuthenticationState &
    UserTestState &
    typeof userAuthenticationActions &
    typeof actions;

const ConfirmCertificateData = (props: ConfirmCertificateDataProps) => {
    const { user, setCertificateName, currentPassingTest } = props;
    const [t] = useTranslation('confirm-certificate-data');
    const { push } = useHistory();
    const { state, pathname } = useLocation<{
        passingTestId: number | undefined;
    }>();
    const [userName, setUserName] = useState<string | undefined>();
    const [isEdit, setIsEdit] = useState(false);
    const passingTestId = state?.passingTestId;

    useTheme(theme.light);
    useTheme(theme.noGlobalNav);

    useEffect(() => {
        setUserName(
            `${user?.name ?? ''} ${user?.middleName ?? ''} ${user?.lastName ?? ''}`.trim().replace(/ {2,}/gm, ' ')
        );
    }, [user]);

    useEffect(() => {
        if (!passingTestId) {
            push('/test');
        }
    }, [passingTestId, push]);

    const handleGetCertificate = () => {
        if (userName && passingTestId) {
            setCertificateName(passingTestId, userName);
            window.history.replaceState({}, '');
            push(pathname.replace('confirm-data', passingTestId.toString()));
        }
    };

    useEffect(() => {
        if (userName && passingTestId && currentPassingTest && !currentPassingTest.enableEditName) {
            handleGetCertificate();
        }
    }, [userName, passingTestId]);

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <div className="confirm-certificate-data">
                    {isEdit || !user?.name ? (
                        <EditCertificateData setUserName={setUserName} setIsEdit={setIsEdit} userName={userName} />
                    ) : (
                        <>
                            <Title titleText={t('title')} />
                            <Paragraph additionalClass="lead">{t('message')}</Paragraph>
                            <Paragraph additionalClass="lead">{userName}</Paragraph>
                            <div>
                                <Button buttonClass="primary" handleClick={handleGetCertificate}>
                                    {t('success-button')}
                                </Button>
                                <Button buttonClass="secondary" handleClick={() => setIsEdit(true)}>
                                    {t('change-button')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Container>
        </Page>
    );
};

export default connect(
    ({ userAuthentication, userTest }: ApplicationState) => ({
        ...userAuthentication,
        ...userTest,
    }),
    {
        ...userAuthenticationActions,
        ...actions,
    }
)(withLoading(ConfirmCertificateData) as () => JSX.Element);
