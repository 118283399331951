import api from '../api';
import { StudentStudyCenterAgreements } from '../models/student-study-center/student-study-center-agreements';
import { UserStudentStudyCenter } from '../models/student-study-center/user-student-study-center';
import { StudyGroupTestCondition, StudyGroupTestConditionPassed } from '../models/study-group-test-condition';

export class UserTestLinkService {
    static getUser = (tempLinkId: string) =>
        api.get<UserStudentStudyCenter>(`StudentStudyCenter/getInfo/${tempLinkId}`);

    static approveAgreements = (agr: StudentStudyCenterAgreements) =>
        api.post<void>(`StudentStudyCenter/approveAgreement`, agr);

    static getConditions = (tempLinkId: string) =>
        api.get<StudyGroupTestCondition | StudyGroupTestConditionPassed>(
            `StudentStudyCenter/getConditions/${tempLinkId}`
        );

    static startTest = (tempLinkId: string) => api.post<void>(`StudentStudyCenter/start`, { tempLinkId });
}
