import React, { FC, useState } from 'react';
import { useUrlQueryParamsForGrid } from 'hooks/useUrlQueryParamsForGrid';
import { ResponsePagination } from 'models/response-pagination';
import { dateTimeFormat } from '../../constants/date-format';
import { AddArea, TestArea, UpdateArea } from './models/AreaModels';
import { addArea, deleteArea, getAreas, updateArea } from './services/TestAreaService';
import {
    Button,
    Column,
    Container,
    EmptyListWarning,
    Grid,
    Icon,
    Loader,
    Row,
    TextInput,
    Title,
} from '../../components';
import moment from 'moment';
import { DownloadFileService } from 'services/download-file.service';
import { AddModal } from './AddModal';

const TestAreaList: FC = () => {
    const [areas, setAreas] = useState<ResponsePagination<TestArea>>({ count: 0, items: [] });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [updateModel, setUpdateModel] = useState<TestArea | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const getAreasFunc = (page: number, size: number, str?: string) => {
        setLoading(true);
        getAreas(page, size, str)
            .then((data) => {
                setAreas(data.data);

                if (data.data.items?.length === 0 && page !== 0) {
                    setPage(page - 1);
                }
            })
            .finally(() => setLoading(false));
    };
    const {
        pagination,
        queryState: [search, setSearch],
        setPage,
    } = useUrlQueryParamsForGrid({
        total: areas.count,
        loadFn: ({ page, size, query }) => getAreasFunc(page, size, query),
    });
    const deleteClick = (id: string) => {
        if (window.confirm('Вы уверены?')) {
            setLoading(true);
            deleteArea(id)
                .then(() => {
                    getAreasFunc(pagination.page, pagination.size, search);
                })
                .finally(() => setLoading(false));
        }
    };
    const getGridData = (): Row[] =>
        areas.items.map((item) => ({
            ...item,
            creationDate: moment(item.creationDate).format(dateTimeFormat),
            updateActions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => handleClickUpdate(item)}>
                        <Icon iconName="edit" />
                    </Button>
                ),
            },
            actions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => deleteClick(item.id)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
            },
        }));
    const getExcel = () => {
        DownloadFileService.download('TestArea/xls');
    };
    const handleClickUpdate = (model: TestArea) => {
        setUpdateModel(model);
        setShowModal(true);
    };
    const handleClickAdd = () => {
        setUpdateModel(undefined);
        setShowModal(true);
    };
    const updateHandle = (model: UpdateArea) => {
        setLoading(true);
        updateArea(model)
            .then((data) => {
                const index = areas.items.findIndex((r) => r.id === model.id);
                if (index < 0) return;

                areas.items[index] = data.data;

                setAreas({
                    ...areas,
                });
            })
            .finally(() => setLoading(false));
    };
    const addHandle = (model: AddArea) => {
        setLoading(true);
        addArea(model)
            .then((data) => {
                setAreas({
                    count: areas.count + 1,
                    items: [data.data, ...areas.items],
                });
            })
            .finally(() => setLoading(false));
    };
    return (
        <>
            {loading && <Loader isBlock />}
            <Container>
                <Column columnSize="100">
                    <Title titleText="Области применения" />
                </Column>
                <Column columnSize="60">
                    <TextInput
                        id="search-field"
                        label="Название"
                        value={search}
                        onChangeHandler={(e) => setSearch(e.target.value)}
                    />
                </Column>
                <Column columnSize="20" className="text-right">
                    <Button buttonClass="primary" handleClick={handleClickAdd}>
                        Создать
                    </Button>
                </Column>
                <Column columnSize="20" className="text-right">
                    <Button buttonClass="primary" handleClick={getExcel}>
                        Excel
                    </Button>
                </Column>
                <Column columnSize="100">
                    {areas.items?.length ? (
                        <Grid rows={getGridData()} columns={columns} pagination={pagination} />
                    ) : (
                        <EmptyListWarning />
                    )}
                </Column>
                {showModal && (
                    <AddModal
                        closeModal={() => setShowModal(false)}
                        updateModel={updateModel}
                        updateFunc={updateHandle}
                        addFunc={addHandle}
                    />
                )}
            </Container>
        </>
    );
};

export default TestAreaList;

const columns = {
    id: {
        value: 'id',
        columnSize: '20',
    },
    name: {
        value: 'Название',
        columnSize: '40',
    },
    creationDate: {
        value: 'Дата создания',
        columnSize: '20',
    },
    updateActions: {
        value: '',
        columnSize: '10',
    },
    actions: {
        value: '',
        columnSize: '10',
    },
};
