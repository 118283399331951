import { Action, Reducer } from 'redux';
import { ResponsePagination } from '../../../models/response-pagination';
import { StudyCenterUser } from '../../../models/study-center-users/study-center-user';
import { ActionTypes, DELETE_USER, EDIT_USER_IS_PARTNER, SET_LOADING, SET_STUDY_CENTERS_USERS } from './actions';

export interface UsersState {
    users: ResponsePagination<StudyCenterUser>;
    isLoading: boolean;
}

const initialState: Readonly<UsersState> = {
    isLoading: true,
    users: {
        items: [],
        count: 0,
    },
};

export const reducer: Reducer<UsersState> = (state: UsersState = initialState, incomingAction: Action): UsersState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case DELETE_USER:
            return {
                ...state,
                users: {
                    count: --state.users.count,
                    items: state.users.items.filter((u) => u.id !== action.userId),
                },
                isLoading: false,
            };
        case SET_STUDY_CENTERS_USERS:
            return {
                ...state,
                users: action.studyCentersUsers,
            };
        case EDIT_USER_IS_PARTNER: {
            const newUsers = [...state.users.items];
            const userIndex = state.users.items.findIndex((user) => user.id === action.userId);
            const newUser: StudyCenterUser = { ...newUsers[userIndex], isPartner: action.isPartner };
            newUsers.splice(userIndex, 1, newUser);

            return {
                ...state,
                users: {
                    items: newUsers,
                    count: state.users.count,
                },
                isLoading: false,
            };
        }

        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};
