import React, { FC } from 'react';

import { Button, Container, Icon, Page } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { theme, useTheme } from 'utils';
import { layoutRoutingUrls } from '../../constants/layout-routing-urls';
import { LOGOUT_PATH } from 'global-constants';

const UnknownError: FC = () => {
    const { push } = useHistory();
    const { path } = useRouteMatch();
    const [t] = useTranslation(['translation', 'notifications', 'study-center']);
    useTheme(theme.pageNotFound);

    const goToMainPage = () => {
        const route = Object.values(layoutRoutingUrls).find((item) => ~path.indexOf(item));

        push(route || layoutRoutingUrls.user);
    };

    const logout = () => {
        document.location.href = LOGOUT_PATH;
    };

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <div className="unknown_error-block">
                    <div className="unknown_error-svg">
                        <Icon iconName="not-found-svg" />
                    </div>
                    <h1 className="unknown_error-header">
                        <span className="user-is-blocked">
                            Unknown error, we try to fix this problem, please try again later
                        </span>
                    </h1>
                    <div className="activates-button">
                        <Button buttonClass="primary" handleClick={goToMainPage}>
                            {t('translation:go-to-main')}
                        </Button>
                        <Button buttonClass="primary" handleClick={logout}>
                            {t('study-center:auth-study-center-logout-btn')}
                        </Button>
                    </div>
                </div>
            </Container>
        </Page>
    );
};

export default UnknownError;
