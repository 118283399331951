import React from 'react';
import ReactModal from 'react-modal';

import { useWindowWidth, sizes } from 'utils';
import { Column } from '../Column';
import { Icon } from '../Icon';
import { DetailsGrid } from '../DetailsGrid';
import { DetailsModalAccordion } from './DetailsModalAccordion';
import { useTranslation } from 'react-i18next';

export interface CategoryResult {
    categoryName: string;
    quantityOfCorrectAnswers: number;
    quantityOfQuestions: number;
}

export interface Details {
    totalQuantityOfCorrectAnswers: number;
    totalQuantityOfQuestions: number;
    resultByCategories: CategoryResult[];
}

interface DetailsModalProps {
    isOpen: boolean;
    title: string;
    handleCloseClick: () => void;
    details: Details;
    theme?: 'standard' | 'modified' | 'modified-darkened';
    additionalClasses?: string;
}

ReactModal.setAppElement('body');

export const DetailsModal = (props: DetailsModalProps) => {
    const { isOpen, handleCloseClick, title, details, theme, additionalClasses } = props;
    const [t] = useTranslation(['attempts-list']);
    const width = useWindowWidth();
    const modalTheme = theme || 'standard';

    return (
        <Column columnSize="100">
            <ReactModal
                isOpen={isOpen}
                onRequestClose={handleCloseClick}
                className={`details-modal react-modal react-modal-${modalTheme} ${
                    additionalClasses ? additionalClasses : ''
                }`}
                overlayClassName={`react-modal-overlay react-modal-overlay-${modalTheme}`}
            >
                <div onClick={handleCloseClick} className={`details-modal-close ${modalTheme}`}>
                    <Icon iconName="close" />
                </div>
                <Column columnSize="100">
                    <div className={`details-modal-title ${modalTheme}`}>{title}</div>
                </Column>
                {width > sizes.sm ? (
                    <DetailsGrid {...details} />
                ) : (
                    <div className="accordions details-modal-accordions">
                        {details.resultByCategories.map((x, index) => (
                            <DetailsModalAccordion
                                key={`${x.categoryName}-${index}`}
                                categoryName={x.categoryName}
                                quantityOfCorrectAnswers={x.quantityOfCorrectAnswers}
                                quantityOfQuestions={x.quantityOfQuestions}
                            />
                        ))}
                        <DetailsModalAccordion
                            categoryName={t('total')}
                            quantityOfCorrectAnswers={details.totalQuantityOfCorrectAnswers}
                            quantityOfQuestions={details.totalQuantityOfQuestions}
                        />
                    </div>
                )}
            </ReactModal>
        </Column>
    );
};
