import React, { FC } from 'react';
import { Checkbox } from '../Checkbox';
import {
    PRIVACY_STATEMENT_FULL_PATH,
    PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH,
    TERMS_OF_USE_FULL_PATH,
} from '../../global-constants';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { language } from '../../constants/language';
import { LanguageKeys } from '../../models/language/language';
import DOMPurify from 'isomorphic-dompurify';

interface AgreementsFormTouRuProps {
    privacyStatementPath: string;
}

const AgreementsFormTou: FC<AgreementsFormTouRuProps> = ({ privacyStatementPath }) => {
    const { register, errors } = useFormContext();
    const { t, i18n } = useTranslation('info');
    const currentLanguage = i18n.language as LanguageKeys;

    return (
        <>
            <div className="confirm-term-of-use">
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            t('save-button-press', {
                                touLink: `/${TERMS_OF_USE_FULL_PATH}`,
                            })
                        ),
                    }}
                />
            </div>
            <div className="confirm-term-of-use">
                <Checkbox
                    additionalClasses={errors?.personalAgreement && 'error'}
                    id="personalAgreement"
                    name="personalAgreement"
                    inputRef={register({
                        required: {
                            value: true,
                            message: '',
                        },
                    })}
                />
                <input
                    name="personalCheckBoxText"
                    type="hidden"
                    ref={register}
                    value={`${t('data-consent-agreement')}`}
                />

                <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                currentLanguage === language.ru.key
                                    ? t('data-consent-agreement-with-link', {
                                          dataConsentLink: `/${privacyStatementPath}`,
                                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                      })
                                    : t('data-consent-agreement', {
                                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                      })
                            ),
                        }}
                    />
                    *
                </div>
            </div>
            <div className="confirm-term-of-use">
                <Checkbox
                    additionalClasses={errors?.marketingAgreement && 'error'}
                    id="marketingAgreement"
                    name="marketingAgreement"
                    inputRef={register({
                        required: {
                            value: false,
                            message: '',
                        },
                    })}
                />
                <input
                    name="marketingCheckBoxText"
                    type="hidden"
                    ref={register}
                    value={`${t('marketing-consent-agreement')}`}
                />
                <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                currentLanguage === language.ru.key
                                    ? t('marketing-consent-agreement-with-link', {
                                          dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                      })
                                    : t('marketing-consent-agreement', {
                                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                      })
                            ),
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default AgreementsFormTou;
