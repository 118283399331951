import { Reducer, Action } from 'redux';

import { SET_LOADING, LOAD_ATTEMPTS, ActionTypes, GET_ATTEMPT_DETAILS } from './actions';

export interface CategoryResult {
    categoryName: string;
    quantityOfCorrectAnswers: number;
    quantityOfQuestions: number;
}

export interface AttemptDetails {
    totalQuantityOfCorrectAnswers: number;
    totalQuantityOfQuestions: number;
    resultByCategories: CategoryResult[];
}

export interface ServerAttempt {
    passingTestId: number;
    testTitle: string;
    attemptNo: number;
    quantityPoints: number;
    maxQuantityPoints: number;
    dateStart: string;
    spentSeconds: string | number;
    levelOfEvaluation: number;
    descriptionOfEvaluation: string;
    certificateLink: string;
    isGiveCertificate: boolean;
}

export interface Attempt extends ServerAttempt {
    isFailure: boolean;
}

export interface AttemptsListState {
    isLoading: boolean;
    attempts: Attempt[];
    attemptDetails: AttemptDetails;
}

const initialState: Readonly<AttemptsListState> = {
    isLoading: false,
    attempts: [],
    attemptDetails: {
        resultByCategories: [] as CategoryResult[],
    } as AttemptDetails,
};

export const reducer: Reducer<AttemptsListState> = (
    state: AttemptsListState = initialState,
    incomingAction: Action
): AttemptsListState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case LOAD_ATTEMPTS:
            return {
                ...state,
                attempts: action.attempts,
                isLoading: false,
            };
        case GET_ATTEMPT_DETAILS:
            return {
                ...state,
                attemptDetails: action.attemptDetails,
                isLoading: false,
            };
        default:
            return state;
    }
};
