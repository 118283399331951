import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActiveLanguages } from '../../../constants/language';
import { Option, Select } from '../../Select';

interface Props {
    className?: string;
    portal?: boolean;
}

export const LanguagePicker: FC<Props> = ({ className, portal }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [languages] = useState<Option[]>(
        getActiveLanguages().map((lang) => ({ label: lang.label, value: lang.key }))
    );

    const changeLanguage = (lang: string): void => {
        document.documentElement.lang = lang;
        i18n.changeLanguage(lang);
    };

    if (!languages?.length || !currentLanguage) {
        return null;
    }

    const getCurrentLanguage = (): Option => languages.find((lang) => lang.value === currentLanguage) || languages[0];

    return (
        <div className={`language-picker ${className || ''}`}>
            <Select
                portal={portal}
                options={languages}
                values={[getCurrentLanguage()]}
                onChange={([selected]) => changeLanguage(selected.value as string)}
            />
        </div>
    );
};
