import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KASPERSKY_MAIL } from '../../global-constants';
import { StudyGroupTestCondition } from '../../models/study-group-test-condition';
import { Link } from '../Link';
import { Paragraph } from '../Paragraph';
import { Title } from '../Title';

const TestInstructionCyberSecurity: FC<StudyGroupTestCondition> = (props) => {
    const {
        attemptLeft,
        highestPoints,
        testTitle,
        quantityMinutes,
        quantityQuestions,
        lowestPoints,
        isPercent,
        minPercent,
    } = props;
    const [t] = useTranslation(['user-test-correct-code', 'user-test']);

    return (
        <Trans>
            <Title titleText={testTitle || ''} />
            <Paragraph additionalClass="success">
                {t('user-test:attempts-allowed', {
                    attempts: `${attemptLeft}`,
                })}
            </Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">
                {t('test-data', {
                    quantityMinutes: t('quantity-minutes', {
                        count: quantityMinutes,
                    }),
                    quantityQuestions: t('quantity-questions', {
                        count: quantityQuestions,
                    }),
                })}
            </Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">{t('one-correct-answer')}</Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">{t('selected-question')}</Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">{t('skip-question')}</Paragraph>
            <Paragraph additionalClass="excerpt">{t('unsure-answer')}</Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">{t('end-test')}</Paragraph>
            <Paragraph additionalClass="excerpt">{t('close-test')}</Paragraph>
            <Paragraph additionalClass="excerpt">{t('quit-test')}</Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">{t('question-price')}</Paragraph>
            <Paragraph additionalClass="excerpt-with-mark">
                {isPercent
                    ? t('min-quantity-question-success-multi', { minPercent })
                    : t('min-quantity-question-success', { lowestPoints })}
            </Paragraph>
            {!isPercent && (
                <Paragraph additionalClass="excerpt">
                    {t('distinction-certificate-quantity-question', {
                        highestPoints,
                    })}
                </Paragraph>
            )}
            <Paragraph additionalClass="excerpt-with-mark">
                {t('find-error')}
                <Link linkHref={`mailto:${KASPERSKY_MAIL}`} additionalClass="success">
                    {KASPERSKY_MAIL}
                </Link>
                {t('check-error')}
            </Paragraph>
        </Trans>
    );
};

export default TestInstructionCyberSecurity;
