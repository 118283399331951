import { CertificationTypeFilter } from 'enums/certification-type';
import { SimpleLevelOfEvaluation } from 'enums/level-of-evaluation';
import { UserTestStatistic } from 'models/test/user-test-statistic';
import { Action, Reducer } from 'redux';
import { UserFilterType } from '../../../enums/user-filter-type';
import { SelectOption } from '../../../models/select-option';
import { TestStatistic } from '../../../models/test/test-statistic';
import {
    ActionTypes,
    CLEAR_ERROR,
    CLOSE_STATISTIC_RESULT,
    GET_STATISTIC,
    GET_STATISTIC_RESULT,
    GET_TESTS,
    SELECT_CERTIFICATION_TYPE,
    SELECT_COUNTRY,
    SELECT_DATES,
    SELECT_TEST,
    SELECT_USER_TYPE,
    SET_ERROR,
    SET_LOADING,
    SET_LEVEL,
    SET_COMPANY,
    SET_EMAIL,
    SET_CERTIFICATION_NUMBER,
    SET_SUCCESS,
} from './actions';

export interface TestStatisticsState {
    statistics: TestStatistic[];
    statisticsTotalCount: number;
    isLoading: boolean;
    error: string;
    success: string;
    testOptions: SelectOption[];

    selectedCountryId?: number;
    selectedTestId?: string;
    dateStart?: string;
    dateEnd?: string;
    selectedUserType?: UserFilterType;
    selectedCertificationType?: CertificationTypeFilter;
    selectedLevel?: SimpleLevelOfEvaluation;
    selectedCompany?: string;
    selectedEmail?: string;
    selectedCertificationNumber?: string;

    currentTestResult?: UserTestStatistic;
    showModalResult: boolean;
}

const getDateStart = (): string => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    date.setHours(0, 0, 0);
    return date.toISOString();
};

const initState = {
    statistics: [],
    statisticsTotalCount: 0,
    isLoading: false,
    error: '',
    success: '',

    dateStart: getDateStart(),
    dateEnd: new Date().toISOString(),
    testOptions: [],

    showModalResult: false,
};

export const reducer: Reducer<TestStatisticsState> = (
    state: TestStatisticsState = initState,
    incomingAction: Action
): TestStatisticsState => {
    const action = incomingAction as ActionTypes;

    switch (action.type) {
        case SET_LEVEL:
            return {
                ...state,
                selectedLevel: action.level,
            };
        case SET_COMPANY:
            return {
                ...state,
                selectedCompany: action.company,
            };
        case SET_EMAIL:
            return {
                ...state,
                selectedEmail: action.email,
            };
        case SET_CERTIFICATION_NUMBER:
            return {
                ...state,
                selectedCertificationNumber: action.certificationNumber,
            };
        case CLOSE_STATISTIC_RESULT:
            return {
                ...state,
                showModalResult: false,
            };
        case GET_STATISTIC_RESULT:
            return {
                ...state,
                currentTestResult: action.testResult,
                isLoading: false,
                showModalResult: true,
            };
        case GET_STATISTIC:
            return {
                ...state,
                statistics: action.statistics.items,
                statisticsTotalCount: action.statistics.count,
                isLoading: false,
            };
        case GET_TESTS:
            return {
                ...state,
                testOptions: action.testOptions,
            };
        case SELECT_COUNTRY:
            return {
                ...state,
                selectedCountryId: action.selectedCountryId,
            };
        case SELECT_TEST:
            return {
                ...state,
                selectedTestId: action.selectedTestId,
            };
        case SELECT_DATES:
            return {
                ...state,
                dateEnd: action.dateEnd,
                dateStart: action.dateStart,
            };
        case SELECT_USER_TYPE:
            return {
                ...state,
                selectedUserType: action.selectedUserType,
            };
        case SELECT_CERTIFICATION_TYPE:
            return {
                ...state,
                selectedCertificationType: action.selectedCertificationType,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: '',
                success: '',
            };
        case SET_SUCCESS:
            return {
                ...state,
                success: action.success,
                isLoading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };

        default: {
            return state;
        }
    }
};
