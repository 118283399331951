import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getDateZeroTimeZoneISO, getTomorrowDate } from '../../../../utils/date';

import { DatePricker } from 'components';

interface DateRangeProps {
    dateStart: string;
    dateEnd: string;
}

export const DateRange = (props: DateRangeProps) => {
    const { dateStart = getDateZeroTimeZoneISO(), dateEnd = getDateZeroTimeZoneISO(getTomorrowDate()) } = props;
    const { control, errors, getValues } = useFormContext();
    const [t] = useTranslation('admin-validation');

    return (
        <>
            <Controller
                as={DatePricker}
                control={control}
                useZeroTime
                name="dateStartSession"
                label="Начало действия сессии"
                returnValue="start"
                defaultValue={dateStart}
                value={dateStart}
                rules={{
                    required: {
                        value: true,
                        message: t('required-select'),
                    },
                    validate: (value) => getDateStartValidationMessage(value, t, dateStart) || true,
                }}
                errorMessage={t(errors.dateStartSession?.message as string)}
            />
            <Controller
                as={DatePricker}
                control={control}
                useZeroTime
                name="dateEndSession"
                label="Конец действия сессии"
                returnValue="start"
                defaultValue={dateEnd}
                value={dateEnd}
                rules={{
                    required: {
                        value: true,
                        message: t('required-select'),
                    },
                    validate: (value) => getDateEndValidationMessage(value, t, getValues('dateStartSession')) || true,
                }}
                errorMessage={t(errors.dateEndSession?.message as string)}
            />
        </>
    );
};

const getDateStartValidationMessage = (value: Date, t: TFunction, defaultDateStart?: string) =>
    moment(value).isSameOrAfter(defaultDateStart || moment().format('LL')) ? '' : t('date-start-session');

const getDateEndValidationMessage = (value: Date, t: TFunction, dateStart: Date) =>
    moment(value).isSameOrAfter(dateStart) ? '' : t('date-end-session');
