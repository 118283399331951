import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { TextInput, Button } from 'components';
import { MIN_TEXT_INPUT_LENGTH } from 'global-constants';

export interface FormData {
    code: string;
}

export const EnterCodeForm = (props: { handleSubmitForm: (value: FormData) => void }) => {
    const { handleSubmitForm } = props;
    const { t, i18n } = useTranslation(['user-test', 'validation']);
    const { register, handleSubmit, errors, formState, triggerValidation } = useForm<FormData>();
    const { isSubmitting, isSubmitted } = formState;

    useEffect(() => {
        if (isSubmitted) {
            triggerValidation();
        }
    }, [triggerValidation, i18n.language, isSubmitted]);

    return (
        <form
            onSubmit={handleSubmit(handleSubmitForm)}
            className="d-flex align-items-center justify-content-space-between"
            autoComplete="off"
        >
            <TextInput
                name="code"
                inputRef={register({
                    required: {
                        value: true,
                        message: t('validation:required-field'),
                    },
                    minLength: {
                        value: MIN_TEXT_INPUT_LENGTH,
                        message: t('validation:min-length', {
                            minLength: MIN_TEXT_INPUT_LENGTH,
                        }),
                    },
                })}
                id="code"
                errorMessage={errors.code?.message as string}
            />
            <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                {t('enter-button')}
            </Button>
        </form>
    );
};
