import React from 'react';
import { useTranslation } from 'react-i18next';

import { AttemptDetails, CategoryResult } from 'pages/attempts-list/store/store';

export const DetailsGrid = (props: AttemptDetails) => {
    const { resultByCategories, totalQuantityOfCorrectAnswers, totalQuantityOfQuestions } = props;
    const [t] = useTranslation(['attempts-list']);
    return (
        <div className="details-grid">
            <div className="details-grid-header">
                <h2 className="details-grid-header-cell">{t('certification-domains')}</h2>
                <div className="details-grid-block">
                    <div className="details-grid-header-cell">{t('correct')}</div>
                    <div className="details-grid-header-cell">{t('from')}</div>
                </div>
            </div>
            {resultByCategories.map((x, index) => (
                <DetailsGridRow key={`${x.categoryName}-${index}`} {...x} />
            ))}
            <DetailsGridRow
                categoryName={t('total')}
                quantityOfCorrectAnswers={totalQuantityOfCorrectAnswers}
                quantityOfQuestions={totalQuantityOfQuestions}
            />
        </div>
    );
};

const DetailsGridRow = (props: CategoryResult) => {
    const { categoryName, quantityOfCorrectAnswers, quantityOfQuestions } = props;
    return (
        <div className="details-grid-row">
            <div className="details-grid-cell">{categoryName}</div>
            <div className="details-grid-block">
                <div className="details-grid-cell">{quantityOfCorrectAnswers}</div>
                <div className="details-grid-cell">{quantityOfQuestions}</div>
            </div>
        </div>
    );
};
