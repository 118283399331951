import { QuestionOption } from './store/store';

export interface AnswerData {
    label: string;
    index: number;
}

export const defaultAnswers: QuestionOption[] = [
    { id: undefined, text: undefined, isRight: false },
    { id: undefined, text: undefined, isRight: false },
];
