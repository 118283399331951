import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Loader, Page } from 'components';
import { ApplicationState } from 'store';
import TestInstruction from '../../../../components/TestInstruction/TestInstruction';
import { actions, UserTestState } from '../../store';
import { withLoading, useTitle } from 'utils';

type CorrectCodeProps = UserTestState & typeof actions;

const CorrectCode = (props: CorrectCodeProps) => {
    const { studyGroupDescription, startTest, clearStudyGroup, clearStore } = props;
    const { testTitle } = studyGroupDescription || {};

    const [t] = useTranslation(['user-test-correct-code', 'user-test']);
    useTitle(testTitle || t('test'));

    useEffect(
        () => () => {
            clearStudyGroup();
        },
        [clearStudyGroup]
    );

    if (!studyGroupDescription) {
        return <Loader />;
    }

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <TestInstruction {...studyGroupDescription} startTest={startTest} cancelTest={clearStore} />
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => state.userTest,
    actions
)(withLoading(CorrectCode) as () => JSX.Element);
