import React from 'react';

interface CheckboxProps {
    id: string;
    dangerHtml: string;
    isChecked: boolean;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxNew = (props: CheckboxProps) => {
    const { id, dangerHtml, isChecked, handleChange } = props;

    const classes = `checkbox_new`;

    return (
        <div className={classes}>
            <input
                className={`${classes}_control`}
                type="checkbox"
                id={id}
                checked={isChecked}
                onChange={handleChange}
            />
            <label
                className={`${classes}_text`}
                htmlFor={id}
                dangerouslySetInnerHTML={{
                    __html: dangerHtml,
                }}
            />
        </div>
    );
};
