import { Button, Column, Container, Page, Paragraph, Title } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { useTitle, withLoading } from 'utils';
import { actions, TCAdminAuthenticationState } from '../../../authentication/TCAdminAuthenticationStore';
import { UserState } from '../../../enums/user-state';
import { ADMIN_STUDY_LOGIN_PATH, ADMIN_STUDY_LOGOUT_PATH } from '../../../global-constants';
import UpdateAdminStudyCenter from '../update-admin-study-center/UpdateAdminStudyCenter';

type AdminLoginProps = typeof actions & TCAdminAuthenticationState;

const AdminLoginTC = (props: AdminLoginProps) => {
    const { admin } = props;
    const [t] = useTranslation('study-center');

    const handleLogIn = () => {
        document.location.href = ADMIN_STUDY_LOGIN_PATH;
    };
    const handleLogOut = () => {
        document.location.href = ADMIN_STUDY_LOGOUT_PATH;
    };

    useTitle('Авторизация');

    return (
        <Page>
            <Container>
                <Column columnSize="100" className="text-left">
                    <Title titleText={t('auth-study-center-title')} />
                    {admin &&
                        (!admin.lastName || !admin.studyCenter ? (
                            <Paragraph additionalClass="lead">{t('auth-send-form-wait-approved')}</Paragraph>
                        ) : admin.approved === UserState.Rejected ? (
                            <Paragraph additionalClass="danger">{t('auth-wait-rejected')}</Paragraph>
                        ) : admin.approved === UserState.WaitingFor ? (
                            <>
                                <Paragraph additionalClass="lead">{t('auth-wait-approved')}</Paragraph>
                                <Paragraph additionalClass="secondary">{t('auth-wait-approved-long-time')}</Paragraph>
                            </>
                        ) : (
                            ''
                        ))}
                </Column>
                {admin && <UpdateAdminStudyCenter />}
                <Column columnSize="100" className="text-left">
                    {!admin && (
                        <Button handleClick={handleLogIn} buttonClass="primary">
                            {t('auth-study-center-sign-in-btn')}
                        </Button>
                    )}

                    {admin && (
                        <Button handleClick={handleLogOut} buttonClass="primary">
                            {t('auth-study-center-logout-btn')}
                        </Button>
                    )}
                </Column>
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState) => state.tcAuthentication,
    actions
)(withLoading(AdminLoginTC) as () => JSX.Element);
