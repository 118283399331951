import api from 'api';
import { UserShort } from '../models/user-short';
import { UserFull } from '../models/user-full';
import { Certificate } from '../models/certificate';
import { MoveCertificate } from '../models/move-certificate';
import { ResponsePagination } from 'models/response-pagination';

export const getUsers = (search?: string): Promise<{ data: ResponsePagination<UserShort> }> =>
    api.get('/MoveCertificate/GetUsers', {
        params: {
            page: 0,
            size: 30,
            search,
        },
    });
export const getUser = (userId: string): Promise<{ data: UserFull }> =>
    api.get('/MoveCertificate/GetUser', {
        params: {
            userId,
        },
    });
export const getCertificates = (userId: string): Promise<{ data: Certificate[] }> =>
    api.get('/MoveCertificate/GetCertificates', {
        params: {
            userId,
        },
    });
export const moveCertificates = (model: MoveCertificate): Promise<void> =>
    api.post('/MoveCertificate/MoveCertificate', { ...model });
