import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Container, Column, Title, UploadButton, Button, Label, Option } from 'components';
import { connectComponent, withLoading } from 'utils';
import { ApplicationState } from 'store';
import { actionCreators } from './store/actions';
import { QuestionsState } from './store/store';

interface FormValues {
    file: FileList;
}

type QuestionsUploadProps = QuestionsState & {
    selectData: Option[];
    selectedOption: Option;
} & typeof actionCreators;

const QuestionsUpload = (props: QuestionsUploadProps) => {
    const { uploadQuestions, questionError, loadCategories, questionSuccess, clearQuestionMessages } = props;
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { register, handleSubmit, errors, watch, setValue, formState } = useForm<FormValues>();
    const { isSubmitting } = formState;
    const [t] = useTranslation('admin-validation');

    const goToQuestions = useCallback(() => {
        push(pathname.replace('/upload', ''));
    }, [pathname, push]);

    const handleSubmitForm = handleSubmit(async ({ file }: FormValues) => {
        await uploadQuestions(file[0]);
    });

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    useEffect(() => {
        if (questionSuccess) {
            goToQuestions();
        }
    }, [goToQuestions, questionSuccess]);

    const file = watch('file');
    useEffect(() => {
        clearQuestionMessages();
    }, [clearQuestionMessages, file]);

    useEffect(() => {
        register('file', {
            required: {
                value: true,
                message: t('required-select-file'),
            },
        });
    }, [register, t]);

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText="Вопросы" />
            </Column>
            <Column columnSize="100">
                <form onSubmit={handleSubmitForm}>
                    <div>
                        <Label
                            labelFor="upload-button"
                            labelText="Загрузите файл с вопросами в формате xlsx"
                            additionalClasses="upload-button-label"
                        />
                        <UploadButton
                            type="file"
                            name="file"
                            setValue={setValue}
                            accept=".xlsx"
                            errorMessage={(errors.file as { message: string })?.message || questionError}
                        />
                        <a className="link" href="/office/questions.xlsx">
                            Скачать шаблон excel
                        </a>
                    </div>
                    <Button buttonClass="primary" type="submit" isDisabled={isSubmitting}>
                        Сохранить
                    </Button>
                    <Button buttonClass="secondary" handleClick={goToQuestions}>
                        Отмена
                    </Button>
                </form>
            </Column>
        </Container>
    );
};

const mapStateToProps = ({ questions }: ApplicationState) => {
    const { categories, selectedCategoryId } = questions;
    const selectedCategory = categories.find((x) => x.id === selectedCategoryId);
    const selectData = categories.map((x) => ({ label: x.name, value: x.id }));
    return {
        ...questions,
        selectData,
        selectedOption: selectedCategory
            ? { label: selectedCategory.name, value: selectedCategory.id }
            : selectData[0] || ({} as Option),
    };
};

export default connectComponent({
    mapStateToProps,
    actions: actionCreators,
    component: withLoading(QuestionsUpload),
});
