import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Error404 from 'pages/error-404/Error404';
import StudyGroupFormAdd from './StudyGroupFormAdd';
import StudyGroupFormEdit from './StudyGroupFormEdit';
import StudyGroupList from './StudyGroupList';
import { useTitle } from 'utils';

const StudyGroups = () => {
    const { path } = useRouteMatch();

    useTitle('Учебные Группы');

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <StudyGroupList />
                </Route>
                <Route exact path={`${path}/add`}>
                    <StudyGroupFormAdd />
                </Route>
                <Route exact path={`${path}/edit/:id`}>
                    <StudyGroupFormEdit />
                </Route>
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default StudyGroups;
