import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PaginationProps } from '../components/Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../constants/default-page-size';
import { useDebouncedEffect } from './useDebouncedEffect/useDebouncedEffect';
import { useQuery } from './useQuery';

interface UseUrlQueryParamsForGridProps {
    loadFn?: (params: { page: number; size: number; query?: string }) => void;
    clearPaginationDeps?: Array<string | number | undefined>;
    total: number;
}

interface UseUrlQueryParamsForGridResult {
    pagination: PaginationProps;
    queryState: [string, Dispatch<SetStateAction<string>>];
    setPage: (page: number) => void;
}

const sizeUrlParam = 'size';
const pageUrlParam = 'page';
const queryUrlParam = 'query';

export const useUrlQueryParamsForGrid = (params: UseUrlQueryParamsForGridProps): UseUrlQueryParamsForGridResult => {
    const { loadFn, total, clearPaginationDeps = [] } = params;

    const [additionalParams, setAdditionalParams] = useState([...clearPaginationDeps]);
    const queryParams = useQuery();
    const urlSize = +(queryParams.get(sizeUrlParam) as string);
    const urlPage = +(queryParams.get(pageUrlParam) as string);
    const urlQuery = queryParams.get(queryUrlParam) || '';

    const { push, replace } = useHistory();
    const { pathname } = useLocation();

    const queryState = useState<string>(urlQuery || '');
    const [queryInput, setQueryInput] = queryState;

    const isUrlNotCorrect = (): boolean => isNaN(urlPage) || isNaN(urlSize) || urlPage < 0 || urlSize < 1;

    const setPage = (page: number) => {
        queryParams.set(pageUrlParam, `${page}`);
        replace({
            search: queryParams.toString(),
            pathname,
        });
    };

    useEffect(() => {
        if (isUrlNotCorrect()) {
            queryParams.set(sizeUrlParam, DEFAULT_PAGE_SIZE + '');
            queryParams.set(pageUrlParam, '0');

            replace({
                search: queryParams.toString(),
                pathname,
            });

            return;
        }

        loadFn &&
            loadFn({
                page: urlPage,
                size: urlSize,
                query: urlQuery || undefined,
            });
    }, [urlPage, urlSize, urlQuery, ...additionalParams]);

    const changePagination = (page: number, size: number) => {
        if (page === urlPage && size === urlSize) {
            return;
        }

        queryParams.set(sizeUrlParam, size + '');
        queryParams.set(pageUrlParam, page + '');

        push({
            search: queryParams.toString(),
            pathname,
        });
    };

    const changeQuery = (query: string) => {
        if (urlQuery === queryInput) {
            return;
        }

        queryParams.set(pageUrlParam, '0');
        queryParams.set(queryUrlParam, query);

        push({
            search: queryParams.toString(),
            pathname,
        });
    };

    useEffect(() => {
        const isDifferent = clearPaginationDeps.some((item, index) => item !== additionalParams[index]);

        if (!isDifferent) {
            return;
        }

        setAdditionalParams([...clearPaginationDeps]);
        queryParams.set(pageUrlParam, '0');

        replace({
            search: queryParams.toString(),
            pathname,
        });
    }, clearPaginationDeps);

    useEffect(() => {
        setQueryInput(urlQuery || '');
    }, [urlQuery]);

    useDebouncedEffect(
        () => {
            changeQuery(queryInput);
        },
        [queryInput],
        400
    );

    return {
        pagination: {
            page: urlPage,
            size: urlSize,
            handleChange: changePagination,
            total,
        },
        queryState,
        setPage,
    };
};
