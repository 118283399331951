import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { actions as actionSettings } from 'store/app-settings/store';
import { layoutRoutingUrls } from './constants/layout-routing-urls';
import MainLayout from './layouts/MainLayout';
import PartnerLayout from './layouts/PartnerLayout';
import TCAdminLayout from './layouts/TCAdminLayout';
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';
import UserTestLinkLayout from './layouts/UserTestLinkLayout';
import { POLICIES } from 'global-constants';
import { DeletedAccountInfo } from 'pages/info/deleted-account-info/DeletedAccountInfo';
import ApplicationPolicies from 'pages/application-policies/ApplicationPolicies';
import Error404 from './pages/error-404/Error404';
import AccessDeny from './pages/access-deny/AccessDeny';
import { ApplicationState } from './store';
import UserIsBlocked from 'pages/user-is-blocked/UserIsBlocked';
import UnknownError from 'pages/unknown_error/UnknownError';
import AdditionalError from 'pages/additional_error/AdditionalError';

type AppProps = typeof actionSettings;

const App = (props: AppProps) => {
    const { getSettings } = props;

    useEffect(() => {
        getSettings();
    }, [getSettings]);

    return (
        <div className="app">
            <Router>
                <Switch>
                    <Route path={layoutRoutingUrls.admin} component={AdminLayout} />
                    <Route path={layoutRoutingUrls.tcAdmin} component={TCAdminLayout} />
                    <Route path="/user-test-link/:linkId" component={UserTestLinkLayout} />
                    <Route path="/partner" component={PartnerLayout} />
                    <Route path="/deleted-account-info" component={DeletedAccountInfo} />
                    <Route path={`/${POLICIES}`} component={ApplicationPolicies} />
                    <Route exact path="/404" component={Error404} />
                    <Route exact path="/access-deny" component={AccessDeny} />
                    <Route exact path="/blocked" component={UserIsBlocked} />
                    <Route exact path="/unknown_error" component={UnknownError} />
                    <Route exact path="/additional_error" component={AdditionalError} />
                    <Route exact path="/" component={MainLayout} />
                    <Route path={layoutRoutingUrls.user} component={UserLayout} />
                </Switch>
            </Router>
        </div>
    );
};

export default connect((state: ApplicationState) => state.appSettings, {
    ...actionSettings,
})(App as () => JSX.Element);
