import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import QuestionsList from './QuestionsList';
import QuestionsUpload from './QuestionsUpload';
import QuestionsStatistic from './QuestionsStatistic';
import Error404 from 'pages/error-404/Error404';
import { useTitle } from 'utils';

const Questions = () => {
    const { path } = useRouteMatch();

    useTitle('Вопросы');

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <QuestionsList />
            </Route>
            <Route exact path={`${path}/upload`}>
                <QuestionsUpload />
            </Route>
            <Route exact path={`${path}/statistic`}>
                <QuestionsStatistic />
            </Route>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default Questions;
