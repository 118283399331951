import { CertDirection } from '../../../enums/cert-direction';
import { LanguageEnum } from '../../../enums/language.enum';
import { ProductLKType } from '../../../enums/product-lk-type';
import { SelectOption } from '../../../models/select-option';
import { GetTrainingGroupParams } from '../../../models/study-group/get-training-group-params';
import { GetTestsParams } from '../../../models/test/get-tests-params';
import { TestService } from '../../../services/test.service';

import { AppThunkAction } from '../../../store';
import { StudyGroupFormData } from '../study-group-form/StudyGroupForm';
import { mapFormValuesToPostData, mapFormValuesToPutData, mapCentersToOptions, mapTestsToOptions } from './mappers';
import StudyCenterService from '../../../services/study-center.service';
import { TrainingGroupService } from '../../../services/training-group.service';
import { TrainingGroup } from '../../../models/training-group/training-group';
import { UpdateTrainingGroup } from '../../../models/training-group/update-training-group';

export const LOAD_STUDY_GROUPS = 'study-groups/LOAD_STUDY_GROUPS';
export const GET_STUDY_GROUP = 'study-groups/GET_STUDY_GROUP';
export const GET_TESTS = 'study-groups/GET_TESTS';
export const GET_STUDY_CENTERS = 'study-groups/GET_STUDY_CENTER';
export const ADD_STUDY_GROUP = 'study-groups/ADD_STUDY_GROUP';
export const UPDATE_STUDY_GROUP = 'study-groups/UPDATE_STUDY_GROUP';
export const DELETE_STUDY_GROUP = 'study-groups/DELETE_STUDY_GROUP';
export const SET_LOADING = 'study-groups/SET_LOADING';
export const SUCCESS_STUDY_GROUP = 'study-groups/SUCCESS_STUDY_GROUP';
export const ERROR_STUDY_GROUP = 'study-groups/ERROR_STUDY_GROUP';
export const CLEAR_MESSAGE_STUDY_GROUP = 'study-groups/CLEAR_MESSAGE_STUDY_GROUP';
export const CLEAR_STUDY_GROUP = 'study-groups/CLEAR_STUDY_GROUP';
export const SET_DIRECTION = 'study-groups/SET_DIRECTION';
export const SET_STUDY_CENTER = 'study-groups/SET_STUDY_CENTER';
export const SET_LANGUAGE = 'study-groups/SET_LANGUAGE';
export const SET_TEST_TYPE = 'study-groups/SET_TEST_TYPE';

interface LoadStudyGroups {
    type: typeof LOAD_STUDY_GROUPS;
    studyGroups: TrainingGroup[];
}

interface GetStudyGroup {
    type: typeof GET_STUDY_GROUP;
    studyGroup: TrainingGroup;
}

interface GetTests {
    type: typeof GET_TESTS;
    tests: SelectOption[];
}

interface GetStudyCenters {
    type: typeof GET_STUDY_CENTERS;
    studyCenters: SelectOption[];
}

interface AddStudyGroup {
    type: typeof ADD_STUDY_GROUP;
    studyGroup: UpdateTrainingGroup;
}

interface UpdateStudyGroup {
    type: typeof UPDATE_STUDY_GROUP;
}

interface SetLoading {
    type: typeof SET_LOADING;
}

interface StudyGroupSuccess {
    type: typeof SUCCESS_STUDY_GROUP;
    studyGroupSuccess: string;
}

interface StudyGroupError {
    type: typeof ERROR_STUDY_GROUP;
    studyGroupError: string;
}

interface DeleteStudyGroup {
    type: typeof DELETE_STUDY_GROUP;
    studyGroupId: number;
}

interface ClearMessageStudyGroup {
    type: typeof CLEAR_MESSAGE_STUDY_GROUP;
}

interface ClearStudyGroup {
    type: typeof CLEAR_STUDY_GROUP;
}

interface SetDirection {
    type: typeof SET_DIRECTION;
    directionId: CertDirection;
}

interface SetStudyCenter {
    type: typeof SET_STUDY_CENTER;
    studyCenterId: number;
}

interface SetLanguage {
    type: typeof SET_LANGUAGE;
    language: LanguageEnum;
}

interface SetTestType {
    type: typeof SET_TEST_TYPE;
    testType: ProductLKType;
}

export type ActionTypes =
    | LoadStudyGroups
    | GetStudyGroup
    | GetTests
    | GetStudyCenters
    | AddStudyGroup
    | SetLoading
    | ClearMessageStudyGroup
    | StudyGroupError
    | StudyGroupSuccess
    | DeleteStudyGroup
    | UpdateStudyGroup
    | ClearStudyGroup
    | SetDirection
    | SetStudyCenter
    | SetLanguage
    | SetTestType;

export const actions = {
    loadStudyGroups:
        (params: GetTrainingGroupParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                dispatch({
                    type: CLEAR_STUDY_GROUP,
                });

                const studyGroups = (await TrainingGroupService.getList(params)).data;

                dispatch({
                    type: LOAD_STUDY_GROUPS,
                    studyGroups,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    getStudyGroup:
        (id?: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const studyGroup = id ? (await TrainingGroupService.getById(id)).data : ({} as TrainingGroup);

                dispatch({
                    type: GET_STUDY_GROUP,
                    studyGroup: studyGroup,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    getStudyCenters:
        (withNoCenters?: boolean): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                const studyCenters = (await StudyCenterService.getList()).data;
                dispatch({
                    type: GET_STUDY_CENTERS,
                    studyCenters: mapCentersToOptions(studyCenters, withNoCenters),
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    getTests:
        (params: GetTestsParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                const tests = (await TestService.getList(params)).data;
                dispatch({
                    type: GET_TESTS,
                    tests: mapTestsToOptions(tests),
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    addStudyGroup:
        (studyGroup: StudyGroupFormData): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const mappedData = mapFormValuesToPostData(studyGroup);
                const studyGroupId = (await TrainingGroupService.add({ ...mappedData })).data;
                dispatch({
                    type: ADD_STUDY_GROUP,
                    studyGroup: {
                        ...mappedData,
                        id: studyGroupId,
                    },
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    updateStudyGroup:
        (studyGroup: StudyGroupFormData & { id: number }): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const mappedData = mapFormValuesToPutData(studyGroup);
                await TrainingGroupService.update({
                    ...mappedData,
                    id: studyGroup.id,
                });

                dispatch({
                    type: UPDATE_STUDY_GROUP,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    deleteStudyGroup:
        (studyGroupId: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await TrainingGroupService.delete(studyGroupId);

                dispatch({
                    type: DELETE_STUDY_GROUP,
                    studyGroupId,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_GROUP,
                    studyGroupError: 'Ошибка',
                });
            }
        },
    clearMessage: () => ({
        type: CLEAR_MESSAGE_STUDY_GROUP,
    }),
    setDirection: (directionId: CertDirection) => ({
        type: SET_DIRECTION,
        directionId,
    }),
    setStudyCenter: (studyCenterId: number) => ({
        type: SET_STUDY_CENTER,
        studyCenterId,
    }),
    setLanguage: (language: LanguageEnum) => ({
        type: SET_LANGUAGE,
        language,
    }),
    setTestType: (testType: ProductLKType) => ({
        type: SET_TEST_TYPE,
        testType,
    }),
};
