import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { AddTest } from '../models/test/add-test';
import { GetTestsParams } from '../models/test/get-tests-params';
import { Test } from '../models/test/test';
import { TestShort } from '../models/test/test-short';
import { TestStatisticParams, TestStatistic, TestStatisticXlsParams } from '../models/test/test-statistic';
import { UserTestStatistic } from '../models/test/user-test-statistic';
import { UpdateTest } from '../models/test/update-test';
import { DownloadFileService } from './download-file.service';
import { UpdateTestStatistic } from '../models/test/update-test-statistic';

export class TestService {
    static getList = (params: GetTestsParams) => api.get<TestShort[]>(`Test`, { params });

    static getById = (id: number) => api.get<Test>(`Test/${id}`);

    static add = (test: AddTest) => api.post<Test>('Test', test);

    static update = (test: UpdateTest) => api.put<Test>('Test', test);

    static delete = (id: number) => api.delete<void>(`Test/${id}`);

    static getStatistic = (params: TestStatisticParams) =>
        api.get<ResponsePagination<TestStatistic>>(`Test/GetStatistic`, { params });

    static getStatisticXls = (params: TestStatisticXlsParams) =>
        DownloadFileService.download(`Test/GetStatisticXls`, { ...params });

    static getStatisticForUser = (id: number, userId?: string) =>
        api.get<UserTestStatistic>(`PassingTest/Result/${id}/${userId || ''}`);

    static updateStatistic = (val: UpdateTestStatistic) => api.post<void>('PassingTest/UpdateStatistic', val);
}
