import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../components';
import { PARTNER_LOGIN_PATH } from '../global-constants';
import { useQuery } from '../hooks';

const PartnerLayout = () => {
    const testCode = useQuery().get('code');
    const lang = useQuery().get('lang');
    const zipCoded = useQuery().get('zipCoded');
    const history = useHistory();

    useEffect(() => {
        if (!testCode) {
            history.replace('/404');
            return;
        }

        window.location.href = PARTNER_LOGIN_PATH.replace('{code}', testCode)
            .replace('{lang}', lang || '')
            .replace('{zipCoded}', zipCoded || 'false');
    }, [testCode, lang]);

    return <Loader />;
};

export default PartnerLayout;
