import { Action, Reducer } from 'redux';
import { Country } from '../../models/country';

import { ActionTypes, SET_COUNTRIES, SET_LOADING } from './actions';

export interface CountriesState {
    countries: Country[];
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: Readonly<CountriesState> = {
    countries: [],
    isLoading: false,
    isLoaded: false,
};

export const reducer: Reducer<CountriesState> = (
    state: CountriesState = initialState,
    incomingAction: Action
): CountriesState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.countries,
                isLoaded: true,
                isLoading: false,
            };
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        }
        default:
            return state;
    }
};
