import api from '../../../api';
import { AppThunkAction } from '../../../store';
import { GetAdmins, UserPaginator } from './store';
import { DownloadFileService } from '../../../services/download-file.service';
import { UserState } from 'enums/user-state';
import { User } from '../../../authentication/UserAuthenticationStore';

export const SET_STUDY_CENTERS_ADMINS = 'admins/SET_STUDY_CENTERS_ADMINS';
export const SET_LOADING = 'admins/SET_LOADING';
export const APPROVE_STUDY_CENTERS_ADMIN = 'admins/APPROVE_STUDY_CENTERS_ADMIN';
export const DELETE_STUDY_CENTERS_ADMIN = 'admins/DELETE_STUDY_CENTERS_ADMIN';

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading: boolean;
}

export interface SetAdmins {
    type: typeof SET_STUDY_CENTERS_ADMINS;
    studyCentersAdmins: UserPaginator;
}

export interface DeleteUser {
    type: typeof DELETE_STUDY_CENTERS_ADMIN;
    userId: string;
}

export interface ApproveAdmin {
    type: typeof APPROVE_STUDY_CENTERS_ADMIN;
    id: string;
    approved: UserState;
}

export type ActionTypes = SetLoading | SetAdmins | DeleteUser | ApproveAdmin;

export const actions = {
    getAdmins:
        (params: GetAdmins): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const users = (
                    await api.get<UserPaginator>(`AdminControlling/GetAllAdminsFromStudyCenters`, {
                        params,
                    })
                ).data;

                dispatch({
                    type: SET_STUDY_CENTERS_ADMINS,
                    studyCentersAdmins: users,
                });
            } catch (e) {
                console.error(e);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    deleteUser:
        (userId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                await api.delete(`AdminControlling/DeleteUser/${userId}`);
                dispatch({
                    type: DELETE_STUDY_CENTERS_ADMIN,
                    userId,
                });
            } catch (e) {
                console.error(e);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    approveUser:
        (user: User, value: boolean): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            const { id, adminStudyCenter } = user;
            const approved = value ? UserState.Approve : UserState.Rejected;

            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                await api.put(`AdminControlling/ApproveAdminStudyCenter/`, {
                    userId: id,
                    approved,
                    studyCenterId: adminStudyCenter.id,
                });
                dispatch({
                    type: APPROVE_STUDY_CENTERS_ADMIN,
                    id,
                    approved,
                });
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            } catch (e) {
                console.error(e);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getExcel:
        (search: string | null): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                await DownloadFileService.download(
                    `AdminControlling/adminStudyCentersXls?search=${!search ? '' : search}`
                );
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            } catch (e) {
                console.error(e);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
};
