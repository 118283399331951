import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Icon } from '../Icon';
import { mapSecondsToTimeString } from 'utils';

interface SessionExpiredProps {
    isSessionEnd: boolean;
    maxNoActiveTime: number;
    goToLogin: () => void;
}

export const SessionExpired = (props: SessionExpiredProps) => {
    const { goToLogin, maxNoActiveTime, isSessionEnd } = props;
    const { listen } = useHistory();
    const [t] = useTranslation(['session-expired']);
    const [isOpenPanel, setIsOpenPanel] = useState(false);

    useEffect(() => {
        if (isSessionEnd) {
            const unregister = listen(() => {
                unregister();
                goToLogin();
            });

            setIsOpenPanel(true);
        }
    }, [isSessionEnd, listen, goToLogin]);

    return isOpenPanel ? (
        <div className="session-expired-panel">
            {t('session-text', {
                noActiveTime: mapSecondsToTimeString(maxNoActiveTime, t),
            })}
            <div className="session-expired-panel-close" onClick={() => setIsOpenPanel(false)}>
                <Icon iconName="close-icon" />
            </div>
        </div>
    ) : null;
};
