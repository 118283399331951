export enum LevelOfEvaluation {
    Fail = 2,
    Lowest = 3,
    Middle = 4,
    Highest = 5,
}

export enum SimpleLevelOfEvaluation {
    Fail = 2,
    Highest = 5,
}
