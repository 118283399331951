import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useForm, ValidationOptions, Controller, FormContext } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SelectCountry from '../../../components/SelectCountry/SelectCountry';
import {
    actions as tcAuthenticationActions,
    TCAdminAuthenticationState,
    UpdateTCAdmin,
    UpdateTCAdminWithAgreements,
} from '../../../authentication/TCAdminAuthenticationStore';
import Captcha from '../../../components/Captcha/Captcha';
import { UserState } from '../../../enums/user-state';
import { actions as studyCentersActions } from '../../study-centers/store/actions';

import AgreementsForm from '../../../components/AgreementsForm/AgreementsForm';
import { StudyCenterState } from '../../study-centers/store/store';
import { Button, Column, ModalWindow, Option, Select, TextInput, Title } from '../../../components';
import {
    CHANGE_PASSWORD_PATH,
    ADMIN_STUDY_DELETE_PATH,
    MAX_USER_NAME_LENGTH,
    MIN_TEXT_INPUT_LENGTH,
} from '../../../global-constants';
import { ApplicationState } from '../../../store';
import { withLoading } from '../../../utils';

import './UpdateAdminStudyCenter.scss';
import { AppSettingsState } from 'store/app-settings/store';

type UpdateAdminStudyCenterProps = TCAdminAuthenticationState &
    StudyCenterState &
    typeof tcAuthenticationActions &
    typeof studyCentersActions & {
        settings: AppSettingsState;
    };

const UpdateAdminStudyCenter: FC<UpdateAdminStudyCenterProps> = (props) => {
    const { admin, updateAdmin, updateAdminWithAgreements, loadStudyCenters, studyCenters, settings } = props;

    const [t] = useTranslation(['study-center', 'info', 'validation']);
    const methods = useForm<UpdateTCAdminWithAgreements>();
    const { register, handleSubmit, control, errors, formState } = methods;
    const { isSubmitting } = formState;
    const captchaRef = useRef<ReCAPTCHA>(null);

    const [confirmUpdateIsOpen, setConfirmUpdateIsOpen] = useState<boolean>(false);
    const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState<boolean>(false);

    const onSubmit = handleSubmit(async (formValues: UpdateTCAdmin | UpdateTCAdminWithAgreements) => {
        const captchaCode = (await captchaRef?.current?.executeAsync()) as string;
        const values = {
            ...formValues,
            captchaCode,
            marketingVersion: settings.marketingVersion,
            personalVersion: settings.personalVersion,
        };

        if ('marketingAgreement' in values) {
            await updateAdminWithAgreements(values);
            return;
        }

        await updateAdmin(values);
    });

    const sendForm = async (e: FormEvent) => {
        e.preventDefault();
        if (admin?.approved === UserState.Approve) {
            setConfirmUpdateIsOpen(true);
            return;
        }

        await onSubmit();
    };

    const handleDeleteAccount = () => {
        window.location.href = ADMIN_STUDY_DELETE_PATH;
    };

    useEffect(() => {
        loadStudyCenters();
    }, [loadStudyCenters]);

    const [studyCenterOptions, setStudyCenterOptions] = useState<Option[]>([]);
    useEffect(() => {
        if (!studyCenters.length) {
            return;
        }

        setStudyCenterOptions(
            studyCenters.map((studyCenter) => ({
                label: studyCenter.name,
                value: studyCenter.id,
            }))
        );
    }, [studyCenters]);

    const getRegisterOptions = (isRequired: boolean): ValidationOptions => ({
        minLength: {
            value: MIN_TEXT_INPUT_LENGTH,
            message: t('validation:min-length', {
                minLength: MIN_TEXT_INPUT_LENGTH,
            }),
        },
        maxLength: {
            value: MAX_USER_NAME_LENGTH,
            message: t('validation:max-length', {
                maxLength: MAX_USER_NAME_LENGTH,
            }),
        },
        required: {
            value: isRequired,
            message: 'validation:required-field',
        },
    });

    const getStudyCenterSelectValue = (): Option => {
        if (!admin?.studyCenter) {
            return studyCenterOptions[0];
        }

        return studyCenterOptions.find((item) => item.value === admin.studyCenter.id) || studyCenterOptions[0];
    };

    const handleChangePass = () => {
        window.location.href = CHANGE_PASSWORD_PATH;
    };

    return (
        <Column columnSize="100" className="update-admin-study-center">
            <Title titleText={t('info:title')} />

            <ModalWindow
                isOpen={confirmUpdateIsOpen}
                onCloseClick={() => setConfirmUpdateIsOpen(false)}
                modalMessage={t('confirm-edit-profile')}
                onConfirmAction={async () => {
                    setConfirmUpdateIsOpen(false);
                    await onSubmit();
                }}
                confirmButtonText={t('info:ok')}
                cancelButtonText={t('info:cancel')}
            />

            <ModalWindow
                isOpen={confirmDeleteIsOpen}
                onCloseClick={() => setConfirmDeleteIsOpen(false)}
                modalMessage={t('study-center-admin-delete-acc')}
                onConfirmAction={handleDeleteAccount}
                confirmButtonText={t('info:confirm-delete')}
                cancelButtonText={t('info:cancel')}
            />

            <FormContext {...methods}>
                <form onSubmit={sendForm} autoComplete="off" className="info-form">
                    <Captcha ref={captchaRef} />
                    <div className="top-space-3x">
                        <TextInput
                            defaultValue={admin?.name}
                            label={t('info:first-name')}
                            name="userName"
                            id="userName"
                            inputRef={register(getRegisterOptions(true))}
                            errorMessage={t(errors.userName?.message as string)}
                        />
                    </div>
                    <div className="top-space-3x">
                        <TextInput
                            defaultValue={admin?.lastName}
                            label={t('info:last-name')}
                            name="lastName"
                            id="lastName"
                            inputRef={register(getRegisterOptions(true))}
                            errorMessage={t(errors.lastName?.message as string)}
                        />
                    </div>
                    <div className="top-space-3x">
                        <TextInput
                            defaultValue={admin?.middleName}
                            label={t('info:middle-name')}
                            name="middleName"
                            id="middleName"
                            inputRef={register(getRegisterOptions(false))}
                            errorMessage={t(errors.middleName?.message as string)}
                        />
                    </div>
                    <fieldset className="form-group top-space-3x">
                        <label className="form-label">{t('info:country')}</label>
                        <Controller
                            control={control}
                            name="countryId"
                            defaultValue={admin?.country?.id || 1}
                            selectedId={admin?.country?.id || 1}
                            onChange={([id]) => id}
                            as={SelectCountry}
                        />
                    </fieldset>
                    {!!studyCenterOptions?.length && (
                        <fieldset className="form-group top-space-3x">
                            <label className="form-label">{t('info:studyCenter')}</label>
                            <Controller
                                control={control}
                                name="studyCenterId"
                                defaultValue={admin?.studyCenter?.id || studyCenterOptions[0].value}
                                values={[getStudyCenterSelectValue()]}
                                onChange={([selectedValue]) => selectedValue[0]?.value}
                                options={studyCenterOptions}
                                searchable
                                as={Select}
                            />
                        </fieldset>
                    )}
                    {(!admin?.lastName || !admin?.approvedAgreements) && <AgreementsForm />}
                    <div className="info-buttons d-flex justify-content-space-between">
                        <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                            {t('info:save-button')}
                        </Button>

                        {(admin?.approved === UserState.Approve || admin?.approved === UserState.Rejected) && (
                            <Button buttonClass="secondary" handleClick={handleChangePass}>
                                {t('info:change-password-button')}
                            </Button>
                        )}
                    </div>
                    {(admin?.approved === UserState.Approve || admin?.approved === UserState.Rejected) && (
                        <button
                            type="button"
                            onClick={() => setConfirmDeleteIsOpen(true)}
                            className="link top-space-3x"
                            disabled={isSubmitting}
                        >
                            {t('info:delete-account')}
                        </button>
                    )}
                </form>
            </FormContext>
        </Column>
    );
};

export default connect(
    ({ tcAuthentication, studyCenters, appSettings }: ApplicationState) => ({
        ...tcAuthentication,
        ...studyCenters,
        settings: appSettings,
    }),
    { ...tcAuthenticationActions, ...studyCentersActions }
)(withLoading(UpdateAdminStudyCenter) as () => JSX.Element);
