import { useEffect, useState } from 'react';

export const theme = {
    light: 'theme-light',
    primary: 'theme-primary',
    noSideBarMenu: 'no-sidebar-menu',
    hideLanguagePicker: 'hide-language-picker',
    pageNotFound: 'page-not-found',
    noGlobalNav: 'no-global-nav',
    loaderOverSidebar: 'loader-over-sidebar',
    landing: 'theme-landing',
} as const;

export type Theme = (typeof theme)[keyof typeof theme];

export const useTheme = (theme: Theme) => {
    const [className, setClassName] = useState<Theme>(theme);

    const changeTheme = (newTheme: Theme) => setClassName(newTheme);
    const removeTheme = () => document.body.classList.remove(className);

    useEffect(() => {
        setClassName(theme);
    }, [theme]);

    useEffect(() => {
        if (className) {
            document.body.classList.add(className);
            return () => document.body.classList.remove(className);
        }
    }, [className]);

    return { changeTheme, removeTheme };
};
