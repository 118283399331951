import React from 'react';
import { useTranslation } from 'react-i18next';
import { NestDataObject, FieldError, ValidationOptions } from 'react-hook-form';

import { TextInput, RefType } from 'components';
import { AddUserData } from '../store/store';
import { MIN_TEXT_INPUT_LENGTH, MAX_USER_NAME_LENGTH } from 'global-constants';

interface UserNameInputProps {
    register: (value: ValidationOptions) => RefType;
    defaultValue?: string | number;
    errors: NestDataObject<AddUserData, FieldError>;
    label?: string;
    name: keyof AddUserData;
    maxLength?: number;
    isRequired: boolean;
}

export const TextFieldInput = (props: UserNameInputProps) => {
    const { register, errors, isRequired, label, name, defaultValue, maxLength = MAX_USER_NAME_LENGTH } = props;
    const [t] = useTranslation('validation');
    const error = errors[name];
    const getOptions: ValidationOptions = {
        minLength: {
            value: MIN_TEXT_INPUT_LENGTH,
            message: 'validation:min-length',
        },
        maxLength: {
            value: maxLength,
            message: 'validation:max-length',
        },
        required: {
            value: isRequired,
            message: 'validation:required-field',
        },
    };
    return (
        <div className="top-space-3x">
            <TextInput
                label={label}
                name={name}
                inputRef={register(getOptions)}
                defaultValue={defaultValue}
                id={name}
                errorMessage={t(error?.message as string, {
                    maxLength: maxLength,
                    minLength: MIN_TEXT_INPUT_LENGTH,
                })}
            />
        </div>
    );
};
