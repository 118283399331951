import { Action, Reducer } from 'redux';
import { CertDirection } from '../../../enums/cert-direction';
import { ProductLKType } from '../../../enums/product-lk-type';
import { Test } from '../../../models/test/test';
import { TestCategoryForm } from '../../../models/test/test-category-form';
import { TestShort } from '../../../models/test/test-short';

import {
    ActionTypes,
    ADD_TEST,
    CHECK_TEST_CATEGORY,
    CLEAR_EDITABLE_TEST,
    CLEAR_MESSAGE_TEST,
    DELETE_TEST,
    GET_CATEGORIES,
    GET_TEST,
    LOAD_TESTS,
    SET_DIRECTION,
    SET_ERROR_TEST,
    SET_LANGUAGE,
    SET_LOADING,
    SET_LOADING_CATEGORIES,
    SET_QUANTITY_SELECTED_QUESTIONS,
    SET_SUCCESS_TEST,
    SET_TEST_TYPE,
    SET_TEST_AREA,
    SET_PRODUCT_GROUP,
    UPDATE_TEST,
} from './actions';
import { LanguageEnum } from '../../../enums/language.enum';
import { checkTestCategory, setQuantitySelectedQuestions } from './mappers';

export interface TestsState {
    isLoading: boolean;
    init: boolean;
    isLoadingCategories: boolean;
    tests: TestShort[];
    editableTest: Test;
    editableTestCategories: TestCategoryForm[];
    testSuccess: string;
    testError: string;
    selectedDirectionId: CertDirection;
    selectedTestType?: ProductLKType;
    selectedLanguage?: LanguageEnum;
    selectedTestAreaId?: string;
    selectedProductGroupId?: string;
}

const initialState: Readonly<TestsState> = {
    init: true,
    isLoading: false,
    isLoadingCategories: false,
    tests: [],
    editableTest: {} as Test,
    editableTestCategories: [],
    testSuccess: '',
    testError: '',
    selectedDirectionId: CertDirection.CyberSecurity,
    selectedLanguage: undefined,
    selectedTestAreaId: undefined,
    selectedProductGroupId: undefined,
};

export const reducer: Reducer<TestsState> = (state: TestsState = initialState, incomingAction: Action): TestsState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case ADD_TEST:
            return {
                ...state,
                tests: [...state.tests, action.test as TestShort],
                testSuccess: 'Тест добавлен',
            };
        case GET_TEST:
            return {
                ...state,
                editableTest: action.editableTest,
                isLoading: false,
            };
        case GET_CATEGORIES:
            return {
                ...state,
                isLoadingCategories: false,
                editableTestCategories: action.editableTestCategories,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case SET_LOADING_CATEGORIES:
            return {
                ...state,
                isLoadingCategories: action.isLoading === undefined || action.isLoading,
            };
        case LOAD_TESTS:
            return {
                ...state,
                tests: action.tests,
                isLoading: false,
                init: false,
            };
        case DELETE_TEST:
            return {
                ...state,
                tests: state.tests.filter((x) => x.id !== action.testId),
                isLoading: false,
                testSuccess: 'Тест удален',
            };
        case CLEAR_EDITABLE_TEST:
            return {
                ...state,
                editableTest: {} as Test,
                editableTestCategories: [],
                isLoading: false,
                isLoadingCategories: false,
            };
        case UPDATE_TEST:
            return {
                ...state,
                testSuccess: 'Тест обновлен',
            };
        case SET_ERROR_TEST:
            return {
                ...state,
                testError: action.testError,
                isLoading: false,
                isLoadingCategories: false,
            };
        case SET_SUCCESS_TEST:
            return {
                ...state,
                testSuccess: action.testSuccess,
            };
        case CLEAR_MESSAGE_TEST:
            return {
                ...state,
                testSuccess: '',
                testError: '',
            };
        case SET_DIRECTION:
            return {
                ...state,
                selectedDirectionId: action.directionId,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.language,
            };
        case SET_TEST_TYPE:
            return {
                ...state,
                selectedTestType: action.testType,
            };
        case SET_TEST_AREA:
            return {
                ...state,
                selectedTestAreaId: action.testAreaId,
            };
        case SET_PRODUCT_GROUP:
            return {
                ...state,
                selectedProductGroupId: action.productGroupId,
            };
        case CHECK_TEST_CATEGORY:
            return {
                ...state,
                editableTestCategories: checkTestCategory(state.editableTestCategories, action.id),
            };
        case SET_QUANTITY_SELECTED_QUESTIONS:
            return {
                ...state,
                editableTestCategories: setQuantitySelectedQuestions(
                    state.editableTestCategories,
                    action.id,
                    action.value
                ),
            };
        default:
            return state;
    }
};
