import React, { FC } from 'react';
import { EmptyListWarning, Grid, Row } from '../../components';
import { AdditionalCertificateLevel } from './models/additional-certificate-level';
import { LevelSettings } from './models/level-settings';
import { UpdateLevelSettingsArea } from './models/update-level-settings-area';
import { ResponsePagination } from 'models/response-pagination';
import { TestArea } from 'pages/test-areas/models/AreaModels';
import api from 'api';
import { AutoCompliteMany } from 'components/AutoComplite/AutoCompliteMany';

const levelConvert = (level?: AdditionalCertificateLevel) => {
    if (!level) return '';

    if (level === AdditionalCertificateLevel.Level1) return 'System Engineer Level I';

    if (level === AdditionalCertificateLevel.Level2) return 'System Engineer Level II';
};

interface Props {
    data: LevelSettings[];
    updateSetting: (model: UpdateLevelSettingsArea) => void;
}

export const SettingsGrid: FC<Props> = ({ data, updateSetting }) => {
    const getAreas = (page: number, size: number, str?: string): Promise<{ data: ResponsePagination<TestArea> }> =>
        api.get('/TestArea/', {
            params: {
                page,
                size,
                str,
            },
        });
    const getGridData = (): Row[] =>
        data.map((item, index) => ({
            level: levelConvert(item.level),
            areaName: {
                additionalComponent: (
                    <div className="top-space-3x">
                        <AutoCompliteMany
                            label="Область применения"
                            getArrAsync={(s?: string) =>
                                getAreas(0, 20, s).then((d) => d.data.items.map((m) => ({ id: m.id, name: m.name })))
                            }
                            labelField="name"
                            valueField="id"
                            name="testAreaId"
                            id="testAreaId"
                            isMulty
                            onChange={(e) => updateSetting({ level: item.level, testAreas: e ?? [], index })}
                            values={item.areas}
                        />
                    </div>
                ),
            },
        }));
    return data.length ? <Grid columns={getColumns()} rows={getGridData()} /> : <EmptyListWarning />;
};

const getColumns = () => ({
    level: {
        value: 'Уровень',
        columnSize: '30',
    },
    areaName: {
        value: 'Область',
        columnSize: '70',
    },
});
