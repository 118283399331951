import { Action, Reducer } from 'redux';
import { User } from '../../../authentication/UserAuthenticationStore';
import { ResponsePagination } from '../../../models/response-pagination';
import { UserHistorySf } from '../../../models/user/user-history-sf';
import { ActionTypes, SET_LOADING, SET_LOADING_EXCEL, SET_USERS, SET_USERS_HISTORY_SF } from './actions';

export interface UsersState {
    users: ResponsePagination<User>;
    usersHistorySf: ResponsePagination<UserHistorySf>;
    isLoading: boolean;
    isLoadingExcel: boolean;
}

const initialState: Readonly<UsersState> = {
    usersHistorySf: {
        items: [],
        count: 0,
    },
    users: {
        items: [],
        count: 0,
    },
    isLoading: true,
    isLoadingExcel: false,
};

export const reducer: Reducer<UsersState> = (state: UsersState = initialState, incomingAction: Action): UsersState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.users,
                isLoading: false,
            };
        case SET_USERS_HISTORY_SF:
            return {
                ...state,
                usersHistorySf: action.users,
                isLoading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_LOADING_EXCEL:
            return {
                ...state,
                isLoadingExcel: action.isLoadingExcel,
            };
        default:
            return state;
    }
};
