interface GetAssessmentInputsData {
    lowestPoints: number;
    lowestPointsDescription: string;
    middlePoints: number;
    middlePointsDescription: string;
    highestPoints: number;
    highestPointsDescription: string;
}

const ENTER_MESSAGE_FOR_USER = 'Введите сообщение для пользователя';

export const getAssessmentInputsData = (props: GetAssessmentInputsData) => ({
    lowestPoints: {
        assessment: {
            label: 'Минимальное кол-во баллов для низкой оценки',
            value: props.lowestPoints,
        },
        description: {
            label: ENTER_MESSAGE_FOR_USER,
            value: props.lowestPointsDescription,
        },
    },
    middlePoints: {
        assessment: {
            label: 'Минимальное кол-во баллов для средняя оценки',
            value: props.middlePoints,
        },
        description: {
            label: ENTER_MESSAGE_FOR_USER,
            value: props.middlePointsDescription,
        },
    },
    highestPoints: {
        assessment: {
            label: 'Минимальное кол-во баллов для высокая оценки',
            value: props.highestPoints,
        },
        description: {
            label: ENTER_MESSAGE_FOR_USER,
            value: props.highestPointsDescription,
        },
    },
});
