import { Role } from '../../../enums/roles';
import { StudentStudyCenterAgreements } from '../../../models/student-study-center/student-study-center-agreements';
import { StudyGroupTestCondition, StudyGroupTestConditionPassed } from '../../../models/study-group-test-condition';
import { UserTestLinkService } from '../../../services/user-test-link.service';
import { AppThunkAction } from '../../../store';
import { AuthStudyCenterUser } from './store';

export const SET_LOADING = 'study-center-student/SET_LOADING';
export const GET_STUDENT = 'study-center-student/GET_STUDENT';
export const GET_STUDENT_FAILED = 'study-center-student/GET_STUDENT_FAILED';
export const APPROVE_AGREEMENTS = 'study-center-student/APPROVE_AGREEMENTS';
export const GET_CONDITIONS = 'study-center-student/GET_CONDITIONS';
export const SET_TEST_PASSED = 'study-center-student/SET_TEST_PASSED';
export const GET_CONDITIONS_FAILED = 'study-center-student/GET_CONDITIONS_FAILED';
export const START_TEST = 'study-center-student/START_TEST';

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface GetUser {
    type: typeof GET_STUDENT;
    student: AuthStudyCenterUser;
}

export interface GetUserFailed {
    type: typeof GET_STUDENT_FAILED;
    statusCode?: number;
    error?: string;
}

export interface ApproveAgreements {
    type: typeof APPROVE_AGREEMENTS;
    personalVersion: number;
    marketingVersion: number;
}

export interface GetConditions {
    type: typeof GET_CONDITIONS;
    studyGroupTestCondition: StudyGroupTestCondition;
}

export interface SetTestPassed {
    type: typeof SET_TEST_PASSED;
    testCondition: StudyGroupTestConditionPassed;
}

export interface GetConditionsFailed {
    type: typeof GET_CONDITIONS_FAILED;
    statusCode?: number;
    error?: string;
}

export interface StartTest {
    type: typeof START_TEST;
}

export type ActionTypes =
    | SetLoading
    | GetUser
    | GetUserFailed
    | ApproveAgreements
    | GetConditions
    | SetTestPassed
    | GetConditionsFailed
    | StartTest;

export const actions = {
    getStudent:
        (tempLinkId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const studentInfo = (await UserTestLinkService.getUser(tempLinkId)).data;

                dispatch({
                    type: GET_STUDENT,
                    student: {
                        ...studentInfo.profile,
                        role: Role.StudentStudyCenter,
                        name: studentInfo.profile.userName,
                        approvedAgreements: studentInfo.profile.approvedAgreements,
                        personalVersion: studentInfo.profile.personalVersion,
                        marketingVersion: studentInfo.profile.marketingVersion,
                    },
                });
            } catch (e: any) {
                dispatch({
                    type: GET_STUDENT_FAILED,
                    error: e?.message,
                    statusCode: e.response?.status,
                });
            }
        },

    approveAgreements:
        (agr: StudentStudyCenterAgreements): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await UserTestLinkService.approveAgreements(agr);

                dispatch({
                    type: APPROVE_AGREEMENTS,
                    personalVersion: agr.personalVersion,
                    marketingVersion: agr.marketingVersion,
                });
            } catch (e: any) {
                console.error(e?.message);
            }
        },

    getConditions:
        (tempLinkId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const studyGroupTestCondition = (await UserTestLinkService.getConditions(tempLinkId)).data;

                if ('statusCode' in studyGroupTestCondition) {
                    dispatch({
                        type: SET_TEST_PASSED,
                        testCondition: studyGroupTestCondition,
                    });

                    dispatch({
                        type: GET_CONDITIONS_FAILED,
                        error: 'test passed',
                        statusCode: studyGroupTestCondition.statusCode,
                    });

                    return;
                }

                dispatch({
                    type: GET_CONDITIONS,
                    studyGroupTestCondition,
                });
            } catch (e: any) {
                dispatch({
                    type: GET_CONDITIONS_FAILED,
                    error: e?.message,
                    statusCode: e.response?.status,
                });
            }
        },

    startTest:
        (tempLinkId: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await UserTestLinkService.startTest(tempLinkId);

                dispatch({
                    type: START_TEST,
                });
            } catch (e: any) {
                console.error(e?.message);
            }
        },
};
