import React from 'react';
import { RefType } from '../Form';

interface CheckboxProps {
    id: string;
    label?: string;
    inputRef?: RefType;
    name?: string;
    isChecked?: boolean;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    click?: () => void;
    additionalClasses?: string;
    isDisabled?: boolean;
}

export const Checkbox = (props: CheckboxProps) => {
    const { id, inputRef, isChecked, handleChange, additionalClasses, name, label, isDisabled, click } = props;

    const classes = `checkbox ${additionalClasses ? additionalClasses : ''} ${isDisabled ? 'disabled' : ''}`;

    const preClick = () => {
        if (!isDisabled && click) {
            click();
        }
    };

    return (
        <div className={classes}>
            <input
                onClick={preClick}
                disabled={isDisabled}
                className="checkbox_control"
                type="checkbox"
                id={id}
                name={name}
                ref={inputRef}
                checked={isChecked}
                onChange={handleChange}
            />
            <label className="checkbox_text" htmlFor={id}>
                {label && <span>{label}</span>}
            </label>
        </div>
    );
};
