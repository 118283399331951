import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../Form';
import { Option, Select } from '../Select';
import { LanguageEnum } from '../../enums/language.enum';
import { getActiveLanguages } from '../../constants/language';

interface SelectLanguageProps {
    selectedLanguage?: LanguageEnum;
    onChange: (lang: LanguageEnum) => void;
    hasEmpty?: boolean;
    additionalClasses?: string;
}

const SelectLanguage: FC<SelectLanguageProps> = ({ selectedLanguage, onChange, hasEmpty, additionalClasses }) => {
    const [t] = useTranslation('test-language');

    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        const options: Option[] = getActiveLanguages().map((lang) => ({ label: lang.labelAdmin, value: lang.id }));

        if (hasEmpty) {
            options.unshift({ label: t('test-language:all-label'), value: '' });
        }

        setOptions(options);
    }, [getActiveLanguages, t]);

    const getSelectedLanguage = options.find((item) => item.value === selectedLanguage) || options[0];

    if (!options.length) {
        return null;
    }

    return (
        <>
            <Label labelText={t('test-language:select-label')} labelFor="testLanguage" />
            <Select
                additionalClasses={additionalClasses}
                name="testLanguage"
                values={[getSelectedLanguage]}
                options={options}
                onChange={([select]) => onChange(select.value as LanguageEnum)}
            />
        </>
    );
};

export default SelectLanguage;
