import React from 'react';
import { UserCardAttempt } from '../store/store';

import { Column, EmptyListWarning, Grid } from 'components';

const columns = {
    trainingGroupCode: {
        value: 'Учебная группы',
        columnSize: '15',
    },
    testName: {
        value: 'Тест',
        columnSize: '25',
    },
    attemptNumber: {
        value: '# Попытки',
        columnSize: '10',
    },
    attemptDate: {
        value: 'Дата',
        columnSize: '10',
    },
    duration: {
        value: 'Время',
        columnSize: '15',
    },
    statistics: {
        value: 'Статистика',
        columnSize: '10',
    },
    levelOfEvaluationDescription: {
        value: 'Результат',
        columnSize: '15',
    },
};

export const UserCardGrid = (props: {
    attempts: UserCardAttempt[];
    getAttemptDetails: (passingTestId: number) => void;
    setModalIsOpen: (value: boolean) => void;
}) => {
    const { attempts, getAttemptDetails, setModalIsOpen } = props;

    const getGridData = () =>
        attempts.map((x) => ({
            ...x,
            attemptNumber: `${x.attemptNumber}/${x.allAttempts}`,
            statistics: {
                value: `${x.resultPoint}/${x.allPoint}`,
                attemptNumber: `${x.attemptNumber}/${x.allAttempts}`,
                additionalComponent: (
                    <div
                        className="details"
                        onClick={() => {
                            getAttemptDetails(x.passingTestId);
                            setModalIsOpen(true);
                        }}
                    >
                        Подробнее
                    </div>
                ),
            },
        }));

    return (
        <Column columnSize="100">
            {attempts.length ? <Grid rows={getGridData()} columns={columns} /> : <EmptyListWarning />}
        </Column>
    );
};
