import React, { FC, useState } from 'react';
import { EmptyListWarning, Button, Icon, Row } from 'components';
import Pagination, { PaginationProps } from 'components/Pagination/Pagination';
import { SfCertificate } from './models/sf-certificate';
import { enumToCode } from 'utils/lang';
import { MultilanguageLanguageEnum } from './models/multy-lang';
import { CertificationEnum } from 'enums/certificationEnum';
import { dateFormat, dateTimeFormat } from '../../constants/date-format';
import moment from 'moment';
import {
    CertificationType,
    ChangeUserNameOnCertificate,
} from 'components/ChangeUserNameOnCertificate/ChangeUserNameOnCertificate';

interface Props {
    data: SfCertificate[];
    pagination: PaginationProps;
    success: (message: string) => void;
    error: (message: string) => void;
    setLoading: (loading: boolean) => void;
    update: () => void;
}

export const SfGrid: FC<Props> = ({ data, pagination, success, error, setLoading, update }) => {
    const [modalIsOpenChangeName, setModalIsOpenChangeName] = useState(false);
    const [userId, setUserId] = useState('');
    const [certificateId, setCertificateId] = useState('');
    return data.length ? (
        <>
            <ul className="sf-grid scroll">
                <li className="sf-grid-header">
                    <div className="sf-grid-header-owner cell">Владелец</div>
                    <div className="sf-grid-header-email cell">Email</div>
                    <div className="sf-grid-header-company cell">Компания</div>
                    <div className="sf-grid-header-country cell">Страна</div>
                    <div className="sf-grid-header-testName cell">Имя теста</div>
                    <div className="sf-grid-header-testArea cell">Область применения</div>
                    <div className="sf-grid-header-productGroup cell">Продуктовая группа</div>
                    <div className="sf-grid-header-testCode cell">Код теста</div>
                    <div className="sf-grid-header-version cell">Версия</div>
                    <div className="sf-grid-header-actual cell">Актуальность</div>
                    <div className="sf-grid-header-download cell">Скачать</div>
                    <div className="sf-grid-header-testLang cell">Язык теста</div>
                    <div className="sf-grid-header-creationDate cell">Дата создания</div>
                    <div className="sf-grid-header-creationDate cell">Дата редактирования</div>
                    <div className="sf-grid-header-finishDate cell">Дата окончания</div>
                    <div className="sf-grid-header-number cell">Номер</div>
                    <div className="sf-grid-header-status cell">Статус</div>
                    <div className="sf-grid-header-edit cell">Редактировать</div>
                </li>
                {data.map((item, index) => (
                    <li className="sf-grid-item" key={`${item.id}-${index}`}>
                        <div className="sf-grid-item-owner cell">{item.userName}</div>
                        <div className="sf-grid-item-email cell">{item.email}</div>
                        <div className="sf-grid-item-company cell">{item.companyName}</div>
                        <div className="sf-grid-item-country cell">{item.country}</div>
                        <div className="sf-grid-item-testName cell">{item.testName}</div>
                        <div className="sf-grid-item-testArea cell">{item.testArea}</div>
                        <div className="sf-grid-item-productGroup cell">{item.productGroup}</div>
                        <div className="sf-grid-item-testCode cell">{item.examCode}</div>
                        <div className="sf-grid-item-version cell">{item.version}</div>
                        <div className="sf-grid-item-actual cell">{item.isActualVersion}</div>
                        <div className="sf-grid-item-download cell">
                            {
                                <a
                                    className="link"
                                    href={`/api/GetCertificate/${item.id}/${item.userId}/certificate.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Скачать
                                </a>
                            }
                        </div>
                        <div className="sf-grid-item-testLang cell">{MultilanguageLanguageEnum[item.testLanguage]}</div>
                        <div className="sf-grid-item-creationDate cell">
                            {moment(item.creationDate).format(dateTimeFormat)}
                        </div>
                        <div className="sf-grid-item-creationDate cell">
                            {moment(item.editDate).format(dateTimeFormat)}
                        </div>
                        <div className="sf-grid-item-finishDate cell">
                            {!item?.expirationDate ? '' : moment(item.expirationDate).format(dateTimeFormat)}
                        </div>
                        <div className="sf-grid-item-number cell">{item.certificationNumber}</div>
                        <div className="sf-grid-item-status cell">{item.isActive ? 'Действительный' : 'Истёк'}</div>
                        <div className="sf-grid-item-edit cell">
                            <Button
                                pSize="none"
                                handleClick={() => {
                                    setUserId(item.userId);
                                    setCertificateId(item.id as string);
                                    setModalIsOpenChangeName(true);
                                }}
                            >
                                <Icon iconName="edit" />
                            </Button>
                        </div>
                    </li>
                ))}
            </ul>
            {pagination?.size && <Pagination {...pagination} />}
            {modalIsOpenChangeName && (
                <ChangeUserNameOnCertificate
                    update={update}
                    isOpen={modalIsOpenChangeName}
                    close={() => setModalIsOpenChangeName(false)}
                    type={CertificationType.History}
                    userId={userId}
                    id={certificateId}
                    success={success}
                    error={error}
                    setLoading={setLoading}
                />
            )}
        </>
    ) : (
        <EmptyListWarning />
    );
};
