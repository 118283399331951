import React, { FC, useEffect, useState } from 'react';
import { useUrlQueryParamsForGrid } from 'hooks/useUrlQueryParamsForGrid';
import { GetCertificates, GetCertificatesXls } from './models/get-certificates';
import { SfCertificate } from './models/sf-certificate';
import { TestName } from './models/test-name';
import { MultilanguageLanguageEnum } from './models/multy-lang';
import { CertificationTypeFilter } from 'enums/certification-type';
import { ResponsePagination } from 'models/response-pagination';
import { DownloadFileService } from '../../services/download-file.service';
import api from 'api';
import { Button, Checkbox, Column, Container, DatePricker, Label, Loader, Select, TextInput, Title } from 'components';
import { SelectOption } from 'models/select-option';
import { getDateZeroTimeZoneISO } from 'utils/date';
import SelectByEnum from 'components/SelectByEnum/SelectByEnum';
import { SfGrid } from './sf-certificates-grid';
import { getAreas } from 'pages/test-areas/services/TestAreaService';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';
import { useAlert } from 'react-alert';
import './sf-container.scss';
import { AutoCompliteMany } from 'components/AutoComplite/AutoCompliteMany';
import { getGroups } from 'pages/productGroups/services/ProductGroupService';

const defaultSelectOption = {
    value: -1,
    label: '',
};

const SfResults: FC = () => {
    const alert = useAlert();
    const [certificateNumber, setCertificateNumber] = useState('');

    const [isLoading, setLoading] = useState(false);

    const [count, setCount] = useState(0);
    const [data, setData] = useState<Array<SfCertificate>>([]);
    const [testNamesSearch, setTestNamesSearch] = useState<Array<TestName>>([]);
    const [testNames, setTestNames] = useState<Array<TestName>>([]);
    const [selectedTest, setSelectedTest] = useState<SelectOption>();
    const [selectedTestFilter, setSelectedTestFilter] = useState<SelectOption>();
    const [useFinishDate, setUseFinishDate] = useState<boolean>(false);
    const [useCountMonth, setUseCountMonth] = useState<boolean>(false);
    const [monthCount, setMonthCount] = useState<number | undefined>(undefined);
    const [expiredDate, setExpiredDate] = useState<string | undefined>(undefined);
    const [disableChangeButton, setDisableChangeButton] = useState<boolean>(false);

    const [testLanguage, setTestLanguage] = useState<MultilanguageLanguageEnum | undefined>(undefined);
    const [certificateType, setCertificateType] = useState<CertificationTypeFilter | undefined>(undefined);

    const [email, setEmail] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [testCode, setTestCode] = useState<string>();
    const [testArea, setTestArea] = useState<string[] | undefined>([]);
    const [groupsId, setGroupsId] = useState<string[] | undefined>([]);

    const [testLanguageVersion, setTestLanguageVersion] = useState<MultilanguageLanguageEnum | undefined>(undefined);
    const [testNamesVersion, setTestNamesVersion] = useState<Array<TestName>>([]);
    const [testCodesVersion, setTestCodesVersion] = useState<string[]>([]);
    const [testVersions, setTestVersions] = useState<string[]>([]);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [disableActiveVersion, setDisableActiveVersion] = useState<boolean>(false);
    const [disableTestArea, setDisableTestArea] = useState<boolean>(false);
    const [disableTestGroup, setDisableTestGroup] = useState<boolean>(false);

    const [testLanguageArea, setTestLanguageArea] = useState<MultilanguageLanguageEnum | undefined>(undefined);
    const [testLanguageGroup, setTestLanguageGroup] = useState<MultilanguageLanguageEnum | undefined>(undefined);
    const [testNamesArea, setTestNamesArea] = useState<Array<TestName>>([]);
    const [testNamesGroup, setTestNamesGroup] = useState<Array<TestName>>([]);

    const [testLanguageDate, setTestLanguageDate] = useState<MultilanguageLanguageEnum | undefined>(undefined);
    const [testNamesDate, setTestNamesDate] = useState<Array<TestName>>([]);

    const [selectedTestNameVersion, setSelectedTestNameVersion] = useState<SelectOption>();
    const [selectedTestCodeVersion, setSelectedTestCodeVersion] = useState<SelectOption>(defaultSelectOption);
    const [selectedTestVersion, setSelectedTestVersion] = useState<SelectOption>(defaultSelectOption);
    const [selectedTestNameArea, setSelectedTestNameArea] = useState<SelectOption>();
    const [selectedTestNameGroup, setSelectedTestNameGroup] = useState<SelectOption>();

    const getDateStart = (): string => {
        const date = new Date();
        date.setDate(date.getDate() - 300 * 8);
        date.setHours(0, 0, 0);
        return date.toISOString();
    };

    const [dateStart, setDateStart] = useState<string | undefined>(getDateStart());
    const [dateEnd, setDateEnd] = useState<string | undefined>(new Date().toISOString());

    useEffect(() => {
        setLoading(true);
        api.get<Array<TestName>>(`/SalesForceCertificate/GetTestNames`, {
            params: {
                language: testLanguageDate,
            },
        })
            .then((d) => {
                setTestNamesDate(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [testLanguageDate]);

    useEffect(() => {
        setLoading(true);
        api.get<Array<TestName>>(`/SalesForceCertificate/GetTestNames`, {
            params: {
                language: testLanguageVersion,
            },
        })
            .then((d) => {
                setSelectedTestCodeVersion(defaultSelectOption);
                setSelectedTestVersion(defaultSelectOption);
                setTestNamesVersion(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [testLanguageVersion]);

    useEffect(() => {
        setLoading(true);
        api.get<Array<TestName>>(`/SalesForceCertificate/GetTestNames`, {
            params: {
                language: testLanguageArea,
            },
        })
            .then((d) => {
                setTestNamesArea(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [testLanguageArea]);

    useEffect(() => {
        setLoading(true);
        api.get<Array<TestName>>(`/SalesForceCertificate/GetTestNames`, {
            params: {
                language: testLanguageGroup,
            },
        })
            .then((d) => {
                setTestNamesGroup(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [testLanguageGroup]);

    useEffect(() => {
        setLoading(true);
        api.get<Array<TestName>>(`/SalesForceCertificate/GetTestNames`, {
            params: {
                language: testLanguage,
            },
        })
            .then((d) => {
                setTestNamesSearch(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [testLanguage]);

    useEffect(() => {
        if (selectedTestNameVersion) {
            setLoading(true);
            api.get<Array<string>>(`/SalesForceCertificate/getCodes`, {
                params: {
                    testName: selectedTestNameVersion.label,
                },
            })
                .then((d) => {
                    setSelectedTestCodeVersion(defaultSelectOption);
                    setSelectedTestVersion(defaultSelectOption);
                    setTestCodesVersion(d.data);
                    setTestVersions([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedTestNameVersion]);

    useEffect(() => {
        if (selectedTestCodeVersion.value !== -1) {
            setLoading(true);
            api.get<Array<string>>(`/SalesForceCertificate/getVersions`, {
                params: {
                    testName: selectedTestNameVersion?.label ?? '',
                    testCode: selectedTestCodeVersion.value,
                },
            })
                .then((d) => {
                    setSelectedTestVersion(defaultSelectOption);
                    setTestVersions(d.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedTestCodeVersion]);

    const updateActiveVersion = () => {
        setDisableActiveVersion(true);
        setLoading(true);
        api.post('/SalesForceCertificate/updateActive', {
            testName: selectedTestNameVersion?.label ?? '',
            testCode: selectedTestCodeVersion.value !== -1 ? selectedTestCodeVersion.value : '',
            testVersions: selectedTestVersion.value !== -1 ? selectedTestVersion.value : '',
            isActiveVersion: isActive,
        })
            .then(
                () => {
                    alert.success('Активная версия изменена');
                },
                (e) => {
                    alert.error(e?.response?.data || 'Ошибка при изменении активной версии');
                }
            )
            .finally(() => {
                setDisableActiveVersion(false);
                handleSearch();
                setLoading(false);
            });
    };

    const updateTestArea = () => {
        setDisableTestArea(true);
        setLoading(true);
        api.post('/SalesForceCertificate/updateTestArea', {
            testName: selectedTestNameArea?.label ?? '',
            areasId: testArea,
            testLanguage: testLanguageArea,
        })
            .then(
                () => {
                    alert.success('Область применения изменена');
                },
                (e) => {
                    alert.error(e?.response?.data || 'Ошибка при изменении области применения');
                }
            )
            .finally(() => {
                setDisableTestArea(false);
                handleSearch();
                setLoading(false);
            });
    };

    const updateProductGroup = () => {
        setDisableTestGroup(true);
        setLoading(true);
        api.post('/SalesForceCertificate/updateProductGroups', {
            testName: selectedTestNameGroup?.label ?? '',
            groupsId: groupsId,
            testLanguage: testLanguageGroup,
        })
            .then(
                () => {
                    alert.success('Продуктовая группа изменена');
                },
                (e) => {
                    alert.error(e?.response?.data || 'Ошибка при изменении продуктовой группы');
                }
            )
            .finally(() => {
                setDisableTestGroup(false);
                handleSearch();
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!useFinishDate) {
            setMonthCount(undefined);
            setExpiredDate(undefined);
        }
        if (!useCountMonth) {
            setMonthCount(undefined);
        }
        if (useCountMonth) {
            setExpiredDate(undefined);
            setMonthCount(1);
        }
    }, [useFinishDate, useCountMonth]);

    useEffect(() => {
        setLoading(true);
        api.get<Array<TestName>>(`/SalesForceCertificate/GetTestNames`)
            .then((d) => {
                setTestNamesSearch(d.data);
                setTestNames(d.data);
                setTestNamesVersion(d.data);
                setTestNamesArea(d.data);
                setTestNamesDate(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const isValidActiveVersion = !selectedTestNameVersion;
    const isValidTestArea = !selectedTestNameArea;
    const isValidTestGroup = !selectedTestNameGroup;

    const changeDate = () => {
        if (!selectedTest) {
            alert.error('Тест не выбран');
            return;
        }

        if (confirm('Вы уверены?')) {
            setDisableChangeButton(true);
            setLoading(true);
            api.put(`/SalesForceCertificate/ChangeDate`, {
                testName: selectedTest?.label ?? '',
                finishDate: expiredDate,
                monthCount,
                language: testLanguageDate,
            })
                .then(
                    () => {
                        alert.success('Дата окончания действия сертификатов изменена');
                        handleSearch();
                    },
                    () => {
                        alert.error(
                            'Вы не можете изменить дату т.к. дата либо равна текущей дате сертификатов, либо меньше чем дата создания одного из сертификатов',
                            {
                                timeout: 20000,
                            }
                        );
                    }
                )
                .finally(() => {
                    setDisableChangeButton(false);
                    setLoading(false);
                });
        }
    };

    const getParamsWithoutPagination = (): GetCertificatesXls => ({
        testLanguage: testLanguage,
        testName: selectedTestFilter?.value === -1 ? undefined : selectedTestFilter?.label,
        type: certificateType || undefined,
    });

    const handleSearch = () => {
        setLoading(true);
        api.get<ResponsePagination<SfCertificate>>(`/SalesForceCertificate/Search`, { params: getParams() })
            .then((d) => {
                setCount(d.data.count);
                setData(d.data.items);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const { pagination } = useUrlQueryParamsForGrid({
        total: count,
        clearPaginationDeps: Object.values(getParamsWithoutPagination()),
        loadFn: handleSearch,
    });

    const getParams = (): GetCertificates => ({
        ...getParamsWithoutPagination(),
        page: pagination.page,
        size: pagination.size,
        email,
        testCode,
        company,
        certificateNumber,
        dateStart,
        dateEnd,
    });

    const downloadXls = () => {
        setLoading(true);
        DownloadFileService.download(`/SalesForceCertificate/ExportExcel`, {
            ...getParamsWithoutPagination(),
            page: 0,
            size: 0,
            email,
            testCode,
            company,
            dateStart,
            dateEnd,
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        testNames &&
        data && (
            <>
                {isLoading && <Loader isBlock />}
                <Container>
                    <Column columnSize="50">
                        <Title titleText="Исторические данные" />
                    </Column>
                    <Column columnSize="50" className="text-right">
                        <Button buttonClass="primary" handleClick={downloadXls}>
                            Excel
                        </Button>
                    </Column>
                    <Column columnSize="100">
                        <Container additionalClass="sf-container">
                            <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <SelectByEnum
                                        selectedId={testLanguageVersion}
                                        label="Язык теста"
                                        onChange={setTestLanguageVersion}
                                        enumOptions={MultilanguageLanguageEnum}
                                        hasEmpty
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="50" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <AutoCompliteForm
                                        placeholder="Не выбрано"
                                        label="Имя теста*"
                                        dataArr={testNamesVersion.map((r) => ({ id: r.id, name: r.name }))}
                                        name="testVersionId"
                                        id="testVersionId"
                                        onChange={(e) =>
                                            setSelectedTestNameVersion({ label: e?.name ?? '', value: e?.id ?? '' })
                                        }
                                        values={{
                                            id: selectedTestNameVersion?.value ?? '',
                                            name: selectedTestNameVersion?.label ?? '',
                                        }}
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="30" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <Label labelText="Код теста" labelFor="" />
                                    <Select
                                        options={testCodesVersion.map((m) => ({
                                            label: m,
                                            value: m,
                                        }))}
                                        onChange={([t]) => setSelectedTestCodeVersion(t)}
                                        values={[selectedTestCodeVersion]}
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="30" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <Label labelText="Версия теста" labelFor="" />
                                    <Select
                                        options={testVersions.map((m) => ({
                                            label: m,
                                            value: m,
                                        }))}
                                        onChange={([t]) => setSelectedTestVersion(t)}
                                        values={[selectedTestVersion]}
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <Checkbox
                                        isChecked={isActive}
                                        label="Актуальная версия"
                                        handleChange={() => setIsActive(!isActive)}
                                        id="isActive"
                                    />
                                </fieldset>
                            </Column>
                        </Container>
                        <Button
                            buttonClass="primary"
                            type="submit"
                            isDisabled={disableActiveVersion || isValidActiveVersion}
                            handleClick={updateActiveVersion}
                        >
                            Применить актуальную версию
                        </Button>
                    </Column>
                    <Column columnSize="100">
                        <hr />
                    </Column>
                    <Column columnSize="100">
                        <Container additionalClass="sf-container start">
                            <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <SelectByEnum
                                        selectedId={testLanguageArea}
                                        label="Язык теста"
                                        onChange={setTestLanguageArea}
                                        enumOptions={MultilanguageLanguageEnum}
                                        hasEmpty
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="50" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <AutoCompliteForm
                                        placeholder="Не выбрано"
                                        label="Имя теста*"
                                        dataArr={testNamesArea.map((r) => ({ id: r.id, name: r.name }))}
                                        name="testreaId"
                                        id="testreaId"
                                        onChange={(e) =>
                                            setSelectedTestNameArea({ label: e?.name ?? '', value: e?.id ?? '' })
                                        }
                                        values={{
                                            id: selectedTestNameArea?.value ?? '',
                                            name: selectedTestNameArea?.label ?? '',
                                        }}
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="30" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <AutoCompliteMany
                                        label="Область применения"
                                        getArrAsync={(s?: string) =>
                                            getAreas(0, 20, s).then((d) =>
                                                d.data.items.map((m) => ({ id: m.id, name: m.name }))
                                            )
                                        }
                                        values={[]}
                                        labelField="name"
                                        id="testAreaId"
                                        name="testAreaId"
                                        valueField="id"
                                        isMulty
                                        onChange={(e) => setTestArea(e?.map((m) => m.id) ?? [])}
                                    />
                                </fieldset>
                            </Column>
                        </Container>
                        <Button
                            buttonClass="primary"
                            type="submit"
                            isDisabled={disableTestArea || isValidTestArea}
                            handleClick={updateTestArea}
                        >
                            Применить область применения
                        </Button>
                    </Column>
                    <Column columnSize="100">
                        <hr />
                    </Column>
                    <Column columnSize="100">
                        <Container additionalClass="sf-container start">
                            <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <SelectByEnum
                                        selectedId={testLanguageGroup}
                                        label="Язык теста"
                                        onChange={setTestLanguageGroup}
                                        enumOptions={MultilanguageLanguageEnum}
                                        hasEmpty
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="50" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <AutoCompliteForm
                                        placeholder="Не выбрано"
                                        label="Имя теста*"
                                        dataArr={testNamesGroup.map((r) => ({ id: r.id, name: r.name }))}
                                        name="testreaId"
                                        id="testreaId"
                                        onChange={(e) =>
                                            setSelectedTestNameGroup({ label: e?.name ?? '', value: e?.id ?? '' })
                                        }
                                        values={{
                                            id: selectedTestNameGroup?.value ?? '',
                                            name: selectedTestNameGroup?.label ?? '',
                                        }}
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="30" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <AutoCompliteMany
                                        label="Продуктовая группа"
                                        getArrAsync={(s?: string) =>
                                            getGroups(0, 20, s).then((d) =>
                                                d.data.items.map((m) => ({ id: m.id, name: m.name }))
                                            )
                                        }
                                        values={[]}
                                        labelField="name"
                                        id="groupIdId"
                                        name="groupIdId"
                                        valueField="id"
                                        isMulty
                                        onChange={(e) => setGroupsId(e?.map((m) => m.id) ?? [])}
                                    />
                                </fieldset>
                            </Column>
                        </Container>
                        <Button
                            buttonClass="primary"
                            type="submit"
                            isDisabled={disableTestGroup || isValidTestGroup}
                            handleClick={updateProductGroup}
                        >
                            Применить продуктовую группу
                        </Button>
                    </Column>
                    <Column columnSize="100">
                        <hr />
                    </Column>
                    <Column columnSize="100">
                        <Container additionalClass="sf-container">
                            <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <SelectByEnum
                                        selectedId={testLanguageDate}
                                        label="Язык теста"
                                        onChange={setTestLanguageDate}
                                        enumOptions={MultilanguageLanguageEnum}
                                        hasEmpty
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="50" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <AutoCompliteForm
                                        placeholder="Не выбрано"
                                        label="Имя теста*"
                                        dataArr={testNamesDate.map((r) => ({ id: r.id, name: r.name }))}
                                        name="testFinishId"
                                        id="testFinishId"
                                        onChange={(e) => setSelectedTest({ label: e?.name ?? '', value: e?.id ?? '' })}
                                        values={{
                                            id: selectedTest?.value ?? '',
                                            name: selectedTest?.label ?? '',
                                        }}
                                    />
                                </fieldset>
                            </Column>
                            <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                <fieldset className="form-group">
                                    <Checkbox
                                        isChecked={useFinishDate}
                                        label="Использовать дату окончания действия сертификата"
                                        handleChange={() => setUseFinishDate(!useFinishDate)}
                                        id="finishDate"
                                    />
                                </fieldset>
                            </Column>
                            {useFinishDate && (
                                <>
                                    <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                        <fieldset className="form-group">
                                            <Checkbox
                                                isChecked={useCountMonth}
                                                label="Использовать количество месяцев"
                                                handleChange={() => setUseCountMonth(!useCountMonth)}
                                                id="monthCount"
                                            />
                                        </fieldset>
                                    </Column>
                                    {useCountMonth ? (
                                        <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                            <fieldset className="form-group">
                                                <TextInput
                                                    type="number"
                                                    value={`${monthCount}`}
                                                    label="Количество месяцев"
                                                    id="certificationMonthCount"
                                                    name="certificationMonthCount"
                                                    onChangeHandler={(v) => {
                                                        const newVal = parseInt(v.target.value.replace('-', ''));
                                                        setMonthCount(newVal || 0);
                                                    }}
                                                />
                                            </fieldset>
                                        </Column>
                                    ) : (
                                        <Column columnSize="20" className="p-right-space-3x top-space-10x">
                                            <div className="form-group">
                                                <DatePricker
                                                    useZeroTime
                                                    name="certificationFinishDate"
                                                    label="Окончание действия сертификата"
                                                    returnValue="end"
                                                    value={expiredDate}
                                                    defaultValue={
                                                        expiredDate || getDateZeroTimeZoneISO(new Date(), 'start')
                                                    }
                                                    onChange={(d) => setExpiredDate(d as string)}
                                                />
                                            </div>
                                        </Column>
                                    )}
                                </>
                            )}
                        </Container>
                        <Button
                            buttonClass="primary"
                            type="submit"
                            isDisabled={disableChangeButton || selectedTest?.value === -1}
                            handleClick={changeDate}
                        >
                            Применить дату окончания действия сертификата
                        </Button>
                    </Column>
                    <Column columnSize="100">
                        <hr />
                    </Column>
                    <Container additionalClass="container--items-end">
                        <Column columnSize="20" className="p-right-space-3x top-space-10x">
                            <fieldset className="form-group">
                                <SelectByEnum
                                    selectedId={testLanguage}
                                    label="Язык теста"
                                    onChange={setTestLanguage}
                                    enumOptions={MultilanguageLanguageEnum}
                                    hasEmpty
                                />
                            </fieldset>
                        </Column>
                        <Column columnSize="70" className="p-right-space-3x top-space-10x">
                            <fieldset className="form-group">
                                <AutoCompliteForm
                                    placeholder="Не выбрано"
                                    label="Имя теста*"
                                    dataArr={testNamesSearch.map((r) => ({ id: r.id, name: r.name }))}
                                    name="testSearchId"
                                    id="testSearchId"
                                    onChange={(e) =>
                                        setSelectedTestFilter({ label: e?.name ?? '', value: e?.id ?? '' })
                                    }
                                    values={{
                                        id: selectedTestFilter?.value ?? '',
                                        name: selectedTestFilter?.label ?? '',
                                    }}
                                />
                            </fieldset>
                        </Column>
                        <Column columnSize="25" className="p-right-space-3x top-space-10x">
                            <fieldset className="form-group">
                                <Label labelText="Тип сертификата" labelFor="" />
                                <SelectByEnum
                                    hasEmpty
                                    enumOptions={CertificationTypeFilter}
                                    selectedId={certificateType}
                                    onChange={setCertificateType}
                                />
                            </fieldset>
                        </Column>
                        <Column columnSize="25" className="p-right-space-3x top-space-10x">
                            <TextInput
                                id="userEmail"
                                label="Email"
                                value={email}
                                onChangeHandler={(e) => setEmail(e.target.value)}
                            />
                        </Column>
                        <Column columnSize="25" className="p-right-space-3x top-space-10x">
                            <TextInput
                                id="companyId"
                                label="Компания"
                                value={company}
                                onChangeHandler={(e) => setCompany(e.target.value)}
                            />
                        </Column>
                        <Column columnSize="25" className="p-right-space-3x top-space-10x">
                            <TextInput
                                id="testCode"
                                label="Код теста"
                                value={testCode}
                                onChangeHandler={(e) => setTestCode(e.target.value)}
                            />
                        </Column>
                        <Column columnSize="100" className="p-right-space-3x top-space-10x">
                            <TextInput
                                id="certificateNumber"
                                label="Номер сертификата"
                                value={certificateNumber}
                                onChangeHandler={(e) => setCertificateNumber(e.target.value)}
                            />
                        </Column>
                        <Column columnSize="20" className="p-right-space-3x">
                            <Label labelText="Дата начала" labelFor="" />
                            <DatePricker
                                name="dateStart"
                                value={dateStart}
                                returnValue="start"
                                onChange={(date) => setDateStart(date as string)}
                            />
                        </Column>
                        <Column columnSize="20" className="p-right-space-3x">
                            <Label labelText="Дата окончания" labelFor="" />
                            <DatePricker
                                name="dateEnd"
                                value={dateEnd}
                                returnValue="end"
                                onChange={(date) => setDateEnd(date as string)}
                            />
                        </Column>
                        <Column columnSize="20" className="bottom-space-3x">
                            <Button buttonClass="primary" handleClick={handleSearch}>
                                Поиск
                            </Button>
                        </Column>
                    </Container>
                    <Column columnSize="100" className="top-space-10x">
                        <SfGrid
                            update={handleSearch}
                            pagination={pagination}
                            data={data}
                            success={(message) => alert.success(message)}
                            error={(message) => alert.error(message)}
                            setLoading={setLoading}
                        />
                    </Column>
                </Container>
            </>
        )
    );
};

export default SfResults;
