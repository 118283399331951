import React from 'react';
import DOMPurify from 'dompurify';
import { useFormContext, Controller } from 'react-hook-form-new';

import { QuestionOption } from '../../../store';
import { ANSWERS_LETTERS } from 'pages/questions/constants';

type QuestionOptionProps = {
    options: QuestionOption[];
    disable: boolean;
};

export const QuestionMulti = (props: QuestionOptionProps) => {
    const { options, disable } = props;
    const { formState, control, getValues } = useFormContext();
    const { isSubmitting } = formState;

    return (
        <>
            {options.map((opt, index) => (
                <div key={opt.questionOptionId} className="question-item-wrap">
                    <label className="question-item">
                        <Controller
                            name={opt.questionOptionId.toString()}
                            control={control}
                            disabled={isSubmitting || (disable && !getValues(opt.questionOptionId.toString()))}
                            render={({ field: { onChange, ...props } }) => (
                                <input
                                    type={'checkbox'}
                                    className="question-item-input"
                                    {...props}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                            )}
                        />
                        <span className="question-item-value">{ANSWERS_LETTERS.split('')[index]}</span>
                        <span
                            className="question-item-text"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(opt.questionOptionText),
                            }}
                        />
                    </label>
                </div>
            ))}
        </>
    );
};
