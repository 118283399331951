import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Title, Button, Container, Link, Page, Loader, EmptyListWarning } from 'components';
import { useWindowWidth, sizes, useTheme, theme } from 'utils';
import { ShareButtons } from './ShareButtons';
import { ApplicationState } from 'store';
import { actions } from '../store/actions';
import { UserCertificatesState } from '../store/store';
import { useQuery } from 'hooks';

type CertificatePageProps = UserCertificatesState &
    typeof actions & {
        isStudentStudyCenter: boolean;
    };

const CertificatePage = (props: CertificatePageProps) => {
    const { certificate, getCertificate, clearCertificateState, isLoading, isStudentStudyCenter } = props;
    const { certificateLink, imageOfCertificateLink = '', pageOfCertificateLink } = certificate;

    const { id } = useParams<{ id: string }>();
    const isSf = useQuery().get('is_sf');

    const { push, replace } = useHistory();
    const { pathname } = useLocation();
    const [t] = useTranslation(['certificates']);
    const [isCertificateLoadError, setIsCertificateLoadError] = useState(false);
    const [isCertificateLoading, setIsCertificateLoading] = useState(true);

    const handleLoadCertificate = () => {
        setIsCertificateLoadError(false);
        setIsCertificateLoading(false);
    };

    const handleErrorLoadCertificate = () => {
        setIsCertificateLoadError(true);
        setIsCertificateLoading(false);
    };

    useTheme(theme.light);
    useTheme(theme.loaderOverSidebar);

    const width = useWindowWidth();

    useEffect(() => {
        if (id) {
            getCertificate(id, !!isSf);
        }

        return () => {
            clearCertificateState();
        };
    }, [clearCertificateState, getCertificate, id, isSf]);

    const handleGoToMenu = () => replace('/attempts-list');
    const handleGoToAllCertificates = () => push(pathname.replace(`/${id}`, ''));
    const isShowCertificateError = isCertificateLoadError && !isLoading && !isCertificateLoading;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Page additionalClass="page-content-center">
            <div className="expired-date">
                {certificate.expiredDate && (
                    <p>
                        {t('validUntil')}: {certificate.expiredDate}
                    </p>
                )}
                {certificate.isExpired ? (
                    <p>
                        {t('status')}: {t('expired')}
                    </p>
                ) : (
                    <p>
                        {t('status')}: {t('valid')}
                    </p>
                )}
            </div>
            <Container>
                {width <= sizes.md && (
                    <div className="mobile-title">
                        <Title titleText={t('certificate-page-title')} />
                    </div>
                )}
                <div className={`certificate  ${isCertificateLoading ? 'loading' : ''}`}>
                    <img
                        onLoad={handleLoadCertificate}
                        onError={handleErrorLoadCertificate}
                        id="certificate-image"
                        className={`certificate-image ${isShowCertificateError ? 'hide' : ''}`}
                        src={imageOfCertificateLink}
                        alt={t('certificate')}
                    />
                    {isShowCertificateError && <EmptyListWarning />}
                </div>
                <div className="certificate-actions">
                    {width > sizes.md && <Title titleText={t('certificate-page-title')} />}
                    <ShareButtons pageOfCertificateLink={pageOfCertificateLink} isDisabled={isCertificateLoading} />
                    <div className="action-link">
                        <Link linkHref={certificateLink} isDownload>
                            <button className="link" disabled={isCertificateLoading || !certificateLink}>
                                {t('download')}
                            </button>
                        </Link>
                    </div>
                    {isStudentStudyCenter ? (
                        <Button buttonClass="primary" handleClick={() => push('/')} isDisabled={isCertificateLoading}>
                            {t('translation:go-to-main')}
                        </Button>
                    ) : (
                        <>
                            <div className="action-link">
                                <button
                                    className="link"
                                    onClick={handleGoToAllCertificates}
                                    disabled={isCertificateLoading}
                                >
                                    {t('all-certificates')}
                                </button>
                            </div>

                            <Button
                                buttonClass="primary"
                                handleClick={handleGoToMenu}
                                isDisabled={isCertificateLoading}
                            >
                                {t('menu-button')}
                            </Button>
                        </>
                    )}
                </div>
            </Container>
        </Page>
    );
};

export default connect(
    (state: ApplicationState, ownProps: { isStudentStudyCenter?: boolean }) => ({
        ...state.userCertificates,
        isStudentStudyCenter: state.userAuthentication?.user?.isStudentStudyCenter || ownProps?.isStudentStudyCenter,
    }),
    actions
)(CertificatePage as () => JSX.Element);
