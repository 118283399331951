import api from '../api';
import { Buffer } from 'buffer';

interface FileApplicationTypes {
    excel: string;
}

const fileApplicationTypes: FileApplicationTypes = {
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
};
const fileFormat: FileApplicationTypes = {
    excel: 'xlsx',
};

export class DownloadFileService {
    static download = (
        url: string,
        params: Record<string, unknown> = {},
        fileName = 'result',
        type: keyof FileApplicationTypes = 'excel'
    ) =>
        api
            .get(url, {
                params,
                headers: {
                    'X-Timezone-Offset': new Date().getTimezoneOffset() * -1,
                    Pragma: 'no-cache',
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((resp) => resp.data)
            .then((data) => {
                if (!data) return;
                const blob = new Blob([Buffer.from(data, 'base64')], { type: fileApplicationTypes[type] });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.download = `${fileName}.${fileFormat[type]}`;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
}
