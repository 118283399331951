import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Attempt } from '../store/store';
import { Accordion } from 'components';
import { getAttemptResultMessage } from './Attempts';
import { mapSecondsToTimeString } from 'utils';

interface AttemptAccordionProps {
    title: string;
    attempt: Attempt;
    handleDetailsClick: () => void;
}

export const AttemptAccordion = (props: AttemptAccordionProps) => {
    const { title, attempt, handleDetailsClick } = props;
    const [t] = useTranslation(['attempts-list']);
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Accordion title={title} isExpanded={isExpanded} handleExpand={() => setIsExpanded(!isExpanded)}>
            <div className="accordion-flex-content">
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('date')}</div>
                    <div className="accordion-flex-content-item-content">{attempt.dateStart}</div>
                </div>
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('time-taken')}</div>
                    <div className="accordion-flex-content-item-content">
                        {mapSecondsToTimeString(+attempt.spentSeconds, t)}
                    </div>
                </div>
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('attempt')}</div>
                    <div className="accordion-flex-content-item-content">{attempt.attemptNo}</div>
                </div>
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('score')}</div>
                    <div className="accordion-flex-content-item-content">
                        {`${attempt.quantityPoints} / ${attempt.maxQuantityPoints}`}
                    </div>
                    <div className="accordion-flex-content-additional-item" onClick={handleDetailsClick}>
                        {t('details')}
                    </div>
                </div>
                <div className="accordion-flex-content-item">
                    <div className="accordion-flex-content-item-header">{t('result')}</div>
                    <div className="accordion-flex-content-item-content">{getAttemptResultMessage(attempt, t)}</div>
                    {!attempt.isFailure && attempt.certificateLink && (
                        <a
                            href={attempt.certificateLink}
                            target="_blank"
                            className="link accordion-flex-content-additional-item"
                            rel="noreferrer"
                        >
                            {t('get-certificate')}
                        </a>
                    )}
                </div>
            </div>
        </Accordion>
    );
};
