import api from 'api';
import { Admin, UpdateAccess } from './store';

import { AppThunkAction } from 'store';

export const SET_SUCCESS = 'superAdmin/SET_SUCCESS';
export const GET_ALL_ADMINS = 'superAdmin/GET_ALL_ADMINS';
export const SET_ERROR = 'superAdmin/SET_ERROR';
export const CLEAR_MESSAGE = 'superAdmin/CLEAR_MESSAGE';
export const SET_LOADING = 'superAdmin/SET_LOADING';
export const CLEAR_STORE = 'superAdmin/CLEAR_STORE';
export const UPDATE_ACCESS = 'superAdmin/UPDATE_ACCESS';

export interface UpdateAdminAccess {
    type: typeof UPDATE_ACCESS;
    payload: UpdateAccess;
}

export interface SetSuccess {
    type: typeof SET_SUCCESS;
    message: string;
}

export interface SetError {
    type: typeof SET_ERROR;
    message: string;
}

export interface GetAllAdmins {
    type: typeof GET_ALL_ADMINS;
    admins: Admin[];
}

export interface ClearMessage {
    type: typeof CLEAR_MESSAGE;
}

export interface SetLoading {
    type: typeof SET_LOADING;
}

export interface ClearStore {
    type: typeof CLEAR_STORE;
}

export type ActionTypes =
    | UpdateAdminAccess
    | GetAllAdmins
    | SetError
    | SetSuccess
    | ClearMessage
    | SetLoading
    | ClearStore;

export const actions = {
    clearMessage: () => ({
        type: CLEAR_MESSAGE,
    }),
    getAllAdmins: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        try {
            const admins = (await api.get<Admin[]>(`AdminControlling`)).data;
            dispatch({
                type: GET_ALL_ADMINS,
                admins,
            });
        } catch {
            dispatch({
                type: SET_ERROR,
                message: 'Ошибка',
            });
        }
    },
    updateAccess:
        (data: UpdateAccess): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await api.put<UpdateAccess>('AdminControlling/UpdateAccess', data);

                dispatch({
                    type: UPDATE_ACCESS,
                    payload: data,
                });
            } catch {
                dispatch({
                    type: SET_ERROR,
                    message: 'Ошибка',
                });
            }
        },
    clearStore: () => ({ type: CLEAR_STORE }),
};
