import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { Container, Column, Title, Paragraph, Button, Page, ModalWindow } from 'components';
import { ApplicationState } from 'store';
import { actions } from 'pages/info/store/actions';
import Captcha from '../../../components/Captcha/Captcha';
import SelectCountry from '../../../components/SelectCountry/SelectCountry';
import { UserInfoState, AddUserData } from '../store/store';
import { actions as userAuthenticationActions, UserAuthenticationState } from 'authentication/UserAuthenticationStore';
import { CHANGE_PASSWORD_PATH, DELETE_ACCOUNT_PATH, MAX_USER_COMPANY_LENGTH } from 'global-constants';
import { TextFieldInput } from './TextFieldInput';
import { withLoading } from 'utils';

type EnterNameProps = UserInfoState & UserAuthenticationState & typeof userAuthenticationActions & typeof actions;

const EnterName = (props: EnterNameProps) => {
    const { userNameSuccess, userNameError, updateUserName, loadUserWithoutSetLoading, user, clearMessages } = props;
    const alert = useAlert();
    const [t] = useTranslation(['info', 'validation']);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { register, handleSubmit, errors, formState } = useForm<AddUserData>({
        defaultValues: {
            userName: user?.userName ?? '',
            lastName: user?.lastName ?? '',
            middleName: user?.middleName ?? '',
            company: user?.company ?? '',
        },
    });
    const { isSubmitting } = formState;
    const captchaRef = useRef<ReCAPTCHA>(null);

    const onSubmit = handleSubmit(async (values: AddUserData) => {
        const captchaCode = await captchaRef?.current?.executeAsync();
        await updateUserName({ ...values, captchaCode: captchaCode || '', countryId });
        loadUserWithoutSetLoading();
    });

    const changeModalVisible = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const handleDeleteAccount = () => {
        window.location.href = DELETE_ACCOUNT_PATH;
    };

    useEffect(() => {
        if (userNameError && alert) {
            alert.error(userNameError);
        }
        return () => {
            clearMessages();
        };
    }, [userNameError, alert, clearMessages]);

    const [countryId, setCountryId] = useState(user?.country.id || 0);

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <Column columnSize="100" className="block-center code-column">
                    <Title titleText={t('title')} />
                    {userNameSuccess && <Paragraph additionalClass="success">{t('changes-saved')}</Paragraph>}
                    <Paragraph additionalClass="secondary">{t('message')}</Paragraph>
                    <Captcha ref={captchaRef} />
                    <form onSubmit={onSubmit} autoComplete="off" className="info-form">
                        <TextFieldInput
                            name="userName"
                            label={t('first-name')}
                            isRequired
                            register={register}
                            errors={errors}
                        />
                        <TextFieldInput
                            name="lastName"
                            label={t('last-name')}
                            isRequired
                            register={register}
                            errors={errors}
                        />
                        <TextFieldInput
                            name="middleName"
                            label={t('middle-name')}
                            isRequired={false}
                            register={register}
                            errors={errors}
                        />

                        <TextFieldInput
                            name="company"
                            label={t('company-or-university') + '*'}
                            isRequired
                            maxLength={MAX_USER_COMPANY_LENGTH}
                            register={register}
                            errors={errors}
                        />

                        <fieldset className="form-group top-space-3x">
                            <label className="form-label">{t('country')}</label>
                            <SelectCountry selectedId={countryId} onChange={setCountryId} />
                        </fieldset>

                        <div className="info-buttons d-flex justify-content-space-between">
                            <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                                {t('save-button')}
                            </Button>
                            <a className="btn btn-secondary btn-pSize-big" href={CHANGE_PASSWORD_PATH}>
                                {t('change-password-button')}
                            </a>
                        </div>
                    </form>
                    <button onClick={changeModalVisible} className="link" disabled={isSubmitting}>
                        {t('delete-account')}
                    </button>
                </Column>
                <ModalWindow
                    isOpen={modalIsOpen}
                    onCloseClick={changeModalVisible}
                    modalMessage={t('delete-account-pop-up-title')}
                    onConfirmAction={handleDeleteAccount}
                    confirmButtonText={t('confirm-delete')}
                    cancelButtonText={t('cancel')}
                />
            </Container>
        </Page>
    );
};

export default connect(
    ({ userInfo, userAuthentication }: ApplicationState) => ({
        ...userInfo,
        ...userAuthentication,
        isLoading: userInfo.isLoading || userAuthentication?.isLoading,
    }),
    { ...actions, ...userAuthenticationActions }
)(withLoading(EnterName) as () => JSX.Element);
