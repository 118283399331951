import React from 'react';
import { RoutingConfig } from '../models/routing-config';
import TrainingGroupTCAdminPage from '../pages/study-center-admin/training-group-tc-admin/TrainingGroupTCAdminPage';
import StudyCentersUsersTCAdminPage from '../pages/study-center-admin/study-centers-users-tc-admin/StudyCentersUsersTCAdminPage';
import UpdateAdminStudyCenter from '../pages/study-center-admin/update-admin-study-center/UpdateAdminStudyCenter';

export const tcAdminRouting: RoutingConfig[] = [
    {
        menuItemHref: 'study-center-groups',
        menuItemIcon: 'menu-group',
        menuItemText: 'menu-titles:training-groups',
        component: <TrainingGroupTCAdminPage />,
    },
    {
        menuItemHref: `study-center-users`,
        menuItemIcon: 'menu-students',
        menuItemText: 'menu-titles:study-center-students',
        component: <StudyCentersUsersTCAdminPage />,
    },
    {
        id: 'profile',
        menuItemHref: `profile`,
        menuItemIcon: 'eye-icon',
        menuItemText: 'menu-titles:profile',
        component: <UpdateAdminStudyCenter />,
    },
];
