import React from 'react';
import { AlertCustomOptionsWithType } from 'react-alert';

import { Icon } from '../Icon';

interface AlertTemplateBasicProps {
    message: React.ReactNode;
    options: AlertCustomOptionsWithType;
}

export const AlertTemplateBasic = (props: AlertTemplateBasicProps) => {
    const { options, message } = props;

    const classes = `alert alert-${options.type}`;
    const iconName = `icon-${options.type}`;

    return (
        <div className={classes}>
            <Icon iconName={iconName} />
            <span>{message}</span>
        </div>
    );
};
