import { Action, Reducer } from 'redux';
import { StudyCenter } from '../../../models/study-center';
import { StudyCenterUser } from '../../../models/study-center-users/study-center-user';

import {
    ActionTypes,
    ADD_STUDY_CENTER,
    CLEAR_EDITABLE_STUDY_CENTER,
    CLEAR_MESSAGE_STUDY_CENTER,
    DELETE_STUDY_GROUP,
    ERROR_STUDY_CENTER,
    GET_STUDY_CENTER,
    GET_STUDY_CENTER_STUDENTS,
    LOAD_STUDY_CENTERS,
    SET_LOADING,
    SUCCESS_STUDY_CENTER,
    UPDATE_STUDY_CENTER,
} from './actions';

export interface StudyCenterListItem {
    id: number;
    name: string;
}

export interface Option {
    label: string;
    value: string;
}

export interface Test {
    id: number;
    title: string;
}

export interface StudyCenterState {
    studyCenters: StudyCenterListItem[];
    isLoading: boolean;
    editableStudyCenter: StudyCenter;
    editableStudyCenterStudents: StudyCenterUser[];
    studyCenterSuccess: string;
    studyCenterError: string;
}

const initialValue: Readonly<StudyCenterState> = {
    studyCenters: [],
    editableStudyCenter: {} as StudyCenter,
    editableStudyCenterStudents: [],
    isLoading: false,
    studyCenterSuccess: '',
    studyCenterError: '',
};

export const reducer: Reducer<StudyCenterState> = (
    state: StudyCenterState = initialValue,
    incomingAction: Action
): StudyCenterState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case LOAD_STUDY_CENTERS:
            return {
                ...state,
                studyCenters: action.studyCenters,
                isLoading: false,
            };
        case GET_STUDY_CENTER:
            return {
                ...state,
                editableStudyCenter: {
                    ...action.studyCenter,
                    studyGroups: action.studyGroups,
                },
                isLoading: false,
            };
        case ADD_STUDY_CENTER:
            return {
                ...state,
                isLoading: false,
                studyCenterSuccess: 'Учебный центр добавлен',
            };
        case DELETE_STUDY_GROUP:
            return {
                ...state,
                editableStudyCenter: {
                    ...state.editableStudyCenter,
                    studyGroups: state.editableStudyCenter?.studyGroups
                        ? state.editableStudyCenter?.studyGroups.filter((x) => x.id !== action.studyGroupId)
                        : [],
                },
                isLoading: false,
            };
        case UPDATE_STUDY_CENTER:
            return {
                ...state,
                studyCenterSuccess: 'Учебный центр обновлен',
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_STUDY_CENTER_STUDENTS: {
            return {
                ...state,
                editableStudyCenterStudents: action.students,
            };
        }
        case CLEAR_EDITABLE_STUDY_CENTER: {
            return {
                ...state,
                editableStudyCenter: initialValue.editableStudyCenter,
                editableStudyCenterStudents: initialValue.editableStudyCenterStudents,
            };
        }
        case SUCCESS_STUDY_CENTER:
            return {
                ...state,
                studyCenterSuccess: action.studyCenterSuccess,
            };
        case ERROR_STUDY_CENTER:
            return {
                ...state,
                studyCenterError: action.studyCenterError,
                isLoading: false,
            };
        case CLEAR_MESSAGE_STUDY_CENTER:
            return {
                ...state,
                studyCenterSuccess: '',
                studyCenterError: '',
            };
        default:
            return state;
    }
};
