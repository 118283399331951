import React, { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';

interface LinkProps {
    additionalClass?: string;
    linkTitle?: string;
    linkHref: string;
    target?: HTMLAttributeAnchorTarget;
}

export const InternalLink = (props: PropsWithChildren<LinkProps>) => {
    const { additionalClass, linkTitle, linkHref, target, children } = props;
    const classes = `link ${additionalClass ? additionalClass : ''}`;

    return (
        <Link to={linkHref} className={classes} title={linkTitle} target={target || '_self'}>
            {children}
        </Link>
    );
};
