import React from 'react';

import { Logout } from 'components';
import { BaseUser } from '../../models/base-user';

export interface PersonProps {
    user: BaseUser;
    handleClick?: () => void;
    handleLogout?: () => void;
}

export const Person = (props: PersonProps) => {
    const { user, handleLogout, handleClick } = props;
    const nameFirstLetter: string = user.name?.[0]?.toUpperCase() ?? '';

    return (
        <div className="person">
            <div className="person-link" title="person" onClick={handleClick}>
                <span className="person-link-name">{user.name}</span>
                <span className="person-link-logo">{nameFirstLetter}</span>
            </div>
            <Logout handleLogout={handleLogout} role={user.role} />
        </div>
    );
};
