import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'components';
import { PrivacyStatementProps } from './PrivacyStatementProps';
import { DATA_PROCESS_HASH, MARKETING_PROCESS_HASH, SC_DATA_PROCESS_HASH } from '../../../global-constants';

const PrivacyStatementRu: FC<PrivacyStatementProps> = (props) => {
    const [t] = useTranslation(['privacy-statement', 'translation']);
    const { scrollInto } = props;

    return (
        <>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-privacy')}</h2>
                <div className="privacy-content">
                    <h2 className="privacy-title">{t('subtitle-privacy-controller')}</h2>
                    <p>{t('text-privacy-controller')}</p>
                    <p>
                        {t('text-privacy-controller-email')}{' '}
                        <Link linkHref={t('support-path')} target="_blank">
                            {t('support-path')}
                        </Link>
                        .
                    </p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('general-information')}</h2>
                <div className="privacy-content">
                    <p>{t('pages-restriction')}</p>
                    <p>{t('user-identification')}</p>
                    <p>{t('sensitive-data')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-legal-basis')}</h2>
                <div className="privacy-content">
                    <p>{t('legal-basis-prelist')}</p>
                    <ul className="privacy-content-list">
                        <li>{t('legal-basis-list-data')}</li>
                        <li>{t('legal-basis-list-consent')}</li>
                        <li>{t('legal-basis-list-specific')}</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-transfer')}</h2>
                <div className="privacy-content">
                    <p>{t('transfer-text-country')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-how-long-keep')}</h2>
                <div className="privacy-content">
                    <p>{t('how-long-keep-text')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('cookies-usage')}</h2>
                <div className="privacy-content">
                    <p>{t('cookies-delete')}</p>
                    <p>{t('cookies-lighthouse')}</p>
                    <p>{t('cookies-params')}</p>
                    <p>
                        {t('cookies-list')}
                        <Link linkHref={t('cookies-url')} target="_blank">
                            {t('cookies-list-link')}
                        </Link>
                    </p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-rights-data')}</h2>
                <div className="privacy-content">
                    <ul className="privacy-content-list">
                        <li>
                            <span className="bold space-right">{t('rights-law')}</span>
                        </li>
                        <li>
                            <span className="bold space-right">{t('rights-your-data')}</span>
                        </li>
                    </ul>
                    <p>
                        {t('rights-post-message')}
                        <Link linkHref={t('rights-post-message-link')} target="_blank">
                            {t('rights-post-message-link')}
                        </Link>
                    </p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 className="privacy-title">{t('title-complains')}</h2>
                <div className="privacy-content">
                    <p>
                        {t('complaints-text-contact')}{' '}
                        <Link linkHref={t('support-path')} target="_blank">
                            {t('complaints-text-link')}
                        </Link>
                    </p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 ref={(ref) => scrollInto(ref, DATA_PROCESS_HASH)} className="privacy-title">
                    {t('title-process')}
                </h2>
                <div className="privacy-content">
                    <p>{t('process-prelist')}</p>
                    <table className="privacy-table">
                        <thead>
                            <tr>
                                <th>{t('process-table-header-category')}</th>
                                <th>{t('process-table-header-goal')}</th>
                                <th>{t('process-table-header-time')}</th>
                                <th>{t('process-table-header-acceptance')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ul className="privacy-content-list">
                                        <li>{t('process-table-body-name')}</li>
                                        <li>{t('process-table-body-country')}</li>
                                        <li>{t('process-table-body-company')}</li>
                                        <li>{t('process-table-body-email-only')}</li>
                                    </ul>
                                </td>
                                <td>
                                    <p>{t('process-table-body-access')}</p>
                                </td>
                                <td>
                                    <p>{t('process-table-body-years')}</p>
                                </td>
                                <td>
                                    <p>{t('process-table-body-personal-data1')}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>{t('process-aftertable-recall')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 ref={(ref) => scrollInto(ref, MARKETING_PROCESS_HASH)} className="privacy-title">
                    {t('title-process')}
                </h2>
                <div className="privacy-content">
                    <p>{t('process-prelist')}</p>
                    <table className="privacy-table">
                        <thead>
                            <tr>
                                <th>{t('process-table-header-category')}</th>
                                <th>{t('process-table-header-goal')}</th>
                                <th>{t('process-table-header-time')}</th>
                                <th>{t('process-table-header-acceptance')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ul className="privacy-content-list">
                                        <li>{t('process-table-body-name-only')}</li>
                                        <li>{t('process-table-body-email-only')}</li>
                                    </ul>
                                </td>
                                <td>
                                    <p>{t('process-table-body-goal')}</p>
                                </td>
                                <td>
                                    <p>{t('process-table-body-years')}</p>
                                </td>
                                <td>
                                    <p>
                                        {t('process-table-body-personal-data')} <sup>1</sup>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <sup>1</sup>
                        {t('process-aftertable-remark')}
                    </p>
                    <p>{t('process-aftertable-recall')}</p>
                </div>
            </div>
            <div className="privacy-item">
                <h2 ref={(ref) => scrollInto(ref, SC_DATA_PROCESS_HASH)} className="privacy-title">
                    {t('title-process-sc')}
                </h2>
                <div className="privacy-content">
                    <p>{t('process-prelist')}</p>
                    <table className="privacy-table">
                        <thead>
                            <tr>
                                <th>{t('process-table-header-category')}</th>
                                <th>{t('process-table-header-goal')}</th>
                                <th>{t('process-table-header-time')}</th>
                                <th>{t('process-table-header-acceptance')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ul className="privacy-content-list">
                                        <li>{t('process-table-body-name')}</li>
                                        <li>{t('process-table-body-country')}</li>
                                        <li>{t('process-table-body-company-sc')}</li>
                                        <li>{t('process-table-body-email-only')}</li>
                                    </ul>
                                </td>
                                <td>
                                    <p>{t('process-table-body-goal-sc')}</p>
                                </td>
                                <td>
                                    <p>{t('process-table-body-years')}</p>
                                </td>
                                <td>
                                    <p>{t('process-table-body-personal-data-sc')}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>{t('process-aftertable-recall')}</p>
                </div>
            </div>
        </>
    );
};

export default PrivacyStatementRu;
