import React, { FC } from 'react';
import { PaginationProps } from 'components/Pagination/Pagination';
import { EmptyListWarning, GridFixed, Icon, Row } from '../../components';
import { dateTimeFormat } from '../../constants/date-format';
import moment from 'moment';
import { AdditionalCertificate } from './models/additional-certificate';
import { AdditionalCertificateLevel } from './models/additional-certificate-level';

interface Props {
    data: AdditionalCertificate[];
    pagination: PaginationProps;
}

export const AdditionalCertificateGrid: FC<Props> = ({ data, pagination }) => {
    const getGridData = (): Row[] =>
        data.map((item) => ({
            ...item,
            level: AdditionalCertificateLevel[item.level],
            creationDate: moment(item.creationDate).format(dateTimeFormat),
            expirationDate: !item?.expirationDate ? '' : moment(item.expirationDate).format(dateTimeFormat),
            isActive: {
                additionalComponent: (
                    <div className="study-center-test-statistics-grid__status-icon">
                        {item.isActive ? (
                            <Icon key="check-mark-2" iconName="check-mark-2" />
                        ) : (
                            <Icon key="close" iconName="close" />
                        )}
                    </div>
                ),
            },
            id: {
                additionalComponent: (
                    <a
                        className="link"
                        href={`/api/GetCertificate/${item.id}/${item.userId}/certificate.pdf`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Скачать
                    </a>
                ),
            },
        }));
    return data.length ? (
        <GridFixed columns={getColumns()} rows={getGridData()} pagination={pagination} />
    ) : (
        <EmptyListWarning />
    );
};

const getColumns = () => ({
    level: {
        value: 'Уровень',
        columnSize: '200',
    },
    certificateName: {
        value: 'Название сертификата',
        columnSize: '250',
    },
    creationDate: {
        value: 'Дата создания',
        columnSize: '200',
    },
    expirationDate: {
        value: 'Дата окончания',
        columnSize: '200',
    },
    userName: {
        value: 'Имя',
        columnSize: '200',
    },
    userLastName: {
        value: 'Фамилия',
        columnSize: '200',
    },
    userMiddleName: {
        value: 'Отчество',
        columnSize: '200',
    },
    email: {
        value: 'Email',
        columnSize: '200',
    },
    areaName: {
        value: 'Область',
        columnSize: '300',
    },
    isActive: {
        value: 'Активный',
        columnSize: '100',
    },
    id: {
        value: 'Скачать',
        columnSize: '100',
    },
});
