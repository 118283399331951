import React, { MouseEvent } from 'react';

import { Link, Icon, InternalLink } from 'components';
import { PATH_TO_CATEGORY_ICON_DEFAULT } from 'global-constants';

export interface CategoryListItemProps {
    id: number;
    name: string;
    iconFileLink: string;
    linkToQuestions: string;
    handleClickDelete(e: MouseEvent<HTMLElement>, id: number): void;
    handleClickEdit(e: MouseEvent<HTMLElement>, id: number): void;
    quantityQuestions: number;
}

export const CategoryGridItem = (props: CategoryListItemProps) => {
    const { id, name, iconFileLink, linkToQuestions, handleClickDelete, handleClickEdit, quantityQuestions } = props;

    return (
        <li className="category-grid-item">
            <div className="d-flex align-items-center text-left">
                <div className="icon-wrapper">
                    <img
                        src={iconFileLink || PATH_TO_CATEGORY_ICON_DEFAULT}
                        className="category-grid-image"
                        alt="category-icon"
                    />
                </div>
                <InternalLink linkHref={linkToQuestions}>{name}</InternalLink>
            </div>
            <div className="d-flex align-items-center text-right">
                <span className="category-grid-item-quantity">{quantityQuestions}</span>
                <Link
                    linkHref="#"
                    linkTitle="Изменить категорию"
                    handleClick={(e: MouseEvent<HTMLElement>) => {
                        handleClickEdit(e, id);
                    }}
                >
                    <Icon iconName="edit" />
                </Link>
                <Link
                    linkHref="#"
                    linkTitle="Удалить категорию"
                    handleClick={(e: MouseEvent<HTMLElement>) => {
                        handleClickDelete(e, id);
                    }}
                >
                    <Icon iconName="delete" />
                </Link>
            </div>
        </li>
    );
};
