import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { StudyCenterUser } from '../models/study-center-users/study-center-user';
import { GetUsersParams } from '../models/user/get-user-params';
import { DownloadFileService } from './download-file.service';

export class AdminUserControlService {
    static deleteUser = (userId: string) => api.delete(`AdminControlling/DeleteUser/${userId}`);
    static deleteStudentStudyCenter = (userId: string) =>
        api.delete(`AdminControlling/DeleteStudentStudyCenter/${userId}`);

    static editUserExternalId = (userId: string, isPartner: boolean) =>
        api.put(`AdminControlling/UpdateUserIsPartner`, {
            userId,
            isPartner,
        });

    static getStudyCenterUsers = (params: GetUsersParams) =>
        api.get<ResponsePagination<StudyCenterUser>>(`AdminControlling/GetAllStudentsFromStudyCenters`, {
            params,
        });

    static getStudyCenterUsersXls = (str?: string) =>
        DownloadFileService.download('AdminControlling/studentsStudyCentersXls', { search: str });
}
