const stepPixels = 15;
const stepDelay = 15;

export const expandingAnimation = (ref: React.MutableRefObject<null>, isExpanded: boolean) => {
    const element = ref.current as unknown as HTMLElement;
    if (isExpanded) {
        const childHeight = element.firstElementChild?.clientHeight || 0;
        let newHeight = 0;
        const changeHeight = () => {
            if (newHeight >= childHeight) {
                clearInterval(intervalId);
            } else {
                newHeight += stepPixels;
                element.style.height = `${newHeight}px`;
            }
        };
        const intervalId = setInterval(changeHeight, stepDelay);
    } else {
        let newHeight = element.clientHeight;
        const changeHeight = () => {
            if (newHeight <= 0) {
                clearInterval(intervalId);
            } else {
                newHeight -= stepPixels;
                element.style.height = `${newHeight}px`;
            }
        };
        const intervalId = setInterval(changeHeight, stepDelay);
    }
};
