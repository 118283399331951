import React, { PropsWithChildren, useState, useEffect } from 'react';

import { sleep } from 'utils';

enum SubmitButtonState {
    Default = 'Default',
    Loading = 'Loading',
}

const TIME_FOR_RESULT_STATE = 500;

export const SubmitButton = (
    props: PropsWithChildren<{
        isSubmitting: boolean;
        isDisabled?: boolean;
    }>
) => {
    const { children, isSubmitting, isDisabled } = props;
    const [state, setState] = useState(SubmitButtonState.Default);

    useEffect(() => {
        if (isSubmitting) {
            setState(SubmitButtonState.Loading);
        } else if (state === SubmitButtonState.Loading) {
            const changeButtonState = async () => {
                await sleep(TIME_FOR_RESULT_STATE);
                setState(SubmitButtonState.Default);
            };

            changeButtonState();
        }
    }, [isSubmitting, state]);

    const submitButtonClasses =
        state === SubmitButtonState.Default ? 'submit-button-default btn' : 'submit-button-loading';

    return (
        <div className="submit-button">
            <button
                className={submitButtonClasses}
                type="submit"
                disabled={state === SubmitButtonState.Loading || isDisabled}
            >
                {state === SubmitButtonState.Default && children}
            </button>
        </div>
    );
};
