import { StudyGroupListItem } from '../../../models/study-group/study-group-list-item';
import StudyCenterService from '../../../services/study-center.service';

import { StudyCenterListItem } from './store';
import { AppThunkAction } from '../../../store';
import { StudyCenterFormData } from '../study-center-form/StudyCenterForm';
import { StudyCenter } from '../../../models/study-center';
import { StudyCenterUser } from '../../../models/study-center-users/study-center-user';

export const LOAD_STUDY_CENTERS = 'study-centers/LOAD_STUDY_CENTERS';
export const GET_STUDY_CENTER = 'study-centers/GET_STUDY_CENTER';
export const GET_STUDY_CENTER_STUDENTS = 'study-centers/GET_STUDY_CENTER_STUDENTS';
export const CLEAR_EDITABLE_STUDY_CENTER = 'study-centers/CLEAR_EDITABLE_STUDY_CENTER';
export const ADD_STUDY_CENTER = 'study-centers/ADD_STUDY_CENTER';
export const UPDATE_STUDY_CENTER = 'study-centers/UPDATE_STUDY_CENTER';
export const DELETE_STUDY_GROUP = 'study-centers/DELETE_STUDY_GROUP';
export const SET_LOADING = 'study-centers/SET_LOADING';
export const SUCCESS_STUDY_CENTER = 'study-centers/SUCCESS_STUDY_CENTER';
export const ERROR_STUDY_CENTER = 'study-centers/ERROR_STUDY_CENTER';
export const CLEAR_MESSAGE_STUDY_CENTER = 'study-centers/CLEAR_MESSAGE_STUDY_CENTER';

interface LoadStudyCenters {
    type: typeof LOAD_STUDY_CENTERS;
    studyCenters: StudyCenterListItem[];
}

interface GetStudyCenter {
    type: typeof GET_STUDY_CENTER;
    studyCenter: StudyCenter;
    studyGroups: StudyGroupListItem[];
}

interface GetStudyCenterStudents {
    type: typeof GET_STUDY_CENTER_STUDENTS;
    students: StudyCenterUser[];
}

interface ClearEditableStudyCenter {
    type: typeof CLEAR_EDITABLE_STUDY_CENTER;
}

interface AddStudyCenter {
    type: typeof ADD_STUDY_CENTER;
    studyCenter: StudyCenter;
}

interface UpdateStudyCenter {
    type: typeof UPDATE_STUDY_CENTER;
    studyCenter: StudyCenter;
}

interface SetLoading {
    type: typeof SET_LOADING;
}

interface StudyCenterSuccess {
    type: typeof SUCCESS_STUDY_CENTER;
    studyCenterSuccess: string;
}

interface StudyCenterError {
    type: typeof ERROR_STUDY_CENTER;
    studyCenterError: string;
}

interface DeleteStudyGroup {
    type: typeof DELETE_STUDY_GROUP;
    studyGroupId: number;
}

interface ClearMessageStudyCenter {
    type: typeof CLEAR_MESSAGE_STUDY_CENTER;
}

export type ActionTypes =
    | LoadStudyCenters
    | GetStudyCenter
    | GetStudyCenterStudents
    | AddStudyCenter
    | SetLoading
    | ClearMessageStudyCenter
    | ClearEditableStudyCenter
    | StudyCenterError
    | StudyCenterSuccess
    | UpdateStudyCenter
    | DeleteStudyGroup;

export const actions = {
    loadStudyCenters:
        (query?: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const studyCenters = (await StudyCenterService.getList(query)).data;

                dispatch({
                    type: LOAD_STUDY_CENTERS,
                    studyCenters,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_CENTER,
                    studyCenterError: 'Ошибка',
                });
            }
        },
    getStudyCenter:
        (id: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const studyCenter = (await StudyCenterService.getById(id)).data;
                const studyGroups = (await StudyCenterService.getStudyGroupsById(id)).data;

                dispatch({
                    type: GET_STUDY_CENTER,
                    studyCenter,
                    studyGroups,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_CENTER,
                    studyCenterError: 'Ошибка',
                });
            }
        },
    getStudyCenterStudents:
        (studyCenterId: number, email: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                const students = (await StudyCenterService.getStudents({ studyCenterId, email })).data;

                dispatch({
                    type: GET_STUDY_CENTER_STUDENTS,
                    students: students.items,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_CENTER,
                    studyCenterError: 'Ошибка',
                });
            }
        },
    addStudyCenter:
        (studyCenter: StudyCenterFormData): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const newStudyCenter = (await StudyCenterService.add(studyCenter)).data;

                dispatch({
                    type: ADD_STUDY_CENTER,
                    studyCenter: newStudyCenter,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_CENTER,
                    studyCenterError: 'Ошибка',
                });
            }
        },
    updateStudyCenter:
        (studyCenter: StudyCenterFormData & { id: number }): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const newStudyCenter = (await StudyCenterService.update(studyCenter)).data;

                dispatch({
                    studyCenter: newStudyCenter,
                    type: UPDATE_STUDY_CENTER,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_CENTER,
                    studyCenterError: 'Ошибка',
                });
            }
        },
    deleteStudyGroup:
        (studyGroupId: number): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                await StudyCenterService.deleteStudyGroupById(studyGroupId);

                dispatch({
                    type: DELETE_STUDY_GROUP,
                    studyGroupId,
                });
            } catch {
                dispatch({
                    type: ERROR_STUDY_CENTER,
                    studyCenterError: 'Ошибка',
                });
            }
        },
    clearMessage: () => ({
        type: CLEAR_MESSAGE_STUDY_CENTER,
    }),
    clearEditableStudyCenter: () => ({
        type: CLEAR_EDITABLE_STUDY_CENTER,
    }),
};
