import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Column, Container, ModalWindow, StudyGroupGrid, TextInput, Title } from '../../components';
import StudyCentersUsersGrid from '../../components/StudyCentersUsersGrid/StudyCentersUsersGrid';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect/useDebouncedEffect';

import { StudyCenterState } from './store/store';
import { actions } from './store/actions';
import { ApplicationState } from '../../store';
import { StudyCenterForm, StudyCenterFormData } from './study-center-form/StudyCenterForm';
import { connectComponent, withLoading } from 'utils';
import Pagination from 'components/Pagination/Pagination';

type StudyCenterFormProps = StudyCenterState & typeof actions;

const StudyCenterFormEdit = (props: StudyCenterFormProps) => {
    const {
        updateStudyCenter,
        getStudyCenter,
        studyCenterSuccess,
        studyCenterError,
        clearMessage,
        editableStudyCenter,
        editableStudyCenterStudents,
        deleteStudyGroup,
        getStudyCenterStudents,
        clearEditableStudyCenter,
    } = props;
    const alert = useAlert();
    const { studyGroups } = editableStudyCenter;
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    const [studyGroupId, setStudyGroupId] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [email, setEmail] = useState('');

    const [page, setPage] = useState(0);

    const size = 100;

    useDebouncedEffect(
        () => {
            getStudyCenterStudents(+id, email);
            if (email) {
                setPage(0);
            }
        },
        [id, email],
        400
    );

    useEffect(() => {
        clearEditableStudyCenter();
        getStudyCenter(+id);

        return () => {
            clearEditableStudyCenter();
        };
    }, [getStudyCenter, id]);

    const goBack = useCallback(() => {
        push(pathname.replace(`/edit/${id}`, ''));
    }, [push, pathname, id]);

    useEffect(() => {
        if (studyCenterSuccess) {
            alert.success(studyCenterSuccess);
            goBack();
        } else if (studyCenterError) {
            alert.error(studyCenterError);
        }

        clearMessage();
    }, [alert, clearMessage, studyCenterError, goBack, studyCenterSuccess]);

    const handleSubmit = async (studyCenter: StudyCenterFormData) => {
        if (id) {
            await updateStudyCenter({ ...studyCenter, id: +id });
        }
    };

    const handleClickEdit = (id: number) => {
        push(`/admin/study-groups/edit/${id}`);
    };

    const changeModalState = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const handleClickDelete = (id: number) => {
        setStudyGroupId(id);
        changeModalState();
    };

    const handelDeleteStudyGroup = (id: number) => {
        deleteStudyGroup(id);
        changeModalState();
    };

    const showReason = (str: string) => {
        alert.info(str, { timeout: 10000 });
    };

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText="Редактировать учебный центр" />
            </Column>
            <Column columnSize="100">
                <StudyCenterForm {...props} onSubmit={handleSubmit} goBack={goBack} />
            </Column>

            {!!editableStudyCenterStudents?.length && (
                <>
                    <Column columnSize="100" className="top-space-10x">
                        <Title titleText="Клиенты учебного центра" />
                    </Column>
                    <Column columnSize="50">
                        <TextInput
                            id="search-field"
                            label="Email"
                            value={email}
                            onChangeHandler={(e) => setEmail(e.target.value)}
                        />
                    </Column>
                    <Column columnSize="100" className="top-space-10x">
                        <StudyCentersUsersGrid
                            showReason={showReason}
                            users={editableStudyCenterStudents.filter(
                                (_, i) => i >= page * size && i < page * size + size
                            )}
                        />
                    </Column>
                    <Column columnSize="100" className="top-space-10x">
                        <Pagination
                            size={size}
                            page={page}
                            total={editableStudyCenterStudents.length}
                            handleChange={(p) => setPage(p)}
                        />
                    </Column>
                </>
            )}

            {!!studyGroups?.length && (
                <Column columnSize="100" className="top-space-10x">
                    <Title titleText="Группы учебного центра" />

                    <StudyGroupGrid
                        studyGroups={studyGroups}
                        handleClickEdit={handleClickEdit}
                        handleClickDelete={handleClickDelete}
                    />

                    <ModalWindow
                        isOpen={modalIsOpen}
                        onCloseClick={changeModalState}
                        modalMessage={'Вы действительно хотите удалить учебную группу из учебного центра?'}
                        confirmButtonText="Удалить"
                        cancelButtonText="Отмена"
                        size="big"
                        onConfirmAction={() => handelDeleteStudyGroup(studyGroupId)}
                    />
                </Column>
            )}
        </Container>
    );
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.studyCenters,
    actions,
    component: withLoading(StudyCenterFormEdit),
});
