import React, { FC } from 'react';
// import { RouterTabs } from '../../components/RouterTabs/RouterTabs';
// import { usersRoutes } from '../../constants/users-routes';
// import { getRouteTabWithPath } from '../../utils/get-route-tab-with-path';
import Error404 from '../error-404/Error404';
import { useTitle } from '../../utils';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UsersList from './UsersList';
import UsersSFList from './UsersSFList';

const UsersPage: FC = () => {
    const { path } = useRouteMatch();
    useTitle('Пользователи');

    return (
        <>
            {/*hidden migrated users tab*/}
            {/*<RouterTabs tabs={getRouteTabWithPath(usersRoutes, path)} />*/}

            <Switch>
                <Route path={`${path}/history-sf`}>
                    <UsersSFList />
                </Route>

                <Route exact path={`${path}`}>
                    <UsersList />
                </Route>

                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default UsersPage;
