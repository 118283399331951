import { AdminUserControlService } from 'services/admin-user-control.service';
import { User } from '../../../authentication/UserAuthenticationStore';
import { ResponsePagination } from '../../../models/response-pagination';
import { GetUsersParams } from '../../../models/user/get-user-params';
import { UserHistorySf } from '../../../models/user/user-history-sf';
import { UsersService } from '../../../services/users.service';
import { AppThunkAction } from '../../../store';

export const SET_USERS = 'users/SET_USERS';
export const SET_USERS_HISTORY_SF = 'users/SET_USERS_HISTORY_SF';
export const SET_LOADING = 'users/SET_LOADING';
export const SET_LOADING_EXCEL = 'users/SET_LOADING_EXCEL';

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading: boolean;
}

export interface SetLoadingExcel {
    type: typeof SET_LOADING_EXCEL;
    isLoadingExcel: boolean;
}

export interface SetUsers {
    type: typeof SET_USERS;
    users: ResponsePagination<User>;
}

export interface SetUsersHistorySF {
    type: typeof SET_USERS_HISTORY_SF;
    users: ResponsePagination<UserHistorySf>;
}

export type ActionTypes = SetLoading | SetLoadingExcel | SetUsers | SetUsersHistorySF;

export const actions = {
    getUsers:
        (params: GetUsersParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const users = (await UsersService.getUsers(params)).data;

                dispatch({
                    type: SET_USERS,
                    users,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    changeIsPartner:
        (userId: string, isPartner: boolean): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                await AdminUserControlService.editUserExternalId(userId, isPartner);

                const { users } = getState();
                const u = users.users;

                const index = u.items.findIndex((r) => r.id === userId);

                if (index >= 0) {
                    u.items[index].isPartner = isPartner;
                }

                dispatch({
                    type: SET_USERS,
                    users: {
                        count: u.count,
                        items: [...u.items],
                    },
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    deleteUser:
        (index: number, user: ResponsePagination<User>): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            const id = user.items[index].id;
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });
                await UsersService.deleteUser(id);
                user.items.splice(index, 1);
                dispatch({
                    type: SET_USERS,
                    users: {
                        count: --user.count,
                        items: [...user.items],
                    },
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getExcel:
        (search?: string): AppThunkAction<ActionTypes> =>
        async () => {
            await UsersService.getExcel(search);
        },
    getUsersHistorySF:
        (params: GetUsersParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                    isLoading: true,
                });

                const users = (await UsersService.getUsersHistorySF(params)).data;

                dispatch({
                    type: SET_USERS_HISTORY_SF,
                    users,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getExcelHistorySF:
        (params: GetUsersParams): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            dispatch({
                type: SET_LOADING_EXCEL,
                isLoadingExcel: true,
            });

            await UsersService.getExcelHistorySF(params);

            dispatch({
                type: SET_LOADING_EXCEL,
                isLoadingExcel: false,
            });
        },
};
