import { AppThunkAction } from 'store';
import api from 'api';
import { UserCertificateListItem, UserCertificate, GetCertificates } from './store';
import { AllSertificateService } from 'services/all-sertificate-service';

export const GET_ALL_CERTIFICATES = 'certificates/GET_ALL_CERTIFICATES';
export const GET_CERTIFICATE = 'certificates/GET_CERTIFICATE';
export const SET_LOADING = 'certificates/SET_LOADING';
export const SET_ERROR_CERTIFICATES = 'certificates/SET_ERROR_CERTIFICATES';
export const SET_SUCCESS_CERTIFICATES = 'certificates/SET_SUCCESS_CERTIFICATES';
export const CLEAR_CERTIFICATE_STATE = 'certificates/CLEAR_CERTIFICATE_STATE';

export interface GetAllCertificates {
    type: typeof GET_ALL_CERTIFICATES;
    allCertificates: UserCertificateListItem[];
    allCertificatesSf: UserCertificateListItem[];
}

export interface CetCertificate {
    type: typeof GET_CERTIFICATE;
    certificate: UserCertificate;
}

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface SetErrorTest {
    type: typeof SET_ERROR_CERTIFICATES;
    certificatesError: string;
}

export interface SetSuccessTest {
    type: typeof SET_SUCCESS_CERTIFICATES;
    certificatesSuccess: string;
}

export interface ClearCertificateState {
    type: typeof CLEAR_CERTIFICATE_STATE;
}

export type ActionTypes =
    | GetAllCertificates
    | CetCertificate
    | SetErrorTest
    | SetSuccessTest
    | SetLoading
    | ClearCertificateState;

export const actions = {
    getAllCertificates:
        (val: GetCertificates): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const [sfCertificates, commonCertificates] = await Promise.all([
                    AllSertificateService.getSfCertificates(val),
                    AllSertificateService.getCertificates(val),
                ]);

                dispatch({
                    type: GET_ALL_CERTIFICATES,
                    allCertificates: commonCertificates.data,
                    allCertificatesSf: sfCertificates.data,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    getCertificate:
        (passingTestId: string, sf: boolean): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const certificate = sf
                    ? (await AllSertificateService.getSfCertificate(passingTestId)).data
                    : (await AllSertificateService.getCertificate(passingTestId)).data;

                dispatch({
                    type: GET_CERTIFICATE,
                    certificate,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    setCertificateName:
        (certificateId: number, userNameOnCertificate: string): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                await api.put('UserCertificate', {
                    certificateId,
                    userNameOnCertificate,
                });
            } catch {
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    clearCertificateState: () => ({ type: CLEAR_CERTIFICATE_STATE }),
};
