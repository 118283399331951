import { Action, Reducer } from 'redux';
import { UserState } from '../../../enums/user-state';

import {
    ActionTypes,
    CLEAR_MESSAGE,
    CLEAR_STORE,
    GET_ALL_ADMINS,
    SET_ERROR,
    SET_LOADING,
    SET_SUCCESS,
    UPDATE_ACCESS,
} from './actions';

export interface Admin {
    id: string;
    name: string;
    approved: UserState;
    email: string;
    lastName: string;
    middleName: string;
    creationDate: Date;
}

export interface UpdateAccess {
    id: string;
    approved: UserState;
}

export interface SuperAdminState {
    superAdminSuccess: string;
    superAdminError: string;
    admins: Admin[];
    isLoading: boolean;
}

const initialState: Readonly<SuperAdminState> = {
    superAdminSuccess: '',
    superAdminError: '',
    admins: [],
    isLoading: true,
};

export const reducer: Reducer<SuperAdminState> = (
    state: SuperAdminState = initialState,
    incomingAction: Action
): SuperAdminState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case UPDATE_ACCESS:
            return {
                ...state,
                admins: state.admins.map((admin) =>
                    admin.id === action.payload.id
                        ? {
                              ...admin,
                              approved: action.payload.approved,
                          }
                        : { ...admin }
                ),
                isLoading: false,
                superAdminSuccess: 'Данные обновлены',
            };
        case SET_SUCCESS:
            return {
                ...state,
                superAdminSuccess: action.message,
                isLoading: false,
            };
        case SET_ERROR:
            return {
                ...state,
                superAdminError: 'Error',
                isLoading: false,
            };
        case GET_ALL_ADMINS:
            return {
                ...state,
                admins: action.admins,
                isLoading: false,
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                superAdminSuccess: '',
                superAdminError: '',
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};
