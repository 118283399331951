import { GridItemProps } from 'components';
import { ColumnSortProps, initColumnProps } from './column.sort.type';

export type testSort = Exclude<keyof GridItemProps, 'id' | 'linkHref' | 'handleClickDelete' | 'handleClickEdit'>;
export type testSortArr = { [index in testSort]: ColumnSortProps };

export const initTestSortArr: testSortArr = {
    name: initColumnProps,
    time: initColumnProps,
    questionCount: initColumnProps,
    testArea: initColumnProps,
    certificationMonthCount: initColumnProps,
    certificationFinishDate: initColumnProps,
    testLanguage: initColumnProps,
    isVersionActive: initColumnProps,
    creationDate: initColumnProps,
    editDate: initColumnProps,
    certificationDirection: initColumnProps,
    testType: initColumnProps,
    productGroup: initColumnProps,
};
