import React, { FC } from 'react';

import { Button, Container, Icon, Page } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { theme, useTheme } from 'utils';
import { layoutRoutingUrls } from '../../constants/layout-routing-urls';

const PageNotFound: FC = () => {
    const { push } = useHistory();
    const { path } = useRouteMatch();
    const [t] = useTranslation('translation');
    useTheme(theme.pageNotFound);

    const goToMainPage = () => {
        const route = Object.values(layoutRoutingUrls).find((item) => ~path.indexOf(item));

        push(route || layoutRoutingUrls.user);
    };

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <div className="page-not-found-block">
                    <div className="page-not-found-svg">
                        <Icon iconName="not-found-svg" />
                    </div>
                    <h1 className="page-not-found-header">
                        404 <span>{t('page-not-found')}</span>
                    </h1>
                    <Button buttonClass="primary" handleClick={() => goToMainPage()}>
                        {t('go-to-main')}
                    </Button>
                </div>
            </Container>
        </Page>
    );
};

export default PageNotFound;
