import React, { FC } from 'react';

interface LoaderProps {
    isBlock?: boolean;
}

export const Loader: FC<LoaderProps> = ({ isBlock }) => (
    <div className={'loader-container' + (isBlock ? 'loader-container--block' : '')}>
        <div className="loader-background" />
        <div className="spinner" />
    </div>
);
