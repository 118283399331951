import { CertificationEnum } from '../enums/certificationEnum';

export const certificationNames: { [p: string]: string } = {
    [CertificationEnum[CertificationEnum.CyberSecurity]]: 'Cybersecurity',
    [CertificationEnum[CertificationEnum.Professional]]: 'Certified Professional',
    [CertificationEnum[CertificationEnum.SystemEngineer]]: 'Certified System Engineer',
    [CertificationEnum[CertificationEnum.SalesSpecialist]]: 'Sales Specialist',
    [CertificationEnum[CertificationEnum.PartnerOnBoardingAccomplishment]]: 'Partner Onboarding Accomplishment',
    [CertificationEnum[CertificationEnum.SolutionSpecialist]]: 'Solution Specialist',
    [CertificationEnum[CertificationEnum.CertifiedSalesEngineer]]: 'Certified Sales Engineer',
    [CertificationEnum[CertificationEnum.AccomplishmentSales]]: 'Accomplishment Sales',
    [CertificationEnum[CertificationEnum.AccomplishmentTechnical]]: 'Accomplishment Technical',
};
