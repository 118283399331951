import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { actions } from './store/actions';
import { TestsState } from './store/store';
import { ApplicationState } from 'store';
import { connectComponent, withLoading } from 'utils';
import { TestForm } from './test-form/TestForm';

type TestForAddProps = TestsState & typeof actions;

const TestFormAdd = (props: TestForAddProps) => {
    const { addTest, testSuccess, testError, clearMessage } = props;
    const { push } = useHistory();
    const { pathname } = useLocation();
    const alert = useAlert();

    const goBack = useCallback(() => {
        push(pathname.replace('/add', ''));
    }, [pathname, push]);

    useEffect(() => {
        if (testSuccess) {
            alert.success(testSuccess);
            goBack();
        } else if (testError) {
            alert.error(testError, { timeout: 10000 });
        }

        clearMessage();
    }, [testSuccess, testError, alert, clearMessage, goBack]);

    return <TestForm {...props} title="Создать тест" onSubmit={addTest} goBack={goBack} buttonSubmitTitle="Создать" />;
};

export default connectComponent({
    mapStateToProps: (state: ApplicationState) => state.tests,
    actions,
    component: withLoading(TestFormAdd),
});
