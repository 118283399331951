import React from 'react';
import { useTranslation } from 'react-i18next';

import EnterName from './enter-name/EnterName';
import { useTitle } from 'utils';

const Info = () => {
    const [t] = useTranslation(['info']);
    useTitle(t('page-title'));
    return <EnterName />;
};

export default Info;
